import { Component, OnInit, OnDestroy } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';

import { OfferingService } from '../../shared/services/offering.service';
import { LineService } from '../../shared/services/line.service';

import { FlexmodalComponent } from '../../shared/components/flexmodal/flexmodal.component';
import { API_ENDPOINT } from '../../app.api';
import { Filter } from '../../shared/model/filter.model';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-offerings',
  templateUrl: './offerings.component.html',
  styleUrls: ['./offerings.component.css']
})
export class OfferingsComponent implements OnInit {


  myTable
  subtitle: string = ""
  itemsList
  filters: Array<Filter> = [{ key: "status", value: "A" }]
  subs: Array<Subscription> = []

  formFields = [
    { key: "id_offering", label: "_id", value: "", type: "text", visible: false },
    { key: "name", label: "Name", value: "", type: "text", visible: true },
    { key: "shortname", label: "Short name", value: "", type: "text", visible: true },
    { key: "id_line", label: "Line", value: "", type: "select", visible: true, dataFrom: { endpoint: 'line', id: 'id_line', label: 'name' } },
    { key: "status", label: "Status", value: "A", type: "AI", visible: true },
  ]

  filterList = [
    { key: "name", label: "Name", value: "", type: "text", visible: true },
    { key: "shortname", label: "Short Name", value: "", type: "text", visible: true },
    { key: "id_line", label: "Line", value: "", type: "select", visible: true, dataFrom: { endpoint: 'line', id: 'id_line', label: 'name' } },
    { key: "status", label: "Status", value: "A", type: "radio", visible: true, dataFrom: [{ label: "Ativo", value: "A" }, { label: "Inativo", value: "I" }] },
  ]

  endpoint = `${API_ENDPOINT}offering`

  constructor(private modalService: NzModalService,
    private notification: NzNotificationService,
    private mainListService: OfferingService,
    private editService: OfferingService,
    private lineService: LineService,
    private gta: GoogleAnalyticsService) { }

  ngOnInit() {
    //this.gta.eventEmitter("add_to_cart", "shop", "cart", "click", 10);
    //this.gta.eventEmitter("teste_um_dois_tres", "shop", "cart", "click", 10);
    this.getData()
  }

  getData() {

    //console.log(this.filters)
    this.subs.push(
      this.mainListService.get(this.filters)
        .subscribe(
          data => {
            this.itemsList = data;
            this.myTable = {
              options: { title: 'Offerings' },
              itemList: {
                fields: [
                  { key: "id_offering", label: "ID", alias: "ID", format: "", visible: true },
                  { key: "name", label: "Name", alias: "Name", format: "", visible: true },
                  { key: "shortname", label: "Shortname", alias: "Shortname", format: "", visible: true },
                  { key: "linname", label: "Line", alias: "Line", format: "", visible: true },
                  { key: "status", label: "Status", alias: "Status", format: "AI", visible: true },
                ],
                results: data,//.slice(0, data.length-1),
                buttons: [
                  { key: "edit", label: "", hint: "Edit", icon: "fas fa-edit" },
                  { key: "delete", label: "", hint: "Delete", icon: "far fa-trash-alt" }
                ]
              },
            }
          },
          error => {
            //console.log('deu erro')
            //this.gta.eventEmitter("add_to_cart", "shop", "cart", "click", 10);
            let errorMsg = "Loading Query => " + error.error.message;
            this.notification.error("Error", errorMsg);
            this.gta.eventEmitter("loading_error", "broken_experience", "listing_table", "[offerings] " + error.error.message, 3);

          }
        ))


  }



  AddOne(id) {
    let title
    if (id != 0) {
      title = "Editing Offering"
    } else {
      title = "New Offering"
    }

    let modalWidth = 500;

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: FlexmodalComponent,
      nzWidth: modalWidth,
      nzComponentParams: {
        _id: id,
        formFields: this.formFields,
        endpoint: this.endpoint
      },
    });

    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => {
      
      this.getData()
    });

  }


  /* START - PARTE DO PADRÃO */
  _count = 0
  selectFieldsToggle: Boolean = false;
  showFilters: Boolean = false;
  exportData: Array<any> = []
  fields: Array<any> = []
  visibleFields: Array<any> = [];


  mostraToggler($eshowFiltersvent) {
    this.FilterToggle();
  }

  FilterToggle() {
    this.showFilters = !this.showFilters;
  }

  FieldsToggle() {
    this.selectFieldsToggle = !this.selectFieldsToggle;
    this._count = this._count + 1;
  }

  mostraEvento($event) {
    if ($event.event == "edit") {
      this.AddOne($event.data.id_offering);
    } else if ($event.event == "delete") {
      this.Delete($event.data.id_offering)
    }
  }

  aplicaFiltros($event) {
    this.filters = $event.event;
    this.getData();
  }
  /* FINISH - PARTE DO PADRÃO */
  Delete(id) {
    this.subs.push(
      this.editService.delete(id)
        .subscribe(
          data => {
            this.notification.success("Success", "deleted successfully");
            this.getData();
            this.gta.eventEmitter("updating_succes", "good_experience", "deleting", "[offerings]", 1);
          },
          error => {
            this.notification.error("Error", error.error.message);
            this.gta.eventEmitter("saving_error", "broken_experience", "deleting", "[offerings] " + error.error.message, 3);

          }
        ));
  }

  ngOnDestroy() {
    this.subs.map(s => s.unsubscribe);
  }

}
