import { Component, OnInit, OnDestroy } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
import { CustomersectorService } from '../../shared/services/customersector.service';
import { FlexmodalComponent } from '../../shared/components/flexmodal/flexmodal.component';
import { API_ENDPOINT } from '../../app.api';
import { Filter } from '../../shared/model/filter.model';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-customer-sectors',
  templateUrl: './customer-sectors.component.html',
  styleUrls: ['./customer-sectors.component.css']
})
export class CustomerSectorsComponent implements OnInit {

  myTable
  itemsList

  subtitle: string = ""
  filters: Array<Filter> = [{ key: 'orderBy', value: "name" }, { key: 'status', value: "A" }]


  subs: Array<Subscription> = [];
  _count = 0
  selectFieldsToggle: Boolean = false;
  showFilters: Boolean = false;
  exportData: Array<any> = []
  fields: Array<any> = []
  visibleFields: Array<any> = [];

  formFields = [
    { key: "id_customer_sector", label: "_id", value: "", type: "text", visible: false },
    { key: "name", label: "Name", value: "", type: "text", visible: true },
    { key: "status", label: "Status", value: "A", type: "AI", visible: true },
  ]

  endpoint = `${API_ENDPOINT}customersector`

  filterList = [
    { key: "name", label: "Name", value: "", type: "text", visible: true },
    { key: "status", label: "Status", value: "A", type: "radio", visible: true, dataFrom: [{ label: "Ativo", value: "A" }, { label: "Inativo", value: "I" }] },
  ]

  constructor(private mainListService: CustomersectorService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private editService: CustomersectorService,
    private gta: GoogleAnalyticsService  ) { }

  ngOnInit() {

    this.getData();
  }

  AddOne(id) {
    let title
    if (id != 0) {
      title = "Editing Sector"
    } else {
      title = "New Sector"
    }

    let modalWidth = 500;
    //if (this.modalWidth) {
    //   modalWidth = this.config.modalWidth;
    //}


    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: FlexmodalComponent,
      nzWidth: modalWidth,
      nzComponentParams: {
        _id: id,
        formFields: this.formFields,
        endpoint: this.endpoint
      },
    });

    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => { this.getData() });

  }


  getData() {
    this.subs.push(
      this.mainListService.get(this.filters)
        .subscribe(
           data => {
          this.myTable = {
            options: { title: 'Sector' },
            itemList: {
              fields: [
                { key: "id_customer_sector", label: "ID", alias: "ID", format: "", visible: true },
                { key: "name", label: "Name", alias: "Name", format: "", visible: true },
                { key: "status", label: "Status", alias: "Status", format: "AI", visible: true },
              ],
              results: data,//.slice(0, data.length-1),
              buttons: [
                { key: "edit", label: "", hint: "Edit", icon: "fas fa-edit" },
                { key: "delete", label: "", hint: "Delete", icon: "far fa-trash-alt" }
              ]
            },
          }
          }, error => {
            let errorMsg = "Loading Query => " + error.error.message;
            this.notification.error("Error", errorMsg);
            this.gta.eventEmitter("loading_error", "broken_experience", "listing_table", "[customer-sectors] " + error.error.message, 3);
          }));
  }




  mostraEvento($event) {
    if ($event.event == "edit") {
      this.AddOne($event.data.id_customer_sector);
    } else if ($event.event == "delete") {
      this.Delete($event.data.id_customer_sector)
    }
  }

  /* START - PARTE DO PADRÃO */

  mostraToggler($eshowFiltersvent) {
    this.FilterToggle();
  }

  FilterToggle() {
    this.showFilters = !this.showFilters;
  }

  FieldsToggle() {
    this.selectFieldsToggle = !this.selectFieldsToggle;
    this._count = this._count + 1;
  }


  aplicaFiltros($event) {
    this.filters = $event.event;
    this.getData();
  }

  /* FINISH - PARTE DO PADRÃO */


  Delete(id) {
    this.subs.push(
      this.editService.delete(id)
        .subscribe(
          data => {
            this.notification.success("Success", "deleted successfully");
            this.gta.eventEmitter("updating_succes", "good_experience", "deleting", "[customer-sectors]", 1);
            this.getData();
          },
          error => {
            this.notification.error("Error", error.error.message);
            this.gta.eventEmitter("saving_error", "broken_experience", "deleting", "[customer-sectors] " + error.error.message, 3);
          }
        ))

  }

  ngOnDestroy() {
    this.subs.map(s => s.unsubscribe);
  }

}
