import { NgModule, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
   loginForm: FormGroup;

  sub1: Subscription;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    mensagem = '';

  constructor(private auth : AuthenticationService, 
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private gta: GoogleAnalyticsService) {

    //console.log("[login component constructor]");
  }

  ngOnInit() {
    this.loginForm  =  this.formBuilder.group({
        login: ['', Validators.required],
        password: ['', Validators.required]
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.loginForm.controls; }

 
  submitForm() {
    //this.auth.login(this.user);
    //console.log(this.loginForm.value);
    this.submitted = true;
    if (this.loginForm.invalid) {
            this.mensagem = 'invalid';
            return;
        }
    
    this.sub1 = this.auth.login(this.loginForm.controls['login'].value, this.loginForm.controls['password'].value )
    .pipe(first())
            .subscribe(
                data => {
                    //this.router.navigate([this.returnUrl]);
                    //console.log(data)
                    this.gta.eventEmitter("new_login", "success", "", this.loginForm.controls['login'].value, 0);
                    this.router.navigateByUrl('/');
                    this.mensagem = 'found you, navigating ...';
                },
              error => {
                    this.gta.eventEmitter("new_login", "error", "", this.loginForm.controls['login'].value, 0);
                    this.error = error;
                    this.loading = false;
                    this.mensagem = error.error.message;
                });
    //this.router.navigateByUrl('/');
  }


  
  
}

