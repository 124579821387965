import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-pre',
  templateUrl: './list-pre.component.html',
  styleUrls: ['./list-pre.component.css']
})
export class ListPreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
