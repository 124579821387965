import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd';
import {Subscription} from 'rxjs';
import {SubprojectService} from '../../../services/subproject.service';
import {SubprojectContractService} from '../../../services/subproject-contract.service';
import {SubContract} from '../../../model/subcontract.model';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-new-contract',
  templateUrl: './new-contract.component.html',
  styleUrls: ['./new-contract.component.css']
})
export class NewContractComponent implements OnInit {
  
  @Input() subprojectId
  @Input() subprojectEdit
  @Input() contractId


  editSubscription  : Subscription;
  subprojectList

  dateFormat = 'yyyy-MM-dd';
  salvando : boolean = false;

  formData

  constructor(private modal: NzModalRef,		
              private fb: FormBuilder,
              private notification: NzNotificationService,
              private editService : SubprojectContractService,
              private subprojectService : SubprojectService
              ) { 
        this.formData = this.fb.group({
          id_subproject_contract : [null],
          id_subproject : [this.subprojectId, [Validators.required]],
          name : [null, [Validators.required]],
          subname : [null],
          comment : [null],
          startdate : [null, [Validators.required]],
          enddate : [null, [Validators.required]],
          tax : [null, [Validators.required]],
          quantity : [null, [Validators.required]],
          status : [null, [Validators.required]]
        });

  }

  ngOnInit() {
  	
    this.formData.controls['id_subproject'].setValue(this.subprojectId);
    this.formData.controls['status'].setValue(true);

    if (this.subprojectEdit) {
       this.getSubProjects();
    }
    
    if (this.contractId != 0) {
        this.getSelectedId(this.contractId);   
    }
    
  }

  getSelectedId(id) {
        this.editSubscription = this.editService.getById(id)
        .subscribe((data : any) => {
              this.fullFillForm(data[0]);
        });
  }

  getSubProjects() {
      this.subprojectService.getAll() 
        .subscribe((data : any) => { 
            this.subprojectList = data;
            console.log(data);
        })
  }

  fullFillForm(obj : SubContract) {
        
        if (obj) {
            
            this.formData.controls['id_subproject_contract'].setValue(obj.id_subproject_contract);
            this.formData.controls['id_subproject'].setValue(obj.id_subproject);
            this.formData.controls['name'].setValue(obj.name);
            this.formData.controls['subname'].setValue(obj.subname);
            this.formData.controls['comment'].setValue(obj.comment);
            
            let startdate = obj.startdate
            startdate = startdate.toString()
			      this.formData.controls['startdate'].setValue(startdate);

			      let enddate = obj.enddate
            enddate = enddate.toString()
            this.formData.controls['enddate'].setValue(obj.enddate);
            
            this.formData.controls['quantity'].setValue(obj.quantity);
            this.formData.controls['tax'].setValue(obj.tax);

            if (obj.status == 'A') {
               this.formData.controls['status'].setValue(true);
            } else {
               this.formData.controls['status'].setValue(false);
            }

            return true;
        } else {
            return false;
        }
  }

   SubmitForm() {

        if (this.formData.invalid) {
            console.log(this.formData)
            this.notification.blank("Error", "Fill required data before saving");
            return;
        } else {
          this.salvando = true;
        }

        let obj = new SubContract;

        
        obj.id_subproject = this.formData.controls['id_subproject'].value;
        obj.id_subproject_contract = this.formData.controls['id_subproject_contract'].value;
        obj.name  = this.formData.controls['name'].value;
        obj.subname  = this.formData.controls['subname'].value;
        obj.comment  = this.formData.controls['comment'].value;
        obj.startdate  = this.formData.controls['startdate'].value;
        obj.enddate  = this.formData.controls['enddate'].value;
        obj.tax  = this.formData.controls['tax'].value;
        obj.quantity  = this.formData.controls['quantity'].value;

        if (this.formData.controls['status'].value === true) {
           obj.status = 'A';
        } else {
           obj.status = 'I';
        }

        // manda para o serviço
        if (!obj.id_subproject_contract) {
             console.log('inserindo form data: ');
             console.log(obj);
             this.editService.insert(obj)
             .subscribe((data : any) => {
               if (data.result === "Success") {
                  this.notification.blank("Success", "inserted successfully");
               } else {
                  this.notification.blank("Error", "error inserting");
               }
               this.salvando = false;
               this.destroyModal()
             });
        // se estiver fazendo update        
        } else {
            this.editService.update(obj.id_subproject_contract, obj)
            .subscribe((data : any) => {
              if (data.result === "Success") {
                  this.notification.blank("Success", "updated successfully");
               } else {
                  this.notification.blank("Error", "error updating");
               }
               this.salvando = false;
               this.destroyModal()   
            });
        } //this.SendNotification(
    }


  /////////////////////////////
  /// UTILIDADES
  /////////////////////////////
  destroyModal(): void {
    this.modal.destroy({ data: 'this the result data' });
  }

  DataParaString(o) {
    let year = o.getFullYear();
    let month = Number(o.getMonth())+1;
    let newMonth = String(month)

    if (month < 10) {
         newMonth = "0" + String(month)
    }  

    let valor = year + newMonth;
    return valor
  }

  StringParaData(o : String) : Date {

    if (o.length > 0) {
       let newYear = o.substr(0, 4)
       //substr(0,3)
       let newMonth = o.substr(4,2)

       let valor = newMonth +'/01/' + newYear;

       console.log(valor)
       return new Date(valor);  
    } else {
       return new Date();  
    }
    
  }




}
