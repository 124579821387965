import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-pre',
  templateUrl: './report-pre.component.html',
  styleUrls: ['./report-pre.component.css']
})
export class ReportPreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
