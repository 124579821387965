import { Injectable } from '@angular/core';
import { GenericalService } from './generical.service';
import {API_ENDPOINT} from '../../app.api';

@Injectable({
  providedIn: 'root'
})
export class CustomersectorService {

  constructor(private gServ : GenericalService) { }

  get(filtros) {
    let url = `${API_ENDPOINT}/customersector`;
    return this.gServ.getFiltered(url, filtros);
  }

  getAll() {
  	let url = `${API_ENDPOINT}/customersector`;
  	return this.gServ.get(url);
  }

  getById(id) {
  	let url = `${API_ENDPOINT}/customersector/${id}`;
  	return this.gServ.get(url);
  }

  insert(obj) {
    let url = `${API_ENDPOINT}/customersector`;
     return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
    let url = `${API_ENDPOINT}/customersector/${id}`;
    return this.gServ.putclean(url, obj);
  }

  delete(id) {
     let url = `${API_ENDPOINT}/customersector/${id}`;
     return this.gServ.delete(url);
  }

}
