import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {Filter} from '../../../shared/model/filter.model';
import {SubprojectResultsService} from '../../services/subproject-results.service';
import {PortfolioFilterService} from '../../services/portfolio-filter.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { TableService } from '../../../shared/services/table.service';
import {Router} from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { NgChartjsService } from 'ng-chartjs';
import { BaseChartDirective } from 'ng2-charts';
import {Subscription} from 'rxjs';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';

@Component({
  selector: 'app-subproject-results',
  templateUrl: './subproject-results.component.html',
  styleUrls: ['./subproject-results.component.css']
})
export class SubprojectResultsComponent implements OnInit {
  
  ordersList
  displayData

  showChart = false

  /* teste bubble */
  bubblechart

  _chartData : Array<any> = [];
  _chartSeries : Array<string> = [];

  offeringColors = []

  subs : Array<Subscription> = [];
  
  filters : Array<Filter> = []
  selectedProject
  selectedPortfolio

  view = "grouped"

  TesteChart
  offerings = []
  colores : Array<any> =[]

  csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: true,
      title: 'SubProject Results',
      useBom: true,
      noDownload: false,
      headers: ["ID", "Name", "Employess", "Gross Revenue", "Net Revenue", "CSR", "EXP", "Margin", "Margin %", "ID Offering", "Offering"]
  };

  constructor(private tableSvc : TableService, 
              private router : Router,
              private listService : SubprojectResultsService,
              private listService2 : SubprojectResultsService,
              private filterService : PortfolioFilterService,
              private colorConfig:ThemeConstantService,
              private ngChartjsService: NgChartjsService) { }

  ngOnInit() {

    this.subs.push(this.filterService.data
      .subscribe ((filtros : any) => {
          this.filters = filtros;
          this.testProject();
      }));

      //const _colors = this.Mycolors;
      //this.test1 = this.getInstance();
      //this.getOfferings();
      
  }


  testProject() {
    this.selectedProject = this.filterService.getValueOf("id_project");
    this.selectedPortfolio = this.filterService.getValueOf("id_portfolio");
    
    if (this.selectedProject) {
      this.getListData(); 

    } else if  (this.selectedPortfolio) {
      this.getListData(); 
      
    }
  }

  toggleChart() {
      if (this.showChart) {
          this.showChart = false
      } else {
          this.getListData();
          this.showChart = true
      }
  }



  getFile() {
     new  AngularCsv(this.displayData, "SubProject Results", this.csvOptions);
  }

  
  
  myTable
  getListData() {


    
   this.subs.push(this.listService.getSubProject(this.filters)
      .subscribe((data1 : any) => {

          this.ordersList = data1;

          // pega os ddos do grafico primeiro
          this._chartSeries = data1.chart.labels;
          this.bubblechart = {
                data : data1.chart.data
          }


          //console.log('resultado de subprojects')
          let data = data1.data;
          //console.log(data)
          this.ordersList = data;
          //this.displayData  = data;

          this.myTable = {
                     itemList : {
                        fields : [
                           {key : "id_project", label: "Project ID", alias : "Project ID", format: "", visible: false},
                           {key : "id_subproject", label: "ID", alias : "ID", format: "", visible: false},
                           { key: "projecttype", label: "Project Type", alias: "Project Type", format: "", visible: true },
                         
                           {key : "offname", label: "Offering", alias : "Offering", format: "", visible: false},
                           {key : "cusname", label: "Customer", alias : "Customer", format: "", visible: false},

    
                           {key : "name", label: "Name", alias : "Subproject Name", format: "", visible: true},
                           {key : "leadname", label: "Leader", alias : "Leader Name", format: "", visible: true},
                           {key : "employees", label: "Employees", alias : "Employees", format: "", visible: true},

                           {key : "REV", label: "Gross Revenue", alias : "Gross Revenue", format: "currency", visible: false},
                           {key : "NETREV", label: "Net Revenue", alias : "Net Revenue", format: "currency", visible: true},

                           {key : "AVG_GROSS_TAX", label: "AVG Gross Tax", alias : "AVG Gross Tax", format: "currency",  red : 135, direction : "<", visible: false},
                           {key : "AVG_NET_TAX", label: "AVG Net Rate", alias : "AVG Net Rate", format: "currency", red : 135, direction : "<", visible: false},
                           {key : "AVG_CSR", label: "AVG CSR", alias : "AVG CSR", format: "currency", red : 72, direction : ">=", visible: false},
                           {key : "EXPECTED_AVG_CSR", label: "EXPECTED_AVG_CSR", alias : "EXPECTED_AVG_CSR", format: "currency",  red : 72, direction : ">=", visible: false},
                           {key : "MARGIN_OVER_AVG_CSR", label: "MARGIN_OVER_AVG_CSR", alias : "MARGIN_OVER_AVG_CSR", format: "percentage", red : 0.45, direction : "<", visible: false},
                           {key : "usecase", label: "Use Case", alias : "Use Case", format: "", visible: false},
                           {key : "technology", label: "Technology", alias : "Technology", format: "", visible: false},
                           {key : "bodyshop", label: "BodyShop", alias : "BodyShop", format: "", visible: false},
                           
                           {key : "CSR", label: "CSR", alias : "CSR", format: "currency", visible: true},
                           {key : "EXP", label: "Expenses", alias : "Expenses", format: "currency", visible: true},

                           {key : "margin", label: "Analytic Margin", alias : "Analytic Margin", format: "currency", visible: false},
                           
                           {
                               key : "marginpercent", 
                               label: "Margin %", 
                               alias : "Margin %", 
                               format: "percentage", 
                               visible: true,
                               red : 0.40,
                               direction : "<"
                           },
                        ],
                        results : data.slice(0, data.length-1),
                        buttons : [
                            {key: "view", label : "", hint: "View", icon: "fa fa-search-plus"},
                        ]
                    },
                    headers : {
                        fields : [
                           {key : "NETREV", label : "Net Revenue", format: "currency"},
                           {key : "CSR", label : "CSR", format: "currency"},
                           {key : "EXP", label : "Expenses", format: "currency"},
                           {key : "margin", label : "Analytic Margin", format: "currency"},
                           {
                               key : "marginpercent", 
                               label : "PTD", 
                               format: "percentage",
                               red : 0.40,
                               direction : "<"
                           },
                           {key : "employees", label : "Employees", format: ""},
                           {
                               key : "AVG_GROSS_TAX", 
                               label: "Average Rate", 
                               format: "currency", 
                               red : 135,
                               direction : "<"
                           },
                           {
                               key : "AVG_CSR", 
                               label: "Average CSR", 
                               format: "currency", 
                               red : 72,
                               direction : ">="
                           },
                        ],
                        results : data.slice(data.length-1)
                    }
                 }
           
           
          
          /* TENTANDO INTIVAR ESSE PROCESSAMENTO NO FRONT       
          let myDataAM : Array<any> = []//= []
          for (var i = 0; i < data.length-1; ++i) {
                
                if ((data[i].marginpercent) && (data[i].AVG_GROSS_TAX) && (data[i].id_offering != 7)) {
                    let obj2 = {}
                    obj2["x" + data[i].offname] = data[i].AVG_GROSS_TAX.toFixed(2), 
                    obj2["y" + data[i].offname] = data[i].marginpercent.toFixed(4), 
                    obj2["value" + data[i].offname] = (data[i].NETREV/1000).toFixed(2), 
                    obj2["label"] = data[i].cusname + " " + data[i].offname + " " + data[i].name;

                    if (this._chartSeries.includes(data[i].offname) == false) {
                       this._chartSeries.push(data[i].offname)
                    }

                    myDataAM.push(obj2);
                }
            }
            this.bubblechart = {

                data : myDataAM
            }

            console.log("bubblechart data")
            console.log(this.bubblechart.data)
            */

                                  
      }));
  }

  ManageProject(projectId) {
      let str : String = `/portfolio/subproject/${projectId}`
      this.router.navigate([str]);
  }

  mostraEvento($event) {
    console.log("subproject results - evento que echgou ")
    console.log($event)
    if ($event.event== "view") {
       this.ManageProject($event.data.id_subproject) 
    } 
  }


  sort(sortAttribute: any) {
    this.displayData = this.tableSvc.sort(sortAttribute, this.displayData);
  }

  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  randomNumber(min, max) {
    return Math.round(Math.random() * (max - min) + min).toFixed(2);
  }

  onStatusChange($event) {
    this.filterService.addFilter({key: "grouped", value : $event})
    this.getListData();
  }

  ngOnDestroy() {
     this.subs.map(s => s.unsubscribe);
  }

   themeColors = this.colorConfig.get().colors;
    blue = this.themeColors.blue;
    blueLight = this.themeColors.blueLight;
    cyan = this.themeColors.cyan;
    cyanLight = this.themeColors.cyanLight;
    gold = this.themeColors.gold;
    purple = this.themeColors.purple;
    purpleLight = this.themeColors.purpleLight;
    red = this.themeColors.red;
    redLight = this.themeColors.redLight;
    volcano = this.themeColors.volcano;
    orange = this.themeColors.orange;
    lime = this.themeColors.lime;
  
    bbChartLabels: string[] = ['New'];
    
    bbChartData : any[] = [ {
      data: [
        { x: 0.15, y: 15, r: 15 },
        { x: 0.25, y: 15, r: 25 },
        { x: 0.36, y: 424, r: 33 },
        { x: 0.10, y: 138, r: 18 },
      ],
      //label: 'Use the colors',
    },]

    
    bbChartColors : Array<any> = [{ 
        backgroundColor: [],//this.Mycolors,//["rgba(43, 91, 101, 1.00)", "rgba(120, 57, 227, 1)", "rgba(43, 91, 101, 1.00)", "rgba(43, 91, 101, 1.00)"],
        pointBackgroundColor : [this.cyan, this.purple, this.gold, this.blue]
    }];
    bbChartOptions: any = {
        tooltips: {
            callbacks: {
                   label: function(tooltipItem, data) {

                       var label = data.labels[tooltipItem.index];
                       if (label) {
                           label += ': ';
                       }
                       //alert(Object.keys(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]));
                       //let valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].r.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
                       //.toFixed(2).replace('.',',')
                       let valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].r.toFixed(2).replace('.',','); 
                       label += valor
                       label += " k"
                       return label
                    },
            }
        }, 
        cutoutPercentage: 75,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Average Gross Tax"
                },
                ticks: {
                    display: true,
                    //beginAtZero: true,
                    fontSize: 13,
                    //padding: 10
                }
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Net Margin"
                },
                ticks: {
                    display: true,
                    beginAtZero: true,
                    fontSize: 13,
                    //padding: 10
                }
            }],
        } 
    }
    bbChartType = 'bubble';

   

}
