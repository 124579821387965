import { Component, OnInit, Output, OnDestroy } from '@angular/core';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'

import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
import { ThemeConstantService } from '../../shared/services/theme-constant.service';
import { Filter } from '../../shared/model/filter.model';

import { Customer, CustomerDisplay } from '../../shared/model/customer.model';

//import { ProjectService } from '../../shared/services/project.service';
//import { LineService } from '../../shared/services/line.service';
import { CustomerService } from '../../shared/services/customer.service';
//import { CustomersectorService } from '../../shared/services/customersector.service';
//import {API_ENDPOINT} from '../../../app.api';
import { CustomersFormComponent } from './customers-form/customers-form.component';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  customerSectorFilterList: any;
  myTable
  mostraAvatar
  subtitle: string = ""
  filters: Array<Filter> = [ { key: 'orderBy', value: "name" }, { key: 'status', value: "A" }] //
  itemsList //{ key: 'page', value: 1 },

  subs: Array<Subscription> = [];
  _count = 0
  selectFieldsToggle: Boolean = false;
  showFilters: Boolean = false;
  exportData: Array<any> = []
  fields: Array<any> = []
  visibleFields: Array<any> = [];

  total_size = 0;
  n_pages = 1;


  filterList = [
    { key: "id_customer", label: "_id", value: "", type: "text", visible: true },
    { key: "name", label: "Name", value: "", type: "text", visible: true },
    { key: "shortname", label: "Short Name", value: "", type: "text", visible: true },
    { key: "id_customer_sector", label: "Sector", value: "", type: "editableselect", visible: true, dataFrom: { endpoint: 'customersector', id: 'id_customer_sector', label: 'name' } },
    { key: "status", label: "Status", value: "A", type: "radio", visible: true, dataFrom: [{ label: "Ativo", value: "A" }, { label: "Inativo", value: "I" }] },
  ]


  constructor(private modalService: NzModalService,
    private notification: NzNotificationService,
    private mainListService: CustomerService,
    private editService: CustomerService,
    private gta: GoogleAnalyticsService
  ) { }

  ngOnInit() {

    this.getData();
  }

  PageDataChange($event): void {
    console.log($event)
    //this.displayData = $event;
    //this.refreshStatus();
  }

  getData() {
    this.subs.push(
      this.mainListService.getFiltered(this.filters)
        .subscribe(
          data => {
            //console.log(data)
            this.itemsList = data;
            //this.total_size = data['total_size'];
            this.myTable = {
              options: { title: 'Customers' },
              itemList: {
                fields: [
                  { key: "id_customer", label: "ID", alias: "ID", format: "", visible: true },
                  { key: "name", label: "Name", alias: "Customer Name", format: "", visible: true },
                  { key: "shortname", label: "Shortname", alias: "Customer Shortname", format: "", visible: false },

                  { key: "customersectorname", label: "Sector", alias: "Sector Name", format: "", visible: true },

                  //{key : "avatario", label: "Sector", alias : "Sector Name", format: "", visible: true},
                  //{key : "isgroup", label: "Sector", alias : "Sector Name", format: "", visible: true},

                  { key: "status", label: "Status", alias: "Status", format: "AI", visible: true },
                ],
                results: data,//.slice(0, data.length-1),
                buttons: [
                  { key: "edit", label: "", hint: "Edit", icon: "fas fa-edit" },
                  { key: "delete", label: "", hint: "Delete", icon: "far fa-trash-alt" }
                ]
              },
            },
              error => {
                let errorMsg = "Loading Query => " + error.error.message;
                this.notification.error("Error", errorMsg);
                this.gta.eventEmitter("loading_error", "broken_experience", "listing_table", "[customers] " + error.error.message, 3);

              }

          }));
  }



  AddOne(id) {

    let title
    if (id != 0) {
      title = "Edit Customer"
    } else {
      title = "New Customer"
    }

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: CustomersFormComponent,
      nzComponentParams: {
        customerId: id,
      },
    });
    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => this.getData());

  }

  /* START - PARTE DO PADRÃO */


  mostraToggler($eshowFiltersvent) {
    this.FilterToggle();
  }

  FilterToggle() {
    this.showFilters = !this.showFilters;
  }

  FieldsToggle() {
    this.selectFieldsToggle = !this.selectFieldsToggle;
    this._count = this._count + 1;
  }

  mostraEvento($event) {
    if ($event.event == "edit") {
      this.AddOne($event.data.id_customer);
    } else if ($event.event == "delete") {
      this.Delete($event.data.id_customer)
    }
  }

  aplicaFiltros($event) {
    this.filters = $event.event;
    this.getData();
  }
  /* FINISH - PARTE DO PADRÃO */

  Delete(id) {
    this.subs.push(
      this.editService.delete(id)
        .subscribe(
          data => {
            this.notification.blank("Success", "deleted successfully");
            this.gta.eventEmitter("updating_succes", "good_experience", "deleting", "[customer] customers-form", 1);

            this.getData();
          },
          error => {
            this.notification.error("Error", error.error.message);
            this.gta.eventEmitter("saving_error", "broken_experience", "deleting", "[customers] " + error.error.message, 3);
          }
        ));

  }

  ngOnDestroy() {
    this.subs.map(s => s.unsubscribe);
  }



}
