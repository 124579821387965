import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { SharedModule } from '../shared/shared.module';

import { NgChartjsModule } from 'ng-chartjs';

import { ChartsModule } from 'ng2-charts';
import { ThemeConstantService } from '../shared/services/theme-constant.service';

import { ProductionRoutingModule } from "./production-routing.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {TemplateModule} from '../shared/template/template.module';


import { ManagerDashboardComponent } from './dashboard/manager-dashboard/manager-dashboard.component';

import { TopProjectsComponent } from './general-results/top-projects/top-projects.component';
import { TableService } from '../shared/services/table.service';



// Line


// Projects
import { ProjectDashboardComponent } from './dashboard/project-dashboard/project-dashboard.component';
import { ResultsViewComponent } from './dashboard/project-dashboard/results-view/results-view.component';
import { DeliveryViewComponent } from './dashboard/project-dashboard/delivery-view/delivery-view.component';
import { PreclosureViewComponent } from './dashboard/project-dashboard/preclosure-view/preclosure-view.component';
import { ImportationComponent } from './importation/importation.component';
import { GeneralResultsComponent } from './general-results/general-results.component';
import { LineResultsComponent } from './general-results/line-results/line-results.component';
import { ProjectResultsComponent } from './general-results/project-results/project-results.component';
import { WipResultsComponent } from './general-results/wip-results/wip-results.component';
import { CustomerResultsComponent } from './general-results/customer-results/customer-results.component';
import { SummarizedResultsComponent } from './general-results/summarized-results/summarized-results.component';
import { WipProjectComponent } from './general-results/wip-project/wip-project.component';
import { ManagerResultsComponent } from './general-results/manager-results/manager-results.component';
import { HistoricalResultsComponent } from './general-results/historical-results/historical-results.component';
import { NetRevenueFYComponent } from './general-results/historical-results/net-revenue-fy/net-revenue-fy.component';
import { WipFyComponent } from './general-results/historical-results/wip-fy/wip-fy.component';


import {PortfolioModule} from '../portfolio/portfolio.module';

import { CustomerDistribuctionComponent } from './general-results/customer-distribuction/customer-distribuction.component';
import { CustomerSectorRevenueComponent } from './general-results/customer-sector-revenue/customer-sector-revenue.component';


@NgModule({
  declarations: [ ManagerDashboardComponent, 
  				 TopProjectsComponent, 
           PreclosureViewComponent, DeliveryViewComponent, 
           ResultsViewComponent, ProjectDashboardComponent, 
           ImportationComponent, 
           GeneralResultsComponent, LineResultsComponent, 
           ProjectResultsComponent, WipResultsComponent, 
           CustomerResultsComponent, SummarizedResultsComponent, 
           WipProjectComponent, ManagerResultsComponent, 
           
          
           CustomerDistribuctionComponent, CustomerSectorRevenueComponent],
  imports: [
    CommonModule,
    SharedModule,
    ProductionRoutingModule,
    TemplateModule,
    FormsModule, ReactiveFormsModule,
    NgChartjsModule, ChartsModule, PortfolioModule,

   
        
  ],
  providers: [ThemeConstantService, TableService]
})
export class ProductionModule { }
