import { ThemeConstantService } from '../../../shared/services/theme-constant.service';
import { Component, OnInit } from '@angular/core';
import {SubprojectNpsService} from '../../services/subproject-nps.service';
import { TableService } from '../../../shared/services/table.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
import {Filter} from '../../../shared/model/filter.model';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'

import {NewNpsComponent} from '../../subproject/subproject-plan/new-nps/new-nps.component';
import {NewContractComponent} from '../../subproject/subproject-plan/new-contract/new-contract.component';

@Component({
  selector: 'app-nps-list',
  templateUrl: './nps-list.component.html',
  styleUrls: ['./nps-list.component.css']
})
export class NpsListComponent implements OnInit {
  
  filters : Array<Filter> = []
  subtitle : string = ""
  itemList
  itemDisplay

  view


  themeColors = this.colorConfig.get().colors;
  blue = this.themeColors.blue;
  blueLight = this.themeColors.blueLight;
  cyan = this.themeColors.cyan;
  cyanLight = this.themeColors.cyanLight;
  gold = this.themeColors.gold;
  goldLight = this.themeColors.goldLight;
  purple = this.themeColors.purple;
  purpleLight = this.themeColors.purpleLight;
  red = this.themeColors.red;
  redLight = this.themeColors.redLight;
  volcano = this.themeColors.volcano;
  orange = this.themeColors.orange;
  lime = this.themeColors.lime;

  

  constructor(private listService : SubprojectNpsService,
              private listService2 : SubprojectNpsService,
  			  private editService : SubprojectNpsService,
  			  private tableSvc : TableService,
  			  private notification: NzNotificationService,
              private modalService: NzModalService, 
              private colorConfig : ThemeConstantService,) { }

  ngOnInit() {
  	this.getListData();
  }
  
  myTable
  labels
  graph_data
  NPS_Chart
  Colaboracao_Chart

  getListData() {
  	 this.listService2.getFY(this.filters) 
     .subscribe((data : any) => {
         //this.labels = data.labels
         //this.graph_data = data.data

         this.NPS_Chart = {
             icon : {
               name : "fas fa-dollar-sign",
               backgroundColor : this.themeColors.blueLight,
               color : this.themeColors.blue,
             },
             title : {
               value : data.avg_rating,//this.header_results.netrevenue,
               format : "number",
               subtitle : "AVG NPS ",
             },
             dataset : {
               backgroundColor : this.themeColors.blue,
               labels : data.labels,
               data : [{data : data.rating}],
               type : "bar",
               beginAtZero : false
             },
         }

         this.Colaboracao_Chart = {
             icon : {
               name : "fas fa-dollar-sign",
               backgroundColor : this.themeColors.blueLight,
               color : this.themeColors.blue,
             },
             title : {
               value : data.avg_colaboracao,//this.header_results.netrevenue,
               format : "number",
               subtitle : "AVG Colaboração ",
             },
             dataset : {
               backgroundColor : this.themeColors.blue,
               labels : data.labels,
               data : [{data : data.colaboracao}],
               type : "bar",
               beginAtZero : false
             },
         }

         console.log(this.NPS_Chart)

     })

     this.listService.getAll(this.filters)
  	 .subscribe((data : any) => {
      this.itemList = data;
      this.itemDisplay = data;
  		console.log(data);

      this.myTable = {
                     itemList : {
                        fields : [
                           {key : "id_subproject_nps", label: "ID", alias : "ID", format: "", visible: true},
                           {key : "period", label: "Period", alias : "Period", format: "", visible: true},
                           {key : "subprojectname", label: "Subproject Name", alias : "Subproject Name", format: "", visible: true},
                           {key : "offname", label: "Offering Name", alias : "Offering Name", format: "", visible: true},
                           {key : "leadname", label: "Leader Name", alias : "Leader Name", format: "", visible: true},
                           {key : "contractname", label: "Contract", alias : "Contract", format: "", visible: true},

                           {key : "rating", label: "Rating", alias : "Rating", format: "stars", visible: true},
                           {key : "colaboracao", label: "Colaboracao Rating", alias : "Colaboracao Rating", format: "stars", visible: false},
                           {key : "conhecimento", label: "Conhecimento Rating", alias : "Conhecimento Rating", format: "stars", visible: false},
                           {key : "qualidade", label: "Qualidade Rating", alias : "Qualidade Rating", format: "stars", visible: false},
                           {key : "produtividade", label: "Produtividade Rating", alias : "Produtividade Rating", format: "stars", visible: false},
                           {key : "comment", label: "Comment", alias : "Comment", format: "", visible: false},

                        ],
                        results : data.slice(0, data.length-1),
                        buttons : [
                          {key : "edit", label: "", hint: "Edit", icon: "fas fa-edit"},
                          {key : "delete", label: "", hint: "Delete", icon: "far fa-trash-alt"}
                        ]
                    },
                    
                 }

  	})
  }

  newNPS(subprojectId, npsId) {
     let title
     if (npsId != 0) {
        title = "Edit NPS"
     } else {
        title = "New NPS"
     }
     const modal = this.modalService.create({
        nzTitle: title,
        nzContent: NewNpsComponent,
        nzComponentParams: {
          subprojectId : subprojectId,
          id_subproject_nps : npsId,
          subprojectEdit : true
        },
     });
     const instance = modal.getContentComponent();
     modal.afterClose.subscribe(result => this.getListData());
  }

  newContract() {
     let title = "New Contract"
     const modal = this.modalService.create({
        nzTitle: title,
        nzContent: NewContractComponent,
        nzComponentParams: {
          subprojectId : 0,
          contractId: 0,
          subprojectEdit : true
        },
     });
     const instance = modal.getContentComponent();
     modal.afterClose.subscribe(result => this.getListData());
  }

 onListTypeChange($event) {
   
 } 

 addFilter(obj : Filter) {
  
      let index = this.filters.findIndex(x => x.key == obj.key);
      if (index === -1) {
         this.filters.push(obj); 
      } else {
         this.filters[index].value = obj.value;
      }        
  }

  sort(sortAttribute: any) {
    this.itemList = this.tableSvc.sort(sortAttribute, this.itemList);
  }

  zeraFilter() {
      this.filters = [];
  }
/*
  getFile() {
     new  AngularCsv(this.itemList, "NPS List", this.csvOptions);
  }

  csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: true,
      useBom: true,
      noDownload: false,
      headers: ["NPS iD", "Subproject Id", "Period", "Contract Id", "Name", "Contract Name", "Rating", "Comment", "Status", "Colaboracao", "Conhecimento", "Qualidade", "Produtividade"]
  };

  PageDataChange($event : Array<any>) : void {
      this.itemDisplay = $event;
  }
*/
mostraEvento($event) {

    if ($event.event == "edit") {
        this.newNPS($event.data.id_subproject, $event.data.id_subproject_nps);
    } else if ($event.event == "delete") {
        this.Delete($event.data)
    }
  }


  Delete(item) {
      this.editService.delete(item.id_subproject_nps)
      .subscribe((data : any) => {
          if (data.result === "Success") {
              this.notification.blank("Success", "deleted successfully");
           } else {
              this.notification.blank("Error", "error deleting");
           }
           this.getListData();
      });
      
    }

}
