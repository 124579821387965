import { Component, OnInit, OnDestroy } from '@angular/core';
import {SubprojectResultsService} from '../../services/subproject-results.service';
import { TableService } from '../../../shared/services/table.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import {Filter} from '../../../shared/model/filter.model';
import {PortfolioFilterService} from '../../services/portfolio-filter.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-people-hours',
  templateUrl: './people-hours.component.html',
  styleUrls: ['./people-hours.component.css']
})
export class PeopleHoursComponent implements OnInit {
  
  itemList
  newItemList : Array<any> = []
  itemCount : number = 0;
  itemDisplay
  filters : Array<Filter> = []
  myTable
  

  count : number = 0;

  mArray : Array<any> = []
  
  selectedProject
  selectedPortfolio

  subs : Array<Subscription> = [];

  constructor(private listService : SubprojectResultsService,
  			      private tableSvc : TableService, 
              private filterService : PortfolioFilterService,) { }

  ngOnInit() {

    this.selectedProject = this.filterService.getValueOf("id_project");

    this.subs.push(this.filterService.data
      .subscribe ((filtros : any) => {
          this.filters = filtros;
          this.testProject();
      }));
  }

  testProject() {
    this.selectedProject = this.filterService.getValueOf("id_project");
    this.selectedPortfolio = this.filterService.getValueOf("id_portfolio");
    
    if (this.selectedProject) {
      console.log("offering resutls -  rolou por portfolio")
      this.getData(); 

    } else if  (this.selectedPortfolio) {
      this.getData(); 
      console.log("offering resutls - nao rolou por portfolio")
    }
  }


  getData() {
    this.subs.push(this.listService.getPeopleHours(this.filters)
      .subscribe((data : any) => {
        console.log("people hours")
        console.log(data)
        this.itemList = data.results;
        //this.itemCount = this.itemList.length-1;

          this.myTable = {
                     itemList : {
                        fields : [
                           { key : "id_employee", label: "ID", alias : "ID", format: "", visible: false},
                           { key : "sapcode", label: "SapCode", alias : "SapCode", format: "", visible: true},
                           { key: "name", label: "Name", alias: "Name", format: "", visible: true },
                           { key: "linname", label: "Line", alias: "Line", format: "", visible: true },
                           { key: "offname", label: "Offering", alias: "Offering", format: "", visible: true },
                           { key : "levelname", label: "Level", alias : "Level", format: "", visible: false},
                           { key: "catname", label: "Category", alias: "Category", format: "", visible: true },
                           { key: "csr", label: "Last CSR", alias: "Last CSR", format: "", visible: true },
                           { key : "leader", label: "Leader", alias : "Leader", format: "", visible: false},
                           { key : "empstatus", label: "Status", alias : "Status", format: "AI", visible: true},
                        ],
                        results : data.results
                        //buttons : [
                        //  {key : "edit", label: "", hint: "Edit this project", icon: "fas fa-edit"}
                        //]
                    },
                 }

          for (var i = 0; i < data.fields.length; ++i) {
            this.myTable.itemList.fields.push({key : data.fields[i], label: data.fields[i], alias : data.fields[i], format: "", visible: true})
          }
          this.count = this.count + 1;
      }))
  }

  sort(sortAttribute: any) {
    console.log("sort:")
    console.log(sortAttribute)
    this.itemList.results = this.tableSvc.sort(sortAttribute, this.itemList.results);
  }

  

  getFile() {
     new  AngularCsv(this.itemList, "People Results", this.csvOptions);
  }

  ngOnDestroy() {
     this.subs.map(s => s.unsubscribe);
  }

  csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: true,
      title: 'SubProject Results',
      useBom: true,
      noDownload: false,
      headers: ["Employee Id", "Employee Name"]
  };

}
