import { Injectable } from '@angular/core';
import { GenericalService } from './generical.service';
import {API_ENDPOINT} from '../../app.api';
import {User} from '../model/user.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private gServ : GenericalService) { }

  getAll() {
  	let url = `${API_ENDPOINT}/user`;
  	return this.gServ.get(url);
  }

  getUserProfiles() {
  	let url = `${API_ENDPOINT}/user/profile`;
  	return this.gServ.get(url);
  }

  getById(id) {
     let retorno : any;
     let url = `${API_ENDPOINT}/user/${id}`;
     return this.gServ.get(url);
  }

  getFiltered(filters) {
    let url = `${API_ENDPOINT}/user/filtered`
    return this.gServ.postclean(url, filters);
  }

  insert(obj) {
    let url = `${API_ENDPOINT}/user`;
    return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
    let url = `${API_ENDPOINT}/user/${id}`;
    return this.gServ.putclean(url, obj);
  }

  delete(id) {
     let url = `${API_ENDPOINT}/user/${id}`;
     return this.gServ.delete(url);
  }
}
