import { Component, OnInit, OnDestroy } from '@angular/core';
import {Subscription} from 'rxjs';
import {ResultsService} from '../../../shared/services/results.service';
import { isNumeric } from 'rxjs/util/isNumeric';
@Component({
  selector: 'app-customer-sector-revenue',
  templateUrl: './customer-sector-revenue.component.html',
  styleUrls: ['./customer-sector-revenue.component.css']
})
export class CustomerSectorRevenueComponent implements OnInit {
  myTableHistorical
  sub1 : Subscription;

  _chartSeries = [];
  _chartData
  _chartCount = 0;

  constructor(private resultsService2 : ResultsService) { }

  ngOnInit() {
  	this.buildHistoricalMargin([])
  }

  _refresh() {
  	this._chartCount = this._chartCount + 1;
  }

   buildHistoricalMargin(filtros) {
    this.sub1 = this.resultsService2.getResultsByPeriod(filtros, "customer_sector")
              .subscribe((data : any) => {
                console.log("customer_sector data")
                console.log(data)

                let myData = data.revenue;
                let labels = data.labels;


                console.log("mydata")
                console.log(myData)

                
                for (var i = 0; i < data.labels.length; ++i) {
                    this._chartSeries.push(data.labels[i])
                }

                /*
                for (var i = 0; i < myData.length; ++i) {
                	 this._chartSeries.push(myData[i]["cussector"])
                
                }

				*/

                let myFinalData = []
                

                for (var i = 0; i < myData.length; ++i) {
                	let obj = {}

                	obj["cussector"] = myData[i]["cussector"]
                	for (var j = 0; j < labels.length; ++j) {
                		

                		obj['"' + labels[j].toString() + '"'] = myData[i][labels[j]]
                		
                	}
                	myFinalData.push(obj)                	
                }
	            console.log("customer sector chart")
	            console.log(this._chartSeries)


	           

	            this._chartData = {
	                data : myFinalData
	            }



	            console.log("no commercial this._chartBubbleData")
	            console.log( this._chartData)


	            this._chartCount = this._chartCount + 1;


                this.myTableHistorical = {
    
                     itemList : {
                        fields : [
                           {key : "id_customer_sector", label: "ID", alias : "ID", format: "", visible: true},
                           {key : "cussector", label: "Customer Sector", alias : "Customer Sector", format: "", visible: true},
                           
                           
                        ],
                        results : data.revenue//.slice(0, data.length-1)
                    },
                 }

                 for (var i = 0; i < data.labels.length; ++i) {
                    this.myTableHistorical.itemList.fields.push({key : data.labels[i], label: data.labels[i], alias : data.labels[i], format: "currency", visible: true},)
                 }
                 
              });
  }

  ngOnDestroy() {
  	if (this.sub1) {
  		this.sub1.unsubscribe();
  	}
  }

}
