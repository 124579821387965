import { Injectable } from '@angular/core';
import { GenericalService } from '../../shared/services/generical.service';
import {API_ENDPOINT} from '../../app.api';

@Injectable({
  providedIn: 'root'
})
export class SubprojectNpsService {

  constructor(private gServ : GenericalService) { }

  getAll(filters) {
    let url = `${API_ENDPOINT}/nps`;
    return this.gServ.getFiltered(url, filters);
  }

  getById(id) {
    let url = `${API_ENDPOINT}/nps/${id}`;
    return this.gServ.get(url);  
  }

   getAVGNPSperSubprojectId(id) {
    let url = `${API_ENDPOINT}/nps/avg/${id}`;
    return this.gServ.get(url);  
  }

  getFY(filters) {
    let url = `${API_ENDPOINT}/nps/fy`;
    return this.gServ.getFiltered(url, filters);
  }

  insert(obj) {
     let url = `${API_ENDPOINT}/nps`;
     return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
    let url = `${API_ENDPOINT}/nps/${id}`;
    return this.gServ.putclean(url, obj);
  }

  delete(id) {
     let url = `${API_ENDPOINT}/nps/${id}`;
     return this.gServ.delete(url);
  }

  /*
  getAll(filters) {
    let url = `${API_ENDPOINT}/subproject/contracts/nps`;
    return this.gServ.getFiltered(url, filters);
  }

  getById(id) {
    let url = `${API_ENDPOINT}/subproject/contracts/nps/${id}`;
    return this.gServ.get(url);  
  }

   getAVGNPSperSubprojectId(id) {
    let url = `${API_ENDPOINT}/subproject/contracts/nps/avg/${id}`;
    return this.gServ.get(url);  
  }

  getFY(filters) {
    let url = `${API_ENDPOINT}/subproject/contracts/nps/fy`;
    return this.gServ.getFiltered(url, filters);
  }

  insert(obj) {
     let url = `${API_ENDPOINT}/subproject/contracts/nps`;
     return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
    let url = `${API_ENDPOINT}/subproject/contracts/nps/${id}`;
    return this.gServ.putclean(url, obj);
  }

  delete(id) {
     let url = `${API_ENDPOINT}/subproject/contracts/nps/${id}`;
     return this.gServ.delete(url);
  }
  */

}