
import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd';
import {Subscription} from 'rxjs';

import {SubprojectNpsService} from '../../../services/subproject-nps.service';
import {SubProjectNPS} from '../../../model/subprojectNPS.model';
import { NzNotificationService } from 'ng-zorro-antd';
import {SubprojectContractService} from '../../../services/subproject-contract.service';
import {SubprojectService} from '../../../services/subproject.service';

@Component({
  selector: 'app-new-nps',
  templateUrl: './new-nps.component.html',
  styleUrls: ['./new-nps.component.css']
})
export class NewNpsComponent implements OnInit {
  
  @Input() subprojectId
  @Input() subprojectEdit
  @Input() id_subproject_nps

  scalarTooltips = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  tooltips = ['Muito insatisfeito', 'Insatisfeito', 'Nem satisfeito / Nem insatisfeito', 'Satisfeito', 'Muito Satisfeito'];

  contractList
  subprojectList

  dateFormat = 'yyyyMM';
  salvando : boolean = false;

  formData
  
  constructor(private modal: NzModalRef,		
              private fb: FormBuilder,
              private notification: NzNotificationService,
              private editService : SubprojectNpsService,
              private contractService : SubprojectContractService,
              private subprojectService : SubprojectService) { 

  	this.formData = this.fb.group({
          id_subproject_nps : [null],
          id_subproject : [this.subprojectId, [Validators.required]],
          period : [null, [Validators.required]],
          id_subproject_contract : [null],
          name : [null, [Validators.required]],
          rating : [0, [Validators.required]],
          colaboracao : [0, [Validators.required]],
          conhecimento : [0, [Validators.required]],
          qualidade : [0, [Validators.required]],
          produtividade : [0, [Validators.required]],
          comment : [null],
          status : [null, [Validators.required]]
        });
  }

  ngOnInit() {

    console.log("this.subprojectEdit");
    console.log(this.subprojectEdit);

  	this.formData.controls['id_subproject'].setValue(this.subprojectId);

    this.formData.controls['status'].setValue(true);
    if (this.subprojectEdit) {
       this.getSubProjects();
    }
    this.getContracts(this.subprojectId);
    
    if (this.id_subproject_nps != 0) {
        this.getSelectedId(this.id_subproject_nps);
    }
  }

  getSelectedId(id) {
    this.editService.getById(id)
    .subscribe((data : any) => {
        console.log(data);
        this.fullFillForm(data[0]);
    });
  }

  getContracts(subprojectId) {

    if (subprojectId) {
      this.contractService.getContractsBySubProjectId(subprojectId) 
        .subscribe((data : any) => { 
            this.contractList = data;
        })
    } else {
      this.contractService.getAll([]) 
        .subscribe((data : any) => { 
            this.contractList = data;
        })
    }
  }

  getSubProjects() {
      this.subprojectService.getAll() 
        .subscribe((data : any) => { 
            this.subprojectList = data;
            console.log(data);
        })
  }

  

  fullFillForm(obj : SubProjectNPS) {
        
        if (obj) {
            
            this.formData.controls['id_subproject_nps'].setValue(obj.id_subproject_nps);
            this.formData.controls['id_subproject'].setValue(obj.id_subproject);

            this.formData.controls['id_subproject_contract'].setValue(obj.id_subproject_contract);
            
            let period = obj.period
            period = period.toString()
            this.formData.controls['period'].setValue(this.StringParaData(period)) ;

            this.formData.controls['name'].setValue(obj.name);
            this.formData.controls['rating'].setValue(obj.rating);

            if (obj.colaboracao) {
               this.formData.controls['colaboracao'].setValue(obj.colaboracao);  
            } else {
              this.formData.controls['colaboracao'].setValue(0);
            }
            
            if (obj.conhecimento) {
              this.formData.controls['conhecimento'].setValue(obj.conhecimento);  
            } else {
              this.formData.controls['conhecimento'].setValue(0);
            }

            if (obj.qualidade) {
              this.formData.controls['qualidade'].setValue(obj.qualidade);  
            } else {
              this.formData.controls['qualidade'].setValue(0);
            }

            if (obj.produtividade) {
               this.formData.controls['produtividade'].setValue(obj.produtividade);
            } else {
              this.formData.controls['produtividade'].setValue(0);
            }
            
            
            

            this.formData.controls['comment'].setValue(obj.comment);
            
            if (obj.status == 'A') {
               this.formData.controls['status'].setValue(true);
            } else {
               this.formData.controls['status'].setValue(false);
            }

            return true;
        } else {
            return false;
        }
  }

  SubmitForm() {

        if (this.formData.invalid) {
            console.log(this.formData)
            this.notification.blank("Error", "Fill required data before saving");
            return;
        } else {
          this.salvando = true;
        }

        let obj = new SubProjectNPS;

        obj.id_subproject_nps = this.formData.controls['id_subproject_nps'].value;

        obj.id_subproject = this.formData.controls['id_subproject'].value;
        obj.id_subproject_contract = this.formData.controls['id_subproject_contract'].value;

        obj.name  = this.formData.controls['name'].value;
        obj.rating  = this.formData.controls['rating'].value;

        if (this.formData.controls['colaboracao'].value) {
          obj.colaboracao  = this.formData.controls['colaboracao'].value;  
        } else {
          obj.colaboracao  = 0;
        }
        
        if (this.formData.controls['conhecimento'].value) {
           obj.conhecimento  = this.formData.controls['conhecimento'].value;   
        } else {
          obj.conhecimento  = 0;
        }

        if (this.formData.controls['qualidade'].value) {
           obj.qualidade  = this.formData.controls['qualidade'].value;  
        } else {
          obj.qualidade  = 0;
        }

        if (this.formData.controls['produtividade'].value) {
           obj.produtividade  = this.formData.controls['produtividade'].value;
        } else {
          obj.produtividade  = 0;
        }
        
        
        


        obj.comment  = this.formData.controls['comment'].value;
        obj.period = this.DataParaString(this.formData.controls['period'].value);

        if (this.formData.controls['status'].value === true) {
           obj.status = 'A';
        } else {
           obj.status = 'I';
        }

        // manda para o serviço
        if (!obj.id_subproject_nps) {
             console.log('inserindo form data: ');
             console.log(obj);
             this.editService.insert(obj)
             .subscribe((data : any) => {
               if (data.result === "Success") {
                  this.notification.blank("Success", "inserted successfully");
               } else {
                  this.notification.blank("Error", "error inserting");
               }
               this.salvando = false;
               this.destroyModal()
             });
        // se estiver fazendo update        
        } else {
            this.editService.update(obj.id_subproject_nps, obj)
            .subscribe((data : any) => {
              if (data.result === "Success") {
                  this.notification.blank("Success", "updated successfully");
               } else {
                  this.notification.blank("Error", "error updating");
               }
               this.salvando = false;
               this.destroyModal()   
            });
        } //this.SendNotification(
    }


  DataParaString(o) {
    let year = o.getFullYear();
    let month = Number(o.getMonth())+1;
    let newMonth = String(month)

    if (month < 10) {
         newMonth = "0" + String(month)
    }  

    let valor = year + newMonth;
    return valor
  }

  StringParaData(o : String) : Date {

    if (o.length > 0) {
       let newYear = o.substr(0, 4)
       //substr(0,3)
       let newMonth = o.substr(4,2)

       let valor = newMonth +'/01/' + newYear;

       console.log(valor)
       return new Date(valor);  
    } else {
       return new Date();  
    }
    
  }

  destroyModal(): void {
    this.modal.destroy({ data: 'this the result data' });
  }

}
