import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-control-portfolio',
  templateUrl: './control-portfolio.component.html',
  styleUrls: ['./control-portfolio.component.css']
})
export class ControlPortfolioComponent implements OnInit {
  
  nzTabPosition = 1
  constructor() { }

  ngOnInit() {
  }

}
