import { Component, OnInit, Input} from '@angular/core';
import {PortfolioService} from '../../services/portfolio.service';
import { TableService } from '../../../shared/services/table.service';
import { NzNotificationService } from 'ng-zorro-antd';
import {EmployeeService} from '../../../shared/services/employee.service'

export class Employee {
  id_employee : number;
  name : string;
}


@Component({
  selector: 'app-portfolio-team',
  templateUrl: './portfolio-team.component.html',
  styleUrls: ['./portfolio-team.component.css']
})
export class PortfolioTeamComponent implements OnInit {
  
  @Input() selectedId
  orderList
  displayData

  employeeList
  selectedEmployee

  constructor(private portfolioService: PortfolioService,
              private notification: NzNotificationService,
              private editService: PortfolioService,
  			      private tableSvc : TableService, 
              private employeeService : EmployeeService) { }

  ngOnInit() {
    this.employeeService.getAll_with_users()
    .subscribe((data : Employee[]) => {
      this.employeeList = data;
    });
    this.getData();
  }

  getData() {
  	 this.portfolioService.getTeam(this.selectedId)
  	 .subscribe((data : any) => {
        console.log(data);
  	 	  this.orderList = data;
        this.displayData = data;
  	 })
  }

  AddEmployee(id) {
    this.editService.addTeamMember(this.selectedId, id)
    .subscribe((data : any) => {
      this.getData();
    });
  }

  Delete(item) {
      this.editService.deleteTeamMember(item.id_portfolio, item.id_employee)
      .subscribe((data : any) => {
          if (data.result === "Success") {
              this.notification.blank("Success", "deleted successfully");
           } else {
              this.notification.blank("Error", "error deleting");
           }
           this.getData();
      });
      
  }

  sort(sortAttribute: any) {
     this.orderList = this.tableSvc.sort(sortAttribute, this.orderList);
  }

}
