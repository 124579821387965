import { Injectable } from '@angular/core';
import { GenericalService } from '../../shared/services/generical.service';
import {API_ENDPOINT} from '../../app.api';


@Injectable({
  providedIn: 'root'
})
export class SubprojectContractService {

  constructor(private gServ : GenericalService) { }

  getAll(filters) {
    let url = `${API_ENDPOINT}/subproject/contracts`;
    return this.gServ.getFiltered(url, filters);
  }

  getContracts(filters) {
   	console.log("getContracts")
  	let url = `${API_ENDPOINT}/subproject/contracts/filtered`;
  	return this.gServ.getFiltered(url, filters);
  }

  getById(id) {
    let url = `${API_ENDPOINT}/subproject/contracts/${id}`;
    return this.gServ.get(url);  
  }

  getLastContractByProjectId(id) {
    let url = `${API_ENDPOINT}/subproject/${id}/lastcontract`;
    return this.gServ.get(url);  
  }

  getContractsSummarized(filters) {

     let url = `${API_ENDPOINT}/subproject/contracts/filtered/summarized`;
     return this.gServ.postclean(url, filters);
 
  }

  getPREUploaded() {
    let url = `${API_ENDPOINT}/subproject/contracts/presupload`;
    return this.gServ.get(url);
  }

  getContractsBySubProjectId(id) {
    let url = `${API_ENDPOINT}/subproject/${id}/contracts`;
    return this.gServ.get(url);
  }

  
  insert(obj) {
     let url = `${API_ENDPOINT}/subproject/contracts`;
     return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
    let url = `${API_ENDPOINT}/subproject/contracts/${id}`;
    return this.gServ.putclean(url, obj);
  }

  delete(id) {
     let url = `${API_ENDPOINT}/subproject/contracts/${id}`;
     return this.gServ.delete(url);
  }

}
