import { Pipe, PipeTransform } from '@angular/core';
import {PercentPipe} from '@angular/common'

@Pipe({
  name: 'percentpipe'
})
export class PercentpipePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return null;
 

   let percentpipe: PercentPipe = new PercentPipe('pt-BR');
   let newValue: string = percentpipe.transform(value)

    return newValue;
   }
}
