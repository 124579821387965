import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


export class Filter {

  key : string;
  value : any;

}

@Injectable({
  providedIn: 'root'
})
export class SubprojectlistfilterService {
//private currentFilterSubject: BehaviorSubject<Filter[]>;
  //public currentFilters: Observable<Filter[]>;
  private dataSource  = new BehaviorSubject<Filter[]>([]);
  public data = this.dataSource.asObservable();

  private filters : Array<Filter>;

  constructor() { 
  	this.clearFilters();
  }

  clearFilters() {
    
    let period = this.DataParaString(new Date())
    this.filters = [
    ];

    this.dataSource.next(this.filters); 
  }


  addFilter(obj : Filter) {
    
    let index = this.filters.findIndex(x => x.key == obj.key);
    if (index === -1) {
       this.filters.push(obj); 
    } else {
       this.filters[index].value = obj.value;
    }        

    this.dataSource.next(this.filters);
  }

  addFilters(obj : Array<Filter>) {
    const arr = this.filters.filter(e => e.key === 'portfolio' ||  e.key === 'subprojects');
    const aux = obj.filter(e => e.key === 'portfolio' ||  e.key === 'subprojects');

    if (aux.length > 0) this.filters = [...obj];
    else if (arr.length > 0) this.filters = [...arr, ...obj];

    this.dataSource.next(this.filters);
  }

  getValueOf(key) {
    let index = this.filters.findIndex(x => x.key == key);
    if (index >= 0) {
       return this.filters[index].value;   
    } else {
       return null;
    }
  }

  DataParaString(o) {
    let year = o.getFullYear();
    let month = Number(o.getMonth())+1;
    let newMonth = String(month)

    if (month < 10) {
         newMonth = "0" + String(month)
    }  

    let valor = year + newMonth;
    return valor
  }
}
