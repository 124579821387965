// Basics
import { Component, OnInit, OnDestroy } from '@angular/core';

// Template
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';
import { TableService } from '../../../shared/services/table.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'

// Filter
import {Filter} from '../../../shared/model/filter.model';
import {FilterService} from '../../../shared/services/filter.service';

// Services
//import {ProjectService} from '../../../shared/services/project.service';

import {ResultsService} from '../../../shared/services/results.service';
import {Subscription} from 'rxjs';
import { NzModalService } from 'ng-zorro-antd';
import { ProjectsFormComponent } from '../../../_registers/projects/projects-form/projects-form.component';


@Component({
  selector: 'app-project-results',
  templateUrl: './project-results.component.html',
  styleUrls: ['./project-results.component.css']
})
export class ProjectResultsComponent implements OnInit {

 
  projectResults
  displayProjectResults

  subtitle : string = ""

  sub1 : Subscription;
  sub2: Subscription; 
  sub3: Subscription; 
  sub4: Subscription; 
  sub5: Subscription; 
  sub6: Subscription; 

  view : string = 'table'

  themeColors = this.colorConfig.get().colors;
    blue = this.themeColors.blue;
    blueLight = this.themeColors.blueLight;
    cyan = this.themeColors.cyan;
    cyanLight = this.themeColors.cyanLight;
    gold = this.themeColors.gold;
    purple = this.themeColors.purple;
    purpleLight = this.themeColors.purpleLight;
    red = this.themeColors.red;
    transparent = this.themeColors.transparent

  csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: ',',
    showLabels: true,
    showTitle: true,
    //title: 'Your Holiday List :',
    useBom: true,
    noDownload: false,
    headers: ["Holiday ID", "Holiday Date", "Holiday Comment", "Holiday Status"]
  };

  myTable
  myTableHistorical

  constructor(private modalService : NzModalService,
              private filterService : FilterService, 
  			      private colorConfig:ThemeConstantService,
  	          private tableSvc : TableService, 
  			      private resultsService : ResultsService,
              private resultsService2 : ResultsService) { }

  ExpectedMargin : Array<number> = []
  ObtainedMargin : Array<number> = []
  DiffMargin : Array<number> = []
  newLabel : Array<string> = []


  ngOnInit() {
  	this.sub1 = this.filterService.data
      .subscribe ((filtros : any) => {
          if (this.view == "graph") {
              this.subtitle = "Margin Analysis"
              this.buildBubble(filtros);
          } else if (this.view == "table") {
              this.subtitle = "Results Table"
              this.buildTable(filtros);
          } else if (this.view == "historical-revenue") {
              this.subtitle = "History of Net Revenue"
              this.buildHistoricalRevenue(filtros);
          } else if (this.view == "historical-margin") {
              this.subtitle = "History of Margin"
              this.buildHistoricalMargin(filtros);
          } else if (this.view == "historical-growth") {
              this.subtitle = "History of Growth"
              this.buildHistoricalGrowth(filtros);
          }           
      });

  }

  mostraEvento($event) {
    //console.log("pai recebeu: ");
    //console.log($event)
    if ($event.event == "edit") {
        this.newProject($event.data.id_project);
    }
  }

  newProject(id) {      

     let title
     if (id != 0) {
        title = "Edit Project"
     } else {
       title = "New Project"
     }

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ProjectsFormComponent,
      nzComponentParams: {
        projectId : id,
      },
    });
    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => this.ngOnInit());

  }

  buildTable(filtros) {

              this.sub2 = this.resultsService.getResults(filtros, "project")
              .subscribe((data : any) => {
                 
                 this.myTable = {
                     itemList : {
                        fields : [
                           {key : "id_project", label: "ID", alias : "ID", format: "", visible: true},
                           {key : "name", label: "Project", alias : "Project Name", format: "", visible: false},
                           {key : "shortname", label: "Project", alias : "Project Shortname", format: "", visible: true},

                           {key : "cusname", label: "Customer", alias : "Customer Name", format: "", visible: false},
                           {key : "cusshortname", label: "Customer", alias : "Customer Shortname", format: "", visible: true},
                           
                           {key : "empname", label: "Manager", alias : "Manager Name", format: "", visible: false},
                           {key : "empshortname", label: "Manager", alias : "Manager Shortname", format: "", visible: true},

                           {key : "netrevenue", label: "Net Revenue", alias : "Net Revenue", format: "number", visible: true},
                           {key : "marginvalue", label: "Analytic Margin", alias : "Analytic Margin", format: "number", visible: true},
                           {key : "salesmargin", label: "Sales Margin", alias : "Sales Margin", format: "percentage", visible: false},
                           {
                               key : "marginpercent", 
                               label: "PTD", 
                               alias : "PTD", 
                               format: "percentage", 
                               visible: true,
                               red : 0.40,
                               direction : "<"
                           },
                        ],
                        results : data.data,
                        buttons : [
                          {key : "edit", label: "", hint: "Edit this project", icon: "fas fa-edit"}
                        ]
                    },
                    headers : {
                        fields : [
                           {key : "netrevenue", label : "Net Revenue", format: "number"},
                           {key : "marginvalue", label : "Analytic Margin", format: "number"},
                           {
                               key : "salesmargin", 
                               label : "Sales Margin", 
                               format: "percentage",
                               red : 0.40,
                               direction : "<"
                           },{
                               key : "marginpercent", 
                               label : "PTD", 
                               format: "percentage",
                               red : 0.40,
                               direction : "<"
                           },
                        ],
                        results : [data.header]
                    }
                 }                 
              });
  }

  buildBubble(filtros) {
      this.sub3 = this.resultsService.getBubbleChart(filtros)
      .subscribe((data : any) => {
         this.projectResults = data;

         this.ObtainedMargin = []
         this.ExpectedMargin  = []
         this.DiffMargin = []
         this.newLabel = []

         for (var i = 0; i < data.length; ++i) {
             this.DiffMargin.push(data[i].diff)
             this.newLabel.push(data[i].id_project + " (" + data[i].empshortname + ")")
         }
         
         this.barChartData = [
           //{data : this.ExpectedMargin, label: "Expected Margin"},
           {data : this.DiffMargin, label: "Diff Sales Margin (Delivery-Sales)"},
         ]
         this.barChartLabels = this.newLabel;

      });
  }

  buildHistoricalRevenue(filtros) {

      this.sub4 = this.resultsService2.getResultsMonthly(filtros, "project")
              .subscribe((data : any) => {

                console.log(data)
                this.myTableHistorical = {
    
                     itemList : {
                        fields : [
                           {key : "id_project", label: "ID", alias : "ID", format: "", visible: true},
                           {key : "name", label: "Project", alias : "Project Name", format: "", visible: false},
                           {key : "shortname", label: "Project", alias : "Project Shortname", format: "", visible: true},
                           {key : "cusshortname", label: "Customer", alias : "Customer Shortname", format: "", visible: false},
                           {key : "empshortname", label: "Manager", alias : "Manager Shortname", format: "", visible: false},

                        ],
                        results : data.revenue//.slice(0, data.length-1)
                    },
                 }

                 for (var i = 0; i < data.labels.length; ++i) {
                    this.myTableHistorical.itemList.fields.push({key : data.labels[i], label: data.labels[i], alias : data.labels[i], format: "number", visible: true},)
                 }
                 
              });
  }

  buildHistoricalMargin(filtros) {
    this.sub5 = this.resultsService2.getResultsMonthly(filtros, "project")
              .subscribe((data : any) => {
                console.log(data)
                this.myTableHistorical = {
    
                     itemList : {
                        fields : [
                           {key : "id_project", label: "ID", alias : "ID", format: "", visible: true},
                           {key : "name", label: "Project", alias : "Project Name", format: "", visible: false},
                           {key : "shortname", label: "Project", alias : "Project Shortname", format: "", visible: true},
                           {key : "cusshortname", label: "Customer", alias : "Customer Shortname", format: "", visible: false},
                           {key : "empshortname", label: "Manager", alias : "Manager Shortname", format: "", visible: false},
                        ],
                        results : data.margin//.slice(0, data.length-1)
                    },
                 }

                 for (var i = 0; i < data.labels.length; ++i) {
                    this.myTableHistorical.itemList.fields.push({key : data.labels[i], label: data.labels[i], alias : data.labels[i], format: "percentage", visible: true, red: 0.40, direction: "<"},)
                 }
                 
              });
  }

  buildHistoricalGrowth(filtros) {
    this.sub6 = this.resultsService2.getResultsMonthly(filtros, "project")
              .subscribe((data : any) => {
                console.log(data)
                this.myTableHistorical = {
    
                     itemList : {
                        fields : [
                           {key : "id_project", label: "ID", alias : "ID", format: "", visible: true},
                           {key : "name", label: "Project", alias : "Project Name", format: "", visible: false},
                           {key : "shortname", label: "Project", alias : "Project Shortname", format: "", visible: true},
                           {key : "cusshortname", label: "Customer", alias : "Customer Shortname", format: "", visible: false},
                           {key : "empshortname", label: "Manager", alias : "Manager Shortname", format: "", visible: false},
                        ],
                        results : data.growth//.slice(0, data.length-1)
                    },
                 }

                 for (var i = 0; i < data.labels.length; ++i) {
                    this.myTableHistorical.itemList.fields.push({key : data.labels[i], label: data.labels[i], alias : data.labels[i], format: "percentage", visible: true, green: 0.0001, direction: ">="},)
                 }
                 this.myTableHistorical.itemList.fields.push({key : "AVG", label: "AVG", alias : "Average Growth", format: "percentage", visible: false},)
                 
              });
  }

  onViewChange($event) {
    this.view = $event;
    this.ngOnInit();
  }

  DownloadResults() {
       let head = []
       for (var i = 0; i < this.projectResults.keys.length; ++i) {
         head.push(this.projectResults.keys[i]);
       }
       this.csvOptions.headers = head;
       new  AngularCsv(this.projectResults, "Project Results", this.csvOptions);
  }

  sort1(sortAttribute: any) {
        this.displayProjectResults = this.tableSvc.sort(sortAttribute, this.displayProjectResults);
        //console.log("sort has been finished")
  }

  ngOnDestroy() {
   if (this.sub1) {
      this.sub1.unsubscribe()
    }
    if (this.sub2) {
      this.sub2.unsubscribe()
    }
    if (this.sub3) {
      this.sub3.unsubscribe()
    }
    if (this.sub4) {
      this.sub4.unsubscribe()
    }
    if (this.sub5) {
      this.sub5.unsubscribe()
    }
    if (this.sub6) {
      this.sub6.unsubscribe()
    }
  }

  barChartOptions: any = {
        scaleShowVerticalLines: false,
        maintainAspectRatio: false,
        responsive: true,
        tooltips: { 
           mode: 'label', 
           label: 'mylabel', 
           callbacks: { 
               label: function(tooltipItem, data) { 
                   return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
               }, 
           }, 
        }, 
        scales: {
            xAxes: [{
                display: true,
                
                categoryPercentage: 0.35,
                barPercentage: 0.70,
                scaleOverride: true,
                scaleLabel: function (valuePayload) {
                    return Number(valuePayload.value).toFixed(2).replace('.',',') + '$';
                },
                

                gridLines: false,
                
            }],
            yAxes: [{
                display: true,
                ticks: {
                    callback: function(label, index, labels) {
                        return (label/1000).toFixed(2).replace('.',',')+'k';
                    }
                },
                scaleOverride: true,
                scaleLabel: function (valuePayload) {
                    return Number(valuePayload.value).toFixed(2).replace('.',',') + '$';
                },
                gridLines: {
                    drawBorder: false,
                    offsetGridLines: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 4]
                },
                
            }],

        }
    };
    barChartLabels: string[] = ['2006', '2007', '2008', '2009', '2010', '2011',];
    barChartType = 'bar';
    barChartLegend = true;
    barChartColors: Array<any> = [
        { 
            backgroundColor: this.themeColors.blue,
            borderWidth: 0
        },
        //{ 
        //    backgroundColor: this.themeColors.blueLight,
        //    borderWidth: 0
        //}
    ];
    barChartData: any[] = [
        { 
            data: [-65, 59, 80, 81, 56, 55],
            label: 'Margin < Sales',
            //categoryPercentage: 0.35,
            //barPercentage: 0.70,
        },
        //{ 
        //    data: [28, 48, 40, 19, 86, 27],
        //    label: 'Obtained Margin',
            //categoryPercentage: 0.35,
            //barPercentage: 0.70,
        //}
    ];

}
