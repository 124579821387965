import { Pipe, PipeTransform } from '@angular/core';
import {CurrencyPipe} from '@angular/common'

@Pipe({
  name: 'currencyformat'
})
export class CurrencyformatPipe implements PipeTransform {

 
  transform(value: number, currencyCode: string = 'BRL', symbolDisplay: string = 'symbol', digits?: string): string {
    if (!value) {
      return '';
    }


    let currencyPipe: CurrencyPipe = new CurrencyPipe('pt-BR');
    let newValue: string = currencyPipe.transform(value, currencyCode, symbolDisplay, digits);

    return newValue;
  }
  


}
