import { Injectable } from '@angular/core';
import { GenericalService } from '../../shared/services/generical.service';
import {API_ENDPOINT} from '../../app.api';

@Injectable({
  providedIn: 'root'
})
export class SubprojectTransactionService {

  constructor(private gServ : GenericalService) { }

  getById(id) {
    let url = `${API_ENDPOINT}/subproject/transaction/${id}`;
    return this.gServ.get(url);  
  }

  getTransactions(filters) {
  	let url = `${API_ENDPOINT}/subproject/transaction/filtered`;
  	return this.gServ.postclean(url, filters);
  }

  getTransactionsOld(filters) {
    let url = `${API_ENDPOINT}/subproject/transaction/filtered/old`;
    return this.gServ.postclean(url, filters);
  }

  getTransactionsSummarized(filters) {
    let url = `${API_ENDPOINT}/subproject/transaction/filtered/summarized`;
    return this.gServ.postclean(url, filters);
  }

  getTransactionsSummarizedPerProject(filters) {
    let url = `${API_ENDPOINT}/subproject/transaction/filtered/summarized/perproject`;
    return this.gServ.postclean(url, filters);
  }

  getHoursPeriod(period) {
  	let url = `${API_ENDPOINT}/subproject/hoursinperiod/${period}`;
  	return this.gServ.get(url);	
  }

  insert(obj) {
     let url = `${API_ENDPOINT}/subproject/transaction`;
     return this.gServ.postclean(url, obj);
  }

  insertComposed(obj) {
     let url = `${API_ENDPOINT}/subproject/transaction/composed`;
     return this.gServ.postclean(url, obj);
  }

  copyNextMonth(obj) {
    let url = `${API_ENDPOINT}/subproject/transaction/copy`;
     return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
    let url = `${API_ENDPOINT}/subproject/transaction/${id}`;
    return this.gServ.putclean(url, obj);
  }

  delete(id) {
     let url = `${API_ENDPOINT}/subproject/transaction/${id}`;
     return this.gServ.delete(url);
  }
}
