import { Component, OnInit, OnDestroy} from '@angular/core';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';
import { TableService } from '../../../shared/services/table.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'

// Filter
import {Filter} from '../../../shared/model/filter.model';
import {FilterService} from '../../../shared/services/filter.service';
import {ResultsService} from '../../../shared/services/results.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-wip-project',
  templateUrl: './wip-project.component.html',
  styleUrls: ['./wip-project.component.css']
})
export class WipProjectComponent implements OnInit { 

  projectWIPResultsDisplay
  projectWIPResults

  myTableHistorical

  view : string = 'table'

  filtersSubscription : Subscription;
  subscription: Subscription; 

  csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: ',',
    showLabels: true,
    showTitle: true,
    //title: 'Your Holiday List :',
    useBom: true,
    noDownload: false,
    headers: ["Holiday ID", "Holiday Date", "Holiday Comment", "Holiday Status"]
  };

  filters : Array<Filter> = [];
  myTable

  subtitle : string = ""


  constructor(private filterService : FilterService,
  			      private tableSvc : TableService, 
              private colorConfig:ThemeConstantService,
              private resultsService : ResultsService,
              private resultsService2 : ResultsService,) { }

  ngOnInit() {

  	this.filtersSubscription = this.filterService.data
      .subscribe ((filtros : any) => {
         
          if (this.view == "table") {
              this.subtitle = "Results Table"
              this.buildTable(filtros);
          } else if (this.view == "WIPTotal") {
              this.subtitle = "History of WIP Total"
              this.buildHistoricalWIPTotal(filtros);
          } else if (this.view == "WIP120") {
              this.subtitle = "History of WIP 120"
              this.buildHistoricalWIP120(filtros);
          }
           
    });
  }

  buildTable(filtros) {
     this.resultsService.getWIP(filtros, "project")
      .subscribe((data : any) => {
         this.myTable = {
             //options : {
             //  title : "Project WIP",
             //},
             itemList : {
                fields : [
                   {key : "id_project", label: "ID", alias : "ID", format: "", visible: true},
                   {key : "name", label: "Project", alias : "Project Name", format: "", visible: false},
                   {key : "shortname", label: "Project", alias : "Project Shortname", format: "", visible: true},
                   
                   {key : "cusname", label: "Customer", alias : "Customer Name", format: "", visible: false},
                   {key : "cusshortname", label: "Customer", alias : "Customer Shortname", format: "", visible: true},
                   
                   {key : "empname", label: "Manager", alias : "Manager Name", format: "", visible: false},
                   {key : "empshortname", label: "Manager", alias : "Manager Shortname", format: "", visible: true},

                   {key : "WIPCurrent", label: "WIPCurrent", alias : "WIPCurrent", format: "number", visible: true},
                   {key : "WIP30", label: "WIP30", alias : "WIP30", format: "number", visible: true},
                   {key : "WIP60", label: "WIP60", alias : "WIP60", format: "number", visible: true},
                   {key : "WIP90", label: "WIP90", alias : "WIP90", format: "number", visible: true},
                   {
                       key : "WIP120", 
                       label: "WIP120", 
                       alias : "WIP120", 
                       format: "number", 
                       visible: true,
                       red: 1,
                       direction:">="
                    },
                    {key : "WIPTotal", label: "WIPTotal", alias : "WIPTotal", format: "number", visible: true},
                    {key : "DSOvalue", label: "DSO", alias : "DSO", format: "number", visible: false},
                ],
                results : data.data
            },
            headers : {
                fields : [
                   {key : "WIP30", label : "WIP30", format: "number"},
                   {key : "WIP60", label : "WIP60", format: "number"},
                   {key : "WIP90", label : "WIP90", format: "number"},
                   {key : "WIP120", label : "WIP120", format: "number", red: 1, direction : ">="},
                   {key : "WIPTotal", label : "WIPTotal", format: "number"},
                ],
                results : [data.header]
            }
         }
     })        
    
  }

  buildHistoricalWIPTotal(filtros) {
      console.log("historical-revenue");
      this.resultsService2.getResultsMonthly(filtros, "project")
          .subscribe((data : any) => {
            console.log(data)
            this.myTableHistorical = {
                 itemList : {
                    fields : [
                       {key : "id_project", label: "ID", alias : "ID", format: "", visible: true},
                       {key : "name", label: "Project", alias : "Project Name", format: "", visible: false},
                       {key : "shortname", label: "Project", alias : "Project Shortname", format: "", visible: true},
                       {key : "cusshortname", label: "Customer", alias : "Customer Shortname", format: "", visible: false},
                       {key : "empshortname", label: "Manager", alias : "Manager Shortname", format: "", visible: false},
                    ],
                    results : data.WIP//.slice(0, data.length-1)
                },
             }

             for (var i = 0; i < data.labels.length; ++i) {
                this.myTableHistorical.itemList.fields.push({key : data.labels[i], label: data.labels[i], alias : data.labels[i], format: "number", visible: true},)
             }
             
          });
  }

  buildHistoricalWIP120(filtros) {
      this.resultsService2.getResultsMonthly(filtros, "project")
          .subscribe((data : any) => {
            console.log(data)
            this.myTableHistorical = {
                 itemList : {
                    fields : [
                       {key : "id_project", label: "ID", alias : "ID", format: "", visible: true},
                       {key : "name", label: "Project", alias : "Project Name", format: "", visible: false},
                       {key : "shortname", label: "Project", alias : "Project Shortname", format: "", visible: true},
                       {key : "cusshortname", label: "Customer", alias : "Customer Shortname", format: "", visible: false},
                       {key : "empshortname", label: "Manager", alias : "Manager Shortname", format: "", visible: false},
                    ],
                    results : data.WIP120//.slice(0, data.length-1)
                },
             }

             for (var i = 0; i < data.labels.length; ++i) {
                this.myTableHistorical.itemList.fields.push({key : data.labels[i], label: data.labels[i], alias : data.labels[i], format: "number", visible: true},)
             }
             
          });
  }

  onViewChange($event) {
    this.view = $event;
    this.ngOnInit();
  }

  
  ngOnDestroy() {
     //this.subscription.unsubscribe();
     this.filtersSubscription.unsubscribe();
  }

}
