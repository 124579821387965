import { Component, OnInit, OnDestroy } from '@angular/core';
import {Subscription} from 'rxjs';
import {SubprojectResultsService} from '../../services/subproject-results.service';
import {PortfolioFilterService} from '../../services/portfolio-filter.service';

@Component({
  selector: 'app-subproject-dated-results',
  templateUrl: './subproject-dated-results.component.html',
  styleUrls: ['./subproject-dated-results.component.css']
})
export class SubprojectDatedResultsComponent implements OnInit {
  filters
  myTableHistorical

  subs : Array<Subscription> = [];
  



  constructor(private resultsService : SubprojectResultsService,
  			  private resultsService2 : SubprojectResultsService,
  			  private filterService : PortfolioFilterService,) { }

  ngOnInit() {

  	this.subs.push(this.filterService.data
      .subscribe ((filtros : any) => {
          this.filters = filtros;
          this.buildHistoricalRevenue(filtros);
      }));
  }

  hasError = false;
  errorMsg = "";

  buildHistoricalRevenue(filtros) {
    console.log("historical-revenue");

    this.subs.push(this.resultsService2.getFY(filtros, "sum", "project")
      .subscribe((data: any) => {

        if (!data) {
          this.hasError = true;
          this.errorMsg = "erroooou "
        } else {
          console.log('dados histricos ')
          console.log(data.revenue)
          this.myTableHistorical = {

            itemList: {
              fields: [
                { key: "id_subproject", label: "ID", alias: "ID", format: "", visible: true },
                { key: "name", label: "Name", alias: "Name", format: "", visible: true },
                { key: "offering", label: "Offering", alias: "Offering", format: "", visible: true },
                { key: "empshortname", label: "Leader", alias: "Leader", format: "", visible: true },
                { key: "comunidade", label: "Department", alias: "Department", format: "", visible: false },
                { key: "rt", label: "Area", alias: "Area", format: "", visible: false },
              ],
              results: data.revenue
            },
          }

          for (var i = 0; i < data.labels.length; ++i) {
            this.myTableHistorical.itemList.fields.push({ key: data.labels[i], label: data.labels[i], alias: data.labels[i], format: "number", visible: true },)
          }
        }
        }));
       
  }

  buildHistoricalMargin(filtros) {
    this.subs.push(this.resultsService2.getFY(filtros, "sum", "project")
              .subscribe((data : any) => {
                console.log(data)
                this.myTableHistorical = {
    
                     itemList : {
                        fields : [
                           {key : "id_project", label: "ID", alias : "ID", format: "", visible: true},
                           {key : "name", label: "Project", alias : "Project Name", format: "", visible: false},
                           {key : "shortname", label: "Project", alias : "Project Shortname", format: "", visible: true},
                           {key : "cusshortname", label: "Customer", alias : "Customer Shortname", format: "", visible: false},
                           {key : "empshortname", label: "Manager", alias : "Manager Shortname", format: "", visible: false},
                        ],
                        results : data.margin//.slice(0, data.length-1)
                    },
                 }

                 for (var i = 0; i < data.labels.length; ++i) {
                    this.myTableHistorical.itemList.fields.push({key : data.labels[i], label: data.labels[i], alias : data.labels[i], format: "percentage", visible: true, red: 0.40, direction: "<"},)
                 }
                 
              }));
  }

  buildHistoricalGrowth(filtros) {
   this.subs.push(this.resultsService2.getFY(filtros, "sum", "project")
              .subscribe((data : any) => {
                console.log(data)
                this.myTableHistorical = {
    
                     itemList : {
                        fields : [
                           {key : "id_project", label: "ID", alias : "ID", format: "", visible: true},
                           {key : "name", label: "Project", alias : "Project Name", format: "", visible: false},
                           {key : "shortname", label: "Project", alias : "Project Shortname", format: "", visible: true},
                           {key : "cusshortname", label: "Customer", alias : "Customer Shortname", format: "", visible: false},
                           {key : "empshortname", label: "Manager", alias : "Manager Shortname", format: "", visible: false},
                        ],
                        results : data.growth//.slice(0, data.length-1)
                    },
                 }

                 for (var i = 0; i < data.labels.length; ++i) {
                    this.myTableHistorical.itemList.fields.push({key : data.labels[i], label: data.labels[i], alias : data.labels[i], format: "percentage", visible: true, green: 0.0001, direction: ">="},)
                 }
                 this.myTableHistorical.itemList.fields.push({key : "AVG", label: "AVG", alias : "Average Growth", format: "percentage", visible: false},)
                 
              }))
  }

 ngOnDestroy() {
     this.subs.map(s => s.unsubscribe);
  }

}
