import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router} from '@angular/router';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';

import {AuthenticationService} from '../../../shared/services/authentication.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import {User} from '../../../shared/model/user.model';

import {Filter} from '../../../shared/model/filter.model';
import {ProjectResultsDisplay} from '../../../shared/model/projectResults.model';


import { TableService } from '../../../shared/services/table.service';
import {FilterService} from '../../../shared/services/filter.service';
import {ProductionFiltersComponent} from '../../../shared/template/production-filters/production-filters.component'


@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html'
})
export class ProjectDashboardComponent implements OnInit {

 allChecked: boolean = false;
  indeterminate: boolean = false;

  displayProjectEstimation = [];

  //lineSelectedPeriod : string = "Last";
  //lineGraphType : string = "PTD";
  //selectedLine : number = 0;

  testeDefault = null;

  view
  headlinesView : string = "line";
  headlinesId : number = 2;
  
  user : User;
  
  projectEstimation : any;
  
  viewType : string ="Project";

  constructor(private filterService : FilterService, 
              private colorConfig:ThemeConstantService,
              private auth : AuthenticationService
              ) { }

  ngOnInit() {
       this.auth.currentUser
       .subscribe((data : User) => {
           this.user = data[0];
           if (this.user.id_line) {
              //this.selectedLine = this.user.id_line;
              //if (this.selectedLine) {
                 this.filterService.addFilter({key: 'id_line', value : this.user.id_line});
              //}
           }
       });
    }

    ngOnDestroy() {
      this.filterService.clearFilters();
    }

}
