import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ThemeConstantService } from '../../../../shared/services/theme-constant.service';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.css']
})
export class DoughnutChartComponent implements OnInit {

   @Input() chart
  
   themeColors = this.colorConfig.get().colors;
    
    gold = this.themeColors.gold;
    blue = this.themeColors.blue;
    red = this.themeColors.red;
    volcano = this.themeColors.volcano;
    cyan = this.themeColors.cyan;
    lime = this.themeColors.lime;
    //orange = this.themeColors.orange;

    blueLight = this.themeColors.blueLight;
    purple = this.themeColors.purple;

    redLight = this.themeColors.redLight;
    purpleLight = this.themeColors.purpleLight;
    cyanLight = this.themeColors.cyanLight;
   
    
    
   
   
    
    
    

  ChartLabels: string[] = ['Direct', 'Referral', 'Social Network'];
  ChartData: number[] = [350, 450, 100];
  ChartColors: Array<any> =  [{ 
      backgroundColor: [this.gold, this.blue, this.red, this.volcano, this.cyan, this.lime, this.blueLight, 
                        this.purple, 
                        '#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477',
                        '#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707',
                        '#329262','#5574A6','#3B3EAC'],
      //pointBackgroundColor : [this.gold, this.blue, this.red]
  }];
  ChartOptions: any = {
        //scaleShowVerticalLines: false,
        maintainAspectRatio: true,
        responsive: true,
        
        //cutoutPercentage: 75,
        
        
        

    }
  ChartType = 'radar';
  //ChartLegend = true;


  constructor(private colorConfig:ThemeConstantService,) { }

  ngOnInit() {}

   ngOnChanges(changes: SimpleChanges) {
     //console.log("componente de doughnut")
     //console.log(this.chart);
     if (this.chart) {
         this.ChartLabels = this.chart.labels;
         this.ChartData = this.chart.data;
         //this.ChartType = this.chart.chartType;
         //this.ChartLegend = this.chart.legends;
     }
     
  }


}
