
export class SubProjectNPS {
  id_subproject_nps : number;
  id_subproject : number;
  period : string;
  id_subproject_contract : string;
  name : string;
  rating : number;
  colaboracao : number;
  conhecimento : number;
  qualidade: number;
  produtividade : number;
  comment : string;
  status : string;

}