import { Component, OnInit, OnDestroy } from '@angular/core';
import {Filter} from '../../../shared/model/filter.model';
import {SubprojectResultsService} from '../../services/subproject-results.service';
import {PortfolioFilterService} from '../../services/portfolio-filter.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-main-results',
  templateUrl: './main-results.component.html',
  styleUrls: ['./main-results.component.css']
})
export class MainResultsComponent implements OnInit {

  selectedProject
  selectedPortfolio
  ordersList
  displayData
  filters : Array<Filter> = []

  subs : Array<Subscription> = [];

  constructor(private filterService : PortfolioFilterService,
              private listService : SubprojectResultsService) { }

  ngOnInit() {

    this.subs.push(this.filterService.data
      .subscribe ((filtros : any) => {
          this.filters = filtros;
          this.testProject();
      }));
      
      //this.getOfferings();
  }

  testProject() {
    this.selectedProject = this.filterService.getValueOf("id_project");
    this.selectedPortfolio = this.filterService.getValueOf("id_portfolio");
    
    if (this.selectedProject) {
      console.log("offering resutls -  rolou por portfolio")
      this.getListData(); 

    } else if  (this.selectedPortfolio) {
      this.getListData(); 
      console.log("offering resutls - nao rolou por portfolio")
    }
  }

  projectItemSelected
  expandSet = new Set<string>();
  onExpandChange(id: string): void {
    console.log("clicou");
    console.log(id);
    this.projectItemSelected = this.ordersList[1];
    /*
    if (checked) {
      this.expandSet.add(id);
      this.projectItemSelected = id;
    } else {
      this.expandSet.delete(id);
    }
    */
  }

  myTable

  getListData() {

    /*
     netrevenue_prod,
						marginvalue_prod,
						marginpercent_prod,
						salesmargin,
						
						netrevenue_port,
						CSR,
						EXP,
						marginvalue_port,
						marginpercent_port,
     */
    this.subs.push(this.listService.getEXT(this.filters)
      .subscribe((data : any) => {
          console.log("get EXT")
          console.log(data)
          this.ordersList= data;
          this.myTable = {
            itemList: {
              fields: [
                
                { key: "id_project", label: "ID", alias: "ID", format: "", visible: true },
                { key: "projname", label: "Name", alias: "Name", format: "", visible: true },
                { key: "cusname", label: "Customer", alias: "Customer", format: "", visible: true },
                { key: "empname", label: "Manager", alias: "Manager", format: "", visible: false },
                { key: "netrevenue_prod", label: "Revenue SAP", alias: "Revenue SAP", format: "number", visible: true },
                { key: "marginvalue_prod", label: "Margin SAP", alias: "Margin SAP", format: "number", visible: false },
                { key: "marginpercent_prod", label: "Margin % SAP", alias: "Margin SAP", format: "percentage", visible: true },
                { key: "salesmargin", label: "Sales Margin", alias: "Sales Margin", format: "percentage", visible: true },

                { key: "netrevenue_port", label: "Revenue PORT", alias: "Revenue PORT", format: "number", visible: true },
                { key: "CSR", label: "CSR PORT", alias: "CSR PORT", format: "number", visible: false },
                { key: "EXP", label: "EXP PORT", alias: "EXP PORT", format: "number", visible: false },
                { key: "marginvalue_port", label: "Margin PORT", alias: "Margin PORT", format: "number", visible: false },
                { key: "marginpercent_port", label: "Margin % PORT", alias: "Margin % PORT", format: "percentage", visible: true },
                { key: "diff_prod_port", label: "Revenue DIFF", alias: "Revenue DIFF", format: "number", visible: true },
                
              ],
              results: this.ordersList,
              buttons: [
                { key: "view", label: "", hint: "View", icon: "fa fa-search-plus" },
              ]
            },
          

        }
      }));
  }

  getFile() {
     new  AngularCsv(this.displayData, "EXT Results", this.csvOptions);
  }

  ngOnDestroy() {
     this.subs.map(s => s.unsubscribe);
  }


  csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: true,
      title: 'SubProject Results',
      useBom: true,
      noDownload: false,
      headers: ["Type", "Category Id", "Category Name", "Avg Rate", "Quantity", "Total"]
  };

}
