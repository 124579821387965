import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors, FormArray, ValidatorFn } from '@angular/forms';
import { Project, ProjectDisplay } from '../../../shared/model/project.model';
import { ProjectService } from '../../../shared/services/project.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { NzModalRef } from 'ng-zorro-antd';
import { LineService } from '../../../shared/services/line.service';
import { CustomerService } from '../../../shared/services/customer.service';
import { ManagerService } from '../../../shared/services/manager.service';
import { first, map } from 'rxjs/operators';
import { Subscription } from 'rxjs'
import { ProjectValidator } from '../../../shared/directives/Project.validator';
import { GoogleAnalyticsService } from '../../../shared/services/google-analytics.service';

@Component({
  selector: 'app-projects-form',
  templateUrl: './projects-form.component.html',
  styleUrls: ['./projects-form.component.css']
})
export class ProjectsFormComponent implements OnInit {

  formData
  @Input() projectId

  estaInserindo: boolean = false;
  sliderBasicValue = false;
  lineFilterList: any;
  customerFilterList: any;
  managerFilterList: any;

  subs: Array<Subscription> = [];

  salvando: boolean = false;
  // console.log("não existe")
  // this.isValidCpf(),
  // ProjectValidator.createValidator(this.editService)
  constructor(private fb: FormBuilder,
    private modal: NzModalRef,
    private notification: NzNotificationService,
    private editService: ProjectService,
    private lineService: LineService,
    private customerService: CustomerService,
    private managerService: ManagerService,
    private gta : GoogleAnalyticsService  ) {


    this.formData = this.fb.group({
        id_project: [null, [Validators.required,
        Validators.pattern(/^[A-Z]{3}-[0-9]{6}-[0-9]{5}/),
        this.isValidProject()]],
        //id_bu : [ null ],
        id_subsidiary: 1,//[ null ],
        id_line: [null, [Validators.required]],
        id_customer: [null, [Validators.required]],
        name: [null, [Validators.required]],
        shortname: [null, [Validators.required]],
        id_employee: [null, [Validators.required]],
        salesmargin: [null, [Validators.required]],
        project_tax: [null, [Validators.required]],
        rappel: [null],
        status: [null],
    });


  }
  //

  ngOnInit() {

    this.subs.push( this.lineService.getAll()
      .subscribe(
        data => {
          this.lineFilterList = data;
        },
        error => {
          let errorMsg = "Loading Lines => " + error.error.message;
          this.notification.error("Error", errorMsg);
        }));

    this.subs.push( this.customerService.getAll()
      .subscribe(
        data => {
          this.customerFilterList = data;
        },
        error => {
          let errorMsg = "Loading Customers => " + error.error.message;
          this.notification.error("Error", errorMsg);
        }));

    this.subs.push( this.managerService.getAll()
      .subscribe(
        data => {
          this.managerFilterList = data;
        },
        error => {
          let errorMsg = "Loading Managers => " + error.error.message;
          this.notification.error("Error", errorMsg);
        }));

    if (this.projectId != 0) {
      this.getSelectedId(this.projectId);
    } else {
      this.estaInserindo = true;
    }


  }

  // # Just change the service end point
  getSelectedId(id) {
    console.log("projects form, qual id chegou");
    console.log(this.projectId)
    this.editService.getById(id)
      .subscribe((data: any) => {

        console.log("projects form get selected id");
        console.log(data);
        this.fullFillForm(data[0]);
      });
  }

  // como temos o caso especial da SALES_MARGIN
  // tem que reescrever esse método no componente
  // outra opcao seria na API, mas vai trazer mais complicação
  fullFillForm(obj: ProjectDisplay) {
    if (obj) {

      this.formData.controls['id_project'].setValue(obj.id_project);
      //this.formData.controls['id_bu'].setValue(obj.id_bu);
      this.formData.controls['id_subsidiary'].setValue(obj.id_subsidiary);
      this.formData.controls['id_line'].setValue(obj.id_line);
      this.formData.controls['id_customer'].setValue(obj.id_customer);
      this.formData.controls['name'].setValue(obj.name);
      this.formData.controls['shortname'].setValue(obj.shortname);
      this.formData.controls['id_employee'].setValue(obj.id_employee);
      this.formData.controls['salesmargin'].setValue(obj.salesmargin * 100);
      this.formData.controls['project_tax'].setValue(obj.project_tax * 100);

      if (obj.rappel === 'S') {
        this.formData.controls['rappel'].setValue(true);
      } else {
        this.formData.controls['rappel'].setValue(false);
      }
      if (obj.status === 'A') {
        this.formData.controls['status'].setValue(true);
      } else {
        this.formData.controls['status'].setValue(false);
      }

      console.log(this.formData.value);
      return true;
    } else {
      return false;
    }
  }



  SubmitForm() {

    this.salvando = true;
    if (this.formData.invalid) {
      this.formData.markAllAsTouched();
      this.salvando = false;
      return;
    }

    let obj = new Project;

    // ###### tem erro no id_employee
    obj.id_project = this.formData.controls['id_project'].value;
    //obj.id_bu = this.formData.controls['id_bu'].value;
    obj.id_subsidiary = this.formData.controls['id_subsidiary'].value;
    obj.id_line = this.formData.controls['id_line'].value;
    obj.id_customer = this.formData.controls['id_customer'].value;
    obj.name = this.formData.controls['name'].value;
    obj.shortname = this.formData.controls['shortname'].value;
    obj.id_employee = this.formData.controls['id_employee'].value;
    obj.salesmargin = this.formData.controls['salesmargin'].value;
    obj.project_tax = this.formData.controls['project_tax'].value;


    // trata os switches
    if (this.formData.controls['rappel'].value === true) {
      obj.rappel = 'S';
    } else {
      obj.rappel = 'N';
    }

    if (this.formData.controls['status'].value === true) {
      obj.status = 'A';
    } else {
      obj.status = 'I';
    }

    console.log("o que vai para o json")
    console.log(obj)

    // manda para o serviço
    if (this.estaInserindo) {


      this.editService.insert(obj)
        .pipe(first())
        .subscribe(
          data => {
            this.gta.eventEmitter("updating_succes", "good_experience", "inserting", "[projects projects-form] ", 1);
            this.notification.success("Success", "inserted successfully");
            this.destroyModal();
          },
          error => {
            this.notification.error("Error", error.error.message);
            this.salvando = false;
          });

      // se estiver fazendo update        
    } else {

      this.editService.update(this.projectId, obj)
        .pipe(first())
        .subscribe(
          data => {
            //this.router.navigate([this.returnUrl]);
            this.gta.eventEmitter("updating_succes", "good_experience", "updating", "[projects projects-form] ", 1);
            this.notification.success("Success", "updated successfully");
            this.destroyModal();
          },
          error => {
            this.notification.error("Error", error.error.message);
            
            this.salvando = false;
          });

    }


  }


  doesProjectExists: boolean = false;

  isValidProject(): ValidatorFn {
    return (control: AbstractControl): Validators => {

      if (!control.value) {
        return true;
      }

      this.editService.getById(control.value).subscribe(
        data => {
          if (data) {
            this.doesProjectExists = true;
            return { invalidProject: true };
          } else {

            this.doesProjectExists = false;
            return false;
          }
        },
        error => {
          this.doesProjectExists = false;
          return false;
        }
      )
  

    }



  }
 
  destroyModal(): void {
    this.subs.map(s => s.unsubscribe);
    this.modal.destroy({ data: 'this the result data' });
  }
}
