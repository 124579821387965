
import { Component, OnInit } from '@angular/core';
import {Filter} from '../../../../shared/model/filter.model';
import {ResultsService} from '../../../../shared/services/results.service';
import {FilterService} from '../../../../shared/services/filter.service';
import {ProjectResultsDisplay} from '../../../../shared/model/projectResults.model';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { ThemeConstantService } from '../../../../shared/services/theme-constant.service';
import { TableService } from '../../../../shared/services/table.service';


@Component({
  selector: 'app-delivery-view',
  templateUrl: './delivery-view.component.html'
})
export class DeliveryViewComponent implements OnInit {
  
   projectResults
  displayProjectResults
  themeColors = this.colorConfig.get().colors;
    blue = this.themeColors.blue;
    blueLight = this.themeColors.blueLight;
    cyan = this.themeColors.cyan;
    cyanLight = this.themeColors.cyanLight;
    gold = this.themeColors.gold;
    purple = this.themeColors.purple;
    purpleLight = this.themeColors.purpleLight;
    red = this.themeColors.red;
    transparent = this.themeColors.transparent

  csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: ',',
    showLabels: true,
    showTitle: true,
    //title: 'Your Holiday List :',
    useBom: true,
    noDownload: false,
    headers: ["Holiday ID", "Holiday Date", "Holiday Comment", "Holiday Status"]
  };

  constructor(private filterService : FilterService, 
  			      private colorConfig:ThemeConstantService,
  	          private tableSvc : TableService, 
  			      private resultsService : ResultsService,) { }

  ngOnInit() {
  	this.filterService.data
      .subscribe ((filtros : any) => {
          this.resultsService.getResults(filtros, "project")
          .subscribe((data : any) => {
             this.projectResults = data.data;
             this.displayProjectResults = data.data;
          }); 
      });
  }

  DownloadProjectResults() {
       let head = []
       for (var i = 0; i < this.projectResults.keys.length; ++i) {
         head.push(this.projectResults.keys[i]);
       }
       this.csvOptions.headers = head;
       new  AngularCsv(this.projectResults, "Delivery Results", this.csvOptions);
  }

  sort1(sortAttribute: any) {
        this.displayProjectResults = this.tableSvc.sort(sortAttribute, this.displayProjectResults);
        //console.log("sort has been finished")
  }


}
