import { Component, OnInit, Input,ChangeDetectionStrategy, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';

@Component({
  selector: 'app-resultcard-with-chart',
  templateUrl: './resultcard-with-chart.component.html',
  styleUrls: ['./resultcard-with-chart.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultcardWithChartComponent implements OnInit {
    /*
    se passar direto uma cor, todos ficam da mesma cor
    [Log] Net Revenue Last Period 
    [Log] #de4436 

    #se passar como lista, vai pintar as barras na sequencia de cores
    [Log] Margin Last Period 
    [Log] [{backgroundColor: "#05c9a7", borderWidth: 0}]
  */
  @Input() chart
  icon
  iconBackgroundColor
  iconColor
  
 
  

  chartLabels
  chartData
  chartType

  ChartOptions
  

  iconStyle = "{ color: red }"


  counter = 0;

  constructor(private colorConfig:ThemeConstantService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
      
      /*

      this.RevenueChart = {
                   icon : {
                     name : "fas fa-chart-line",
                     backgroundColor : this.themeColors.blueLight,
                     iconColor : this.themeColors.blue,
                   },
                   title : {
                     value : this.header_results.netrevenue,
                     format : "currency",
                     subtitle : "Net Revenue " + this.selectedperiod,
                   },
                   dataset : {
                     backgroundColor : this.themeColors.blue,
                     labels : data.labels,
                     data : data.labels,
                     type : "bar"
                   },
               }
      */

     

      if (this.chart) {

          //console.log(this.chart)
          this.icon = this.chart.icon.name + " fa-2x circle-icon";
          this.iconBackgroundColor = this.chart.icon.BackgroundColor;
          this.iconColor = this.chart.icon.iconColor;
          //this.value = this.chart.title.value;
          
          this.ChartColors = [
            { 
                backgroundColor:  this.chart.dataset.backgroundColor,
                borderWidth: 0
            }
          ]

          

          this.ChartLabels = this.chart.dataset.labels;
          this.ChartData = this.chart.dataset.data;
          this.ChartType = this.chart.dataset.type;

          if (this.chart.title.format == "currency") {
              this.ChartOptions = this.CurrencyChartOptions
          } else if (this.chart.title.format == "percentage") {
              this.ChartOptions = this.PercentageChartOptions
          } else {
              this.ChartOptions = this.NumberChartOptions
          }
      }
  }

  themeColors = this.colorConfig.get().colors;
    blue = this.themeColors.blue;
    blueLight = this.themeColors.blueLight;
    cyan = this.themeColors.cyan;
    cyanLight = this.themeColors.cyanLight;
    gold = this.themeColors.gold;
    purple = this.themeColors.purple;
    purpleLight = this.themeColors.purpleLight;
    red = this.themeColors.red;
    redLight = this.themeColors.redLight;
    volcano = this.themeColors.volcano;
    orange = this.themeColors.orange;
    lime = this.themeColors.lime;

  
    ChartLabels: string[] = ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'];
    ChartType = 'bar';
    ChartColors: Array<any> = [
        { 
            backgroundColor: this.themeColors.blue,
            borderWidth: 0
        },
        
    ];
    ChartData: any[] = [
        { 
            data: [20, 30, 35, 45, 55, 45],
        },
 
    ];

     ///////////////////////////////////////////
     /// currency Chart 
     ///////////////////////////////////////////

     CurrencyChartOptions: any = {
        scaleShowVerticalLines: false,
        maintainAspectRatio: false,
        responsive: true,
        tooltips: { 
           mode: 'label', 
           label: 'mylabel', 
           callbacks: { 
               label: function(tooltipItem, data) { 
                   return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
               }, 
           }, 
        }, 
        scales: {
            xAxes: [{
                display: false,
                
                categoryPercentage: 0.35,
                barPercentage: 0.70,
              
                scaleLabel: {
                    display: false,
                    labelString: 'Month'
                },
                ticks: {
                    beginAtZero: true
                },

                gridLines: false,
                
            }],
            yAxes: [{
                display: false,
                ticks: {
                    callback: function(label, index, labels) {
                        return (label/1000).toFixed(2).replace('.',',')+'k';
                    }
                },
                scaleOverride: true,
                scaleLabel: function (valuePayload) {
                    return Number(valuePayload.value).toFixed(2).replace('.',',') + '$';
                },
                gridLines: {
                    drawBorder: false,
                    offsetGridLines: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 4]
                },
                
            }],

        }
    };

    ///////////////////////////////////////////
    /// percentage Chart 
    ///////////////////////////////////////////

    PercentageChartOptions: any = {
        scaleShowVerticalLines: false,
        maintainAspectRatio: false,
        responsive: true,
        tooltips: { 
           mode: 'label', 
           label: 'mylabel', 
           callbacks: { 
               label: function(tooltipItem, data) { 
                   return (tooltipItem.yLabel*100).toString() + "%";
               }, 
           }, 
        }, 
        scales: {
            xAxes: [{
                display: false,
                
                categoryPercentage: 0.35,
                barPercentage: 0.70,
              
                scaleLabel: {
                    display: false,
                    labelString: 'Month'
                },
                ticks: {
                    beginAtZero: true
                },

                gridLines: false,
                
            }],
            yAxes: [{
                display: false,
                ticks: {
                    callback: function(label, index, labels) {
                        return (label/1000).toFixed(2).replace('.',',')+'k';
                    }
                },
                scaleOverride: true,
                scaleLabel: function (valuePayload) {
                    return Number(valuePayload.value).toFixed(2).replace('.',',') + '$';
                },
                gridLines: {
                    drawBorder: false,
                    offsetGridLines: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 4]
                },
                
            }],

        }
    };

    ///////////////////////////////////////////
    /// number Chart 
    ///////////////////////////////////////////

    NumberChartOptions: any = {
        scaleShowVerticalLines: false,
        maintainAspectRatio: false,
        responsive: true,
        tooltips: { 
           mode: 'label', 
           label: 'mylabel', 
           callbacks: { 
               label: function(tooltipItem, data) { 
                   return tooltipItem.yLabel.toString();
               }, 
           }, 
        }, 
        scales: {
            xAxes: [{
                display: false,
                beginAtZero: true,
                categoryPercentage: 0.35,
                barPercentage: 0.70,
              
                scaleLabel: {
                    display: false,
                    labelString: 'Month'
                },
                

                gridLines: false,
                
            }],
            yAxes: [{
                display: false,
                
                
            }],

        }
    };

}
