import { Injectable } from '@angular/core';
import { GenericalService } from '../../shared/services/generical.service';
import {API_ENDPOINT} from '../../app.api';


@Injectable({
  providedIn: 'root'
})
export class OfferingService {

  constructor(private gServ : GenericalService) { }

  getAll() {
  	let url = `${API_ENDPOINT}/offering`;
  	return this.gServ.get(url);
  }

  get(obj) {
    let url = `${API_ENDPOINT}/offering`;
    return this.gServ.getFiltered(url, obj);
  }

   getById(id) {
    let url = `${API_ENDPOINT}/offering/${id}`;
    return this.gServ.get(url);
  }

  insert(obj) {
  	let url = `${API_ENDPOINT}/offering`;
     return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
  	let url = `${API_ENDPOINT}/offering/${id}`;
  	return this.gServ.putclean(url, obj);
  }

  delete(id) {
  	 let url = `${API_ENDPOINT}/offering/${id}`;
  	 return this.gServ.delete(url);
  }

}
