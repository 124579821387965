// Serviços gerais
import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import {Subscription} from 'rxjs';
import {Router, ActivatedRoute} from '@angular/router';

// Somente para a listagem
import {Filter} from '../../../shared/model/filter.model';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
import { TableService } from '../../../shared/services/table.service';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import {NewTransactionComponent} from './new-transaction/new-transaction.component'

// Para ambos
import {SubprojectService} from '../../services/subproject.service';
import {SubProject} from '../../model/subproject.model';
import {SubProjectResults} from '../../model/results.model';
import {SubprojectResultsService} from '../../services/subproject-results.service';
import {SubprojectContractService} from '../../services/subproject-contract.service';
import {SubprojectNpsService} from '../../services/subproject-nps.service';




export class ResultsHeader {
  REV: number;
  NETREV : number;
  CSR : number;
  EXP : number;
  margin : number;
  marginpercent : number;
 }

@Component({
  selector: 'app-subproject-plan',
  templateUrl: './subproject-plan.component.html',
  styleUrls: ['./subproject-plan.component.css']
})
export class SubprojectPlanComponent implements OnInit {
  
getAVGNPSperSubprojectId
  // variáveis da LISTA
  

  selectedProjectId : number;
  
  selectedProjectData : SubProject;

  nzTabPosition = 0;

  lastContract

  NPSMedio 

  sub1 : Subscription;
  sub2 : Subscription;
  sub3 : Subscription;

  constructor(// Serviços da LISTA
              private route : ActivatedRoute,
              private subprojectService : SubprojectService,
              private NPSService : SubprojectNpsService,
              private contractService : SubprojectContractService) {}

  ngOnInit() {

      // starta o periodo com a data atual

      // busca o id do projeto
      if (this.route.snapshot.params['id']) {
          this.selectedProjectId = this.route.snapshot.params['id'];
          this.getSubProjectHeader(this.selectedProjectId);
      }
  }

  getSubProjectHeader(id) {
      this.sub1 = this.subprojectService.getById(id)
                              .subscribe((data : Array<SubProject>) => {
                                  this.selectedProjectData = data[0];
                                 
                              })

      this.sub2 = this.NPSService.getAVGNPSperSubprojectId(id)
      .subscribe((data : any) => {
         console.log("nps medio")
         console.log(data)
         if (data) {
           this.NPSMedio = data[0].rating;
         } 
      })

      this.sub3 = this.contractService.getLastContractByProjectId(id)
      .subscribe((data : any) => {
           console.log("last contract")
           console.log(data)
           this.lastContract = data[0];
      })
  }

  ngOnDestroy() {
    if (this.sub1) {
      this.sub1.unsubscribe();
    }
    if (this.sub2) {
      this.sub2.unsubscribe();
    }
    if (this.sub3) {
      this.sub3.unsubscribe();
    }
  }

}
