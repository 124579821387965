import { Injectable } from '@angular/core';
import { GenericalService } from '../../shared/services/generical.service';
import {API_ENDPOINT} from '../../app.api';

@Injectable({
  providedIn: 'root'
})
export class PipelineService {

  constructor(private gServ : GenericalService) { }


  getLeaderBoard() {
    let url = `${API_ENDPOINT}/opportunity/leaderboards`;
    return this.gServ.get(url);
  }

  /// GERAL
  getPipelines() {
    let url = `${API_ENDPOINT}/pipeline`;
    return this.gServ.get(url);
  }

  /// TEAM

  getPipelinesFromEmployeeId(id) {
    let url = `${API_ENDPOINT}/pipeline/employee/${id}`;
    return this.gServ.get(url);
  }


  getTeam(id) {
    let url = `${API_ENDPOINT}/pipeline/${id}/team`;
    return this.gServ.get(url);
  }

  addTeamMember(id_pipeline, id_employee) {
    let url = `${API_ENDPOINT}/pipeline/${id_pipeline}/team/${id_employee}`;
     return this.gServ.postOnlyUrl(url);
  }

  deleteTeamMember(id_pipeline, id_user) {
    let url = `${API_ENDPOINT}/pipeline/${id_pipeline}/team/${id_user}`;
     return this.gServ.delete(url);
  }

  ///////

  getOpportunities() {
  	let url = `${API_ENDPOINT}/opportunity`;
  	return this.gServ.get(url);
  }

  getOpportunityMap() {
    let url = `${API_ENDPOINT}/opportunity/map`;
    return this.gServ.get(url);
  }

  getOpportunityMapMap() {
    let url = `${API_ENDPOINT}/opportunity/map/map`;
    return this.gServ.postclean(url, []);
  }

  getKanban(pipelineId, filtros) {
     let url = `${API_ENDPOINT}/opportunity/kanban/${pipelineId}`;
     return this.gServ.getFiltered(url, filtros);
  }

  getTable(pipelineId, filtros) {
     let url = `${API_ENDPOINT}/opportunity/table/${pipelineId}`;
     return this.gServ.getFiltered(url, filtros);
  }

  getById(id) {
    let url = `${API_ENDPOINT}/opportunity/${id}`;
    return this.gServ.get(url);
  }

  getPipelineSummary(pipelineId, filtros) {
    let url = `${API_ENDPOINT}/opportunity/summary/${pipelineId}`;
    return this.gServ.getFiltered(url, filtros);
  }

  
  getTop5(pipelineId, aspect, vision, filtros) {
    let url = `${API_ENDPOINT}/opportunity/${pipelineId}/top5/${aspect}/${vision}`;
    return this.gServ.getFiltered(url, filtros);
  }

  setProgress(id, statusId) {
    let url = `${API_ENDPOINT}/opportunity/${id}/progress/${statusId}`;
    return this.gServ.putclean(url, []);
  }

  setWinLost(id, winlost) {
    let url = `${API_ENDPOINT}/opportunity/${id}/winlost/${winlost}`;
    return this.gServ.putclean(url, []);
  }

  insert(obj) {
    let url = `${API_ENDPOINT}/opportunity`;
    return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
    let url = `${API_ENDPOINT}/opportunity/${id}`;
    return this.gServ.putclean(url, obj);
  }

  delete(id) {
     let url = `${API_ENDPOINT}/opportunity/${id}`;
     return this.gServ.delete(url);
  }

  ////////////
  getVelocity(pipelineId, aspectId, filtros) {
    let url = `${API_ENDPOINT}/opportunity/velocity/${pipelineId}/${aspectId}`;
    return this.gServ.getFiltered(url, filtros);
  }

  getAvgTicket(pipelineId, aspectId, filtros) {
    let url = `${API_ENDPOINT}/opportunity/${pipelineId}/avgticket?aspect=${aspectId}`;
    return this.gServ.postclean(url, filtros);
  }

  

  getFullReport(pipelineId, filtros) {
    let url = `${API_ENDPOINT}/opportunity/report/${pipelineId}`;
    return this.gServ.postclean(url, filtros);
  }

  getComunidade(comunidade) {
    let url = `${API_ENDPOINT}/opportunity/autocomplete/comunidade`;
    return this.gServ.postclean(url, {"comunidade" : comunidade});
  }

  getCustomerContact(contact) {
    let url = `${API_ENDPOINT}/opportunity/autocomplete/customer_contact`;
    return this.gServ.postclean(url, {"customer_contact" : contact});
  }

  getPipedriveField(field) {
    let url = `${API_ENDPOINT}/opportunity/dealFields/${field}`
    return this.gServ.get(url);
  }
  

}
