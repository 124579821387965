import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { NgChartjsModule } from 'ng-chartjs';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';

import { NzCollapseModule } from 'ng-zorro-antd/collapse';

import { NzTableModule } from 'ng-zorro-antd/table';
import { ThemeConstantService } from '../shared/services/theme-constant.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TemplateModule} from '../shared/template/template.module';
import { TableService } from '../shared/services/table.service';
import { PortfolioRoutingModule } from './portfolio-routing.module';

import { SubprojectListComponent } from './subproject/subproject-list/subproject-list.component';
import { SubprojectPlanComponent } from './subproject/subproject-plan/subproject-plan.component';
import { NewTransactionComponent } from './subproject/subproject-plan/new-transaction/new-transaction.component';
import { SubprojectManageComponent } from './subproject/subproject-plan/subproject-manage/subproject-manage.component';
import { SubprojectContractComponent } from './subproject/subproject-plan/subproject-contract/subproject-contract.component';
import { NewContractComponent } from './subproject/subproject-plan/new-contract/new-contract.component';
import { PortfolioResultsComponent } from './portfolio-results/portfolio-results.component';
import { SubprojectResultsComponent } from './portfolio-results/subproject-results/subproject-results.component';
import { OfferingResultsComponent } from './portfolio-results/offering-results/offering-results.component';
import { MainResultsComponent } from './portfolio-results/main-results/main-results.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

import { NewSubprojectComponent } from './subproject/subproject-list/new-subproject/new-subproject.component';
import { EmployeeListComponent } from './portfolio-registers/employee-list/employee-list.component';
import { NewEmployeeComponent } from './portfolio-registers/employee-list/new-employee/new-employee.component';
import { NewEmployeeFastComponent } from './portfolio-registers/employee-list/new-employee-fast/new-employee-fast.component';
import { EmployeeCsrComponent } from './portfolio-registers/employee-list/employee-csr/employee-csr.component';
import { EmployeeCsrFastComponent } from './portfolio-registers/employee-list/employee-csr-fast/employee-csr-fast.component';
import { ResultsStatementComponent } from './portfolio-results/results-statement/results-statement.component';
import { PeopleHoursComponent } from './portfolio-results/people-hours/people-hours.component';
import { PortfolioSetupComponent } from './portfolio-setup/portfolio-setup.component';
import { PortfolioPageComponent } from './portfolio-setup/portfolio-page/portfolio-page.component';
import { PortfolioProjectComponent } from './portfolio-setup/portfolio-project/portfolio-project.component';
import { PortfolioTeamComponent } from './portfolio-setup/portfolio-team/portfolio-team.component';
import { NewPortfolioComponent } from './portfolio-setup/new-portfolio/new-portfolio.component';
import { LeaderResultsComponent } from './portfolio-results/leader-results/leader-results.component';
import { NpsListComponent } from './portfolio-registers/nps-list/nps-list.component';

import { SubprojectNpsComponent } from './subproject/subproject-plan/subproject-nps/subproject-nps.component';
import { NewNpsComponent } from './subproject/subproject-plan/new-nps/new-nps.component';
import { OfferingAnalysisComponent } from './portfolio-results/offering-analysis/offering-analysis.component';
import { ControleDeHorasComponent } from './controle-de-horas/controle-de-horas.component';
import { UploadPreComponent } from './controle-de-horas/upload-pre/upload-pre.component';
import { ListPreComponent } from './controle-de-horas/list-pre/list-pre.component';
import { ReportPreComponent } from './controle-de-horas/report-pre/report-pre.component';
import { SubprojectDatedResultsComponent } from './portfolio-results/subproject-dated-results/subproject-dated-results.component';
import { KpisComponent } from './portfolio-results/kpis/kpis.component';
import { NewTransactionComposedComponent } from './subproject/subproject-plan/new-transaction-composed/new-transaction-composed.component';
import { AdminPortfolioComponent } from './admin-portfolio/admin-portfolio.component';
import { ControlPortfolioComponent } from './control-portfolio/control-portfolio.component';
import { CsrUpdateComponent } from './control-portfolio/csr-update/csr-update.component';
import { SummarizedResultsComponent } from './summarized-results/summarized-results.component';
import { PortfolioCustomerResultsComponent } from './summarized-results/portfolio-customer-results/portfolio-customer-results.component';
import { CustomerResultsComponent } from './portfolio-results/customer-results/customer-results.component';
 import { NonbillableListComponent } from './portfolio-results/nonbillable-list/nonbillable-list.component';
//// NonbillableListComponent,],


@NgModule({
  declarations: [SubprojectListComponent, SubprojectPlanComponent, 
  NewTransactionComponent, SubprojectManageComponent, 
  SubprojectContractComponent, NewContractComponent, 
  PortfolioResultsComponent, SubprojectResultsComponent, 
  OfferingResultsComponent, MainResultsComponent, 
  NewSubprojectComponent, EmployeeListComponent, 
  NewEmployeeComponent, NewEmployeeFastComponent, EmployeeCsrComponent, 
  EmployeeCsrFastComponent, ResultsStatementComponent, PeopleHoursComponent, 
  PortfolioSetupComponent, PortfolioPageComponent, PortfolioProjectComponent, 
  PortfolioTeamComponent, NewPortfolioComponent, LeaderResultsComponent, 
  NpsListComponent, SubprojectNpsComponent, NewNpsComponent, OfferingAnalysisComponent, ControleDeHorasComponent, UploadPreComponent, 
    ListPreComponent, ReportPreComponent, SubprojectDatedResultsComponent, KpisComponent, NewTransactionComposedComponent,
    AdminPortfolioComponent, ControlPortfolioComponent, CsrUpdateComponent, SummarizedResultsComponent, PortfolioCustomerResultsComponent,
    CustomerResultsComponent, NonbillableListComponent],
  imports: [
    CommonModule,
    SharedModule,
    PortfolioRoutingModule,
    TemplateModule,
    FormsModule, ReactiveFormsModule,
    NgChartjsModule,NzSelectModule,
    NzAutocompleteModule,
    NzCollapseModule,
    NzTableModule,
    NzLayoutModule
    
        
  ],
  providers: [ThemeConstantService, TableService]
})
export class PortfolioModule { }
