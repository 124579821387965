import { Component, OnInit, OnDestroy} from '@angular/core';
import {SubProjectTransaction} from '../../model/subprojecttransaction.model';
import {SubprojectTransactionService} from '../../services/subproject-transaction.service';
import { TableService } from '../../../shared/services/table.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import {Filter} from '../../../shared/model/filter.model';
import {PortfolioFilterService} from '../../services/portfolio-filter.service';
import {OfferingService} from '../../../shared/services/offering.service';
import {Subscription} from 'rxjs';

export class ResultsHeader {
  REV: number;
  NETREV : number;
  CSR : number;
  EXP : number;
  margin : number;
  marginpercent : number;
 }

@Component({
  selector: 'app-results-statement',
  templateUrl: './results-statement.component.html',
  styleUrls: ['./results-statement.component.css']
})
export class ResultsStatementComponent implements OnInit {
  
  subs : Array<Subscription> = [];
  summresults : ResultsHeader = {REV : 0.00, NETREV: 0.00, CSR: 0.00, EXP: 0.00, margin : 0.00, marginpercent : 0.00}
  itemList
  itemDisplay
  offeringList
  ordersListCount : number = null;
  filters : Array<Filter> = []

  selectedProject : string = ""
  selectedPortfolio

  constructor(private listService : SubprojectTransactionService,
  			      private summService : SubprojectTransactionService,
  			      private tableSvc : TableService, 
              private filterService : PortfolioFilterService,
              private offeringService : OfferingService,) { }

  ngOnInit() {

  	 this.subs.push(this.filterService.data
      .subscribe ((filtros : any) => {
          this.filters = filtros;
          this.testProject();
      }));
      
      //this.getOfferings();
  }

  testProject() {
    this.selectedProject = this.filterService.getValueOf("id_project");
    this.selectedPortfolio = this.filterService.getValueOf("id_portfolio");
    
    if (this.selectedProject) {
      console.log("offering resutls -  rolou por portfolio")
      this.getListData(); 

    } else if  (this.selectedPortfolio) {
      this.getListData(); 
      console.log("offering resutls - nao rolou por portfolio")
    }
  }

   myTable
   getListData() {
       console.log("tentou pegar transactions")
       this.subs.push(this.listService.getTransactions(this.filters)
      .subscribe((data1 : any) => {
          this.itemList = data1;
          this.itemDisplay = data1;
          console.log("results statements ")
          console.log(data1)
          this.ordersListCount = data1.length -1 ; // retirando o total

          this.myTable = {
              itemList : {
                fields : [
                   {key : "id_subproject_results", label: "ID", alias : "ID", format: "", visible: false},
                   {key : "id_subproject_contract", label: "Contract ID", alias : "Contract ID", format: "", visible: false},
                   {key : "contractname", label: "Contract Name", alias : "Contract Name", format: "", visible: false},
                   {key : "contractsubname", label: "Contract Subname", alias : "Contract Subname", format: "", visible: false},
                  
                   {key : "period", label: "Period", alias : "Period", format: "", visible: false},
                   {key : "projname", label: "Project", alias : "Project Name", format: "", visible: false},
                   {key : "id_project", label: "Project ID", alias : "Project ID", format: "", visible: false},
                   {key : "offname", label: "Row Offering", alias : "Row Offering", format: "", visible: false}, 

                   { key: "cusname", label: "Customer", alias: "Customer", format: "", visible: false },
                   { key: "comunidade", label: "Department", alias: "Department", format: "", visible: false },
                   { key: "rt", label: "Area", alias: "Area", format: "", visible: false }, 

                   { key: "projempname", label: "Leader Name", alias: "Leader Name", format: "", visible: false }, 
                   {key : "projoffname", label: "Proj Offering", alias : "Proj Offering", format: "", visible: false}, 
                   {key : "subprojname", label: "Subproject Name", alias : "Subproject Name", format: "", visible: true}, 
                   
                   {key : "type", label: "Type", alias : "Type", format: "", visible: true},
                   {key : "offname", label: "Transaction Offering", alias : "Transaction Offering", format: "", visible: false}, 
                   {key : "catname", label: "Category", alias : "Category", format: "", visible: true}, 
                   {key : "sapcode", label: "Employee SapCode", alias : "Employee SapCode", format: "", visible: false}, 
                   {key : "empname", label: "Employee Name", alias : "Employee Name", format: "", visible: true}, 
                   {key : "empshortname", label: "Employee Shortname", alias : "Employee Shortname", format: "", visible: false}, 
                   { key: "extraname", label: "Employee Extra Name", alias: "Employee Extra Name", format: "", visible: false },
                   { key: "comment", label: "Comment", alias: "Comments", format: "", visible: false },
                 
                   {key : "tax", label: "Rate", alias : "Rate", format: "currency", visible: true},
                   {key : "quantity", label: "Qtt", alias : "Quantity", format: "number", visible: true},
                   {key : "total", label: "Total", alias : "Total", format: "currency", visible: true},
                ],
                results : data1.data,//data.slice(0, data.length-1)
                //buttons : [
                //  {key : "edit", label: "", hint: "Edit this project", icon: "fas fa-edit"}
                //]
              },
              headers : {
                fields : [
                   {key : "REV", label : "Gross Revenue", format: "currency"},
                   {key : "NETREV", label : "Net Revenue", format: "currency"},
                   {key : "CSR", label : "CSR", format: "currency"},
                   {key : "EXP", label : "Expenses", format: "currency"},
                   {key : "margin", label : "Analytic Margin", format: "currency"},
                   {
                       key : "marginpercent", 
                       label : "PTD", 
                       format: "percentage",
                       red : 0.40,
                       direction : "<"
                   },
              ],
              results : data1.headers//data.slice(data.length-1)
          }
            
         }
      }));
      //this.filterService.addFilter({"key": "groupby", "value" : "id_project"})
      this.subs.push(this.summService.getTransactionsSummarizedPerProject(this.filters)
      .subscribe((data2 : any) => {
          this.summresults = data2[0];
          console.log(data2)

          //this.myTable.
      }));
      
  }

   PageDataChange($event : Array<SubProjectTransaction>) : void {
      this.itemDisplay = $event;
  }

  getOfferings() {
    this.subs.push(this.offeringService.getAll()
      .subscribe((data : any) => {
          this.offeringList = data;
      }))
  }

  sort1(sortAttribute: any) {
        //this.itemDisplay = this.tableSvc.sort(sortAttribute, this.itemDisplay);
        this.itemList = this.tableSvc.sort(sortAttribute, this.itemList);
  }

  getFile() {
     new  AngularCsv(this.itemList, "SubProject Transactions", this.csvOptions);
  }

  ngOnDestroy() {
     this.subs.map(s => s.unsubscribe);
  }

  csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: true,
      title: 'Subproject Transactions',
      useBom: true,
      noDownload: false,
      headers: ["ProjectId", "Project Name" , "TransactionId", "SubprojectId", "Subproject Name", "Period", "Type" , "CategoryId", "Category Name", "EmployeeId", "Employee Name", "Extraname", "Tax", "Quantity", "Total", "Comment", "OfferingId", "Offering Name", "ContractId", "Contract Name" , "Contract SubName", "Type Id"]
  };

}
