import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offering-analysis',
  templateUrl: './offering-analysis.component.html',
  styleUrls: ['./offering-analysis.component.css']
})
export class OfferingAnalysisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
