import { Component, OnInit } from '@angular/core';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';
import {ResultsService} from '../../../shared/services/results.service';
import {ManagerService} from '../../../shared/services/manager.service';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {User} from '../../../shared/model/user.model';

export class ManagerResults {
	id_project : string;
	name : string;
  	periods : string;
  	NetRevenue : number;
  	AnalyticMargin : number;
  	sales_margin : number;
  	MarginPercent : number ;
  	ExpectedMargin : number;

  	constructor (response: any) {
	      this.id_project = response.id_project;
	      this.name = response.name;
	      this.periods = response.periods;
	      this.NetRevenue = response.NetRevenue;
	      this.AnalyticMargin = response.AnalyticMargin;
	      this.sales_margin = response.sales_margin;
	      this.MarginPercent = response.MarginPercent;
	      this.ExpectedMargin = response.ExpectedMargin;
	}
}

export class ManagerHeader {
	  empId : number =0;
	  empName : string = "";
	  NetRevenue : number = 0.00;
	  AnalyticMargin : number = 0.00;
	  MarginPercent : number = 0.00;
	  results : ManagerResults[]
	  
	  constructor (response: any) {
	      this.empId = response.empId;
	      this.empName = response.empName;
	      this.NetRevenue = response.NetRevenue;
	      this.AnalyticMargin = response.AnalyticMargin;
	      this.MarginPercent = response.MarginPercent;
	      this.results = response.results;
	  }
}



@Component({
  selector: 'app-manager-dashboard',
  templateUrl: './manager-dashboard.component.html'
})
export class ManagerDashboardComponent implements OnInit {

	managerList : any;
	managerResults: ManagerHeader;
	periodResults = new Array;
	periodos = new Array;
	selectedManager : number = 0;
    user: any;

	constructor(private auth : AuthenticationService,
                private colorConfig:ThemeConstantService, 
				private managerService : ManagerService,
				private resultsService : ResultsService,
				private managerPeriodsService : ResultsService) { }

	ngOnInit() {
	  	this.managerService.getAll()
	            .subscribe((data : any) => {
	               this.managerList = data;
	               //console.log("managers:")
	               //console.log(data)
	    });

        this.auth.currentUser
       .subscribe((data : User) => {
           //console.log("[manager dashboard] user:");
           //console.log(data);
           this.user = data[0];
           this.selectedManager = this.user.id_employee;
           this.onItemChange(this.selectedManager);
           
       });
	}
  	
  onItemChange(id) {
    console.log(id);
    let filtros = [{key : 'id_employee' , 'value' : id}]
    /*
    this.resultsService.getResults(filtros, "project")
		.subscribe((data : any) => {
	    	this.managerResults = data.data;
	    	console.log(data)
	   	});
    */
	this.managerPeriodsService.getResultsMonthly(filtros, "project")
		.subscribe((data : any) => {
			
            console.log("[Manager Results over time");
			console.log(data);
			let valores = new Array;
			let labels = new Array;
	    	for (var i = 0; i < data.length; ++i) {           
                labels[i] = data[i].period;
                valores[i] = data[i].MarginPercent;
             }

             this.revenueChartData = [{
                    data : valores,
                    label : "Margin"
                }];

            this.revenueChartLabels = labels;

		     console.log("periodos")
		     console.log(this.revenueChartLabels)
		     console.log("valores")
		     console.log(this.revenueChartData)
		   	});
  }
  
	themeColors = this.colorConfig.get().colors;
    blue = this.themeColors.blue;
    blueLight = this.themeColors.blueLight;
    cyan = this.themeColors.cyan;
    cyanLight = this.themeColors.cyanLight;
    gold = this.themeColors.gold;
    purple = this.themeColors.purple;
    purpleLight = this.themeColors.purpleLight;
    red = this.themeColors.red;


 	revenueChartFormat: string = 'revenueMonth';

    revenueChartData: Array<any> = [{ 
        data: [30, 60, 40, 50, 40, 55, 85, 65, 75, 50, 70],
        label: 'Series A' 
    }];
    currentrevenueChartLabelsIdx = 1;
    revenueChartLabels:Array<any> = ["16th", "17th", "18th", "19th", "20th", "21th", "22th", "23th", "24th", "25th", "26th"];
    revenueChartOptions: any = {
        maintainAspectRatio: false,
        responsive: true,
        hover: {
            mode: 'nearest',
            intersect: true
        },
        tooltips: {
            mode: 'index'
        },
        scales: {
            xAxes: [{ 
                gridLines: [{
                    display: false,
                }],
                ticks: {
                    display: true,
                    fontColor: this.themeColors.grayLight,
                    fontSize: 13,
                    padding: 10
                }
            }],
            yAxes: [{
                gridLines: {
                    drawBorder: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 4]  
                },
                ticks: {
                    display: true,
                    max: 0.7,                            
                    stepSize: 0.1,
                    fontColor: this.themeColors.grayLight,
                    fontSize: 13,
                    padding: 10
                }  
            }],
        }
    };
    revenueChartColors: Array<any> = [
        { 
            backgroundColor: this.themeColors.transparent,
            borderColor: this.blue,
            pointBackgroundColor: this.blue,
            pointBorderColor: this.themeColors.white,
            pointHoverBackgroundColor: this.blueLight,
            pointHoverBorderColor: this.blueLight
        }
    ];
    revenueChartType = 'line';

	

}
