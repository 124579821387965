
export class Project {

  id_project : string;
  id_subsidiary : number;
  id_bu : number;
  id_line : number;
  linname : string;
  linshortname : string;
  id_customer : number;
  cusname : string;
  cusshortname : string;
  id_employee : number;
  empname :string;
  empshortname : string;
  name : string;
  shortname : string;
  salesmargin : number;
  project_tax : number;
  rappel : string;
  status : string;

  

}

export class ProjectDisplay {
  id_project : string;
  id_subsidiary : number;
  id_bu : number;
  id_line : number;
  linname : string;
  linshortname : string;
  id_customer : number;
  cusname : string;
  cusshortname : string;
  id_employee : number;
  empname :string;
  empshortname : string;
  name : string;
  shortname : string;
  salesmargin : number;
  project_tax : number;
  rappel : string;
  status : string;
	checked : boolean = false;
 }
