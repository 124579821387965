import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {User, Menu} from '../model/user.model';

import {AuthenticationService} from './authentication.service';
import {API_ENDPOINT} from '../../app.api'
import { BehaviorSubject, Observable, of } from 'rxjs';
import { cache } from '@amcharts/amcharts4/core';
import { tap, catchError} from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class GenericalService {

  user : User;
  podeSeguir = false;

  private myMenusSubject  = new BehaviorSubject<Menu[]>([]);
  public myMenus = this.myMenusSubject.asObservable();

  constructor(private http : HttpClient, private auth : AuthenticationService) { 
      this.auth.currentUser.subscribe((data : User) => {
            this.user = data[0];
            //console.log("[generical service] user data (data mesmo): ");
            //console.log(data);
      });
  }
 
  get(url) {
    

      	let params = new HttpParams;
        params = params.append('organization',this.user.id_organization.toString());
        //params = params.append('businessunit',this.user.id_bu.toString());
        params = params.append('id_user',this.user.id_user.toString());

        let authorizationData = 'Basic ' + btoa(this.user.login + ':' + this.user.password);
        let headerOptions = new HttpHeaders({ 
         			'Content-Type': 'application/json',
         			'Authorization' : authorizationData
        });
    //console.log(url)
        return this.http.get(url,  { headers: headerOptions , params: params  });
     
  }

/*
  get(url): Observable<any> {
    let params = new HttpParams;
    params = params.append('organization', this.user.id_organization.toString());
    //params = params.append('businessunit',this.user.id_bu.toString());
    params = params.append('id_user', this.user.id_user.toString());

    let authorizationData = 'Basic ' + btoa(this.user.login + ':' + this.user.password);
    let headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': authorizationData
    });
    //console.log(url)
    return this.http.get<any>(url, { headers: headerOptions, params: params })
      .pipe(
        tap(_ => this.log('fetched heroes')),
        catchError(this.handleError<any>('getHeroes', []))
      );

  }
  */
  
  getFiltered(url, filters) {
    let params = new HttpParams;
    params = params.append('organization',this.user.id_organization.toString());
    //params = params.append('businessunit',this.user.id_bu.toString());
    params = params.append('id_user',this.user.id_user.toString());

    for (var i = 0; i < filters.length; ++i) {
       if (filters[i].key !== 0) {
          let value = filters[i].value;
          if (typeof(value) === "number") {
             value = value.toString();
          }
          params = params.append(filters[i].key, value)
       }
    }

    let authorizationData = 'Basic ' + btoa(this.user.login + ':' + this.user.password);
    let headerOptions = new HttpHeaders({ 
           'Content-Type': 'application/json',
           'Authorization' : authorizationData
    });
     return this.http.get(url,  { headers: headerOptions , params: params  });
  }
  
  /*
  getFiltered(url, filters): Observable<any[]> {
    let params = new HttpParams;
    params = params.append('organization', this.user.id_organization.toString());
    //params = params.append('businessunit',this.user.id_bu.toString());
    params = params.append('id_user', this.user.id_user.toString());

    for (var i = 0; i < filters.length; ++i) {
      if (filters[i].key !== 0) {
        let value = filters[i].value;
        if (typeof (value) === "number") {
          value = value.toString();
        }
        params = params.append(filters[i].key, value)
      }
    }

    let authorizationData = 'Basic ' + btoa(this.user.login + ':' + this.user.password);
    let headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': authorizationData
    });
    return this.http.get<any[]>(url, { headers: headerOptions, params: params })
      .pipe(
        tap(_ => this.log('fetched heroes')),
        catchError(this.handleError<any[]>('getHeroes', []))
      );
  }
  */

  getFilteredwithEndpoint(url, filters) {
    let params = new HttpParams;
    params = params.append('organization',this.user.id_organization.toString());
    //params = params.append('businessunit',this.user.id_bu.toString());
    params = params.append('id_user',this.user.id_user.toString());

    for (var i = 0; i < filters.length; ++i) {
       if (filters[i].key !== 0) {
          let value = filters[i].value;
          if (typeof(value) === "number") {
             value = value.toString();
          }
          params = params.append(filters[i].key, value)
       }
    }

    let authorizationData = 'Basic ' + btoa(this.user.login + ':' + this.user.password);
    let headerOptions = new HttpHeaders({ 
           'Content-Type': 'application/json',
           'Authorization' : authorizationData
    });
     return this.http.get(`${API_ENDPOINT}/${url}`,  { headers: headerOptions , params: params  });
  }


  getFilteredWithEntity(url, filters, entity) {
    let params = new HttpParams;
    params = params.append('organization',this.user.id_organization.toString());
    params = params.append('id_user',this.user.id_user.toString());
    if (entity != "") {
       params = params.append('entity', entity);  
    }
    
    for (var i = 0; i < filters.length; ++i) {
       if (filters[i].key !== 0) {
          let value = filters[i].value;
          if (typeof(value) === "number") {
             value = value.toString();
          }
          params = params.append(filters[i].key, value)
       }
    }
    let authorizationData = 'Basic ' + btoa(this.user.login + ':' + this.user.password);
    let headerOptions = new HttpHeaders({ 
           'Content-Type': 'application/json',
           'Authorization' : authorizationData
    });
     return this.http.get(url,  { headers: headerOptions , params: params  });
  }

  put(url, obj) {
    let json = JSON.stringify(obj);
    let params = new HttpParams;
    params = params.append('organization',this.user.id_organization.toString());
    params = params.append('businessunit',this.user.id_bu.toString());
    params = params.append('id_user',this.user.id_user.toString());

    let authorizationData = 'Basic ' + btoa(this.user.login + ':' + this.user.password);
    let headerOptions = new HttpHeaders({ 
           'Content-Type': 'application/json',
           'Authorization' : authorizationData
    });

    //console.log("entrei no put, obj:");
    //console.log(obj)

    return this.http.put(url, json, { headers: headerOptions , params: params})
    .subscribe(
      data  => obj,
      error  => {
        console.log("Error", error);
    })
  }

  putclean(url, obj) {
    console.log("generical service put clean");
    console.log(url)
    let json = JSON.stringify(obj);
    let params = new HttpParams;
    params = params.append('organization',this.user.id_organization.toString());
    params = params.append('businessunit',this.user.id_bu.toString());
    params = params.append('id_user',this.user.id_user.toString());

    let authorizationData = 'Basic ' + btoa(this.user.login + ':' + this.user.password);
    let headerOptions = new HttpHeaders({ 
           'Content-Type': 'application/json',
           'Authorization' : authorizationData
    });

    //console.log("entrei no put, obj:");
    //console.log(obj)

    return this.http.put(url, json, { headers: headerOptions , params: params})
    
  }

  post(url, obj) {
    let json = JSON.stringify(obj);
    let params = new HttpParams;
    params = params.append('organization',this.user.id_organization.toString());
    params = params.append('businessunit',this.user.id_bu.toString());
    params = params.append('id_user',this.user.id_user.toString());

    let authorizationData = 'Basic ' + btoa(this.user.login + ':' + this.user.password);
    let headerOptions = new HttpHeaders({ 
           'Content-Type': 'application/json',
           'Authorization' : authorizationData
    });

    return this.http.post(url, json, { headers: headerOptions , params: params})
    .subscribe(
      data  => obj,
      error  => {
        console.log("Error", error);
      })
  }

  postclean(url, obj) {

    let json = JSON.stringify(obj);
    let params = new HttpParams;
    params = params.append('organization',this.user.id_organization.toString());
    params = params.append('businessunit',this.user.id_bu.toString());
    params = params.append('id_user',this.user.id_user.toString());

    let authorizationData = 'Basic ' + btoa(this.user.login + ':' + this.user.password);
    let headerOptions = new HttpHeaders({ 
           'Content-Type': 'application/json',
           'Authorization' : authorizationData
    });

    return this.http.post(url, json, { headers: headerOptions , params: params})
  }

  postOnlyUrl(url) {

    //console.log(this.user);
    let json = JSON.stringify({});
    let params = new HttpParams;
    params = params.append('organization',this.user.id_organization.toString());
    params = params.append('businessunit',this.user.id_bu.toString());
    params = params.append('id_user',this.user.id_user.toString());

    let authorizationData = 'Basic ' + btoa(this.user.login + ':' + this.user.password);
    let headerOptions = new HttpHeaders({ 
           'Content-Type': 'application/json',
           'Authorization' : authorizationData
    });

    return this.http.post(url, json, { headers: headerOptions , params: params})
  }

  delete(url) {    
    let params = new HttpParams;
    params = params.append('organization',this.user.id_organization.toString());
    params = params.append('businessunit',this.user.id_bu.toString());
    params = params.append('id_user',this.user.id_user.toString());

    let authorizationData = 'Basic ' + btoa(this.user.login + ':' + this.user.password);
    let headerOptions = new HttpHeaders({ 
           'Content-Type': 'application/json',
           'Authorization' : authorizationData
    });

    return this.http.delete(url,  { headers: headerOptions , params: params})
    //.subscribe(
    //  error  => {
    //    console.log("Error", error);
    //})
  }


  goTo(filters) {
    let url = `${API_ENDPOINT}/goto`;

    //console.log("goTo obj recebido:");
    //console.log(filters)

    let params = new HttpParams;
    params = params.append('organization',this.user.id_organization.toString());
    params = params.append('businessunit',this.user.id_bu.toString());
    params = params.append('id_user',this.user.id_user.toString()); 

    let jsonfinal = JSON.stringify(filters);

    let authorizationData = 'Basic ' + btoa(this.user.login + ':' + this.user.password);
    let headerOptions = new HttpHeaders({ 
           'Content-Type': 'application/json',
           'Authorization' : authorizationData
    });
     return this.http.post(url,  jsonfinal, { headers: headerOptions , params: params  });
  }

  runSQL(filters) {
    let url = `${API_ENDPOINT}/sql`;

    let params = new HttpParams;
    params = params.append('organization',this.user.id_organization.toString());
    params = params.append('businessunit',this.user.id_bu.toString());
    params = params.append('id_user',this.user.id_user.toString()); 

    let jsonfinal = JSON.stringify(filters);

    let authorizationData = 'Basic ' + btoa(this.user.login + ':' + this.user.password);
    let headerOptions = new HttpHeaders({ 
           'Content-Type': 'application/json',
           'Authorization' : authorizationData
    });
     return this.http.post(url,  jsonfinal, { headers: headerOptions , params: params  });
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    //this.messageService.add(`HeroService: ${message}`);
    console.log(message)
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
