import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard} from '../shared/auth-guard/auth.guard'

import {SubprojectListComponent} from './subproject/subproject-list/subproject-list.component'
import {NewTransactionComponent} from './subproject/subproject-plan/new-transaction/new-transaction.component'
import {NewTransactionComposedComponent} from './subproject/subproject-plan/new-transaction-composed/new-transaction-composed.component';

import {NewContractComponent} from './subproject/subproject-plan/new-contract/new-contract.component';
import {SubprojectPlanComponent} from './subproject/subproject-plan/subproject-plan.component'
import {PortfolioResultsComponent} from './portfolio-results/portfolio-results.component'
import {NewSubprojectComponent} from './subproject/subproject-list/new-subproject/new-subproject.component';
import {EmployeeListComponent} from './portfolio-registers/employee-list/employee-list.component';
import {NewEmployeeComponent} from './portfolio-registers/employee-list/new-employee/new-employee.component';
import {NewEmployeeFastComponent} from './portfolio-registers/employee-list/new-employee-fast/new-employee-fast.component';
import {EmployeeCsrFastComponent} from './portfolio-registers/employee-list/employee-csr-fast/employee-csr-fast.component'; 
import {PortfolioSetupComponent} from './portfolio-setup/portfolio-setup.component';
import {PortfolioPageComponent} from './portfolio-setup/portfolio-page/portfolio-page.component';
import {NewPortfolioComponent} from './portfolio-setup/new-portfolio/new-portfolio.component';
import {NpsListComponent} from './portfolio-registers/nps-list/nps-list.component';
import {NewNpsComponent} from './subproject/subproject-plan/new-nps/new-nps.component';
import {ControleDeHorasComponent} from './controle-de-horas/controle-de-horas.component';
import {AdminPortfolioComponent} from './admin-portfolio/admin-portfolio.component';
import {ControlPortfolioComponent} from './control-portfolio/control-portfolio.component';
import {SummarizedResultsComponent} from './summarized-results/summarized-results.component'
import { OfferingResultsComponent } from './portfolio-results/offering-results/offering-results.component';
import { LeaderResultsComponent } from './portfolio-results/leader-results/leader-results.component';
import { SubprojectResultsComponent } from './portfolio-results/subproject-results/subproject-results.component';
import { CustomerResultsComponent } from './portfolio-results/customer-results/customer-results.component';
import { SubprojectDatedResultsComponent } from './portfolio-results/subproject-dated-results/subproject-dated-results.component';
import { ResultsStatementComponent } from './portfolio-results/results-statement/results-statement.component';
import { PeopleHoursComponent } from './portfolio-results/people-hours/people-hours.component';
import { NonbillableListComponent } from './portfolio-results/nonbillable-list/nonbillable-list.component';
import { MainResultsComponent } from './portfolio-results/main-results/main-results.component';

const routes: Routes = [
	  
    {
        path: 'control',
        component: ControlPortfolioComponent, 
        data: {
            title: 'Portfolio Control',
            headerDisplay: "none"
        },
        canActivate : [AuthGuard]
    },
    {
        path: 'results',
        component: PortfolioResultsComponent, 
        data: { title: 'Portfolio Results', headerDisplay: "true" },
        canActivate: [AuthGuard],
        children: [
          { path: '', component: OfferingResultsComponent, data: { title: 'Offering', headerDisplay: "none" } },
          { path: 'offering', component: OfferingResultsComponent, data: { title: 'Offering', headerDisplay: "none" }},
          { path: 'leader', component: LeaderResultsComponent, data: { title: 'Leader', headerDisplay: "none" }, },
          { path: 'subprojects', component: SubprojectResultsComponent, data: { title: 'SubProjects', headerDisplay: "none" },  },
          { path: 'customer', component: CustomerResultsComponent, data: { title: 'Customer', headerDisplay: "none" }, },
          { path: 'subprojects-history', component: SubprojectDatedResultsComponent, data: { title: 'Subprojects History', headerDisplay: "none" }, },
          { path: 'statement', component: ResultsStatementComponent, data: { title: 'Statement', headerDisplay: "none" }, },
          { path: 'hours', component: PeopleHoursComponent, data: { title: 'Hours', headerDisplay: "none" }, },
          { path: 'nonbillable', component: NonbillableListComponent, data: { title: 'Non Billable', headerDisplay: "none" }, },
          { path: 'ext', component: MainResultsComponent, data: {title : 'EXTs'}}
        ]
    },
    {
        path: 'subproject',
        component: SubprojectListComponent, 
        data: {
            title: 'SubProject',
            headerDisplay: "none"
        },
        canActivate : [AuthGuard]
    },
    {
        path: 'nps',
        component: NpsListComponent, 
        data: {
            title: 'NPS',
            headerDisplay: "none"
        },
        canActivate : [AuthGuard]
    },
    {
        path: 'nps/addnps',
        component: NewNpsComponent, 
        data: {
            title: 'Dashboard',
            headerDisplay: "none"
        },
        canActivate : [AuthGuard]
    },
    {
        path: 'employee',
        component: EmployeeListComponent, //FiltertestComponent,
        data: {
            title: 'Employee',
            headerDisplay: "none"
        },
        canActivate : [AuthGuard]
    },

    {
        path: 'setup',
        component: PortfolioSetupComponent, //FiltertestComponent,
        data: {
            title: 'Setup',
            headerDisplay: "none"
        },
        canActivate : [AuthGuard]
    },
    {
        path: ':id',
        component: PortfolioPageComponent, //FiltertestComponent,
        data: {
            title: 'Dashboard',
            headerDisplay: "none"
        },
        canActivate : [AuthGuard]
    },
    { /////
        path: 'addportfolio',
        component: NewPortfolioComponent, //FiltertestComponent,
        data: {
            title: 'Dashboard',
            headerDisplay: "none"
        },
    },
    {
        path: 'subproject/:id',
        component: SubprojectPlanComponent, //FiltertestComponent,
        data: {
            title: 'Dashboard',
            headerDisplay: "none"
        },
        canActivate : [AuthGuard]
    },
    {
        path: 'subproject/addtransaction',
        component: NewTransactionComponent, //FiltertestComponent,
        data: {
            title: 'Dashboard',
            headerDisplay: "none"
        },
    },
    {
        path: 'subproject/addtransactioncomposed',
        component: NewTransactionComposedComponent, //FiltertestComponent,
        data: {
            title: 'Dashboard',
            headerDisplay: "none"
        },
    },    
    {
        path: 'subproject/addcontract',
        component: NewContractComponent, //FiltertestComponent,
        data: {
            title: 'Dashboard',
            headerDisplay: "none"
        },
    },
    {
        path: 'subproject/addnew',
        component: NewSubprojectComponent, //FiltertestComponent,
        data: {
            title: 'Dashboard',
            headerDisplay: "none"
        },
    },
    {
        path: 'subproject/employee/adnew',
        component: NewEmployeeComponent, //FiltertestComponent,
        data: {
            title: 'Dashboard',
            headerDisplay: "none"
        },
    },
    {
        path: 'subproject/employee/adnewfast',
        component: NewEmployeeFastComponent, //FiltertestComponent,
        data: {
            title: 'Dashboard',
            headerDisplay: "none"
        },
    },
    {
        path: 'subproject/employee/csrfast',
        component: EmployeeCsrFastComponent, //FiltertestComponent,
        data: {
            title: 'Dashboard',
            headerDisplay: "none"
        },
    },
    {
        path: 'results/summary',
        component: SummarizedResultsComponent, 
        data: {
            title: 'Dashboard',
            headerDisplay: "none"
        },
       //canActivate : [AuthGuard]
    },
    
    




    
    
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortfolioRoutingModule { }
