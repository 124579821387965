import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ThemeConstantService } from '../../../../shared/services/theme-constant.service';

@Component({
  selector: 'app-bar-chart-stacked',
  templateUrl: './bar-chart-stacked.component.html',
  styleUrls: ['./bar-chart-stacked.component.css']
})
export class BarChartStackedComponent implements OnInit {
  
  @Input() chart

  themeColors = this.colorConfig.get().colors;
    
    gold = this.themeColors.gold;
    blue = this.themeColors.blue;
    red = this.themeColors.red;
    volcano = this.themeColors.volcano;
    cyan = this.themeColors.cyan;
    lime = this.themeColors.lime;
    //orange = this.themeColors.orange;

    blueLight = this.themeColors.blueLight;
    purple = this.themeColors.purple;

    redLight = this.themeColors.redLight;
    purpleLight = this.themeColors.purpleLight;
    cyanLight = this.themeColors.cyanLight;


  ChartOptions: any = {
        scaleShowVerticalLines: false,
        maintainAspectRatio: true,
        responsive: true,
        tooltips: { 
           mode: 'label', 
           label: 'mylabel', 
           callbacks: { 
               label: function(tooltipItem, data) { 
                   return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
               }, 
           }, 
        }, 
        scales: {
            xAxes: [{
                categoryPercentage: 0.35,
                barPercentage: 0.70,
                display: true,
                stacked: true, 
                scaleOverride: true,
                scaleLabel: function (valuePayload) {
                    return Number(valuePayload.value).toFixed(2).replace('.',',') + '$';
                },
                
                gridLines: {
                    drawBorder: false,
                    offsetGridLines: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 4]
                },
                ticks: {
                    display: true,
                    beginAtZero: false,
                    fontSize: 1,
                    padding: 10
                }
            }],
            yAxes: [{
                display: true,
                stacked: true, 
                ticks: {
                    callback: function(label, index, labels) {
                        //return (label/1000).toFixed(2).replace('.',',')+'k';
                        return (label).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
                    },
                    display: true,
                    beginAtZero: true,
                    fontSize: 13,
                },
                scaleOverride: true,
                scaleLabel: function (valuePayload) {
                    return Number(valuePayload.value).toFixed(2).replace('.',',') + '$';
                },
                
                gridLines: {
                    drawBorder: false,
                    offsetGridLines: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 4]
                },
                
            }]
        }
    };
    ChartLabels: string[] = ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'];
    ChartType = 'bar';
    ChartColors: Array<any> = [
        { 
            backgroundColor: this.themeColors.gold,
            borderWidth: 0
        },
        { 
            backgroundColor: this.themeColors.lime,
            borderWidth: 0
        },
        {
            backgroundColor: this.themeColors.volcano,
            borderWidth: 0
        },
        
    ];
    ChartData: any[] = [
        { 
            data: [20, 30, 35, 45, 55, 45],
            label : "open"
        },
        { 
            data: [20, 30, 35, 45, 55, 45],
            label : "won"
        },
        { 
            data: [20, 30, 35, 45, 55, 45],
            label : "lost"
        },
 
    ];

  constructor(private colorConfig:ThemeConstantService,) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
     
     if (this.chart) {
         this.ChartLabels = this.chart.labels;
         this.ChartData = this.chart.data;
         //this.ChartType = this.chart.chartType;
     }
     
     //this.ChartLegend = this.chart.legends;
  }

}
