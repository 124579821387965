import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';

import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {Subscription} from 'rxjs';

import { GenericalService} from '../../services/generical.service';
import {DateService} from '../../services/date.service';

import { UploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';

import {API_ENDPOINT} from '../../../app.api';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';


@Component({
  selector: 'app-flexmodal',
  templateUrl: './flexmodal.component.html',
  styleUrls: ['./flexmodal.component.css']
})
export class FlexmodalComponent implements OnInit {

  @Input() _id // Recebe um id, que pode ser 0 ou o valor da chave
  @Input() formFields // composicao dos campos
  @Input() endpoint // endpoint para salvar (insert/update)

  formProps : Array<any> = [];
  formData

  salvando : boolean = false;

  arrayzao = {}

  subs: Array<Subscription> = [];
  

  dateFormat : string = 'dd/MM/yyyy';

  loading : boolean = false;
  uploadURL : string = `${API_ENDPOINT}avatar`;
  
  sliderBasicValue = false;
  

  editorConfig = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],               
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],  
            [{ 'align': [] }],
            ['link', 'image']                        
        ]
    };

  constructor(private gServ : GenericalService,
              private gServAux : GenericalService,
              private dateService : DateService,
              private notification: NzNotificationService, 
              private modal: NzModalRef,
              private gta : GoogleAnalyticsService  ) { }

  ngOnInit(): void {

    // criação do formulário a partir da variável de input formFields
    // essa variável vem do componente listagem e é composta por um json
    // disponível o arquivo cadastro.model.ts

  
    if (this._id != 0) {
      this.getSelectedId(this._id);
      
    }

    const formDataObj = {};
  	for (const prop of this.formFields) {
      //formDataObj[prop.key] = new FormControl(this.formFields[prop.key]);
      if (prop.label == "_id") {
        formDataObj[prop.key] = new FormControl(this.formFields[prop.key]);
      } else {
        formDataObj[prop.key] = new FormControl(this.formFields[prop.key], { validators: [Validators.required] });
      }

   
      
      
      this.formProps.push(prop.key);
      // se for do tipo AI, tem que passar boolean
      
        
      this.populaDadosSelect(prop); // caso seja um select

      
  	}  
    this.formData = new FormGroup(formDataObj);

    for (const prop of this.formFields) {
      if (prop.type == "AI") {
        if (prop.value = "A") {
          this.formData.controls[prop.key].setValue(true);
        } else {
          this.formData.controls[prop.key].setValue(false);
        }
      } else {
        if (prop.value != "") {
          this.formData.controls[prop.key].setValue(prop.value);
        }
      }
    }

   
    
  }

  // Função exclusiva para popular selects
  populaDadosSelect(prop) {
    if (prop.type == 'select') {
      this.subs.push(
        this.gServAux.getFiltered(`${API_ENDPOINT}/${prop.dataFrom.endpoint}`, [])
          .subscribe(
            data => {
              this.arrayzao[prop.key] = data;
            },
            error => {
              let errorMsg = "Loading " + this.endpoint + " => " + error.error.message;
              this.notification.error("Error", errorMsg);
              this.gta.eventEmitter("loading_error", "broken_experience", "listing_a_box", "[flex-modal] " + errorMsg, 3);
            }
          ));

    }
  }

  // Busca os dados através do serviço genérico
  getSelectedId(id) {
    

    this.subs.push(
      this.gServ.get(`${this.endpoint}/${id}`)
        .subscribe(
          data => {
            if (data) {
              
              let mydata = data[0];
              this.fullFillForm(mydata);
            }
          },
          error => {
            let errorMsg = "Loading " + this.endpoint + " => " + error.error.message;
            this.notification.error("Error", errorMsg);
            this.gta.eventEmitter("loading_error", "broken_experience", "loading_by_id", "[flex-modal] " + errorMsg, 5);
          }
        ));
  }

  // Faz um loop para preencher o form
  fullFillForm(obj : any) {
      //console.log("fullfill form values");
      //console.log(obj)
      for (const prop of this.formFields) {
      	  
          switch(prop.type) {

              case 'AI': {
                
                  if (obj[prop.key] == 'A') {
                   this.formData.controls[prop.key].setValue(true); 
                  } else {
                   this.formData.controls[prop.key].setValue(false); 
                  }
                  break;
              }
              case 'percentage': {
                  if (obj[prop.key]) {
                     this.formData.controls[prop.key].setValue(obj[prop.key]*100);
                  } else {
                     this.formData.controls[prop.key].setValue(null);
                  }
                  break;
              }

              default : {
                this.formData.controls[prop.key].setValue(obj[prop.key]);
                break;
              }
          }

    }
    this.final_obj = obj;

      
  }

  final_obj = {}

  SubmitForm() {
    this.salvando = true;
    if (this.formData.invalid) {
      this.formData.markAllAsTouched();
      this.salvando = false;

      console.log("formulario invalido");
      console.log(this.formData)
      return;
    }

      const obj = {};

      for (const prop of this.formFields) {

          // tratativas de texto

          switch(prop.type) {

              case 'AI': {
                  if (this.formData.controls[prop.key].value === true) {
                    obj[prop.key] = 'A';
                  } else {
                    obj[prop.key] = 'I';
                  }
                  break;
              }

              case 'percentage': {

                  //console.log("entrou em percentage [" + prop.key + "] com o tipo [" + prop.type + "]")
                  //console.log(this.formData.controls[prop.key].value);

                  obj[prop.key] = 0;
                  if (this.formData.controls[prop.key].value) {
                     console.log("if1 ")
                     if (Number(this.formData.controls[prop.key].value) > 0) {
                        console.log("if2 ")
                         obj[prop.key] = Number(this.formData.controls[prop.key].value)/100;
                     }
                  }

                  //console.log("valor final do campo [" + prop.key + "]");
                  //console.log (obj[prop.key]);
                  break;
                  
              }

              default : {
                  obj[prop.key] = this.formData.controls[prop.key].value;
                  break;
              }  
          }
          
       

      }
      this.final_obj = obj;
      //console.log("submitted object");
      //console.log(obj)
      
    if (this._id == 0) {

      this.subs.push(
        this.gServ.postclean(`${this.endpoint}`, obj)
          .subscribe(
            data => {
              //this.gta.eventEmitter("saving_successfully", "flex-modal", "inserting", this.endpoint, 1);
              this.notification.success("Success", "inserted successfully");
              this.destroyModal();
            },
            error => {
              let errorMsg = "Error => " + error.error.message;
              this.notification.error("Error", errorMsg);
              this.gta.eventEmitter("saving_error", "broken_experience", "inserting", "[flex-modal] " + this.endpoint, 10);
              
              this.salvando = false;
            }
          ));

    } else {

      this.subs.push(
        this.gServ.putclean(`${this.endpoint}/${this._id}`, obj)
          .subscribe(
            data => {
              //this.gta.eventEmitter("saving_successfully", "flex-modal", "updating", this.endpoint, 1);
              this.notification.success("Success", "updated successfully");
              this.destroyModal()
            },
            error => {

              let errorMsg = "Error => " + error.error.message;
              this.notification.error("Error", errorMsg);
              this.gta.eventEmitter("saving_error", "broken_experience", "updating", "[flex-modal] " + errorMsg, 5);
              this.salvando = false;
            }
          )); 
      }
  } 


  beforeUpload = (file: UploadFile, _fileList: UploadFile[]) => {
    return new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.notification.error('Erro', 'You can only upload JPG file!');
        observer.complete();
        return;
      }
      const isLt2M = file.size! / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.notification.error('Erro', 'Image must smaller than 2MB!');
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng && isLt2M);
      observer.complete();
    });
  };

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  handleChange(info: { file: UploadFile }, propkey): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        // Get this url from response in real world.
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loading = false;
          
          //this.formData.controls[propkey] = img;
          this.formData.controls[propkey].setValue(info.file.response.uploadUrl);
          //console.log("img:")
          //console.log(info.file.response.uploadUrl);
          //console.log("formdata apos upload")
          //console.log(this.formData);
        });
        break;
      case 'error':
        this.gta.eventEmitter("uploading_error", "broken_experience", "uploading", "[flex-modal] " + this.endpoint, 5);
        this.notification.error('Error', 'Network error');
        this.loading = false;
        break;
    }
  }


  destroyModal(): void {
    this.subs.map(s => s.unsubscribe);
    this.modal.destroy({ data: this.final_obj });
  }


}
