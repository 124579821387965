import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd';
import {EmployeeService} from '../../../../shared/services/employee.service';
import {Employee, EmployeeDisplay} from '../../../../shared/model/employee.model';
import { NzNotificationService } from 'ng-zorro-antd';
import {LineService} from '../../../../shared/services/line.service';
import {UploadAreaComponent} from '../../../../shared/template/upload-area/upload-area.component';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';

@Component({
  selector: 'app-new-employee',
  templateUrl: './new-employee.component.html',
  styleUrls: ['./new-employee.component.css']
})
export class NewEmployeeComponent implements OnInit {
  
  formData
  @Input() employeeId

  lineFilterList
  empCategoryList

  loading 
  avatarUrl
  

  salvando : boolean = false;

  constructor(private fb: FormBuilder,
              private editService : EmployeeService,
              private notification: NzNotificationService,
              private modal: NzModalRef,
              private lineService : LineService,
              private empCategoryService : EmployeeService,) { 

      this.formData = this.fb.group({
          id_employee : [null, [Validators.required]],
          sapcode : [null],
          name : [null, [Validators.required]],
          shortname : [null],
          extraname : [null],
          //avatario : [""],
          email : [null],
          //date_start : [null],
          id_category : [null, [Validators.required]],
          id_line : [ null, [Validators.required] ],
          status : [ null, [Validators.required] ],
      });




  }

  ngOnInit() {

      if (this.employeeId != 0) {
          this.getSelectedId(this.employeeId);   
      }

      this.lineService.getAll()
         .subscribe((data : any[]) => {
             this.lineFilterList = data;
    });

     this.empCategoryService.getEmployeeCategory()
          .subscribe((data : any) => {
              this.empCategoryList = data;
              //console.log("empcateg")
              //console.log(data)
          })     
  }

  getSelectedId(id) {
        this.editService.getById(id)
        .subscribe((data : any) => {
            
            this.fullFillForm(data[0]);
        });
  }

  fullFillForm(obj : EmployeeDisplay) {

    console.log("fullFillForm")
    console.log(obj)
    if (obj) {
        this.formData.controls['id_employee'].setValue(obj.id_employee);
        this.formData.controls['sapcode'].setValue(obj.sapcode);
        this.formData.controls['name'].setValue(obj.name);
        this.formData.controls['shortname'].setValue(obj.shortname);
        this.formData.controls['extraname'].setValue(obj.extraname);
        this.formData.controls['email'].setValue(obj.email);
        this.formData.controls['id_category'].setValue(obj.id_category);
        this.formData.controls['id_line'].setValue(obj.id_line);
        if (obj.status === 'A') {
           this.formData.controls['status'].setValue(true);
        } else {
           this.formData.controls['status'].setValue(false);
        }

        //if (!obj.avatario) {
        // this.formData.controls['avatario'].setValue("");
        //} else {
        //  this.formData.controls['avatario'].setValue(obj.avatario);
       // }
        
        
        
        //this.formData.controls['date_start'].setValue(this.getDate(obj.date_start));
        
        
        return true;
    } else {
        return false;
    }
  }

  SubmitForm() {
    if (this.formData.invalid) {
        this.notification.blank("Error", "Fill required data before saving");
        return;
    }else {
        this.salvando = true;
    }

    let obj = new Employee;

    // ###### tem erro no id_employee
    obj.id_employee = this.formData.controls['id_employee'].value;
    obj.sapcode = this.formData.controls['sapcode'].value;
    obj.name = this.formData.controls['name'].value;
    obj.shortname = this.formData.controls['shortname'].value;
    obj.extraname = this.formData.controls['extraname'].value;
    //obj.avatario = this.formData.controls['avatario'].value;

    obj.email = this.formData.controls['email'].value;
    //obj.date_start = this.formData.controls['date_start'].value;
    //obj.date_new_category = this.formData.controls['date_new_category'].value;
    obj.id_category = this.formData.controls['id_category'].value;
    obj.id_line = this.formData.controls['id_line'].value;

    if (this.formData.controls['status'].value === true) {
       obj.status = 'A';
    } else {
       obj.status = 'I';
    }

    console.log("o que vai para o json")
    console.log(obj)
    
    // manda para o serviço
    if (this.employeeId == 0) {
         console.log('inserindo form data: ');
         console.log(obj);
         this.editService.insert(obj)
         .subscribe((data : any) => {
           if (data.result === "Success") {
              this.notification.blank("Success", "inserted successfully");
           } else {
              this.notification.blank("Error", "error inserting");
           }
           this.salvando = false;
           this.destroyModal();
         });

    // se estiver fazendo update        
    } else {
        this.editService.update(this.employeeId, obj)
        .subscribe((data : any) => {
          if (data.result === "Success") {
              this.notification.blank("Success", "updated successfully");
           } else {
              this.notification.blank("Error", "error updating");
           }
           this.salvando = false;
           this.destroyModal();
        });
    } //this.SendNotification(
  }

  destroyModal(): void {
    this.modal.destroy({ data: 'this the result data' });
  }

   getDate(str : string) {
    if (str !== null) {
       return new Date(str);
    } else {
      return null;
    }
  }

  beforeUpload = (file: File) => {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png'
        if (!isJPG && !isPNG) {
             this.notification.error("Error", "You can only upload JPG file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
             this.notification.error("Error", "Image must smaller than 2MB!");
        }
        return  isJPG && isLt2M; 
    }

  private getBase64(img: File, callback: (img: {}) => void): void {
      //console.log("[getbase64] img:");
      //console.log(img)
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
  }

  handleChange(info: { file: UploadFile }): void {
        //console.log(info);
        if (info.file.status === 'uploading') {
            this.loading = true;
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, (img: string) => {
              this.loading = false;
              this.avatarUrl = img;
            });

            this.formData.controls['avatario'].setValue(info.file.response.uploadUrl);
        }
    }

}
