import { Component, OnInit } from '@angular/core';
import {PortfolioService} from '../../services/portfolio.service';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-portfolio-page',
  templateUrl: './portfolio-page.component.html',
  styleUrls: ['./portfolio-page.component.css']
})
export class PortfolioPageComponent implements OnInit {
  
  selectedId : number;
  orderList

  nzTabPosition = 0;

  constructor(private route : ActivatedRoute,
  			      private portfolioService: PortfolioService) { }

  ngOnInit() {
  	if (this.route.snapshot.params['id']) {
        this.selectedId = this.route.snapshot.params['id'];
        this.getHeader(this.selectedId);
    }
  }

  getHeader(id) {
     this.portfolioService.getById(id)
          .subscribe((data : any) => {
              this.orderList = data[0];
          })
  }

}
