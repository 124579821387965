import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { ThemeConstantService } from '../../services/theme-constant.service';
import {MenuService} from '../../services/menu.service';

@Component({
  selector: 'app-side-nav-custom',
  templateUrl: './side-nav-custom.component.html'
})
export class SideNavCustomComponent implements OnInit {

  menu
  isCollapsed = true;
  isFolded: boolean = true;
  isSideNavDark: boolean = false;

  constructor(//private menuService: MenuService,
    private themeService: ThemeConstantService) { }

  ngOnInit() {
    /*
    this.menuService.menu
      .subscribe((data: any) => {
        this.menu = data;
        console.log("menu:")
        console.log(this.menu)
      });
    */
    this.menu = this.meu_menu;
    //console.log("isFolded:" + this.isFolded)
    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
  }

 


meu_menu = [
    
    {
      "id_user_menu": 32,
      "path": null,
      "title": "Commercial",
      "iconType": null,
      "iconTheme": null,
      "icon": "fas fa-store",
      "id_menu_father": null,
      "order": 10,
      "listable": 1,
      "submenu": [
        {
          "id_user_menu": 39,
          "path": "/commercial/setup",
          "title": "Setup",
          "iconType": null,
          "iconTheme": null,
          "icon": "fas fa-cog",
          "id_menu_father": 32,
          "order": 1,
          "listable": 1,
          "submenu": ""
        },
        {
          "id_user_menu": 33,
          "path": "/commercial",
          "title": "Pipeline",
          "iconType": null,
          "iconTheme": null,
          "icon": "fas fa-tags",
          "id_menu_father": 32,
          "order": 2,
          "listable": 1,
          "submenu": ""
        }
      ]
    },
    {
      "id_user_menu": 19,
      "path": null,
      "title": "Portfolio",
      "iconType": null,
      "iconTheme": null,
      "icon": "far fa-folder-open",
      "id_menu_father": null,
      "order": 17,
      "listable": 1,
      "submenu": [
        {
          "id_user_menu": 30,
          "path": "/portfolio/setup",
          "title": "Setup",
          "iconType": null,
          "iconTheme": null,
          "icon": "fas fa-cog",
          "id_menu_father": 19,
          "order": 1,
          "listable": 1,
          "submenu": ""
        },
        {
          "id_user_menu": 20,
          "path": "/portfolio/subproject",
          "title": "Subprojects",
          "iconType": null,
          "iconTheme": null,
          "icon": "fas fa-project-diagram",
          "id_menu_father": 19,
          "order": 2,
          "listable": 1,
          "submenu": ""
        },
        {
          "id_user_menu": 26,
          "path": "/portfolio/results",
          "title": "Results",
          "iconType": null,
          "iconTheme": null,
          "icon": "fas fa-chart-bar",
          "id_menu_father": 19,
          "order": 3,
          "listable": 1,
          "submenu": ""
        },
        
        {
          "id_user_menu": 31,
          "path": "/portfolio/nps",
          "title": "NPS",
          "iconType": null,
          "iconTheme": null,
          "icon": "fas fa-heartbeat",
          "id_menu_father": 19,
          "order": 6,
          "listable": 1,
          "submenu": ""
        },
        
        {
          "id_user_menu": 40,
          "path": "/portfolio/control",
          "title": "Control",
          "iconType": null,
          "iconTheme": null,
          "icon": null,
          "id_menu_father": 19,
          "order": 10,
          "listable": 1,
          "submenu": ""
        }
      ]
  },
  {
    "id_user_menu": 12,
    "path": "/production/register",
    "title": "Registers",
    "iconType": "",
    "iconTheme": "",
    "icon": "fas fa-indent",
    "id_menu_father": null,
    "order": 6,
    "listable": 1,
    "submenu": [
      
      {
        "id_user_menu": 36,
        "path": "/customer-sectors",
        "title": "Customer Sectors",
        "iconType": null,
        "iconTheme": null,
        "icon": "fas fa-industry",
        "id_menu_father": 12,
        "order": 3,
        "listable": 1,
        "submenu": ""
      },
      
      {
        "id_user_menu": 10,
        "path": "/customers",
        "title": "Customers",
        "iconType": "",
        "iconTheme": "",
        "icon": "fas fa-users",
        "id_menu_father": 12,
        "order": 7,
        "listable": 1,
        "submenu": ""
      },
      {
        "id_user_menu": 9,
        "path": "/projects",
        "title": "Projects",
        "iconType": "",
        "iconTheme": "",
        "icon": "far fa-folder",
        "id_menu_father": 12,
        "order": 8,
        "listable": 1,
        "submenu": ""
      },
      {
        "id_user_menu": 27,
        "path": "/employees",
        "title": "Employees",
        "iconType": null,
        "iconTheme": null,
        "icon": "fas fa-user",
        "id_menu_father": 19,
        "order": 5,
        "listable": 1,
        "submenu": ""
      },
      
    ]
  },
  {
    "id_user_menu": 12,
    "path": "/production/register",
    "title": "Admin",
    "iconType": "",
    "iconTheme": "",
    "icon": "fa fa-cog",
    "id_menu_father": null,
    "order": 6,
    "listable": 1,
    "submenu": [
      {
        "id_user_menu": 28,
        "path": "/users",
        "title": "Users",
        "iconType": null,
        "iconTheme": null,
        "icon": "fas fa-user-shield",
        "id_menu_father": 12,
        "order": 10,
        "listable": 1,
        "submenu": ""
      },
      {
        "id_user_menu": 37,
        "path": "/businessunits",
        "title": "Business Units",
        "iconType": null,
        "iconTheme": null,
        "icon": "fas fa-city",
        "id_menu_father": 12,
        "order": 1,
        "listable": 1,
        "submenu": ""
      },
      {
        "id_user_menu": 35,
        "path": "/lines",
        "title": "Lines",
        "iconType": null,
        "iconTheme": null,
        "icon": "fas fa-stream",
        "id_menu_father": 12,
        "order": 2,
        "listable": 1,
        "submenu": ""
      },
      {
        "id_user_menu": 38,
        "path": "/offerings",
        "title": "Offerings",
        "iconType": null,
        "iconTheme": null,
        "icon": "fab fa-buffer",
        "id_menu_father": 12,
        "order": 3,
        "listable": 1,
        "submenu": ""
      },
      {
        "id_user_menu": 389,
        "path": "/import",
        "title": "Import",
        "iconType": null,
        "iconTheme": null,
        "icon": "fab fa-buffer",
        "id_menu_father": 12,
        "order": 3,
        "listable": 1,
        "submenu": ""
      },
      
    ]
  },
  ]

}

/*
 *
 *
 {
        "id_user_menu": 43,
        "path": "/portfolio/results/summary",
        "title": "Sector view",
        "iconType": null,
        "iconTheme": null,
        "icon": null,
        "id_menu_father": 19,
        "order": 6,
        "listable": 1,
        "submenu": ""
      },
 */
