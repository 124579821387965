import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import {Subscription} from 'rxjs';
import { GenericalService} from '../../services/generical.service';
import {DateService} from '../../services/date.service';
import {API_ENDPOINT} from '../../../app.api';
import {Filter} from '../../model/filter.model';


/*
 Este componente gera um formulário de filtro, que pode ser incorporado em qualquer tela.

COMO ATIVAR

 No componente de origem, deve-se adicionar a seguinte tag:
   <app-flexfilter [filterFields]="filterFields"
                  (filtered)="aplicaFiltros($event)"
                  (toggler)="mostraToggler($event)">
    </app-flexfilter>

    onde:
     - [filterFields] são os parâmetros de entrada, explicados a seguir.
     - (filtered) é um evento que receberá o output deste componente, explicdo a seguir
     - (toggler) é um boolean para ocultar o formulário, caso ocupe muito espaço na tela

OUTPUT
 Ele devolve um array com key, value, de acordo com as configurações passadas

    em (filtered), ele devolverá um array com o formato a seguir:

      $event = [{key: "campo", value: "valor}, {key: "campo", value: "valor}, ...]

      você deverá tratar esse retorno, faezndo os devidos filtros no componente origem


  
 INPUTS
 1. [filterFields] é um array com a lista de campos que serão usados e suas configurações. Exemplos:

    {key : "id_bu", label: "_id" , value: "", visible : true, type: "text" }

    a. [key] é o nome da key de retorno
    b. [label] é o label que vc deseja ver na tela, quando o formulário for criado
    c. [value] é um valor que se deseja ter como padrão
    d. [visible] caso vc queira parar de mostrar algum campo
    e. [type] podem ser vários:

        - [text] é um campo normal, de string
        - [number] só aceita numeros
        - [percentage] é um slider de 0 a 100
        - [radio] é uma lista pequena de opções exclusivas
        - [date] é um componente de data
        - [dateRange] é um componente com duas datas, devolve um período
        - [switch] é um liga/desliga
        - [AI] é um tipo que trata ATIVO/INATIVO, padrão usado no banco de dados desse sistema
        - [select] faz uma busca no banco e traz uma lista suspensa
        - [manualselect] voce mesmo envia as opcoes que deseja como parâmetro
        - [editableselect] é um select que espera que o usuário digite alguma coisa antes de carregar opcoes

        há ainda dois tipos que estão inativados, por não terem muito sentido no contexto de busca
        que são: richtext e upload-image

        Configurações adicionais para [select], [manualselect] e [editableselect]:

          - [select] e [editableselect] podem ter os seguints campos:

              - dataFrom: { endpoint: 'customer', id: 'id_customer', label: 'name' }, onde:
                  - [endpoint] é simplificado, pois o arquivo /app.api.ts já define o endereço completo
                  - [id] é o campo-chave, que será usado como "value" no formulário
                  - [name] é qual informação vc deseja mostrar, por exemplo, no listbox

              - searchField: O padrão do sistema é buscar o campo [name], mas o [searchField]
                             permite que você busque outro campo. Este parâmetro não é obrigatório.

          - [editableselect] pode usar o campo [minChar] para determinar a quantidae minima
                             de caracteres a serem digitados antes que ele vá buscar no banco
          
          - [manualselect] precisa receber a lista de opcoes a mostrar, o que se faz no formato abaixo
                dataFrom: [{ label: "All", value: "0" }, { label: "Active", value: "A" }, { label: "Inativo", value: "I" }] },
  
 */

@Component({
  selector: 'app-flexfilter',
  templateUrl: './flexfilter.component.html',
  styleUrls: ['./flexfilter.component.css']
})
export class FlexfilterComponent implements OnInit {

  @Input() filterFields: Array<any> = [];
  @Input() count: number = 0;
  @Output() filtered: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggler: EventEmitter<any> = new EventEmitter<any>();

  formProps: Array<any> = [];
  sub1: Subscription;
  sub2: Subscription;
  dateFormat: string = 'dd/MM/yyyy';
  formFilter
  arrayzao = {}
  @Input() filters: Array<Filter> = [];

  constructor(private gServ: GenericalService,
              private gServAux: GenericalService,
              private dateService: DateService,) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {

    //console.log("flex filter recebeu os seguintes campos");
    //console.log(this.filterFields);

    const formDataObj = {};
    for (const prop of this.filterFields) {
      formDataObj[prop.key] = new FormControl();
      this.formProps.push(prop.key);
      // this.populaDados(prop); // caso seja um select
    }
    this.formFilter = new FormGroup(formDataObj);

    for (const prop of this.filterFields) {
      let data = prop

      if (this.filters.map(e => e.key).includes(data.key.trim())) {
        const filter = this.filters.find(e => e.key === data.key)
        data = { ...prop, value: filter.value }
      }

      this.populaDados(data);
      
      if (data.type === 'editableselect') this.initialEditableSelect(data)
      if (data.value != "") {
        this.formFilter.controls[data.key].setValue(data.value)
      }
      if (data.type == 'dateRange_CurrentMonth') {
         //console.log("achei um datarange a preencher")

         //console.log("first day")
         let date1 = this.dateService.FirstDayThisMonth();
         //console.log(date1)

         //console.log('last day');
         let date2 = this.dateService.LastDayThisMonth();
         //console.log(date2)
         this.formFilter.controls[data.key].patchValue([date1, date2]);
      }
    }

    //console.log("flexfilter - filterform no compone")
    //console.log(this.formFilter)

  }

  populaDados(prop) {
    
    if (prop.type == 'select') { // || (prop.type == 'editableselect')
      this.sub2 = this.gServAux.getFiltered(`${API_ENDPOINT}/${prop.dataFrom.endpoint}`, [])
        .subscribe((data: any) => {
          this.arrayzao[prop.key] = data;
          if (prop.value != '') {
              this.formFilter.controls[prop.key].patchValue(prop.value);
          }
        })
    } else {
      if (prop.type == 'editableselect') {
        this.arrayzao[prop.key] = [];
      }
    }
  }

  changeSelect(prop, endpoint, $event) {

    let searchField = 'name';
    if ('searchField' in prop) {
      searchField = prop['searchField'];
    }

    this.sub2 = this.gServAux.getFiltered(`${API_ENDPOINT}/${endpoint}`, [{ key: searchField, value: $event }])
      .subscribe((data: any) => {
        this.arrayzao[prop.key] = data;
        //this.arrayzao[prop.key] = data.data;
        //console.log("normal populaDados_com_filtro(prop, filtro)")
        //console.log(prop)
        
        //console.log(this.arrayzao[prop.key]);
      })
  }


  //nzFilterOption = (): boolean => true;

  initialEditableSelect(prop) {
    if (prop.value) {
      this.sub2 = this.gServAux.getFiltered(`${API_ENDPOINT}/${prop.dataFrom.endpoint}`, [{ key: 'id', value: prop.value }])
        .subscribe((data: any) => {
          this.arrayzao[prop.key] = data;
        })
    } else {
      this.arrayzao[prop.key] = [];
    }
  }

  changeEditableSelect(prop, endpoint, $event) {
   
    let val = $event
    val = val.toString()

    let searchField = 'name';
    if ('searchField' in prop) {
      searchField = prop['searchField'];
    }
   

    if ((val != "") && (val.length >= prop.minChar)) {
      this.sub2 = this.gServAux.getFiltered(`${API_ENDPOINT}/${endpoint}`, [{ key: searchField, value: val }])
        .subscribe((data: any) => {
          this.arrayzao[prop.key] = data;
        })
    } else {
      this.arrayzao[prop.key] = [];
    }
  }


  SubmitFilter() {
   
  	//console.log("flexFilter, submit filter")
    this.filters = [];

    Object.keys(this.formFilter.controls).forEach((key : string) => {
      if (this.formFilter.get(key).value) {
      	 if (this.formFilter.get(key).value instanceof Array ) {
      	 	 let meuArray = this.devolveArray(key);
      	 	 let pos1 = {key : key + "_start", value :  this.dateService.DateToString(this.formFilter.get(key).value[0])}
      	 	 let pos2 = {key : key + "_end", value :   this.dateService.DateToString(this.formFilter.get(key).value[1])}
      	 	 this.addFilter(pos1);
      	 	 this.addFilter(pos2);
      	 } else {
      	   this.addFilter({key : key, value : this.formFilter.get(key).value})	
      	 }
          
      }
    })

    //console.log("flexFilter");
    //console.log(this.filters);

    this.filtered.emit({event: this.filters})
  }

  //Funcao apenas para tratar array
  devolveArray(key) {
  	let retorno = []
  	for (const prop of this.filterFields) {
    	if (prop.key === key) {
    		retorno.push(prop.dataFrom.start);
    		retorno.push(prop.dataFrom.end);
    	}
    }

    return retorno

  }



  toggle() {
    this.toggler.emit({event : "toggler enviado"})
  }

  addFilter(obj : Filter) {
  
      let index = this.filters.findIndex(x => x.key == obj.key);
      if (index === -1) {
         this.filters.push(obj); 
      } else {
         this.filters[index].value = obj.value;
      }        
  }


  ngOnDestroy() {
  	if (this.sub1) {
  		this.sub1.unsubscribe()
  	}
  	if (this.sub2) {
  		this.sub2.unsubscribe()
  	}
  }

}
