
import { Component, OnInit, Input, SimpleChanges} from '@angular/core';
import { ThemeConstantService } from '../../../services/theme-constant.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';

@Component({
  selector: 'app-bar-chart-multicolor',
  templateUrl: './bar-chart-multicolor.component.html',
  styleUrls: ['./bar-chart-multicolor.component.css']
})
export class BarChartMulticolorComponent implements OnInit {

  @Input() chart
  

  themeColors = this.colorConfig.get().colors;

	gold = this.themeColors.gold;
	blue = this.themeColors.blue;
	red = this.themeColors.red;
	volcano = this.themeColors.volcano;
	cyan = this.themeColors.cyan;
	lime = this.themeColors.lime;
	//orange = this.themeColors.orange;

	blueLight = this.themeColors.blueLight;
	purple = this.themeColors.purple;

	redLight = this.themeColors.redLight;
	purpleLight = this.themeColors.purpleLight;
	cyanLight = this.themeColors.cyanLight;
   
    
  ChartLabels: string[] = ['Direct', 'Referral', 'Social Network'];
  ChartData: number[] = [350, 450, 100];
  ChartColors: Array<any> =  [{ 
      backgroundColor: [this.gold, this.blue, this.red, this.volcano, this.cyan, this.lime, this.blueLight, 
                        this.purple, 
                        '#3366CC','#DC3912','#FF9900','#109618','#990099','#3B3EAC','#0099C6','#DD4477',
                        '#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707',
                        '#329262','#5574A6','#3B3EAC'],
      //pointBackgroundColor : [this.gold, this.blue, this.red]
  }];
  ChartOptions: any = {
        scaleShowVerticalLines: false,
        maintainAspectRatio: true,
        responsive: true,
        
        tooltips: { 
           mode: 'label', 
           label: 'mylabel', 
           callbacks: { 
               label: function(tooltipItem, data) { 
                   return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
               }, 
           }, 
        }, 

        scales: {
            xAxes: [{
                display: true,
                
                categoryPercentage: 0.35,
                barPercentage: 0.70,
                scaleOverride: true,
                scaleLabel: function (valuePayload) {
                    return Number(valuePayload.value).toFixed(2).replace('.',',') + '$';
                },
                

                gridLines: false,
                
            }],
            yAxes: [{
                display: true,
                
                ticks: {
                    callback: function(label, index, labels) {
                        //return (label/1000).toFixed(2).replace('.',',')+'k';
                        return (label).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
                    },
                    beginAtZero: true,
                },
                scaleOverride: true,
                scaleLabel: function (valuePayload) {
                    return Number(valuePayload.value).toFixed(0).replace('.',',') + '$';
                },
                gridLines: {
                    drawBorder: false,
                    offsetGridLines: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 4]
                },
                
            }],

        }
    };
  ChartType = 'bar';
  ChartLegend = true;


  constructor(private colorConfig:ThemeConstantService,) { }

  ngOnInit() {}

   ngOnChanges(changes: SimpleChanges) {
     if (this.chart) {
         this.ChartLabels = this.chart.labels;
         this.ChartData = this.chart.data;
         this.ChartLegend = this.chart.legends;
     }
     
  }
}
