import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { first } from 'rxjs/operators';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  submitted : boolean = false;
  code : string = '';
  mensagem : string = "";
  titulodamensagem : string = "";
  emailEnviado : string = "";

  constructor(private auth : AuthenticationService, 
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private gta: GoogleAnalyticsService  ) { }

  ngOnInit() {
  	this.resetForm  =  this.formBuilder.group({
        password1: ['', [Validators.required, Validators.minLength(4)]],
        password2: ['', [Validators.required, Validators.minLength(4)]]
    }, {validator: this.checkPasswords});

    if (this.route.snapshot.params['code']) {
          console.log(this.route.snapshot.params['code'])
          this.code = this.route.snapshot.params['code'] || '/';
      }

  }
  
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.get('password1').value;
    let confirmPass = group.get('password2').value;

    return pass === confirmPass ? null : { notSame: true }     
  }

  get f() { return this.resetForm.controls; }

  submitForm() {
  	//this.auth.login(this.user);
    //console.log(this.loginForm.value);
   this.submitted = true;
    if (this.resetForm.invalid) {
            this.mensagem = 'invalid';
            return;
        }
    
    this.auth.resetPassword(this.code, this.resetForm.controls['password1'].value, this.resetForm.controls['password2'].value )
    .pipe(first())
            .subscribe(
                data => {
                    this.titulodamensagem = "Sucesso";
                    this.mensagem  = "Senha alterada com sucesso";
                    this.emailEnviado = "success";
                    //this.router.navigateByUrl("/");
                },
                error => {
                    this.titulodamensagem = "Erro no token";
                    this.mensagem  = "Solicite novamente um token";
                    this.emailEnviado = "error";
                    //console.log(error);
                });
    //this.router.navigateByUrl('/');
  }
}
