import { Injectable } from '@angular/core';
import { GenericalService } from '../../shared/services/generical.service';
import {API_ENDPOINT} from '../../app.api';

@Injectable({
  providedIn: 'root'
})
export class SubprojectResultsService {

  constructor(private gServ : GenericalService) { }

  getOffering(filters) {
  	let url = `${API_ENDPOINT}/subproject/results/offering`;
  	return this.gServ.postclean(url, filters);
  }

  getCustomer(filters) {
    let url = `${API_ENDPOINT}/subproject/results/customer`;
    return this.gServ.postclean(url, filters);
  }

  getOfferingHistorical(filters) {
    let url = `${API_ENDPOINT}/subproject/results/offering/historical`;
    return this.gServ.postclean(url, filters);
  }

  getOKRsNumericos(filters) {
    let url = `${API_ENDPOINT}/subproject/results/okrnumerico`;
    return this.gServ.getFiltered(url, filters);
  }

  getLeader(filters) {
    let url = `${API_ENDPOINT}/subproject/results/leader`;
    return this.gServ.postclean(url, filters);
  }

  getFY(filters, type, entity) {
    let url = `${API_ENDPOINT}/subproject/results/fy`;
    return this.gServ.getFilteredWithEntity(url, filters, entity);
  }
  
  getSubProject(filters) {
  	let url = `${API_ENDPOINT}/subproject/results/subproject`;
  	return this.gServ.postclean(url, filters);
  }

  getEXT(filters) {
    let url = `${API_ENDPOINT}/subproject/results/project`;
    return this.gServ.postclean(url, filters);

  }


  getPeopleHours(filters) {
    let url = `${API_ENDPOINT}/subproject/results/peoplehours`;
    return this.gServ.postclean(url, filters);
  }

  getCustomers(filters) {
    let url = `${API_ENDPOINT}/subproject/results/customer`;
    return this.gServ.postclean(url, filters);
  }

  getNonBillable(filters) {
    let url = `${API_ENDPOINT}/subproject/results/nonbillable`;
    return this.gServ.postclean(url, filters);
  }
  
}
