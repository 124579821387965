import { Component, OnInit, OnDestroy } from '@angular/core';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';

import { TableService } from '../../../shared/services/table.service';
import {ResultsService} from '../../../shared/services/results.service';

import {Subscription} from 'rxjs';
//import {Filter} from '../../../shared/model/filter.model';
import {FilterService} from '../../../shared/services/filter.service';


@Component({
  selector: 'app-customer-results',
  templateUrl: './customer-results.component.html',
  styleUrls: ['./customer-results.component.css']
})
export class CustomerResultsComponent implements OnInit { 
  
  myTable
  myTableHistorical


  sub1 : Subscription;
  sub2 : Subscription;
  sub3 : Subscription;
  sub4 : Subscription;
  sub5 : Subscription;

  view : string = "table";
  subtitle : string = "";

  constructor(private filterService : FilterService,
  			      private resultsService : ResultsService,
              private resultsService2 : ResultsService
              ) { }

  ngOnInit() {

      this.filterService.addFilter({key : "entity", value: "customer"})
      this.sub1 = this.filterService.data
      .subscribe ((filtros : any) => {

          if (this.view == "table") {
              this.subtitle = "Results Table"
              this.buildTable(filtros);
          } else if (this.view == "historical-revenue") {
              this.subtitle = "History of Net Revenue"
              this.buildHistoricalRevenue(filtros);
          } else if (this.view == "historical-margin") {
              this.subtitle = "History of Margin"
              this.buildHistoricalMargin(filtros);
          } else if (this.view == "historical-growth") {
              this.subtitle = "History of Growth"
              this.buildHistoricalGrowth(filtros);
          }    
      })
         
  }

  onViewChange($event) {
    this.view = $event;
    this.ngOnInit();
  }

  buildTable(filtros) {
    this.sub2 = this.resultsService.getResults(filtros, "customer")
          .subscribe((data : any) => {
             this.myTable = {
                 //options : {
                 //  title : "Customer Results",
                 //},
                 itemList : {
                    fields : [
                       {key : "id_customer", label: "ID", alias : "ID", format: "", visible: false},
                       {key : "name", label: "Customer", alias : "Customer Name", format: "", visible: false},
                       {key : "shortname", label: "Customer", alias : "Customer Shortname", format: "", visible: true},
                       {key : "projectcount", label: "Project Count", alias : "Project Count", format: "number", visible: false},
                       {key : "netrevenue", label: "Net Revenue", alias : "Net Revenue", format: "number", visible: true},
                       {key : "marginvalue", label: "Analytic Margin", alias : "Analytic Margin", format: "number", visible: true},
                       {key : "salesmargin", label: "Sales Margin", alias : "Sales Margin", format: "percentage", visible: false},
                       {
                           key : "marginpercent", 
                           label: "PTD", 
                           alias : "PTD", 
                           format: "percentage", 
                           visible: true,
                           red : 0.40,
                           direction : "<"
                       },
                    ],
                    results : data.data
                },
                headers : {
                    fields : [
                       {key : "netrevenue", label : "Net Revenue", format: "number"},
                       {key : "marginvalue", label : "Analytic Margin", format: "number"},
                       {
                           key : "marginpercent", 
                           label : "PTD", 
                           format: "percentage",
                           red : 0.40,
                           direction : "<"
                       },
                    ],
                    results : [data.header]
                }
             }
         }) 
      
  }

  buildHistoricalRevenue(filtros) {

      this.sub2 = this.resultsService2.getResultsByPeriod(filtros, "customer")
        .subscribe((data : any) => {
          this.myTableHistorical = {

               itemList : {
                  fields : [
                     {key : "cusshortname", label: "Customer", alias : "Customer Shortname", format: "", visible: true},
                     {key : "empshortname", label: "Manager", alias : "Manager Shortname", format: "", visible: false},

                  ],
                  results : data.revenue//.slice(0, data.length-1)
              },
           }

           for (var i = 0; i < data.labels.length; ++i) {
              this.myTableHistorical.itemList.fields.push({key : data.labels[i], label: data.labels[i], alias : data.labels[i], format: "number", visible: true},)
           }
           
        });
  }

  buildHistoricalMargin(filtros) {
   
    this.sub2 = this.resultsService2.getResultsByPeriod(filtros, "customer")
        .subscribe((data : any) => {
          console.log(data)
          this.myTableHistorical = {

               itemList : {
                  fields : [
                     {key : "cusshortname", label: "Customer", alias : "Customer Shortname", format: "", visible: true},
                     {key : "empshortname", label: "Manager", alias : "Manager Shortname", format: "", visible: false},
                  ],
                  results : data.margin//.slice(0, data.length-1)
              },
           }

           for (var i = 0; i < data.labels.length; ++i) {
              this.myTableHistorical.itemList.fields.push({key : data.labels[i], label: data.labels[i], alias : data.labels[i], format: "percentage", visible: true, red: 0.40, direction: "<"},)
           }
           
        });
  }

  buildHistoricalGrowth(filtros) {

    this.sub2 = this.resultsService2.getResultsByPeriod(filtros, "customer")
              .subscribe((data : any) => {
                console.log("customer historical growth")
                console.log(filtros)
                console.log(data)
                this.myTableHistorical = {
    
                     itemList : {
                        fields : [
                           {key : "cusshortname", label: "Customer", alias : "Customer Shortname", format: "", visible: true},
                           {key : "empshortname", label: "Manager", alias : "Manager Shortname", format: "", visible: false},
                        ],
                        results : data.growth//.slice(0, data.length-1)
                    },
                 }

                 for (var i = 0; i < data.labels.length; ++i) {
                    this.myTableHistorical.itemList.fields.push({key : data.labels[i], label: data.labels[i], alias : data.labels[i], format: "percentage", visible: true, green: 0.0001, direction: ">="},)
                 }
                 this.myTableHistorical.itemList.fields.push({key : "AVG", label: "AVG", alias : "Average Growth", format: "percentage", visible: false},)
                 
              });
  }

  ngOnDestroy() {
    if (this.sub1) { this.sub1.unsubscribe() }
    if (this.sub2) { this.sub2.unsubscribe() }
    
  }

}
