import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors, FormArray, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GenericalService } from '../../shared/services/generical.service'
import { API_ENDPOINT } from '../../app.api';

import { NzNotificationService } from 'ng-zorro-antd';
@Component({
  selector: 'app-importation',
  templateUrl: './importation.component.html',
  styleUrls: ['./importation.component.css']
})
export class ImportationComponent implements OnInit {
  
  viewType : string = "results";
  uploadEndPoint : string;
  uploadFields: Array<any>;
  salvando = false;
  subs: Array<Subscription> = [];

  formData
  resultsArray = [
      {name: 'period', isKey: 'Yes', 	  type: 'number' , description: 'yyyymm', 		example: '201911' },
      {name: 'id_project', isKey: 'Yes', type: 'string' , description: '16 characters', 		example: 'EXT-012345-12345' },
      {name: 'netRevenue', isKey: 'No',  type: 'number' , description: 'Value without .', 		example: '123456,78' },
      {name: 'MarginValue', isKey: 'No',  type: 'number' , description: 'Value without .', 	example: '123456,78' },
      {name: 'MarginPercent', 	isKey: 'No',  type: 'number' , description: 'Value without .', example: '123456,78' },
  ]


  constructor(private fb: FormBuilder,
                private editService: GenericalService,
                private notification: NzNotificationService,  ) {
    this.formData = this.fb.group({
      csv: [null, [Validators.required]],
    })
  }

  ngOnInit() {
  	this.goToResults();
  }

  goToPreclosure() {
    console.log("goToPreclosure");
  	this.uploadEndPoint = "import/preclosure"
    this.uploadFields = this.resultsArray;
  }

  goToResults() {
  	this.uploadEndPoint = "import/results"
    this.uploadFields = this.resultsArray;
  }

  goToWIP() {
  	this.uploadEndPoint = "import/wip"
    this.uploadFields = [
      {name: 'period', isKey: 'Yes', 	  type: 'number' , description: 'yyyymm', 		example: '201911' },
      {name: 'id_project', isKey: 'Yes', type: 'string' , description: '16 characters', example: 'EXT-012345-12345' },
      {name: 'WIPCurrent', isKey: 'No',  type: 'number' , description: 'Value without .', example: '123456,78' },
      {name: 'WIP30', isKey: 'No',  type: 'number' , description: 'Value without .', 	example: '123456,78' },
      {name: 'WIP60', 	isKey: 'No',  type: 'number' , description: 'Value without .', example: '123456,78' },
      {name: 'WIP90', 	isKey: 'No',  type: 'number' , description: 'Value without .', example: '123456,78' },
      {name: 'WIP120', 	isKey: 'No',  type: 'number' , description: 'Value without .', example: '123456,78' },
    ]
  }

  SubmitForm() {

    
    this.salvando = true;
    /*if (this.formData.invalid) {
      this.formData.markAllAsTouched();
      this.salvando = false;
      console.log("erro de validacao")
      return;
    }*/

    console.log("vou enviar!!")

    let obj = { 'csv': '' };
    obj.csv = this.formData.controls['csv'].value;

    console.log("text area value")
    console.log(this.formData.controls['csv'].value)


    // manda para o serviço
    if (obj.csv != '') {
      this.subs.push(
        this.editService.postclean(`${API_ENDPOINT}/${this.uploadEndPoint}`, obj)
          .subscribe(
            data => {
              this.notification.success("Success", "inserted successfully");
              console.log("deu certo!!")
              this.destroyModal();
            },
            error => {
              console.log("erro!!")
              let errorMsg = "Error Inserting => " + error.error.message;
              this.notification.error("Error", errorMsg);
              //this.gta.eventEmitter("error_found", "inserting", "new-opportunity", "SubmitForm", 0);
              this.salvando = false;
            }
          ));
    }

  }

  destroyModal() {
    this.subs.map(s => s.unsubscribe);
  }

   

}
