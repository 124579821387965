import { Injectable } from '@angular/core';
import { GenericalService } from './generical.service';
import {API_ENDPOINT} from '../../app.api';
import {User} from '../model/user.model';



@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  constructor(private gServ : GenericalService) { }

  getAll() {
  	let url = `${API_ENDPOINT}/manager`;
    return this.gServ.get(url);
  }

  getLeaders() {
    let url = `${API_ENDPOINT}/manager/leaders`;
    return this.gServ.get(url);
  }

  getLeadersFromPortfolio() {
    let url = `${API_ENDPOINT}/manager/leaders?portfolio=S`;
    return this.gServ.get(url);
  }

  getFilteredByLine(id) {
    let url = `${API_ENDPOINT}/manager/line/${id}`;
    return this.gServ.get(url);
  }

  insert(obj) {
    let url = `${API_ENDPOINT}/manager/`;
     return this.gServ.put(url, obj);
  }

  update(id, obj) {
    let url = `${API_ENDPOINT}/manager/${id}`;
    return this.gServ.post(url, obj);
  }

  delete(id) {
     let url = `${API_ENDPOINT}/manager/${id}`;
     return this.gServ.delete(url);
  }
  /*
  getAllResults(filters) {
    let url = `${API_ENDPOINT}/manager/results`;
    return this.gServ.getFiltered(url, filters);
  }
  */
  
}
