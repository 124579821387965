import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors, FormArray, ValidatorFn } from '@angular/forms';

import { NzModalRef } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
import {SubProject} from '../../../model/subproject.model';
import { SubprojectService} from '../../../services/subproject.service';

import {ProjectService} from '../../../../shared/services/project.service';
import {OfferingService} from '../../../../shared/services/offering.service';
import {ManagerService} from '../../../../shared/services/manager.service';
import {PhaseService} from '../../../services/phase.service';
import {Filter} from '../../../../shared/model/filter.model';
import { first } from 'rxjs/operators';
import {Subscription} from 'rxjs';
import { GoogleAnalyticsService } from '../../../../shared/services/google-analytics.service';

@Component({
  selector: 'app-new-subproject',
  templateUrl: './new-subproject.component.html',
  styleUrls: ['./new-subproject.component.css']
})
export class NewSubprojectComponent implements OnInit {

  dateFormat = 'yyyyMM';
  formData
  @Input() subprojectId

  projectList
  offeringList
  managerList
  phaseList

  subs : Array<Subscription> = []
  

  salvando : boolean = false;

   constructor(private fb: FormBuilder,
  			   private modal: NzModalRef,
  			   private notification: NzNotificationService,
               private editService : SubprojectService,
               private listService1 : SubprojectService,
               private listService2 : SubprojectService,
               private offeringService : OfferingService,
               private managerService : ManagerService,
               private projectService : ProjectService,
               private phaseService: PhaseService,
               private gta : GoogleAnalyticsService
  			  ) { 

		this.formData = this.fb.group({
          id_subproject : [null],
          name : [null, [Validators.required]],
          shortname : [null, [Validators.required]],
          id_offering : [null, [Validators.required]],
          id_employee : [null, [Validators.required]],
          start_end : [null, [Validators.required]],
          id_project : [null, [Validators.required]],
          usecase : [false, [Validators.required]],
          technology : [false, [Validators.required]],
          bodyshop : [false, [Validators.required]],
          id_subproject_phase : [null, [Validators.required]],
          comunidade  : [null, ],
          rt: [null, ],
          id_subproject_type : [null, [Validators.required]],
          subproject_description : [null, [Validators.required]],
          status : [true, [Validators.required]]
        });


   } 

   ngOnInit() {

        this.formData.controls['id_subproject'].setValue(this.subprojectId);
        this.formData.controls['status'].setValue(true);
        if (this.subprojectId != 0) {
            this.getSelectedId(this.subprojectId);   
        }
   		//this.offSubscription = 
     this.subs.push(
       this.offeringService.getAll()
         .subscribe(
           data => {
             this.offeringList = data;
           },
           error => {
             let errorMsg = "Loading Offerings => " + error.error.message;
             this.notification.error("Error", errorMsg);
             this.gta.eventEmitter("form_error", "broken_experience", "listing_a_box", "[new-subproject / offerings]  " + error.error.message, 5);

           }));

     this.subs.push(
       this.managerService.getLeaders()
       .subscribe(
         data => {
           this.managerList = data;
         },
         error => {
           let errorMsg = "Loading Managers => " + error.error.message;
           this.notification.error("Error", errorMsg);
           this.gta.eventEmitter("form_error", "broken_experience", "listing_a_box", "[new-subproject / managers]  " + error.error.message, 5);

         }));

        
        let filtro : Array<Filter> = [{key: "portfolio", value: "S"}]
     this.subs.push(
       this.projectService.getFiltered(filtro)
         .subscribe(
             data => {
                  this.projectList = data;
            },
              error => {
                let errorMsg = "Loading Projects => " + error.error.message;
                this.notification.error("Error", errorMsg);
                this.gta.eventEmitter("form_error", "broken_experience", "listing_a_box", "[new-subproject / projects]  " + error.error.message, 5);

              }));


     //this.phaseSubscription =
     this.subs.push(
       this.phaseService.getAll()
         .subscribe(
           data => {
             this.phaseList = data;
           },
           error => {
             let errorMsg = "Loading Phases => " + error.error.message;
             this.notification.error("Error", errorMsg);
             this.gta.eventEmitter("form_error", "broken_experience", "listing_a_box", "[new-subproject / phases]  " + error.error.message, 5);

           }));
   }

   getSelectedId(id) {
     this.subs.push(
       this.editService.getById(id)
         .subscribe(
           data => {
             this.fullFillForm(data[0]);
           },
           error => {
             this.gta.eventEmitter("loading_error", "broken_experience", "loading_by_id", "[new-subproject]  " + error.error.message, 5);

           }));
    }

    fullFillForm(obj : SubProject) {

        if (obj) {

            this.formData.controls['id_subproject'].setValue(obj.id_subproject);
            this.formData.controls['name'].setValue(obj.name);
            this.formData.controls['shortname'].setValue(obj.shortname);
            this.formData.controls['id_offering'].setValue(obj.id_offering);
            this.formData.controls['id_employee'].setValue(obj.id_employee);
            this.formData.controls['id_project'].setValue(obj.id_project);
            this.formData.controls['id_subproject_phase'].setValue(obj.id_subproject_phase);

            this.formData.controls['comunidade'].setValue(obj.comunidade);
            this.formData.controls['id_subproject_type'].setValue(obj.id_subproject_type);
            this.formData.controls['subproject_description'].setValue(obj.subproject_description);

            this.formData.controls['rt'].setValue(obj.rt);
            /*
            let startdate = obj.startdate
            startdate = startdate.toString()
            this.formData.controls['startdate'].setValue(this.StringParaData(startdate)) ;

            let enddate = obj.enddate
            enddate = enddate.toString()
            this.formData.controls['enddate'].setValue(this.StringParaData(enddate)) ;
            */

            let startdate = obj.startdate
            startdate = startdate.toString()
            let enddate = obj.enddate
            enddate = enddate.toString()
            this.formData.controls['start_end'].setValue([this.StringParaData(startdate), this.StringParaData(enddate)] ) ;


            if (obj.bodyshop == 'S') {
               this.formData.controls['bodyshop'].setValue(true);
            } else {
               this.formData.controls['bodyshop'].setValue(false);
            }

            if (obj.usecase == 'S') {
               this.formData.controls['usecase'].setValue(true);
            } else {
               this.formData.controls['usecase'].setValue(false);
            }

            if (obj.technology == 'S') {
               this.formData.controls['technology'].setValue(true);
            } else {
               this.formData.controls['technology'].setValue(false);
            }

            if (obj.status == 'A') {
               this.formData.controls['status'].setValue(true);
            } else {
               this.formData.controls['status'].setValue(false);
            }
            return true;
        } else {
            return false;
        }
    }



  
    SubmitForm() {

      this.salvando = true;
      if (this.formData.invalid) {
        this.formData.markAllAsTouched();
        this.salvando = false;
        return;
      } 

       

      let obj = new SubProject;

      obj.id_subproject = this.formData.controls['id_subproject'].value;
      obj.name = this.formData.controls['name'].value;
      obj.shortname = this.formData.controls['shortname'].value;
      obj.id_offering = this.formData.controls['id_offering'].value;
      obj.id_employee = this.formData.controls['id_employee'].value;
      obj.id_project = this.formData.controls['id_project'].value;
      obj.id_subproject_phase = this.formData.controls['id_subproject_phase'].value;
      obj.id_subproject_type = this.formData.controls['id_subproject_type'].value;
      obj.subproject_description = this.formData.controls['subproject_description'].value;

      obj.comunidade = this.formData.controls['comunidade'].value;
      obj.rt = this.formData.controls['rt'].value;


      /*
      obj.startdate = this.DataParaString(this.formData.controls['startdate'].value);
      obj.enddate =  this.DataParaString(this.formData.controls['enddate'].value);
      */
      obj.startdate = this.DataParaString(this.formData.controls['start_end'].value[0]);
      obj.enddate =  this.DataParaString(this.formData.controls['start_end'].value[1]);

      if (this.formData.controls['bodyshop'].value === true) {
         obj.bodyshop = 'S';
      } else {
         obj.bodyshop = 'N';
      }

      if (this.formData.controls['usecase'].value === true) {
         obj.usecase = 'S';
      } else {
         obj.usecase = 'N';
      }

      if (this.formData.controls['technology'].value === true) {
         obj.technology = 'S';
      } else {
         obj.technology = 'N';
      }

      if (this.formData.controls['status'].value === true) {
         obj.status = 'A';
      } else {
         obj.status = 'I';
      }

      
      // manda para o serviço
      if (!obj.id_subproject) {
           console.log('inserindo form data: ');
           console.log(obj);
        this.subs.push(
          this.editService.insert(obj)
            .pipe(first())
            .subscribe(
              data => {
                this.notification.blank("Success", "inserted successfully");
                this.destroyModal();
              },
              error => {
                let errorMsg = "Error Inserting => " + error.error.message;
                this.notification.error("Error", errorMsg);
                this.gta.eventEmitter("saving_error", "broken_experience", "inserting", "[new-subproject] " + error.error.message, 5);

                this.salvando = false;
              }
            ));
             
               
           
      } else {
        this.subs.push(
          this.editService.update(this.subprojectId, obj)
            .pipe(first())
            .subscribe(
              data => {
                this.notification.blank("Success", "UPDATED successfully");
                this.destroyModal();
              },
              error => {
                let errorMsg = "Error Updating => " + error.error.message;
                this.notification.error("Error", errorMsg);
                this.salvando = false;
                this.gta.eventEmitter("saving_error", "broken_experience", "updating", "[new-subproject] " + error.error.message, 5);

              }
            ));
      }
  } 
   comunidadeList

   onComunidadeInput(value: string): void {
     this.subs.push(
       this.listService1.getComunidade(value)
         .subscribe((data: any) => {
           this.comunidadeList = data;


         }));
  }

  RTList

  onRTInput(value: string): void {
    this.subs.push(
      this.listService2.getRT(value)
        .subscribe((data: any) => {
          this.RTList = data;

        }));
  }


  DataParaString(o) {
    let year = o.getFullYear();
    let month = Number(o.getMonth())+1;
    let newMonth = String(month)

    if (month < 10) {
         newMonth = "0" + String(month)
    }  

    let valor = year + newMonth;
    return valor
  }

  StringParaData(o : String) : Date {

    if (o.length > 0) {
       let newYear = o.substr(0, 4)
       //substr(0,3)
       let newMonth = o.substr(4,2)

       let valor = newMonth +'/01/' + newYear;

       console.log(valor)
       return new Date(valor);  
    } else {
       return new Date();  
    }
    
  }
  
  destroyModal(): void {
    this.subs.map(s => s.unsubscribe);
    this.modal.destroy({ data: 'this the result data' });
  }

}
    




