import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';
import {BusinessunitService} from '../../../shared/services/businessunit.service';
//import {LineService} from '../../../shared/services/line.service';
import {Filter} from '../../../shared/model/filter.model';
import {FilterService} from '../../../shared/services/filter.service';
import {ProjectService} from '../../../shared/services/project.service';
import {ResultsService} from '../../../shared/services/results.service';
import {EmployeeService} from '../../../shared/services/employee.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-summarized-results',
  templateUrl: './summarized-results.component.html',
  styleUrls: ['./summarized-results.component.css'],
})
export class SummarizedResultsComponent implements OnInit {


  sub1 : Subscription;
  sub2 : Subscription;
  sub3 : Subscription;

  header_results : any;
  selectedperiod : any;

  themeColors = this.colorConfig.get().colors;
  blue = this.themeColors.blue;
  blueLight = this.themeColors.blueLight;
  cyan = this.themeColors.cyan;
  cyanLight = this.themeColors.cyanLight;
  gold = this.themeColors.gold;
  goldLight = this.themeColors.goldLight;
  purple = this.themeColors.purple;
  purpleLight = this.themeColors.purpleLight;
  red = this.themeColors.red;
  redLight = this.themeColors.redLight;
  volcano = this.themeColors.volcano;
  orange = this.themeColors.orange;
  lime = this.themeColors.lime;

  //oldFilters : Array<Filter> = []

  RevenueChart
  MarginChart
  WIPChart
  ProjectChart
  CustomerChart

  annuals_percent : number = 0;
  annuals_lastupload : string = ""
  
  reviews_percent : number = 0;
  reviews_lastupload : string =""
  

  constructor(private buService : BusinessunitService,
  			  private filterService : FilterService,
              private colorConfig : ThemeConstantService,
              private resultsService : ResultsService,
              private employeeService : EmployeeService,
              private employeeService2 : EmployeeService,) { }

  ngOnInit() {

    

  	this.sub1 = this.filterService.data
      .subscribe ((filtros : any) => {
          this.selectedperiod = this.filterService.getValueOf("period_start")
          if (this.selectedperiod == "0") {this.selectedperiod = "Last Period"}
          else if (this.selectedperiod == "1") {this.selectedperiod = "Current FY"}
         
          
          this.sub2 = this.buService.getAllResults(filtros)
          .subscribe((data : any) => {
              //console.log("summarized results")
              //console.log(data)
              
              this.RevenueChart = null;
              this.MarginChart = null;
              
              this.header_results = data.data[0];
              //console.log("header_results")
              //console.log(this.header_results);
               
               this.RevenueChart = {
                   icon : {
                     name : "fas fa-dollar-sign",
                     backgroundColor : this.themeColors.blueLight,
                     color : this.themeColors.blue,
                   },
                   title : {
                     value : this.header_results.netrevenue,
                     format : "currency",
                     subtitle : "Net Revenue " + this.selectedperiod,
                   },
                   dataset : {
                     backgroundColor : this.themeColors.blue,
                     labels : data.labels,
                     data : [{data : data.revenue}],
                     type : "bar",
                     beginAtZero : true
                   },
               }

               //console.log(this.RevenueChart)
               this.MarginChart = {
                   icon : {
                     name : "fas fa-chart-line",
                     backgroundColor : this.themeColors.cyanLight,
                     color : this.themeColors.cyan,
                   },
                   title : {
                     value : this.header_results.marginpercent, ///
                     format : "percentage",
                     subtitle : "Margin " + this.selectedperiod,
                   },
                   dataset : {
                     backgroundColor : this.themeColors.cyan,
                     labels : data.labels,
                     data : [{data : data.margin}],
                     type : "line"
                   },
               }


               this.WIPChart = {
                   icon : {
                     name : "far fa-list-alt",
                     backgroundColor : this.themeColors.goldLight,
                     color : this.themeColors.gold,
                   },
                   title : {
                     value : this.header_results.wiptotal, ///
                     format : "currency",
                     subtitle : "WIP " + this.selectedperiod,
                   },
                   dataset : {
                     backgroundColor : this.themeColors.gold,
                     labels : data.labels,
                     data : [{data : data.WIP}],
                     type : "bar"
                   },
               }

               this.ProjectChart = {
                   icon : {
                     name : "fas fa-project-diagram",
                     backgroundColor : this.themeColors.purpleLight,
                     color : this.themeColors.purple,
                   },
                   title : {
                     value : this.header_results.projectcount, ///
                     format : "number",
                     subtitle : "Projects " + this.selectedperiod,
                   },
                   dataset : {
                     backgroundColor : this.themeColors.purple,
                     labels : data.labels,
                     data : [{data : data.projectcount}],
                     type : "line"
                   },
               }

               this.CustomerChart = {
                   icon : {
                     name : "fas fa-project-diagram",
                     backgroundColor : this.themeColors.purpleLight,
                     color : this.themeColors.purple,
                   },
                   title : {
                     value : this.header_results.customercount, ///
                     format : "number",
                     subtitle : "Customers " + this.selectedperiod,
                   },
                   dataset : {
                     backgroundColor : this.themeColors.purple,
                     labels : data.labels,
                     data : [{data : data.customercount}],
                     type : "line"
                   },
               }

               

               /*

               

               

               this.WIPChartLabels = data.labels;
               this.WIPChartData = [{data : data.WIP}]

               this.ProjCountChartLabels = data.labels;
               this.ProjCountChartData = [{data : data.projectcount}]*/
                              
          });
        
          this.sub3 = this.resultsService.getResults(filtros, "customer")
          .subscribe((data2 : any) => {
               let labels : Array<string> = [];
               let revenue = data2.data;
               let valores : Array<number> = [];
               //console.log(data2)
          }) 
          
          /*
          this.employeeService.getAnnuals([{headerOnly : "Y"}])
          .subscribe((data : any) => {
              console.log("employee service data : ")
              console.log(data)
              this.annuals_percent = data.header[0].annualok
              this.annuals_lastupload = data.header[0].dt_upload
          });

          this.employeeService2.getReviews([{headerOnly : "Y"}])
          .subscribe((data : any) => {
              console.log("employee service data : ")
              console.log(data)
              this.reviews_percent = data.header[0].reviewok
              this.reviews_lastupload = data.header[0].dt_upload
          });
          */
      });
  }

  ngOnDestroy() {
    if (this.sub1) {
      this.sub1.unsubscribe()
    }
     if (this.sub2) {
      this.sub2.unsubscribe()
    }
     if (this.sub3) {
      this.sub3.unsubscribe()
    }
    
  }


  /*

  RevenueChartOptions: any = {
        scaleShowVerticalLines: false,
        maintainAspectRatio: false,
        responsive: true,
        tooltips: { 
           mode: 'label', 
           label: 'mylabel', 
           callbacks: { 
               label: function(tooltipItem, data) { 
                   return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
               }, 
           }, 
        }, 
        scales: {
            xAxes: [{
                display: false,
                
                categoryPercentage: 0.35,
                barPercentage: 0.70,
              
                scaleLabel: {
                    display: false,
                    labelString: 'Month'
                },

                gridLines: false,
                
            }],
            yAxes: [{
                display: false,
                ticks: {
                    callback: function(label, index, labels) {
                        return (label/1000).toFixed(2).replace('.',',')+'k';
                    }
                },
                scaleOverride: true,
                scaleLabel: function (valuePayload) {
                    return Number(valuePayload.value).toFixed(2).replace('.',',') + '$';
                },
                gridLines: {
                    drawBorder: false,
                    offsetGridLines: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 4]
                },
                
            }],

        }
    };
    RevenueChartLabels: string[] = ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'];
    RevenueChartType = 'bar';
    RevenueChartColors: Array<any> = [
        { 
            backgroundColor: this.themeColors.blue,
            borderWidth: 0
        },
        
    ];
    RevenueChartData: any[] = [
        { 
            data: [20, 30, 35, 45, 55, 45],
        },
 
    ];
    

     ///////////////////////////////////////////

    MarginChartOptions: any = {
        scaleShowVerticalLines: false,
        maintainAspectRatio: false,
        responsive: true,
        tooltips: { 
           mode: 'label', 
           label: 'mylabel', 
           callbacks: { 
               label: function(tooltipItem, data) { 
                   return (tooltipItem.yLabel*100).toString() + "%";
               }, 
           }, 
        }, 
        scales: {
            xAxes: [{
                display: false,
                
                categoryPercentage: 0.35,
                barPercentage: 0.70,
              
                scaleLabel: {
                    display: false,
                    labelString: 'Month'
                },

                gridLines: false,
                
            }],
            yAxes: [{
                display: false,
                ticks: {
                    callback: function(label, index, labels) {
                        return (label/1000).toFixed(2).replace('.',',')+'k';
                    }
                },
                scaleOverride: true,
                scaleLabel: function (valuePayload) {
                    return Number(valuePayload.value).toFixed(2).replace('.',',') + '$';
                },
                gridLines: {
                    drawBorder: false,
                    offsetGridLines: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 4]
                },
                
            }],

        }
    };
    MarginChartLabels: string[] = ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'];
    MarginChartType = 'line';
    MarginChartColors: Array<any> = [
        { 
            backgroundColor: this.themeColors.cyan,
            borderWidth: 0
        },
        
    ];
    MarginChartData: any[] = [
        { 
            data: [20, 30, 35, 45, 55, 45],
        },
 
    ];
*/
    ////////////////////////////////////////////

    ProjCountChartOptions: any = {
        scaleShowVerticalLines: false,
        maintainAspectRatio: false,
        responsive: true,
        tooltips: { 
           mode: 'label', 
           label: 'mylabel', 
           callbacks: { 
               label: function(tooltipItem, data) { 
                   return tooltipItem.yLabel.toString();
               }, 
           }, 
        }, 
        scales: {
            xAxes: [{
                display: false,
                
                categoryPercentage: 0.35,
                barPercentage: 0.70,
              
                scaleLabel: {
                    display: false,
                    labelString: 'Month'
                },

                gridLines: false,
                
            }],
            yAxes: [{
                display: false,
                
                
            }],

        }
    };
    ProjCountChartLabels: string[] = ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'];
    ProjCountChartType = 'line';
    ProjCountChartColors: Array<any> = [
        { 
            backgroundColor: this.themeColors.purple,
            borderWidth: 0
        },
        
    ];
    ProjCountChartData: any[] = [
        { 
            data: [20, 30, 35, 45, 55, 45],
        },
 
    ];


    ///////////////////////////////////////////

    WIPChartOptions: any = {
        scaleShowVerticalLines: false,
        maintainAspectRatio: false,
        responsive: true,
        tooltips: { 
           mode: 'label', 
           label: 'mylabel', 
           callbacks: { 
               label: function(tooltipItem, data) { 
                   return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
               }, 
           }, 
        }, 
        scales: {
            xAxes: [{
                display: false,
                
                categoryPercentage: 0.35,
                barPercentage: 0.70,
              
                scaleLabel: {
                    display: false,
                    labelString: 'Month'
                },

                gridLines: false,
                
            }],
            yAxes: [{
                display: false,
                ticks: {
                    callback: function(label, index, labels) {
                        return (label/1000).toFixed(2).replace('.',',')+'k';
                    }
                },
                scaleOverride: true,
                scaleLabel: function (valuePayload) {
                    return Number(valuePayload.value).toFixed(2).replace('.',',') + '$';
                },
                gridLines: {
                    drawBorder: false,
                    offsetGridLines: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 4]
                },
                
            }],

        }
    };
    WIPChartLabels: string[] = ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'];
    WIPChartType = 'bar';
    WIPChartColors: Array<any> = [
        { 
            backgroundColor: this.themeColors.gold,
            borderWidth: 0
        },
        
    ];
    WIPChartData: any[] = [
        { 
            data: [20, 30, 35, 45, 55, 45],
        },
 
    ];
    

}
