import {Injectable} from '@angular/core';
import {Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {MenuService} from '../../shared/services/menu.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';

//declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

   authorizedMenus
   cleanUrl
   menusCarregados : boolean = false;

  constructor(private router: Router,
    private auth: AuthenticationService,
    //private menuService: MenuService,
    private gta : GoogleAnalyticsService  ) { 
     /*
     this.menuService.menuWithPath.subscribe((data: any) => {
            this.authorizedMenus = data;
            this.menusCarregados = true;
        })
        */
    }



/* // ESSE FUNCIONAVA BEM
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
         
         
         const currentUser = this.auth.currentUserValue;
         console.log(state.url )
         
         if (!currentUser) {
             return this.router.navigate(['/login']);
         } else {

             if (state.url === '/') {
                 return true;
             } else {
                 let dirtUrl = new String()
                 dirtUrl = state.url;
                 this.cleanUrl = state.url;

                 if (next.paramMap.get('id')) {
                     this.cleanUrl = this.trataParametro(dirtUrl , next.paramMap.get('id'));
                     
                      
                 }
                 if (!this.buscaIndice(this.cleanUrl)) {
                     this.router.createUrlTree(['/accessdenied']);
                 } else {
                     return true;
                 }
             }
        }
    }
*/

canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
         
         //console.log("ATIVOU O CanActivate -------------------------------");
         const currentUser = this.auth.currentUserValue;
        
         //console.log("[AuthGuard] next: ");
         //console.log(next);
         //console.log("state: ");
         //console.log(state);

          //let page_title = next.data.title;
          //let page_location = "/somwhere/";
  //gtag('config', 'G-XD5YTBVPLW', { 'page_path': page_location, 'page_title': page_title });

  //console.log(page_title)


  //this.gta.eventEmitter("my_page_view", page_title, page_location);
         
         if (currentUser) {
             return true;
             //console.log("[auth guard] user")
             //console.log(this.auth.currentUserValue)
            



         } else {
             
             //console.log(state.url)
        
             return this.router.createUrlTree(
                ['/login'], { 
                    queryParams: {
                        returnUrl : state.url
                    }
                } 
                //, { message: 'you do not have the permission to enter' }]
                // { skipLocationChange: true }
              );


             
             
         }
        
    }

    buscaIndice(cleanUrl) {
        let indice = 0;
        let i = 0;
        //console.log("[buscaIndice] ")
        //console.log("menus carregados?")
        //console.log(this.menusCarregados)

         while (!this.menusCarregados || i <= 10) {
             if (i == 10) { return false }
             this.authorizedMenus.findIndex(x => x.path == cleanUrl);
             //console.log("indice : " + indice)
             // Se existir, segue
             if (indice >= 0) {
                 return true
             } else { // Se nao existir, mostra o componente Access Denied
                 return false;
             }
             i = i + 1;
        }
    }

    trataParametro(dirtUrl, parametro ) {
        if (parametro) {
              let testePos = dirtUrl.indexOf( parametro ); 
              this.cleanUrl = this.cleanUrl.substring(0,testePos-1);
              //console.log("clean URL")
              //console.log(this.cleanUrl)
         }
    }
  
}
