import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd';
import {EmployeeService} from '../../../../shared/services/employee.service';
import {Employee, EmployeeDisplay} from '../../../../shared/model/employee.model';
import { NzNotificationService } from 'ng-zorro-antd';
import {LineService} from '../../../../shared/services/line.service';
import { Subscription } from 'rxjs';
import { OfferingService } from '../../../../shared/services/offering.service';


@Component({
  selector: 'app-new-employee-fast',
  templateUrl: './new-employee-fast.component.html',
  styleUrls: ['./new-employee-fast.component.css']
})
export class NewEmployeeFastComponent implements OnInit {

  formData
  @Input() employeeId

  lineFilterList
  empCategoryList
  offeringList

  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;


  loading 
  avatarUrl

  dateFormat = 'yyyyMM';
  

  salvando : boolean = false;

  constructor(private fb: FormBuilder,
              private editService : EmployeeService,
              private notification: NzNotificationService,
              private modal: NzModalRef,
              private lineService : LineService,
              private empCategoryService: EmployeeService,
              private offeringService : OfferingService ) { 

      this.formData = this.fb.group({
          id_employee : [null, [Validators.required]],
          sapcode: [null, [Validators.pattern(/^[0-9]/),
                            this.isValidSapcode()]],
          name : [null, [Validators.required]],
          shortname : [null],
          extraname : [null],
          email : [null],
          id_category: [null, [Validators.required]],
          id_offering: [null, [Validators.required]],
          id_line : [ null, [Validators.required] ],
          status : [ null, [Validators.required] ],
          date_resignation : [null],
          cpf : [null],
          racf : [null],
      });

  }

  ngOnInit() {

      this.formData.controls['id_employee'].setValue(this.employeeId);

      if (this.employeeId != 0) {

          this.getSelectedId(this.employeeId);   
      }

   
    this.sub1 = this.lineService.getAll()
      .subscribe(
        data => {
          this.lineFilterList = data;
        },
        error => {
          let errorMsg = "Loading Lines => " + error.error.message;
          this.notification.error("Error", errorMsg);
        });

    this.sub2 = this.offeringService.getAll()
      .subscribe(
        data => {
          this.offeringList = data;
        },
        error => {
          let errorMsg = "Loading Offerings => " + error.error.message;
          this.notification.error("Error", errorMsg);
        });


    this.sub3 = this.empCategoryService.getEmployeeCategory()
      .subscribe(
        data => {
          this.empCategoryList = data;
        },
        error => {
          let errorMsg = "Loading Categories => " + error.error.message;
          this.notification.error("Error", errorMsg);
        });
     
  }

  getSelectedId(id) {
        this.editService.getById(id)
        .subscribe((data : any) => {
            
            this.fullFillForm(data[0]);
        });
  }

  fullFillForm(obj : EmployeeDisplay) {

    console.log("fullFillForm")
    console.log(obj)
    if (obj) {
        this.formData.controls['id_employee'].setValue(obj.id_employee);
        this.formData.controls['sapcode'].setValue(obj.sapcode);
        this.formData.controls['name'].setValue(obj.name);
        this.formData.controls['shortname'].setValue(obj.shortname);
        this.formData.controls['extraname'].setValue(obj.extraname);
        this.formData.controls['email'].setValue(obj.email);
        this.formData.controls['id_category'].setValue(obj.id_category);
      this.formData.controls['id_line'].setValue(obj.id_line);
      this.formData.controls['id_offering'].setValue(obj.id_offering);

        this.formData.controls['cpf'].setValue(obj.cpf);
        this.formData.controls['racf'].setValue(obj.racf);
        
        if (obj.status === 'A') {
           this.formData.controls['status'].setValue(true);
        } else {
           this.formData.controls['status'].setValue(false);
        }

        if (obj.date_resignation) {
            let date_resignation1 = obj.date_resignation
            date_resignation1 = date_resignation1.toString()
            this.formData.controls['date_resignation'].setValue(this.StringParaData(date_resignation1)) ;

        } else {
            this.formData.controls['date_resignation'].setValue(null) ;
        }
        
        

        return true;
    } else {
        return false;
    }
  }

 DataParaString(o) {
    let year = o.getFullYear();
    let month = Number(o.getMonth())+1;
    let newMonth = String(month)

    if (month < 10) {
         newMonth = "0" + String(month)
    }  

    let valor = year + newMonth;
    
    return valor
  }

  StringParaData(o : String) : Date {

    if (o.length > 0) {
       let newYear = o.substr(0, 4)
       //substr(0,3)
       let newMonth = o.substr(4,2)

       let valor = newMonth +'/01/' + newYear;

       console.log(valor)
       return new Date(valor);  
    } else {
       return new Date();  
    }
  }

  SubmitForm() {
    this.salvando = true;
    if (this.formData.invalid) {
      this.formData.markAllAsTouched();
      /*
      let vetor = Object.keys(this.formData.controls)
      for (var i = 0; i < vetor.length; ++i) {
        console.log(vetor[i]);
        if (this.formData.controls[vetor[i]].errors != null) {
          this.notification.error("Erro", `Verify the field [${vetor[i]}] before saving`);
        }
      }
      */
      this.salvando = false;
      return;
    } 

    let obj = new Employee;

    // ###### tem erro no id_employee
    obj.id_employee = this.formData.controls['id_employee'].value;
    obj.sapcode = this.formData.controls['sapcode'].value;
    obj.name = this.formData.controls['name'].value;
    obj.shortname = this.formData.controls['shortname'].value;
    obj.extraname = this.formData.controls['extraname'].value;
    //obj.avatario = this.formData.controls['avatario'].value;

    obj.email = this.formData.controls['email'].value;

     obj.cpf = this.formData.controls['cpf'].value;
     obj.racf = this.formData.controls['racf'].value;
    //obj.date_start = this.formData.controls['date_start'].value;
    //obj.date_new_category = this.formData.controls['date_new_category'].value;
    obj.id_category = this.formData.controls['id_category'].value;
    obj.id_line = this.formData.controls['id_line'].value;
    obj.id_offering = this.formData.controls['id_offering'].value;

    if (this.formData.controls['status'].value === true) {
       obj.status = 'A';
    } else {
       obj.status = 'I';
       obj.date_resignation = this.DataParaString(this.formData.controls['date_resignation'].value);

    }
    
    // manda para o serviço
    if (this.employeeId == 0) {
         
         this.editService.insert(obj)
        .subscribe(
          data => {
            this.notification.blank("Success", "inserted successfully");
            this.destroyModal();
          },
          error => {
            let errorMsg = "Error Inserting => " + error.error.message;
            this.notification.error("Error", errorMsg);
            this.salvando = false;
           }
          
          
         );

    // se estiver fazendo update        
    } else {
        this.editService.update(this.employeeId, obj)
          .subscribe(
            data => {
              this.notification.blank("Success", "updated successfully");
              this.destroyModal();
            },
            error => {
              let errorMsg = "Error updating => " + error.error.message;
              this.notification.error("Error", errorMsg);
              this.salvando = false;
            }


          );
    } //this.SendNotification(
  }


  doesSapCodeExists : boolean = false;
  isValidSapcode(): ValidatorFn {
    return (control: AbstractControl): Validators => {

      if ((!control.value) || (control.value == '' )) { 
        return true;
      }

      this.editService.getFiltered([{key : 'sapcode', value: control.value}]).subscribe(
        data => {
          if (data) {
            this.doesSapCodeExists = true;
            return { invalidProject: true };
          } else {

            this.doesSapCodeExists = false;
            return false;
          }
        },
        error => {
          this.doesSapCodeExists = false;
          return false;
        }
      )
    }
  }

  destroyModal(): void {
    if (this.sub1) {
      this.sub1.unsubscribe();
    }
    if (this.sub2) {
      this.sub2.unsubscribe();
    }
    if (this.sub3) {
      this.sub3.unsubscribe();
    }
    
    this.modal.destroy({ data: 'this the result data' });
  }


}
