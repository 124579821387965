import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, } from '@angular/core'; //LOCALE_ID

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgZorroAntdModule, NZ_I18N, en_US, pt_BR, de_DE } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import pt from '@angular/common/locales/pt';

//import { NgZorroAntdModule, pt_BR, NZ_I18N} from 'ng-zorro-antd'; //
//import de from '@angular/common/locales/de';
//import en from '@angular/common/locales/en';
//import br from '@angular/common/locales/br';
//import pt from '@angular/common/locales/pt';
//import pt from '@angular/common/locales/pt';
//import { ChartsModule } frcd com 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';
import { TemplateModule } from './shared/template/template.module';
import { SharedModule } from './shared/shared.module';
import { ProductionModule } from './production/production.module';

import { AppComponent } from './app.component';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';

import { NgChartjsModule } from 'ng-chartjs';
import { ThemeConstantService } from './shared/services/theme-constant.service';

import {AuthGuard} from './shared/auth-guard/auth.guard';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { BusinessUnitsComponent } from './_registers/business-units/business-units.component';
import { UsersComponent } from './_registers/users/users.component';
import { LinesComponent } from './_registers/lines/lines.component';
import { CustomerSectorsComponent } from './_registers/customer-sectors/customer-sectors.component';
import { OfferingsComponent } from './_registers/offerings/offerings.component';
import { CustomersComponent } from './_registers/customers/customers.component';
import { CustomersFormComponent } from './_registers/customers/customers-form/customers-form.component';
import { ProjectsComponent } from './_registers/projects/projects.component';
import { ProjectsFormComponent } from './_registers/projects/projects-form/projects-form.component';
import { EmployeesComponent } from './_registers/employees/employees.component';
import { EmployeesFormComponent } from './_registers/employees/employees-form/employees-form.component';

registerLocaleData(pt);

@NgModule({
    declarations: [
        AppComponent,
        CommonLayoutComponent,
        FullLayoutComponent,
        BusinessUnitsComponent,
        UsersComponent,
        LinesComponent,
        CustomerSectorsComponent,
        OfferingsComponent,
        CustomersComponent,
        CustomersFormComponent,
        ProjectsComponent,
        ProjectsFormComponent,
        EmployeesComponent,
        EmployeesFormComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgZorroAntdModule,
        AppRoutingModule,
        TemplateModule,
        SharedModule,
        ProductionModule,
      NgChartjsModule,
      FormsModule, ReactiveFormsModule
    ],
    providers: [
        { 
            provide: NZ_I18N,
            useValue: pt_BR
        },
        //{ provide: NZ_I18N, useValue: 'en'},
        //{provide: LOCALE_ID, useValue: 'pt'},
        ThemeConstantService,
        AuthGuard
    ],
    bootstrap: [AppComponent],
    exports: [FormsModule, ReactiveFormsModule]
})
////, 
export class AppModule { }
