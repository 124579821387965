import { Component } from '@angular/core';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Router} from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
import { FlexmodalComponent } from '../../components/flexmodal/flexmodal.component';
import { API_ENDPOINT } from '../../../app.api';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent{

    searchVisible : boolean = false;
    quickViewVisible : boolean = false;
    isFolded : boolean;
    isExpand : boolean;

    //users : [];
    username: string = "";
    usershortname: string = "";
    position: string = "";
    userAvatar: string = "";
  employeeId
  userId

    constructor( private auth : AuthenticationService, 
                 private themeService: ThemeConstantService, 
                 private router: Router,
                 private modalService: NzModalService,
                 private notification: NzNotificationService
                 ) {}

    ngOnInit(): void {
      this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
      this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
       
      let user = this.auth.currentUserValue[0];
      //console.log(user)
      this.usershortname = user.usershortname;
      this.userAvatar = user.avatario;
      this.position = user.login;
      this.employeeId = user.id_employee;
      this.userId = user.id_user;
        //this.userAvatar = localStorage.getItem('avatar');
        //this.users = this.auth.getUser()
        //this.usershortname = localStorage.getItem('usershortname');
        //this.position = localStorage.getItem('categoryname');



        //console.log("[header] user:")
        //console.log(this.users)
    }

    toggleFold() {
        this.isFolded = !this.isFolded;
        this.themeService.toggleFold(this.isFolded);
    }

    toggleExpand() {
        this.isFolded = false;
        this.isExpand = !this.isExpand;
        this.themeService.toggleExpand(this.isExpand);
        this.themeService.toggleFold(this.isFolded);
    }

    searchToggle(): void {
        this.searchVisible = !this.searchVisible;
    }

    quickViewToggle(): void {
        this.quickViewVisible = !this.quickViewVisible;
  }


  profile_formFields = [
    
    { key: "id_employee", label: "_id", value: "", type: "text", visible: false },
    { key: "avatario", label: "Photo", value: "", type: "upload-image", visible: true },
    { key: "name", label: "Name", value: "", type: "text", visible: true },
    { key: "shortname", label: "Short name", value: "", type: "text", visible: true },
    { key: "id_line", label: "Line", value: "", type: "select", visible: true, dataFrom: { endpoint: 'line', id: 'id_line', label: 'name' } },
    { key: "id_offering", label: "Chapter", value: "", type: "select", visible: true, dataFrom: { endpoint: 'offering', id: 'id_offering', label: 'name' } },
    

    
  ]



  user_formFields = [
    { key: "id_user", label: "_id", value: "", type: "number", visible: false },
    { key: "login", label: "Login", value: "", type: "text", visible: true },
    { key: "password", label: "Passeord", value: "", type: "password", visible: true },
    { key: "id_employee", label: "Employee", value: "", type: "select", visible: true, dataFrom: { endpoint: 'employee', id: 'id_employee', label: 'name' } },
    { key: "id_user_profile", label: "Profile", value: "", type: "select", visible: true, dataFrom: { endpoint: 'user/profile', id: 'id_user_profile', label: 'name' } },
    { key: "status", label: "Status", value: "A", type: "AI", visible: true },
  ]



  UpdateProfile(id) {
    this.AddOne(id, this.profile_formFields, `${API_ENDPOINT}employee`, "My Profile")
  }

  UpdateUser(id) {
    this.AddOne(id, this.user_formFields, `${API_ENDPOINT}user`, "My Settings")
  }

  AddOne(_id, _formFields, _endpoint, title) {
    
    let modalWidth = 500;

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: FlexmodalComponent,
      nzWidth: modalWidth,
      nzComponentParams: {
        _id: _id,
        formFields: _formFields,
        endpoint: _endpoint
      },
    });

    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => {  });
  }

    


    notificationList = []
    /*
    notificationList = [
        {
            title: 'You received a new message',
            time: '8 min',
            icon: 'mail',
            color: 'ant-avatar-' + 'blue'
        },
        {
            title: 'New user registered',
            time: '7 hours',
            icon: 'user-add',
            color: 'ant-avatar-' + 'cyan'
        },
        {
            title: 'System Alert',
            time: '8 hours',
            icon: 'warning',
            color: 'ant-avatar-' + 'red'
        },
        {
            title: 'You have a new update',
            time: '2 days',
            icon: 'sync',
            color: 'ant-avatar-' + 'gold'
        }
    ];
    */

    logOut() {
        this.auth.logout();
        this.router.navigate(["/login"]);
    }
}
