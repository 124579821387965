
export class SubProjectTransaction {
  id_subproject_results : number;
  id_subproject : number;
  type : string;
  id_category : number;
  id_employee : number;
  repeat : number;
  times : number;
  period : string;
  tax : number;
  quantity : number;
  id_offering : number;
  comment : string;
  id_subproject_contract : number;

 }


export class SubProjectTransactionComposed {
  id_subproject_results : number;
  id_subproject : number;
  type : string;
  id_employee : number;
  
  id_category_REV : number;
  tax_REV : number;
  quantity_REV : number;

  id_category_CSR : number;
  tax_CSR : number;
  quantity_CSR : number;

  id_category_EXP1 : number;
  tax_EXP1 : number;
  quantity_EXP1 : number;

  id_category_EXP2 : number;
  tax_EXP2 : number;
  quantity_EXP2 : number;

  id_category_EXP3 : number;
  tax_EXP3 : number;
  quantity_EXP3 : number;
  

  repeat : number;
  times : number;
  period : string;
  id_offering : number;
  comment : string;
  id_subproject_contract : number;

 }

