import { Routes } from '@angular/router';
// cadastros
import { BusinessUnitsComponent } from '../../_registers/business-units/business-units.component';
import { AuthGuard } from '../../shared/auth-guard/auth.guard';
import { UsersComponent } from '../../_registers/users/users.component';
import { LinesComponent } from '../../_registers/lines/lines.component';
import { CustomerSectorsComponent } from '../../_registers/customer-sectors/customer-sectors.component';
import { OfferingsComponent } from '../../_registers/offerings/offerings.component';
import { CustomersComponent } from '../../_registers/customers/customers.component';
import { CustomersFormComponent } from '../../_registers/customers/customers-form/customers-form.component';
import { ProjectsComponent } from '../../_registers/projects/projects.component';
import { ProjectsFormComponent } from '../../_registers/projects/projects-form/projects-form.component';
import { EmployeesComponent } from '../../_registers/employees/employees.component';
import { EmployeesFormComponent } from '../../_registers/employees/employees-form/employees-form.component';

export const CommonLayout_ROUTES: Routes = [
    {
        path: 'commercial',
        loadChildren: () => import('../../commercial/commercial.module').then(m => m.CommercialModule),
    },
    {
        path: 'portfolio',
        loadChildren: () => import('../../portfolio/portfolio.module').then(m => m.PortfolioModule),
    },
    {
        path: 'production',
        loadChildren: () => import('../../production/production.module').then(m => m.ProductionModule),
    },
    {
        path: 'cadastros',
        loadChildren: () => import('../../cadastros/cadastros.module').then(m => m.CadastrosModule),
  },
  {
    path: "businessunits",
    component: BusinessUnitsComponent,
    data: {
      title: 'Business Units',
      headerDisplay: "none"
    },
    canActivate: [AuthGuard]
  },
  {
    path: "users",
    component: UsersComponent,
    data: {
      title: 'Users',
      headerDisplay: "none"
    },
    canActivate: [AuthGuard]
  },
  {
    path: "lines",
    component: LinesComponent,
    data: {
      title: 'Lines',
      headerDisplay: "none"
    },
    canActivate: [AuthGuard]
  },
  {
    path: "customer-sectors",
    component: CustomerSectorsComponent,
    data: {
      title: 'Customer Sectors',
      headerDisplay: "none"
    },
    canActivate: [AuthGuard]
  },
  {
    path: "offerings",
    component: OfferingsComponent,
    data: {
      title: 'Offerings',
      headerDisplay: "none"
    },
    canActivate: [AuthGuard]
  },
  {
    path: "customers",
    component: CustomersComponent,
    data: {
      title: 'Customers',
      headerDisplay: "none"
    },
    canActivate: [AuthGuard]
  },

  {
    path: 'customer/addcustomer',
    component: CustomersFormComponent,
    data: {
      title: 'Dashboard',
      headerDisplay: "none"
    },
    canActivate: [AuthGuard]
  },
  {
    path: "projects",
    component: ProjectsComponent,
    data: {
      title: 'Projects',
      headerDisplay: "none"
    },
    canActivate: [AuthGuard]
  },

  {
    path: 'projects/addproject',
    component: ProjectsFormComponent,
    data: {
      title: 'Dashboard',
      headerDisplay: "none"
    },
    canActivate: [AuthGuard]
  },
  {
    path: "employees",
    component: EmployeesComponent,
    data: {
      title: 'Employees',
      headerDisplay: "none"
    },
    canActivate: [AuthGuard]
  },

  {
    path: 'employees/addproject',
    component: EmployeesFormComponent,
    data: {
      title: 'Dashboard',
      headerDisplay: "none"
    },
    canActivate: [AuthGuard]
  },
        
];

/*

*/

