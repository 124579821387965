import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TableService } from '../../../shared/services/table.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.css']
})
export class CustomTableComponent implements OnInit {

  @Input() table
  @Input() count
  @Input() pagination
  @Input() n_records
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() radioGroupClicked: EventEmitter<any> = new EventEmitter<any>();

  exportData: Array<any> = []
  toggleSearch: boolean = false;
  selectedFieldSearch: string = ""
  searchString: string = ""

  paginationFlag: boolean = false;
  pageSize: number = 20;
  selectFieldsToggle: boolean = false;

  themeColors = this.colorConfig.get().colors;
  blue = this.themeColors.blue;
  blueLight = this.themeColors.blueLight;
  cyan = this.themeColors.cyan;
  cyanLight = this.themeColors.cyanLight;
  gold = this.themeColors.gold;
  purple = this.themeColors.purple;
  purpleLight = this.themeColors.purpleLight;
  red = this.themeColors.red;
  transparent = this.themeColors.transparent

  constructor(private tableSvc: TableService,
    private colorConfig: ThemeConstantService) { }

  /*
     OK - tratar busca de percentuais
     OK - FALTA POR O TITULO
     OK - DOWNLOAD
     OK PARA PERCENTUAL - TRATAR REGRAS 
 
     OK - PERMITIR COLOCAR BOTOES
     TRATAR CAMPOS BOOLEANOS E A/I
     AVATARES
     OK - TRATAR NOMES SHORT E LONG
 
       this.myTable = {
          options : {
             title : "",
             buttons : [
                 {}
             ]
          }
          itemList : {
             fields : [
                {key : "id_project", alias : "ID", format: ""},
                {key : "name", alias : "Name", format: ""},
                {key : "cusname", alias : "Customer", format: ""},
                {key : "empname", alias : "Manager", format: ""},
                {key : "netrevenue", alias : "Net Revenue", format: "currency"},
                {key : "marginvalue", alias : "Analytic Margin", format: "currency"},
                {key : "marginpercent", alias : "PTD", format: "percentage"},
             ],
             results : data.slice(0, data.length-1);
         },
         headers : {
           fields : [
               {key : "netrevenue", alias : "Net Revenue", format: "currency"},
               {key : "marginvalue", alias : "Analytic Margin", format: "currency"},
               {key : "marginpercent", alias : "PTD", format: "percentage"},
           ],
           results : data.slice(data.length-1);
        }
      }
 */
  ngOnInit() {
    /*
        if (this.pagination) {
           if (this.pagination == true) {
             this.paginationFlag = true
           } else {
             this.paginationFlag = false;
    
           }
        }
      */
  }

  itemList: Array<any> = []
  itemDisplay: Array<any> = []
  itemBackup: Array<any> = []
  fields: Array<any> = []
  visibleFields: Array<any> = []

  csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: ',',
    showLabels: true,
    showTitle: true,
    //title: 'Your Holiday List :',
    useBom: true,
    noDownload: false,
    headers: ["Holiday ID", "Holiday Date", "Holiday Comment", "Holiday Status"]
  };

  ngOnChanges(changes: SimpleChanges) {
    //console.log(this.table)
    if (this.table) {
      this.itemList = this.table.itemList.results;
      this.itemDisplay = this.table.itemList.results;
      this.itemBackup = this.table.itemList.results;
      this.fields = this.table.itemList.fields;
      this.visibleFields = []
      let firstTime = true;

      //if (!this.pagination) {
      //  this.pageSize = this.itemList.length;
      //}
      for (var i = 0; i < this.fields.length; ++i) {
        if (this.fields[i].visible) {
          this.visibleFields.push(this.fields[i])
          if (firstTime) {
            if ((this.fields[i].format == "") || (this.fields[i].format == "string")) {
              this.selectedFieldSearch = this.fields[i].key;
              firstTime = false;
            }
          }
        }
      }
    }

    //if (this.table.hasOwnProperty("headers")) {

  }

  PageDataChange($event): void {
    //console.log($event)
    //this.displayData = $event;
    //this.refreshStatus();
  }


  FieldsToggle() {
    this.selectFieldsToggle = !this.selectFieldsToggle;
  }

  sendEvent(eventKey, $event) {
    this.buttonClicked.emit({ event: eventKey, data: $event })
  }

  sort(sortAttribute: any) {
    this.itemList = this.tableSvc.sort(sortAttribute, this.itemList);
    //console.log("sort has been finished")
  }
  /*
  PageDataChange($event: Array<any>): void {
    this.itemDisplay = $event;
  }*/

  CheckField(field, currentVisibility) {
    // primeiro atualiza o menu de campos
    let index = this.fields.findIndex(x => x.key == field.key);
    if (index !== -1) {
      this.fields[index].visible = !currentVisibility;
    }

    // se for visivel, tornar invisível
    // se já existir, apaga dentre os visíveis
    // se nao existir, adiciona
    this.visibleFields = []
    for (var i = 0; i < this.fields.length; ++i) {
      if (this.fields[i].visible) {
        this.visibleFields.push(this.fields[i]);
      }
    }
  }


  ShowSearchArea() {
    this.toggleSearch = !this.toggleSearch;
  }

  // Filtra conforme a digitação do usuário
  // Funciona ok para string e percentuais
  FilterArray() {
    //console.log("FilterArray()")
    if (this.selectedFieldSearch) {
      //console.log("field search = " + this.selectedFieldSearch)
      let fieldSearch = this.selectedFieldSearch;
      let index = this.visibleFields.findIndex(x => x.key == fieldSearch);
      let format = this.visibleFields[index].format
      let searchingBy = this.searchString;
      //console.log("searching by = " + searchingBy)
      if (searchingBy == "") {
        this.itemDisplay = this.itemBackup;
      } else {
        if (format == "percentage") {
          this.itemList = this.itemBackup.filter(function (pilot) {
            return (Number(pilot[fieldSearch]) * 100).toString().toLowerCase().includes(searchingBy.toLowerCase());
          });
        } else {
          //console.log("to aqui")
          this.itemList = this.itemBackup.filter(function (pilot) {
            //return pilot[fieldSearch] == searchingBy;
            //console.log("pilot")
            //console.log(pilot)
            if (pilot[fieldSearch]) {
              return pilot[fieldSearch].toString().toLowerCase().includes(searchingBy.toLowerCase());
            }
          });
        }

      }
    }
  }

  onHeaderRadioStatusChange($event) {
    this.radioGroupClicked.emit($event)
  }

  // Organiza o download a partir do campos visíveis  
  DownloadResults() {
    let head = []
    this.exportData = []

    for (var i = 0; i < this.visibleFields.length; ++i) {
      head.push(this.visibleFields[i].label);
    }

    for (var i = 0; i < this.itemList.length; ++i) {
      let coisa = {}
      for (var j = 0; j < this.visibleFields.length; ++j) {
        coisa[this.visibleFields[j].key] = this.itemList[i][this.visibleFields[j].key];
      }
      this.exportData.push(coisa);
    }
    this.csvOptions.headers = head;
    if (this.table.hasOwnProperty("options")) {
      if (this.table.options.hasOwnProperty("title")) {
        new AngularCsv(this.exportData, this.table.options.title, this.csvOptions);
      } else {
        new AngularCsv(this.exportData, "Export data", this.csvOptions);
      }
    } else {
      new AngularCsv(this.exportData, "Export data", this.csvOptions);
    }

  }
 }
