import { Component, OnInit, OnDestroy } from '@angular/core';
import {SubprojectResultsService} from '../../services/subproject-results.service';
import {Filter} from '../../../shared/model/filter.model';
import {PortfolioFilterService} from '../../services/portfolio-filter.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-kpis',
  templateUrl: './kpis.component.html',
  styleUrls: ['./kpis.component.css']
})
export class KpisComponent implements OnInit {
  
  itemList
  filters : Array<Filter> = []

  selectedProject = null
  selectedPortfolio

  subs : Array<Subscription> = [];

  constructor(private listService : SubprojectResultsService,
  			  private filterService : PortfolioFilterService,) { }

  ngOnInit() {
  	
    this.subs.push(this.filterService.data
      .subscribe ((filtros : any) => {
          this.filters = filtros;
          this.testProject();
      }));

  }

  testProject() {
    this.selectedProject = this.filterService.getValueOf("id_project");
    this.selectedPortfolio = this.filterService.getValueOf("id_portfolio");
    
    if (this.selectedProject) {
      this.getData(); 

    } else if  (this.selectedPortfolio) {
      this.getData(); 
    }
  }

  getData() {

  	
  		this.subs.push(this.listService.getOKRsNumericos(this.filters)
	      .subscribe((data : any) => {
	        console.log("OKRS NUMERICOS")
	        console.log(data)
	        this.itemList = data;
	        //this.itemCount = this.itemList.length-1;
	    }))
  	
  	
  }

  ngOnDestroy() {
     this.subs.map(s => s.unsubscribe);
  }

}
