import { Component, OnInit } from '@angular/core';
import { TableService } from '../../services/table.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';

import { Filter } from '../../model/filter.model';
import {API_ENDPOINT} from '../../../app.api';

import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { ReflectiveInjector } from '@angular/core';
import {UploadAreaComponent} from '../../../shared/template/upload-area/upload-area.component';


@Component({
  selector: 'app-default-register',
  template: 'defaut-register.component.html'
})
export class DefaultRegisterComponent implements OnInit {
  
  //private static injector  : ReflectiveInjector;
  
  // variables for smart TableService
  allChecked: boolean = false;
  indeterminate: boolean = false;
  search : any;
  displayData = [];
  ordersList = [];
  selectedData = [];
  displayKeys = [];

  public testVariable = "teste variavel";

  // Data handling
  selectedId
  formData 
  filters : Array<Filter> = [];

  // Modal handling
  modalBasicIsVisible = false;
  estaInserindo = false;

  //for avatars
  customurl :string = `${API_ENDPOINT}/image`;
  customurl2 :string = `${API_ENDPOINT}/avatar`;
  avatarUrl: string = "";
  mostraAvatar : string = "";
  loading : boolean = false;
  uploading = false;

  //CSV
  csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    //title: 'Your Holiday List :',
    useBom: true,
    noDownload: false,
    headers: ["Holiday ID", "Holiday Date", "Holiday Comment", "Holiday Status"]
  };

  // add some color to the paiting
  
    themeColors = this.colorConfig.get().colors;
    blue = this.themeColors.blue;
    blueLight = this.themeColors.blueLight;
    cyan = this.themeColors.cyan;
    cyanLight = this.themeColors.cyanLight;
    gold = this.themeColors.gold;
    purple = this.themeColors.purple;
    purpleLight = this.themeColors.purpleLight;
    red = this.themeColors.red;
    transparent = this.themeColors.transparent
 

  // Just the basic services
  constructor(protected colorConfig:ThemeConstantService,
              protected tableSvc : TableService, 
              protected modalService: NzModalService, 
              protected fb: FormBuilder,
              protected notification: NzNotificationService) {}

  // Init only calls getData
  ngOnInit() {
  	this.getData();
    //this.zeraForm();
  }

  popKeys(obj) {
    let typeKeys : Array<any> = [];
    let tempObj : any;

    if (obj.length > 1) {
       tempObj = obj[0];
    } else {
       tempObj = obj;
    }

    Object.keys(tempObj).forEach((key : string) => {
      typeKeys.push(
        key
      );
    })

    return typeKeys;
  }

  createForm(typeKeys : Array<any>) {

    /*
    let teste : FormGroup;

    typeKeys.forEach((key : string) => {
      teste.addControl(key, AbstractControl);
    });
    */
  }
/*
  createForm(group : FormGroup) {

    Object.keys(group.controls).forEach((key: string) => {
       const abstractControl = group.get(key);
       if (abstractControl instanceof FormGroup) {
          this.fullFillForm(abstractControl);
       } else {
          group.controls[key].setValue(this.selectedData[key]);
       }
    });

  }
*/
  NewItem() {

  }
  //Add data to display and orderList
  getData() {}

  zeraForm(){
    if (this.formData) {
        Object.keys(this.formData.controls).forEach((key : string ) => {
          this.formData.controls[key].setValue(null);
        });
        return true;
    } else {
        return false;
    }
    this.mostraAvatar = "";
  }

  // Treat datePicker component
  // receives string and transforms to Date
  getDate(str : string) {
    console.log("app-default-register / get date: " + str )
    
    if ((str !== null)) {
       return new Date(str);
    } else {
      return null;
    }
  }

  fullFillForm(obj) {
    if (obj) {
        // Loop no Form, pega os dados do response (se houver) e preenche
        Object.keys(this.formData.controls).forEach((key : string ) => {
           if (Object.prototype.hasOwnProperty.call(obj, key)) { // true
              this.formData.controls[key].setValue(obj[key]);
           } else {
              this.formData.controls[key].setValue(null);
              console.log("[father] a chave (" + key + ") não existe");
           }
        });
        console.log(this.formData.value)
        return true;
    } else {
        // Se nao tiver nada, fica vazio
        //console.log("empty");
        return false;
    }

  }

  // Add Filters to a query
  addToFilterArray(obj : Filter) {
    let index = this.filters.findIndex(x => x.key == obj.key);
    if (index === -1) {
       this.filters.push(obj); 
    } else {
       this.filters[index].value = obj.value;
    }    
    console.log(this.filters);
    this.getData();
  }

  // Sort table
  sort(sortAttribute: any) {
    
	  this.ordersList = this.tableSvc.sort(sortAttribute, this.ordersList);
  }

  // ### currentPageDataChange dunno how to do


  refreshStatus(): void {
  	const allChecked = this.displayData.every(value => value.checked === true);
  	const allUnChecked = this.displayData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.displayData.forEach(data => {
        data.checked = value;
    });
  }

  // getSelectedId
  getSelectedId(id) {}

  //show modal
  basicModalShow(id): void {
    if (id!=0) {
        this.estaInserindo = false;
        this.selectedId = id;
        this.getSelectedId(id);
    } else {
        this.estaInserindo = true;
        console.log("está inserindo")
    }
    this.modalBasicIsVisible = true;
  }

  //zeraTudo
  basicModalHandleOk() : void {
  	this.modalBasicIsVisible = false;
    this.zeraForm();
    this.getData();
    this.mostraAvatar = "";
  }

  getFile() {
	  let head = []
   	for (var i = 0; i < this.ordersList.keys.length; ++i) {
		head.push(this.ordersList.keys[i]);
   	}
    this.csvOptions.headers = head;
    new  AngularCsv(this.displayData, "Data", this.csvOptions);
  }

  ConfirmationValidator = (control: FormControl): { [s: string]: boolean } => {
        if (!control.value) {
            return { required: true };
        } 
        /*else if (control.value !== this.OKRForm.controls.password.value) {
            return { confirm: true, error: true };
        }*/
  }

  SubmitForm(): void {}

  Delete(item) {}

  Message(element, type, worked) {
    let notifTitle = ""
    let notifText = ""  
    if (worked) {
       notifTitle = "Success"
       if (type !== "insert") {
          notifText = element + " successfully " + type + "d";  
       } else {
          notifText = element + " successfully " + type + "ed";  
       }
       
    } else {
       notifTitle = "Error"
       notifText = element + " had an error " + type + "ing";
    }

    this.notification.blank(notifTitle, notifText);
  }

  SendNotification(worked : boolean) {
    if (worked) {
      this.notification.blank("Success", "Item successfully update");
    } else {
      this.notification.error("Error", "Error occurred on edition");
    }
  }


  beforeUpload = (file: File) => {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png'
        if (!isJPG && !isPNG) {
             this.notification.error("Error", "You can only upload JPG file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
             this.notification.error("Erro", "Image must smaller than 2MB!");
        }
        return  isJPG && isLt2M; 
    }

  private getBase64(img: File, callback: (img: {}) => void): void {
      //console.log("[getbase64] img:");
      //console.log(img)
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
  }

  handleChange(info: { file: UploadFile }): void {
        //console.log(info);
        if (info.file.status === 'uploading') {
            this.loading = true;
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.getBase64(info.file.originFileObj, (img: string) => {
              this.loading = false;
              this.avatarUrl = img;
            });

            this.formData.controls['avatario'].setValue(info.file.response.uploadUrl);
        }
    }

  openUploadDialog() {
    this.uploading = !this.uploading;
  }

  DataParaString(o) {
    let year = o.getFullYear();
    let month = Number(o.getMonth())+1;
    let newMonth = String(month)

    if (month < 10) {
         newMonth = "0" + String(month)
    }  

    let valor = year + newMonth;
    return valor
  }

  StringParaData(o : String) : Date {

    if (o.length > 0) {
       let newYear = o.substr(0, 4)
       //substr(0,3)
       let newMonth = o.substr(4,2)

       let valor = newMonth +'/01/' + newYear;

       console.log(valor)
       return new Date(valor);  
    } else {
       return new Date();  
    }
    
  }

  



}
