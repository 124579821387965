import { NgModule } from '@angular/core';
import { CommonModule, DatePipe} from "@angular/common";


import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { HighlightModule } from 'ngx-highlightjs';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ThemeConstantService } from './services/theme-constant.service';
import { CodeBoxComponent } from './directives/code-box.component'
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';

//import {DefaultRegisterComponent} from './template/default-register/default-register.component';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { AuthGuard} from './auth-guard/auth.guard'
import {SearchPipe} from './pipes/search.pipe';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { ScrollTrackerDirective } from './directives/scroll-tracker.directive';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { PercentpipePipe } from './pipes/percentpipe.pipe';

import { LoginComponent } from './login-flow/login/login.component';
import { ForgottenPasswordComponent } from './login-flow/forgotten-password/forgotten-password.component';
import { ResetPasswordComponent } from './login-flow/reset-password/reset-password.component';
import { CurrencyformatPipe } from './pipes/currencyformat.pipe';
import { FlexfilterComponent } from './components/flexfilter/flexfilter.component';
import { FlexmodalComponent } from './components/flexmodal/flexmodal.component';
import { CadastrosAuxiliaresComponent } from './components/cadastros-auxiliares/cadastros-auxiliares.component';
import { FlexlistComponent } from './components/flexlist/flexlist.component';
import { ModernTableComponent } from './components/modern-table/modern-table.component';

import { GoogleAnalyticsService } from './services/google-analytics.service';
import { CustomTableComponent } from './components/custom-table/custom-table.component';

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        NgZorroAntdModule,
        PerfectScrollbarModule,
        CodeBoxComponent,
        HighlightModule,
        SearchPipe,
        EllipsisPipe,
        CurrencyformatPipe,
        PercentpipePipe,
        ScrollTrackerDirective,
        FlexfilterComponent,
        FlexmodalComponent,
        FlexlistComponent,
        CadastrosAuxiliaresComponent,
    ModernTableComponent,
    CustomTableComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
      ReactiveFormsModule,
      NzAutocompleteModule,
        HighlightModule.forRoot({ theme: 'atom-one-light'}),
        NgZorroAntdModule,
        PerfectScrollbarModule,
        
    ],
    declarations: [
        CodeBoxComponent,
        SearchPipe,
        LoginComponent,
        EllipsisPipe,
        ScrollTrackerDirective,
        AccessDeniedComponent,
        CurrencyformatPipe,
        PercentpipePipe,
        ForgottenPasswordComponent,
        ResetPasswordComponent,
        FlexfilterComponent,
        FlexmodalComponent,
        CadastrosAuxiliaresComponent,
        FlexlistComponent,
        ModernTableComponent,
        CustomTableComponent
    ],
    providers: [
      ThemeConstantService, AuthGuard, DatePipe, GoogleAnalyticsService
    ]
})

export class SharedModule { }
