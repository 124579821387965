import { Component, OnInit, OnDestroy } from '@angular/core';
import {Filter} from '../../../shared/model/filter.model';
import {SubprojectResultsService} from '../../services/subproject-results.service';
import {PortfolioFilterService} from '../../services/portfolio-filter.service';
import { TableService } from '../../../shared/services/table.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-leader-results',
  templateUrl: './leader-results.component.html',
  styleUrls: ['./leader-results.component.css']
})
export class LeaderResultsComponent implements OnInit {
  
  ordersList
  displayData
  ordersListCount : number = null;
  filters : Array<Filter> = []

  selectedProject
  selectedPortfolio

  view = "clean"

  subs : Array<Subscription> = [];

  csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: true,
      title: 'Leader Results',
      useBom: true,
      noDownload: false,
      headers: ["Leader Id", "Leader Name", "Employees", "Gross Revenue", "Net Revenue", "CSR", "EXP", "Margin", "Margin %"]
  };

  constructor(private tableSvc : TableService, 
              private listService : SubprojectResultsService,
              private filterService : PortfolioFilterService, ) { }

  ngOnInit() {

    this.subs.push(this.filterService.data
      .subscribe ((filtros : any) => {
          this.filters = filtros;
          this.testProject();
      }));
      
      //this.getOfferings();
  }

 testProject() {
    this.selectedProject = this.filterService.getValueOf("id_project");
    this.selectedPortfolio = this.filterService.getValueOf("id_portfolio");
    
    if (this.selectedProject) {
      console.log("offering resutls -  rolou por portfolio")
      this.getListData(); 

    } else if  (this.selectedPortfolio) {
      this.getListData(); 
      console.log("offering resutls - nao rolou por portfolio")
    }
  }

  myTable

  getListData() {
    this.subs.push(this.listService.getLeader(this.filters)
      .subscribe((data : any) => {
          this.ordersList= data;
          //this.displayData  = data;
          console.log("resultados em leader results")
          console.log(data)

          this.myTable = {
                     itemList : {
                        fields : [
                           {key : "id_employee", label: "ID", alias : "ID", format: "", visible: false},
                           {key : "name", label: "Name", alias : "Leader Name", format: "", visible: true},
                           {key : "employees", label: "Employees", alias : "Employees", format: "", visible: true},

                           {key : "REV", label: "Gross Revenue", alias : "Gross Revenue", format: "currency", visible: false},
                           {key : "NETREV", label: "Net Revenue", alias : "Net Revenue", format: "currency", visible: true},

                           {key : "AVG_GROSS_TAX", label: "AVG Gross Rate", alias : "AVG Gross Rate", format: "currency",  red : 135, direction : "<", visible: false},
                           {key : "AVG_NET_TAX", label: "AVG Net Rate", alias : "AVG Net Rate", format: "currency", red : 135, direction : "<", visible: false},
                           {key : "AVG_CSR", label: "AVG CSR", alias : "AVG CSR", format: "currency", red : 72, direction : ">=", visible: false},
                           {key : "EXPECTED_AVG_CSR", label: "EXPECTED_AVG_CSR", alias : "EXPECTED_AVG_CSR", format: "currency",  red : 72, direction : ">=", visible: false},
                           {key : "MARGIN_OVER_AVG_CSR", label: "MARGIN_OVER_AVG_CSR", alias : "MARGIN_OVER_AVG_CSR", format: "percentage", red : 0.45, direction : "<", visible: false},

                           
                           {key : "CSR", label: "CSR", alias : "CSR", format: "currency", visible: true},
                           {key : "EXP", label: "Expenses", alias : "Expenses", format: "currency", visible: true},

                           {key : "margin", label: "Analytic Margin", alias : "Analytic Margin", format: "currency", visible: false},
                           
                           {
                               key : "marginpercent", 
                               label: "Margin %", 
                               alias : "Margin %", 
                               format: "percentage", 
                               visible: true,
                               red : 0.40,
                               direction : "<"
                           },
                        ],
                        results : data.slice(0, data.length-1),
                        //buttons : [
                        //  {key : "edit", label: "", hint: "Edit this project", icon: "fas fa-edit"}
                        //]
                    },
                    headers : {
                        fields : [
                           {key : "NETREV", label : "Net Revenue", format: "currency"},
                           {key : "CSR", label : "CSR", format: "currency"},
                           {key : "EXP", label : "Expenses", format: "currency"},
                           {key : "margin", label : "Analytic Margin", format: "currency"},
                           {
                               key : "marginpercent", 
                               label : "PTD", 
                               format: "percentage",
                               red : 0.40,
                               direction : "<"
                           },
                           {key : "employees", label : "Employees", format: ""},
                           {
                               key : "AVG_GROSS_TAX", 
                               label: "Average Rate", 
                               format: "currency", 
                               red : 135,
                               direction : "<"
                           },
                           {
                               key : "AVG_CSR", 
                               label: "Average CSR", 
                               format: "currency", 
                               red : 72,
                               direction : ">="
                           },
                        ],
                        results : data.slice(data.length-1)
                    }
                 }

      }));
  }

  getFile() {
     new  AngularCsv(this.displayData, "Offering Results", this.csvOptions);
  }

  onStatusChange($event) {
    this.filterService.addFilter({key: "grouped", value : $event})
    this.getListData();
  }

  sort(sortAttribute: any) {
    this.displayData = this.tableSvc.sort(sortAttribute, this.displayData);
  }

  ngOnDestroy() {
     this.subs.map(s => s.unsubscribe);
  }



}
