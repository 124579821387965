import { Component, OnInit, Input} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
import { ProjectService } from '../../../shared/services/project.service';
import { LineService } from '../../../shared/services/line.service';
import { CustomerService } from '../../../shared/services/customer.service';
import { CustomersectorService } from '../../../shared/services/customersector.service';
import { Customer, CustomerDisplay } from '../../../shared/model/customer.model';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from '../../../shared/services/google-analytics.service';

@Component({
  selector: 'app-customers-form',
  templateUrl: './customers-form.component.html',
  styleUrls: ['./customers-form.component.css']
})
export class CustomersFormComponent implements OnInit {


  subs: Array<Subscription> = [];
  mostraAvatar
  @Input() customerId
  formData
  estaInserindo: boolean = false;
  salvando: boolean = false;
  customerSectorFilterList: any;


  //, 

  constructor(private fb: FormBuilder,
    private modal: NzModalRef,
    private notification: NzNotificationService,
    private editService: CustomerService,
    private customerSectorService: CustomersectorService,
    private gta : GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.formData = this.fb.group({
      id_customer: [null, [Validators.required, Validators.pattern(/^[0-9]/), this.isValidCustomer()]],
      name: [null, [Validators.required]],
      shortname: [null, [Validators.required]],
      //avatario : [null],
      //id_customer_group : [null],
      //isgroup : [null],
      id_customer_sector: [null, [Validators.required]],
      id_organization: [1],
      status: [null, [Validators.required]],
    });

    this.subs.push(
      this.customerSectorService.getAll()
        .subscribe(
          data => {
            this.customerSectorFilterList = data;
          },
          error => {
            let errorMsg = "Loading Customers => " + error.error.message;
            this.notification.error("Error", errorMsg);
            this.gta.eventEmitter("error_found", "loading", "customers-form", "customerSectorService_getAll", 1);

          }
        ));

    this.mostraAvatar = "";

    if (this.customerId != 0) {
      this.getSelectedId(this.customerId);
    } else {
      this.estaInserindo = true;
    }
  }

  // # Just change the service end point
  getSelectedId(id) {

    this.subs.push(this.editService.getById(id)
      .subscribe(
        data => {
          this.fullFillForm(data[0]);
        },
        error => {
          let errorMsg = "Loading Customer => " + error.error.message;
          this.notification.error("Error", errorMsg);
          this.gta.eventEmitter("error_found", "loading", "customers-form", "getSelectedId", this.customerId);

        }
      ));
  }

  // como temos o caso especial da SALES_MARGIN
  // tem que reescrever esse método no componente
  // outra opcao seria na API, mas vai trazer mais complicação
  fullFillForm(obj: CustomerDisplay) {

    if (obj) {

      

      this.formData.controls['id_customer'].setValue(obj.id_customer);
      this.formData.controls['name'].setValue(obj.name);
      this.formData.controls['shortname'].setValue(obj.shortname);
      //this.formData.controls['avatario'].setValue(obj.avatario);
      //
      //

      this.formData.controls['id_customer_sector'].setValue(obj.id_customer_sector);
      this.formData.controls['id_organization'].setValue(obj.id_organization);

      this.mostraAvatar = obj.avatario;

      if (obj.status === 'A') {
        this.formData.controls['status'].setValue(true);
      } else {
        this.formData.controls['status'].setValue(false);
      }

      return true;
    } else {
      return false;
    }
  }



  SubmitForm() {
    console.log(this.formData)

    this.salvando = true;
    if (this.formData.invalid) {
      this.formData.markAllAsTouched();
      this.salvando = false;
      return;
    }

    let obj = new Customer;

    // ###### tem erro no id_employee
    obj.id_customer = this.formData.controls['id_customer'].value;
    obj.name = this.formData.controls['name'].value;
    obj.shortname = this.formData.controls['shortname'].value;
    //obj.avatario = this.formData.controls['avatario'].value;

    obj.id_customer_sector = this.formData.controls['id_customer_sector'].value;
    obj.id_organization = this.formData.controls['id_organization'].value;

    if (this.formData.controls['status'].value === true) {
      obj.status = 'A';
    } else {
      obj.status = 'I';
    }

    // manda para o serviço
    if (this.estaInserindo) {
      this.subs.push(
        this.editService.insert(obj)
          .pipe(first())
          .subscribe(
            data => {
              //this.router.navigate([this.returnUrl]);
              this.gta.eventEmitter("updating_succes", "good_experience", "inserting", "[customer] customers-form", 1);

              this.notification.success("Success", "inserted successfully");
              this.destroyModal();
            },
            error => {
              this.gta.eventEmitter("error_found", "inserting", "customers-form", "SubmitForm", 0);
              this.notification.error("Error", error.error.message);
              this.salvando = false;
            }));

      // se estiver fazendo update        
    } else {

      this.subs.push(this.editService.update(this.customerId, obj)
        .pipe(first())
        .subscribe(
          data => {
            //this.router.navigate([this.returnUrl]);
            this.notification.success("Success", "updated successfully");
            this.gta.eventEmitter("updating_succes", "good_experience", "updating", "[customer] customers-form", 1);
            
            this.destroyModal();
          },
          error => {
            console.log(error)
            this.notification.error("Error", error.error.message);
            this.gta.eventEmitter("error_found", "updating", "customers-form", "SubmitForm", this.customerId);
            this.salvando = false;
          }));
    }
  }


  doesCustomerExists: boolean = false;
  isValidCustomer(): ValidatorFn {
    return (control: AbstractControl): Validators => {

      if ((!control.value) || (control.value == '')) {
        return true;
      }

      this.subs.push(this.editService.getById(control.value).subscribe(
        data => {
          if (data) {
            this.doesCustomerExists = true;
            return { invalidProject: true };
          } else {

            this.doesCustomerExists = false;
            return false;
          }
        },
        error => {
          this.doesCustomerExists = false;
          return false;
        }
      ))
    }
  }

  destroyModal(): void {
    this.modal.destroy({ data: 'this the result data' });
    this.subs.map(s => s.unsubscribe);
  }

}

