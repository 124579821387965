import { Component, OnInit } from '@angular/core';
import { TableService } from '../../../../shared/services/table.service';
import {FilterService} from '../../../../shared/services/filter.service';
import {LineService} from '../../../../shared/services/line.service';
import { ThemeConstantService } from '../../../../shared/services/theme-constant.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'


@Component({
  selector: 'app-preclosure-view',
  templateUrl: './preclosure-view.component.html'
})
export class PreclosureViewComponent implements OnInit {
  
  projectEstimation : any;

  displayProjectEstimation : any;

  themeColors = this.colorConfig.get().colors;
    blue = this.themeColors.blue;
    blueLight = this.themeColors.blueLight;
    cyan = this.themeColors.cyan;
    cyanLight = this.themeColors.cyanLight;
    gold = this.themeColors.gold;
    purple = this.themeColors.purple;
    purpleLight = this.themeColors.purpleLight;
    red = this.themeColors.red;
    transparent = this.themeColors.transparent

  csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: ',',
    showLabels: true,
    showTitle: true,
    //title: 'Your Holiday List :',
    useBom: true,
    noDownload: false,
    headers: ["Holiday ID", "Holiday Date", "Holiday Comment", "Holiday Status"]
  };


  constructor(private filterService : FilterService, 
  			      private colorConfig:ThemeConstantService,
  	          private tableSvc : TableService, 
  	          private lineEstimationService : LineService,) { }

  ngOnInit() {

  	this.filterService.data
      .subscribe ((filtros : any) => {

          this.lineEstimationService.getProjectEstimation(filtros)
          .subscribe((data: any) =>{
            this.projectEstimation = data;
            this.displayProjectEstimation = data;
          }); 
      });
  }

   DownloadProjectResults() {
       let head = []
       for (var i = 0; i < this.projectEstimation.keys.length; ++i) {
         head.push(this.projectEstimation.keys[i]);
       }
       this.csvOptions.headers = head;
       new  AngularCsv(this.projectEstimation, "Project Pre-closure Diff", this.csvOptions);
  }

  sort(sortAttribute: any) {
        this.displayProjectEstimation = this.tableSvc.sort(sortAttribute, this.displayProjectEstimation);
        //console.log("sort has been finished")
  }

}
