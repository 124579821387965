import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, HostListener, ElementRef} from '@angular/core';
import { TableService } from '../../../shared/services/table.service';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import {DateService} from '../../../shared/services/date.service';

@Component({
  selector: 'app-modern-table',
  templateUrl: './modern-table.component.html',
  styleUrls: ['./modern-table.component.css']
})
export class ModernTableComponent implements OnInit {

  
  @Input() table
  @Input() selectFieldsToggle : boolean;
  @Output() buttonClicked : EventEmitter<any> = new EventEmitter<any>();
  @Input() count


  exportData : Array<any> = []
  toggleSearch : boolean = false;
  selectedFieldSearch : string = ""
  searchString : string = ""

  itemList: Array<any> = []
  itemDisplay : Array<any> = []
  itemBackup : Array<any> = []
  fields : Array<any> = []
  visibleFields : Array<any> = []

  csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: true,
      title: 'SubProject Results',
      useBom: true,
      noDownload: false,
      headers: []
  };


  constructor(private tableSvc : TableService, 
              private colorConfig:ThemeConstantService,
              private dateService : DateService) {}
              //public el: ElementRef<HTMLElement>,) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log("onchanges");
    //console.log(this.table)
    if (this.table) {
        this.itemList = this.table.itemList.results;
        this.itemDisplay = this.table.itemList.results;
        this.itemBackup = this.table.itemList.results; 
        this.fields = this.table.itemList.fields;
        this.visibleFields = [];
        this.csvOptions.headers = [];
        let firstTime = true;

        for (var i = 0; i < this.fields.length; ++i) {
            if (this.fields[i].visible) {
                this.visibleFields.push(this.fields[i])
                this.csvOptions.headers.push(this.fields[i].key)
                if (firstTime) {
                   if ((this.fields[i].format == "") || (this.fields[i].format == "string")) {
                      this.selectedFieldSearch = this.fields[i].key;
                      firstTime = false;  
                   }
                }
            }
        }
    }
    //console.log("modern table - ny table");
    //console.log(this.table);

    //if (this.table.hasOwnProperty("headers")) {

  }

  convertDate(valor) {
    //console.log("[modertn table] rodou convert date")
    return valor//this.dateService.fromMongoToAngular(valor);
  }

  sendEvent(eventKey, $event) {
    //console.log("modern table = emitiu evento ")
    //console.log($event)
    this.buttonClicked.emit({event: eventKey, data : $event})
  }

  sort(sortAttribute: any) {
        this.itemList = this.tableSvc.sort(sortAttribute, this.itemList);
        //console.log("sort has been finished")
  }

  PageDataChange($event : Array<any>) : void {
      this.itemDisplay = $event;
  }

  CheckField(field, currentVisibility) {
    // primeiro atualiza o menu de campos
    let index = this.fields.findIndex(x => x.key == field.key);
    if (index !== -1) {
        this.fields[index].visible = !currentVisibility;
    }
    
    // se for visivel, tornar invisível
    // se já existir, apaga dentre os visíveis
    // se nao existir, adiciona
    this.csvOptions.headers = [];
    this.visibleFields = []
    for (var i = 0; i < this.fields.length; ++i) {
       if (this.fields[i].visible) {
          this.visibleFields.push(this.fields[i]);
          this.csvOptions.headers.push(this.fields[i].key);
       }
    }
  }

  isButtonVisible(loads, attr, value){
    //console.log("isButtonVisible");
    //console.log(loads)
    //console.log(attr)
    //console.log(value)
    if (!loads || !attr || !value) {
       return true
    } else {
       return loads[attr] == value;
    }
  }

  rowFocused = 0;

  @HostListener('mouseenter')
  onRowFocused(id) {
     this.rowFocused = id;
  }

  DownloadResults() {
    //console.log("entrou em downlad results")
    new AngularCsv(this.itemDisplay, "Resultados", this.csvOptions);
  }

}
