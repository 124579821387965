import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { SharedModule } from '../shared.module';
import { NgChartjsModule } from 'ng-chartjs';

import { ChartsModule } from 'ng2-charts';


import { HeaderComponent } from "./header/header.component";
import { SearchComponent } from "./search/search.component";
import { QuickViewComponent } from './quick-view/quick-view.component';

import { FooterComponent } from "./footer/footer.component";

import { SideNavDirective } from "../directives/side-nav.directive";
import { ThemeConstantService } from '../services/theme-constant.service';
import { SideNavCustomComponent } from './side-nav-custom/side-nav-custom.component';
import { DefaultRegisterComponent } from './default-register/default-register.component';
import { UploadAreaComponent } from './upload-area/upload-area.component';
import { ProductionFiltersComponent } from './production-filters/production-filters.component';
import { ResultcardWithChartComponent } from './resultcard-with-chart/resultcard-with-chart.component';
import { BubbleChartComponent } from './charts/bubble-chart/bubble-chart.component';
import { TableWithTotalComponent } from './table-with-total/table-with-total.component';
import { DoughnutChartComponent } from './charts/doughnut-chart/doughnut-chart.component';
import { BarChartMulticolorComponent } from './charts/bar-chart-multicolor/bar-chart-multicolor.component';
import { BarChartUnicolorComponent } from './charts/bar-chart-unicolor/bar-chart-unicolor.component';
import { BarChartStackedComponent } from './charts/bar-chart-stacked/bar-chart-stacked.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { BubbleAmchartComponent } from './charts/bubble-amchart/bubble-amchart.component';
import { BarChartStackedAmchartComponent } from './charts/bar-chart-stacked-amchart/bar-chart-stacked-amchart.component';
import { DonughtChartAmchartComponent } from './charts/donught-chart-amchart/donught-chart-amchart.component';
import { StackedAreaAmchartComponent } from './charts/stacked-area-amchart/stacked-area-amchart.component';


@NgModule({
    exports: [
        CommonModule,
        HeaderComponent,
        SearchComponent,
        QuickViewComponent,
        SideNavDirective,
        FooterComponent,
        SideNavCustomComponent,
        UploadAreaComponent,
        ProductionFiltersComponent,
        ResultcardWithChartComponent,
        BubbleChartComponent,
        TableWithTotalComponent,
        DoughnutChartComponent,
        BarChartMulticolorComponent,
        BarChartUnicolorComponent,
        BarChartStackedComponent,
        LineChartComponent,
        BubbleAmchartComponent,
        BarChartStackedAmchartComponent,
        DonughtChartAmchartComponent,
        StackedAreaAmchartComponent

        //UploadAreaComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        SharedModule,
        NgChartjsModule,
        ChartsModule,
        
    ],
    declarations: [
        HeaderComponent,
        SearchComponent,
        QuickViewComponent,
        SideNavDirective,
        FooterComponent,
        SideNavCustomComponent,
        DefaultRegisterComponent,
        UploadAreaComponent,
        ProductionFiltersComponent,
        ResultcardWithChartComponent,
        BubbleChartComponent,
        TableWithTotalComponent,
        DoughnutChartComponent,
        BarChartMulticolorComponent,
        BarChartUnicolorComponent,
        BarChartStackedComponent,
        LineChartComponent,
        BubbleAmchartComponent,
        BarChartStackedAmchartComponent,
        DonughtChartAmchartComponent,
        StackedAreaAmchartComponent,

        
        
        //UploadAreaComponent
    ],
    providers: [ 
        ThemeConstantService
    ]
})

export class TemplateModule { }
