import { Component, OnInit, Input,ChangeDetectionStrategy, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { ThemeConstantService } from '../../../../shared/services/theme-constant.service';
@Component({
  selector: 'app-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.css']
})
export class BubbleChartComponent implements OnInit {
  
  @Input() chart

  constructor(private colorConfig:ThemeConstantService,) { }

  ngOnInit() {
    console.log("bubble chart");
    console.log(this.chart)
    //this.ChartColors[0].backgroundColor = this.chart.dataset.backgroundColor;
  }

  ngOnChanges(changes: SimpleChanges) {
    /*
    if (this.chart) {

          
          this.ChartColors = [
            { 
                backgroundColor:  this.chart.dataset.backgroundColor,
                borderWidth: 0
            }
          ]

          

          this.ChartLabels = this.chart.dataset.labels;
          this.ChartData = this.chart.dataset.data;
          //this.ChartType = this.chart.dataset.type;

          
      }
  	  */
      if (this.chart) {

          // tentativas
          // 1. chartcolor = [{backgroundColor : [""] }] - nao muda
          // 2. igual aos outros de linha - nao muda
          // 3. sem ser um array - fica tudo cinza
          
          /*
          this.ChartColors  = [{ 
            backgroundColor: this.chart.dataset.backgroundColor,      
            borderWidth: 0
        }]
        */

          
          //this.ChartColors[0].backgroundColor = this.chart.dataset.backgroundColor;
          
          //this.ChartColors = this.chart.dataset.backgroundColor,
          

          this.ChartLabels = this.chart.dataset.labels;
          this.ChartData = this.chart.dataset.data;//[{data : this.chart.dataset.data}]
          

          console.log("bubble chart - data")
          console.log(this.ChartData)
          
          console.log("bubble chart - colors")
          console.log(this.ChartColors)
          
         
      }


  }

  
   ChartLabels: string[] = ['New'];
   ChartData: any[] = [{
      data: [
        { x: 0.15, y: 15, r: 15 },
        { x: 0.25, y: 15, r: 25 },
        { x: 0.36, y: 424, r: 33 },
        { x: 0.10, y: 138, r: 18 },
      ],
      //label: 'Use the colors',
    }
 
    ];
    /*
   ChartData : ChartDataSets[] = [ {
      data: [
        { x: 0.15, y: 15, r: 15 },
        { x: 0.25, y: 15, r: 25 },
        { x: 0.36, y: 424, r: 33 },
        { x: 0.10, y: 138, r: 18 },
      ],
      //label: 'Use the colors',
    },]
*/
   /* 
   
    
   */
   ChartColors: Array<any> = [
        { 
            backgroundColor: "blue",
            borderWidth: 0
        },
        
    ];

    
   ChartOptions: any = {
        responsive: true,
        cutoutPercentage: 75,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Average Gross Tax"
                },
                ticks: {
                    display: true,
                    beginAtZero: true,
                    fontSize: 13,
                    //padding: 10
                }
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Net Margin"
                },
                ticks: {
                    display: true,
                    beginAtZero: true,
                    fontSize: 13,
                    //padding: 10
                }
            }],
        } 
    }
    ChartType = 'bubble';

    CurrencyChartOptions: any = {
        scaleShowVerticalLines: false,
        maintainAspectRatio: false,
        responsive: true,
        tooltips: { 
           mode: 'label', 
           label: 'mylabel', 
           callbacks: { 
               label: function(tooltipItem, data) { 
                   return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
               }, 
           }, 
        }, 
        scales: {
            xAxes: [{
                display: false,
                
                categoryPercentage: 0.35,
                barPercentage: 0.70,
              
                scaleLabel: {
                    display: false,
                    labelString: 'Month'
                },
                ticks: {
                    beginAtZero: true
                },

                gridLines: false,
                
            }],
            yAxes: [{
                display: false,
                ticks: {
                    callback: function(label, index, labels) {
                        return (label/1000).toFixed(2).replace('.',',')+'k';
                    }
                },
                scaleOverride: true,
                scaleLabel: function (valuePayload) {
                    return Number(valuePayload.value).toFixed(2).replace('.',',') + '$';
                },
                gridLines: {
                    drawBorder: false,
                    offsetGridLines: false,
                    drawTicks: false,
                    borderDash: [3, 4],
                    zeroLineWidth: 1,
                    zeroLineBorderDash: [3, 4]
                },
                
            }],

        }
    };

}
