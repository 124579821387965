import { Component, OnInit, OnDestroy } from '@angular/core';
import { TableService } from '../../shared/services/table.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
//
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
//import { DefaultRegisterComponent } from '../../shared/template/default-register/default-register.component';
import { UserService } from '../../shared/services/user.service';
//import { EmployeeService } from '../../shared/services/employee.service';
import { ThemeConstantService } from '../../shared/services/theme-constant.service';
import { Subscription } from 'rxjs'
import { FlexmodalComponent } from '../../shared/components/flexmodal/flexmodal.component';
import { API_ENDPOINT } from '../../app.api';
import { Filter } from '../../shared/model/filter.model';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';

export class User {
  id_user: number;
  login: string;
  password: string;
  id_employee: number;
  id_user_profile: number;

  status: string;
}
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {



  subs: Array<Subscription> = []
  filters: Array<Filter> = [{ key: "status", value: "A" }];

  myTable
  itemsList

  formFields = [
    { key: "id_user", label: "_id", value: "", type: "number", visible: false },
    { key: "login", label: "Login", value: "", type: "text", visible: true },
    { key: "password", label: "Password", value: "", type: "password", visible: true },
    { key: "id_employee", label: "Employee", value: "", type: "select", visible: true, dataFrom: { endpoint: 'employee', id: 'id_employee', label: 'name' } },
    { key: "id_user_profile", label: "Profile", value: "", type: "select", visible: true, dataFrom: { endpoint: 'user/profile', id: 'id_user_profile', label: 'name' } },
    { key: "status", label: "Status", value: "A", type: "AI", visible: true },
  ]

  endpoint = `${API_ENDPOINT}user`

  
  _count = 0
  selectFieldsToggle: Boolean = false;
  showFilters: Boolean = false;
  exportData: Array<any> = []
  fields: Array<any> = []
  visibleFields: Array<any> = [];
  filterList = [
    { key: "id_user", label: "_id", value: "", type: "text", visible: true },
    { key: "name", label: "Name", value: "", type: "text", visible: true },
    { key: "shortname", label: "Short Name", value: "", type: "text", visible: true },
    { key: "id_user_profile", label: "Profile", value: "", type: "select", visible: true, dataFrom: { endpoint: 'user/profile', id: 'id_user_profile', label: 'name' } },
    { key: "status", label: "Status", value: "A", type: "radio", visible: true, dataFrom: [{ label: "Ativo", value: "A" }, { label: "Inativo", value: "I" }] },
  ]

  constructor(private modalService: NzModalService,
    private notification: NzNotificationService,
    private mainListService: UserService,
    private editService: UserService,
    private profileService: UserService,
    private gta : GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.getData();
  }

  getData() {

    this.mainListService.getFiltered(this.filters)
      .subscribe(

        data => {
          
          this.itemsList = data;
          this.myTable = {
            options: { title: 'Users' },
            itemList: {
              fields: [
                { key: "id_user", label: "ID", alias: "ID", format: "", visible: true },
                { key: "login", label: "Login", alias: "Login", format: "", visible: true },
                { key: "empname", label: "Name", alias: "Name", format: "", visible: true },
                { key: "profilename", label: "Profile", alias: "Profile", format: "", visible: true },
                { key: "status", label: "Status", alias: "Status", format: "AI", visible: true },
              ],
              results: data,
              buttons: [
                { key: "edit", label: "", hint: "Edit", icon: "fas fa-edit" },
                { key: "delete", label: "", hint: "Delete", icon: "far fa-trash-alt" }
              ]
            },
          }
        },
        error => {
          let errorMsg = "Loading Query => " + error.error.message;
          this.notification.error("Error", errorMsg);
          this.gta.eventEmitter("loading_error", "broken_experience", "listing_table", "[users] " + error.error.message, 3);

        },
      )
  }



  AddOne(id) {

    console.log("add one id: ")
    console.log(id)

    let title
    if (id != 0) {
      title = "Editing User"
    } else {
      title = "New User"
    }

    let modalWidth = 500;
   


    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: FlexmodalComponent,
      nzWidth: modalWidth,
      nzComponentParams: {
        _id: id,
        formFields: this.formFields,
        endpoint: this.endpoint
      },
    });

    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => { this.getData() });

  }


  /* START - PARTE DO PADRÃO */

  mostraToggler($eshowFiltersvent) {
    this.FilterToggle();
  }

  FilterToggle() {
    this.showFilters = !this.showFilters;
  }

  FieldsToggle() {
    this.selectFieldsToggle = !this.selectFieldsToggle;
    this._count = this._count + 1;
  }


  aplicaFiltros($event) {
    this.filters = $event.event;
    this.getData();
  }

  /* FINISH - PARTE DO PADRÃO */

  mostraEvento($event) {
    //console.log("evento que chegou")
    //console.log($event)
    if ($event.event == "edit") {
      this.AddOne($event.data.id_user);
    } else if ($event.event == "delete") {
      this.Delete($event.data.id_user)
    }
  }

 
  /* FINISH - PARTE DO PADRÃO */

  Delete(id) {
    this.subs.push(
      this.editService.delete(id)
        .subscribe(
          data => {
            this.notification.success("Success", "deleted successfully");
            this.gta.eventEmitter("updating_succes", "good_experience", "deleting", "[users] ", 1);
            this.getData();
          },
          error => {
            this.notification.error("Error", error.error.message);
            this.gta.eventEmitter("saving_error", "broken_experience", "deleting", "[users] " + error.error.message, 3);

          }
        ));

  }

  ngOnDestroy() {
    this.subs.map(s => s.unsubscribe);
  }

}


/*
this.gta.eventEmitter("loading_error", "broken_experience", "listing_table", "[business-units] " + error.error.message, 3);
this.gta.eventEmitter("saving_error", "broken_experience", "deleting", "[business-units] " + error.error.message, 3);
*/
