import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';

import { Router, ActivatedRoute} from '@angular/router';
import { first } from 'rxjs/operators';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.css']
})
export class ForgottenPasswordComponent implements OnInit {
  
  Auth: FormGroup;
  loading = false;
  submitted = false;
  mensagem : string = "";
  titulodamensagem : string = "";
  error = '';
  emailEnviado : string = "";

  constructor(private auth : AuthenticationService, 
              private formBuilder: FormBuilder,
              private router: Router,
              private gta : GoogleAnalyticsService  ) { }

  ngOnInit() {
    //Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
  	this.Auth  =  this.formBuilder.group({
        email: ['', [Validators.required, Validators.minLength(5), Validators.email]]
    });
    //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

 

  get f() { return this.Auth.controls; }

  submitForm() {
  	//this.auth.login(this.user);
    //console.log(this.loginForm.value);
   this.submitted = true;
    if (this.Auth.invalid) {
            this.mensagem = 'invalid';
            return;
    }
    
    this.auth.forgottenPassword(this.Auth.controls['email'].value )
    .pipe(first())
            .subscribe(
                data => {
                    if (data["ok"]) {
                       this.titulodamensagem = "E-mail sent successfully!";
                       this.mensagem = "Check your e-mail for the link to change your password";
                        this.emailEnviado = "success";
                        this.gta.eventEmitter("forgotten_password", "success", "", this.Auth.controls['email'].value, 0);

                    }
                    
                },
                error => {
                    this.error = error;
                    this.loading = false;
                    this.emailEnviado = "error";
                    this.titulodamensagem = "E-mail not found!";
                    this.mensagem = "Verify the registered e-mail and try again.";
                    //console.log(error);
                    this.gta.eventEmitter("forgotten_password", "error", "", this.Auth.controls['email'].value, 0);

                });
    //this.router.navigateByUrl('/');
  }

}
