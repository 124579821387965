import { Component, OnInit, Input, OnDestroy, SimpleChanges, Inject, NgZone, PLATFORM_ID  } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

@Component({
  selector: 'app-bubble-amchart',
  templateUrl: './bubble-amchart.component.html',
  styleUrls: ['./bubble-amchart.component.css']
})
export class BubbleAmchartComponent implements OnInit {
  
  /*

  	Inputs Obrigatórios:

  		- chartSeries é um vetor de strings com os nomes (OBRIGATÓRIO TER PELO MENOS 1)
  	
  		- chartData com x, y, value e label (se precisar tratar o label, fazer fora)
  	  apos o x (y ou value) deve ter o mesmo nome da série
	  Ex: xDXP, yChatbots, valueUX

    Inputs Opcionais:
		
    	- axisXLabel e axisYLabel adicionarão um label aos eixos
		- chartDiv : caso tenha mais de um gráfico de bolhas no mesmo componente

    Legendas:

    	- Por hora, as legendas estão fixas, trazendo o seguinte formato:
	  		_bullet[offering].tooltipText = "{label} | {xAxis.title.text} {valueX} | {yAxis.title.text} {valueY}";

  */

  @Input() chartData
  @Input() chartSeries
  @Input() axisXLabel
  @Input() axisYLabel
  @Input() chartDiv
  @Input() count
  @Input() _style

  chart

 
 
  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) { }

   ngOnChanges(changes: SimpleChanges) {
  	
  	if (this.chartData) {
  		this.chegaramOsDados();
  	}
  }

  ngOnInit() {
  	if (!this.chartDiv) {
  		this.chartDiv = "chartdiv";	
  	}

  	if (!this._style) {
  		this._style = {'width': '100%', 'height' : '500px'}
  	}
  	console.log("bubble chart div");
  	console.log(this.chartDiv)
  }

  chegaramOsDados() {
  	console.log("chegarm os dados")
  	am4core.useTheme(am4themes_animated);
  	
  	this.chart = am4core.create(this.chartDiv, am4charts.XYChart);
  	
	this.chart.colors.step = 3;
	this.chart.data = this.chartData.data;
	console.log("chartData");
	console.log(this.chart.data)
	// Add data
	

	// Create axes
	let xAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
	xAxis.renderer.minGridDistance = 50;
	if (this.axisXLabel) {
		xAxis.title.text = this.axisXLabel;
	}
	

	let yAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
	yAxis.renderer.minGridDistance = 50;
	if (this.axisYLabel) {
		yAxis.title.text =this.axisYLabel;
	}

	let _series = {}
	let _bullet = {}
	let _rectangle = {}

	//let i = 0;

	console.log("dentro do bubble chart chartSeries")
    console.log(this.chartSeries)
    let offering = ""

	for (var i = 0; i < this.chartSeries.length; ++i) {
	
		offering = this.chartSeries[i]
		//console.log("entrou aqui")
		//console.log(offering)
		
		_series[offering] = this.chart.series.push(new am4charts.LineSeries());
		//_series[offering].calculatePercent = true;
		_series[offering].dataFields.valueY = "y"+offering;
		_series[offering].dataFields.valueX = "x"+offering;
		_series[offering].dataFields.value = "value"+offering;
		_series[offering].strokeOpacity = 0;
		_series[offering].name = offering;

		_bullet[offering] = _series[offering].bullets.push(new am4charts.CircleBullet());
		_bullet[offering].strokeOpacity = 0.2;
		_bullet[offering].stroke = am4core.color("#ffffff");
		_bullet[offering].nonScalingStroke = true;
		_bullet[offering].tooltipText = "{label} | {xAxis.title.text} {valueX} | {yAxis.title.text} {valueY}";
		_series[offering].heatRules.push({
		  target: _bullet[offering].circle,
		  min: 10,
		  max: 80,
		  property: "radius"
		})
		
	}

	console.log("series:")
	console.log(_series)
	

	this.chart.scrollbarX = new am4core.Scrollbar();
	this.chart.scrollbarY = new am4core.Scrollbar();

	this.chart.legend = new am4charts.Legend();


  }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}
