import { Injectable } from '@angular/core';
import { GenericalService } from '../../shared/services/generical.service';
import {API_ENDPOINT} from '../../app.api';

@Injectable({
  providedIn: 'root'
})
export class EmployeecsrService {

  constructor(private gServ : GenericalService) { }

  getPeriods() {
  	let url = `${API_ENDPOINT}/csr/periods`;
  	return this.gServ.get(url);
  }

  getCSRValues(filters) {
  	let url = `${API_ENDPOINT}/csr/csrvalues`;
  	return this.gServ.getFiltered(url, filters);
  }

   getCSRTable(filters) {
    let url = `${API_ENDPOINT}/csr/table`;
    return this.gServ.getFiltered(url, filters);
  }

  insert(id, obj) {
    let url = `${API_ENDPOINT}/csr/${id}/csrvalues`;
     return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
    let url = `${API_ENDPOINT}/csr/${id}/csrvalues`;
    return this.gServ.putclean(url, obj);
  }

  
}
