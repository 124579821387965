import { Component, OnInit } from '@angular/core';
import {SubprojectContractService} from '../../services/subproject-contract.service';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UploadFile } from 'ng-zorro-antd/upload';
import { filter } from 'rxjs/operators';
import {API_ENDPOINT} from '../../../app.api';

@Component({
  selector: 'app-upload-pre',
  templateUrl: './upload-pre.component.html',
  styleUrls: ['./upload-pre.component.css']
})
export class UploadPreComponent implements OnInit {
  
  uploadFields = [
      {name: 'data', isKey: 'Yes', 	  type: 'date' , description: '2020-03-14', 		example: '2020-03-14' },
      {name: 'PRE', isKey: 'Yes', type: 'string' , description: '16 characters', example: 'EXT-012345-12345' },
      {name: 'employee_name', isKey: 'No',  type: 'number' , description: 'Value without .', example: '123456,78' },
      {name: 'horas', isKey: 'No',  type: 'number' , description: 'Value without .', 	example: '123456,78' },
      {name: 'flag', 	isKey: 'No',  type: 'number' , description: 'Value without .', example: '123456,78' },
      {name: 'responsavel', 	isKey: 'No',  type: 'number' , description: 'Value without .', example: '123456,78' },
  ]

  constructor(private contractService : SubprojectContractService,
              private http: HttpClient, 
              private msg: NzMessageService) { }

  myTable

  ngOnInit() {
    this.contractService.getPREUploaded()
    .subscribe((data : any) => {
        console.log("pres")
        console.log(data)
        this.myTable = {
           itemList : {
              fields : [
                 {key : "id", label: "ID", alias : "ID", format: "", visible: false},
                 {key : "PRE", label: "PRE", alias : "PRE", format: "", visible: true},
                 {key : "data", label: "Data", alias : "Data", format: "", visible: true},
                 {key : "employee_name", label: "Empregado", alias : "Empregado", format: "", visible: true},
                 {key : "horas", label: "Horas", alias : "Horas", format: "number", visible: true},
                 {key : "flag", label: "Flag", alias : "Flag", format: "", visible: true},
                 {key : "responsavel", label: "Leader/Manager", alias : "Leader/Manager", format: "", visible: true},
              ],
              results : data,//.slice(0, data.length-1),
             },
             options : {
                title : "PREs Itau por Status",
             },
           } 
    })
  }

  uploading = false;
  fileList: UploadFile[] = [];

  beforeUpload = (file: UploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };


  handleUpload(): void {

    console.log("handleUpload file list")
    console.log(this.fileList)
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    this.fileList.forEach((file: any) => {
      formData.append('file', file);
    });
    this.uploading = true;
    console.log("formdata")
    console.log(formData)
    // You can use any AJAX library you like
    let url = `${API_ENDPOINT}/import/preitau`;
    const req = new HttpRequest('POST', url, formData, {
      // reportProgress: true
    });
    this.http
      .request(req)
      .pipe(filter(e => e instanceof HttpResponse))
      .subscribe(
        (data: any) => {
          console.log(data)
          this.uploading = false;
          this.fileList = [];
          this.msg.success(data.body.result);
        },
        (data : any) => {
          console.log(data)
          this.uploading = false;
          this.msg.error(data.error.result);
        }
      );
  }

}
