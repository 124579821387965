import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {Subscription} from 'rxjs';

import {Filter} from '../../../../shared/model/filter.model';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
import { TableService } from '../../../../shared/services/table.service';


import {SubprojectNpsService} from '../../../services/subproject-nps.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import {NewNpsComponent} from '../new-nps/new-nps.component';

@Component({
  selector: 'app-subproject-nps',
  templateUrl: './subproject-nps.component.html',
  styleUrls: ['./subproject-nps.component.css']
})
export class SubprojectNpsComponent implements OnInit {
  
  dateFormat = "yyyymm"
  
  listSubscription : Subscription;
  @Input() selectedProjectId : number;


  // campos a mostrar
  fdView : string = "qtt"

  filters : Array<Filter> = []

  itemList : any;
  itemDisplay : any;

  view = "A"

  constructor(private tableSvc : TableService, 
              private modalService: NzModalService, 
              private notification: NzNotificationService,
              private listService : SubprojectNpsService,
              private editService : SubprojectNpsService) { }

  ngOnInit() {
  	this.addFilter({key: 'id_subproject', value: this.selectedProjectId}) 
    this.getListData();
  }

  getListData() {
    this.listSubscription = this.listService.getAll(this.filters)
    .subscribe((data : any) => {
        console.log(data);
        this.itemList = data;
        this.itemDisplay = data;
    });    
  }

  newTransaction(id) {
     console.log("newTransaction NPS id = ")
     console.log(id)
     let title
     if (id != 0) {
        title = "Edit NPS"
     } else {
        title = "New NPS"
     }
     const modal = this.modalService.create({
        nzTitle: title,
        nzContent: NewNpsComponent,
        nzComponentParams: {
          subprojectId : this.selectedProjectId,
          id_subproject_nps : id,
          subprojectEdit : false
        },
     });
     const instance = modal.getContentComponent();
     modal.afterClose.subscribe(result => this.getListData());
  }

  Delete(id) {
  	this.editService.delete(id)
      .subscribe((data : any) => {
          if (data.result === "Success") {
              this.notification.blank("Success", "deleted successfully");
           } else {
              this.notification.blank("Success", "deleted successfully");
           }
           this.getListData();
    });
  }

  onListTypeChange($event) {
    
  }

  addFilter(obj : Filter) {
    
    let index = this.filters.findIndex(x => x.key == obj.key);
    if (index === -1) {
       this.filters.push(obj); 
    } else {
       this.filters[index].value = obj.value;
    }        
  }

  sort1(sortAttribute: any) {
        this.itemDisplay = this.tableSvc.sort(sortAttribute, this.itemDisplay);
  }

  ngOnDestroy() {
  	this.listSubscription.unsubscribe()
  }

  getFile() {
     let head = []
     for (var i = 0; i < this.itemDisplay.keys.length; ++i) {
       head.push(this.itemDisplay.keys[i]);
     }
     this.csvOptions.headers = head;
     new  AngularCsv(this.itemDisplay, "SubProject List", this.csvOptions);
  }

  csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: true,
      //title: 'Your Holiday List :',
      useBom: true,
      noDownload: false,
      headers: ["Holiday ID", "Holiday Date", "Holiday Comment", "Holiday Status"]
  };

}
