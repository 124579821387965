import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import {ManagerService} from '../../../shared/services/manager.service';
import {PortfolioService} from '../../services/portfolio.service';


export class Portfolio {
  id_portfolio : number;
  name : string;
  shortname : string;
  id_employee : number;
  status : string;
}


@Component({
  selector: 'app-new-portfolio',
  templateUrl: './new-portfolio.component.html',
  styleUrls: ['./new-portfolio.component.css']
})
export class NewPortfolioComponent implements OnInit {
  
  @Input() portfolioId
  formData

  managerList

  salvando : boolean = false;
  
  constructor(private fb: FormBuilder,
  			  private modal: NzModalRef,
  			  private notification: NzNotificationService,
  			  private managerService : ManagerService,
  			  private editService : PortfolioService,) { 

  	this.formData = this.fb.group({
          id_portfolio : [null],
          name : [null, [Validators.required]],
          shortname : [null, [Validators.required]],
          id_employee : [null, [Validators.required]],
          status : [true, [Validators.required]]
        });
  }

  ngOnInit() {
  	this.formData.controls['id_portfolio'].setValue(this.portfolioId);
    this.formData.controls['status'].setValue(true);

    //this.empSubscription = 
    this.managerService.getLeaders()
      .subscribe((data : any) => {
            this.managerList = data;
      });

    if (this.portfolioId != 0) {
        this.getSelectedId(this.portfolioId);   
    }
  }

  getSelectedId(id) {
        this.editService.getById(id)
        .subscribe((data : any) => {
              this.fullFillForm(data[0]);
        });
  }

  fullFillForm(obj : Portfolio) {

        if (obj) {

            this.formData.controls['id_portfolio'].setValue(obj.id_portfolio);
            this.formData.controls['name'].setValue(obj.name);
            this.formData.controls['shortname'].setValue(obj.shortname);
            this.formData.controls['id_employee'].setValue(obj.id_employee);

            if (obj.status == 'A') {
               this.formData.controls['status'].setValue(true);
            } else {
               this.formData.controls['status'].setValue(false);
            }
            return true;
        } else {
            return false;
        }
  }

  SubmitForm() {

      console.log("o submit cheogu ate aqui")

      if (this.formData.invalid) {
              this.notification.blank("Error", "Fill required data before saving");
              console.log(this.formData);
              return;
      } else {
        this.salvando = true;
      }

      let obj = new Portfolio;

      obj.id_portfolio = this.formData.controls['id_portfolio'].value;
      obj.name = this.formData.controls['name'].value;
      obj.shortname = this.formData.controls['shortname'].value;
      obj.id_employee = this.formData.controls['id_employee'].value;

      if (this.formData.controls['status'].value === true) {
         obj.status = 'A';
      } else {
         obj.status = 'I';
      }

      //console.log("o que vai para o json")
      //console.log(obj)
      
      // manda para o serviço
      if (!obj.id_portfolio) {
           console.log('inserindo form data: ');
           console.log(obj);
           this.editService.insert(obj)
           .subscribe((data : any) => {
             if (data.result === "Success") {
                this.notification.blank("Success", "inserted successfully");
             } else {
                this.notification.blank("Error", "error inserting");
             }
             this.salvando = false;
             this.destroyModal()   
           });
      } else {
          this.editService.update(this.portfolioId, obj)
          .subscribe((data : any) => {
            if (data.result === "Success") {
                this.notification.blank("Success", "updated successfully");
             } else {
                this.notification.blank("Error", "error updating");
             }
             this.salvando = false;
             this.destroyModal()   
          });
      }
  } 

  destroyModal(): void {
    this.modal.destroy({ data: 'this the result data' });
  }

}
