import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-portfolio',
  templateUrl: './admin-portfolio.component.html',
  styleUrls: ['./admin-portfolio.component.css']
})
export class AdminPortfolioComponent implements OnInit {
  
  nzTabPosition = 1
  constructor() { }

  ngOnInit() {
  }

}
