import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User, Menu } from '../model/user.model';
import {API_ENDPOINT} from '../../app.api';
import {GenericalService} from '../../shared/services/generical.service';
const USER_AUTH_API_URL = `${API_ENDPOINT}/auth/login`;
const MENU_AUTH_API_URL = `${API_ENDPOINT}/menu`;



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    user: User;

    

    constructor(private http: HttpClient) {
        //console.log("[authentication service] constructor")
        //if (typeof localStorage.getItem('_currentUser') !== "string") {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('_currentUserProd')));

        //} else {
         //   let user = new User;
        //    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('_currentUser')));
       // }

        this.currentUser = this.currentUserSubject.asObservable();
       
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(login: string, password: string) {
        
        return this.http.post<any>(USER_AUTH_API_URL, { login, password })
        .pipe(map(user => {

            if (user.length > 0 ) { //&& user.token
                localStorage.setItem('_currentUserProd', JSON.stringify(user));
                this.currentUserSubject.next(user);
                
                
            }
            return user;
        }));
    }

    logout() {
        localStorage.removeItem('_currentUserProd');
        this.currentUserSubject.next(null);
        return true;
    }

    forgottenPassword(xemail) {
        let endpoint = `${API_ENDPOINT}/auth/forgot-password`;
        return this.http.post(endpoint, {"email": xemail})
    }

    resetPassword(xcode, xpassword1, xpassword2) {
        let endpoint = `${API_ENDPOINT}/auth/reset-password`;
        return this.http.post(endpoint, {"code": xcode, "password" : xpassword1, "passwordConfirmation" : xpassword2})
    }


    // meio esquisito criar outra variável para user, mas, vamos lá.


   

    
}
