import { Component, OnInit, Input, OnDestroy, SimpleChanges, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

@Component({
  selector: 'app-stacked-area-amchart',
  templateUrl: './stacked-area-amchart.component.html',
  styleUrls: ['./stacked-area-amchart.component.css']
})
export class StackedAreaAmchartComponent implements OnInit {
  

  // dados do gráfico
  // um dos campos deve condizer com a variável axisXLabel
  // os demais devem ter os mesmos nomes da variável chartSeries
  // ex: customer_name = axisXLabel
  //     ['m1' , 'm2', 'm3'] => chartSeries
  //		 ['customer_name', 'm1', 'm2', 'm3'] => chartData

  @Input() chartData 

  @Input() chartSeries // vetor de strings com os nomes dos itens do eixo x
  @Input() axisXLabel // diz o nome do eixo X
  @Input() chartColors // um vetor de strings em Hexa para manipular as cores
  
  @Input() chartDiv // permite nomear o DIV, para o caso em que haja vários gráficos
  @Input() _style // Estilo a ser aplicado no Div
  @Input() type // se for 'percentage' vai mostrar as barras em 100%, se nao, mostra os valores
  @Input() count // apenas para atualizar o gráfico

  chart
  chartReg: any = {};


  

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) { }

   ngOnInit() {
  	if (!this.chartDiv) {
  		this.chartDiv = "chartdiv";	
  	}
  	if (!this.type) {
  		this.type = ""
  	}

  	if (!this._style) {
  		this._style = {'width': '100%', 'height' : '500px'}
  	}


  	
  	//this.chegaramOsDados()
  }

  ngOnChanges(changes: SimpleChanges) {
  	
  	if (this.chartData) {
  		this.chegaramOsDados();
  	} 
  }

  getStyles() {
  	return this._style;
  }



  chegaramOsDados() {
  	//console.log("chegarm os dados")
  	am4core.useTheme(am4themes_animated);
  	
  	this.chart = am4core.create(this.chartDiv, am4charts.LineSeries);
  	this.chart.data = this.chartData;
  	this.chart.innerRadius = am4core.percent(100);

  	// se tiver enviado cores, aplica
  	if (this.chartColors) {
  		for (var i = 0; i < this.chartColors.length; ++i) {
  			this.chart.colors.list.push(am4core.color(this.chartColors[i]))
  		}
  	}
  	


	// Create axes
	let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
	categoryAxis.dataFields.category = this.axisXLabel;
	categoryAxis.renderer.grid.template.location = 0;


	let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
	valueAxis.renderer.inside = true;
	valueAxis.renderer.labels.template.disabled = true;
	valueAxis.min = 0;
	valueAxis.strictMinMax = true;
	valueAxis.renderer.minWidth = 50;

	// SE QUISER PERCENTUAL
	if (this.type == "percentage") {
		
		valueAxis.calculateTotals = true;
		
	}
	

	let _series = {}
	let _bullet = {}

	for (var i = 0; i < this.chartSeries.length; ++i) {

		let item = this.chartSeries[i]

		// Create series
		_series[item] = this.chart.series.push(new am4charts.ColumnSeries());
		_series[item].name = this.chartSeries[i];
		_series[item].dataFields.valueY = this.chartSeries[i];


		// SE QUISER PERCENTUAL
		//_series[item].dataFields.valueY = "value1";
		

		_series[item].dataFields.categoryX = this.axisXLabel;
		_series[item].sequencedInterpolation = true;
		  
	  	// Make it stacked
	  	_series[item].stacked = true;
		  
		// Configure columns
		_series[item].columns.template.width = am4core.percent(60);
		if (this.type == "percentage") {
			_series[item].dataFields.valueYShow = "totalPercent";
			_series[item].columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}% | {valueY}";
		} else {
			_series[item].columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {categoryY} {valueY}";
		}

		// Add label
		_bullet[item] = _series[item].bullets.push(new am4charts.LabelBullet());
		_bullet[item].label.text = "{valueY}";
		_bullet[item].locationY = 0.5;
		_bullet[item].label.hideOversized = true;

	}
	// Legend
	this.chart.legend = new am4charts.Legend();

	//console.log("chart");
	//console.log(this.chart)
  }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }


  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}