import { Injectable } from '@angular/core';
import { GenericalService } from '../../shared/services/generical.service';
import {API_ENDPOINT} from '../../app.api';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
  
  constructor(private gServ : GenericalService) { }

  getAll() {
  	let url = `${API_ENDPOINT}/portfolio`;
  	return this.gServ.get(url);
  }

  getFiltered(filters) {
  	let url = `${API_ENDPOINT}/portfolio/filtered`;
  	return this.gServ.postclean(url, filters);
  }

  getById(id) {
  	let url = `${API_ENDPOINT}/portfolio/${id}`;
  	return this.gServ.get(url);
  }

  getTeam(id) {
    let url = `${API_ENDPOINT}/portfolio/${id}/team`;
    return this.gServ.get(url);
  }

  getProjects(id) {
    let url = `${API_ENDPOINT}/portfolio/${id}/project`;
    return this.gServ.get(url);
  }

  insert(obj) {
    let url = `${API_ENDPOINT}/portfolio`;
     return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
    let url = `${API_ENDPOINT}/portfolio/${id}`;
    return this.gServ.putclean(url, obj);
  }

  delete(id) {
     let url = `${API_ENDPOINT}/portfolio/${id}`;
     return this.gServ.delete(url);
  }

  addTeamMember(id_portfolio, id_employee) {
    let url = `${API_ENDPOINT}/portfolio/${id_portfolio}/team/${id_employee}`;
     return this.gServ.postOnlyUrl(url);
  }

  deleteTeamMember(id_portfolio, id_user) {
    let url = `${API_ENDPOINT}/portfolio/${id_portfolio}/team/${id_user}`;
     return this.gServ.delete(url);
  }
  

  addProject(id_portfolio, id_project) {
    let url = `${API_ENDPOINT}/portfolio/${id_portfolio}/project/${id_project}`;
     return this.gServ.postOnlyUrl(url);
  }

  deleteProject(id_portfolio, id_project) {
    let url = `${API_ENDPOINT}/portfolio/${id_portfolio}/project/${id_project}`;
     return this.gServ.delete(url);
  }
}
