
export class Employee {
  
  id_employee : number;
  sapcode : number;
  name : string;
  shortname : string;
  extraname: string;
  avatario : string;
  email : string;
  date_start : string;
  date_new_category : string;
  date_resignation : string;
  id_line: number;
  id_offering: number;
  linshortname : string;
  id_subsidiary : number;
  id_category : number;
  categname : string;
  status : string;
  racf : string;
  cpf : number;

}

export class EmployeeDisplay {
  id_employee : number;
  sapcode : number;
  name : string;
  shortname : string;
  extraname: string;
  avatario : string;
  email : string;
  date_start : string;
  date_new_category : string;
  date_resignation : string;
  id_line: number;
  id_offering: number;
  linshortname : string;
  id_subsidiary : number;
  id_category : number;
  categname : string;
  status : string;
	checked : boolean = false;
  racf : string;
  cpf : number;
 }
