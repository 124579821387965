import { Component, OnInit, OnDestroy } from '@angular/core';
import { TableService } from '../../shared/services/table.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { User } from '../../shared/model/user.model';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { DefaultRegisterComponent } from '../../shared/template/default-register/default-register.component';
import { ProjectService } from '../../shared/services/project.service';
import { LineService } from '../../shared/services/line.service';
import { CustomerService } from '../../shared/services/customer.service';
import { ManagerService } from '../../shared/services/manager.service';
import { Project, ProjectDisplay } from '../../shared/model/project.model';
import { ThemeConstantService } from '../../shared/services/theme-constant.service';
import { Filter } from '../../shared/model/filter.model';
import { BusinessunitService } from '../../shared/services/businessunit.service'
import { ProjectsFormComponent } from './projects-form/projects-form.component';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  lineFilterList: any;
  customerFilterList: any;
  managerFilterList: any;
  buList: any;
  subtitle: string = ""
  sliderBasicValue = false;

  subs : Array<Subscription> = []

  busca

  filters: Array<Filter> = []


  itemsList

  selectedLine: any;
  selectedCustomer: any;
  selectedManager: any;

  myTable




  constructor(
    private auth: AuthenticationService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private mainListService: ProjectService,
    private editService: ProjectService,
    private gta : GoogleAnalyticsService,
    
  ) { } 

  ngOnInit() {

    this.auth.currentUser
      .subscribe((data: User) => {
        let user: User;
        user = data[0];
        this.selectedManager = user.id_employee;
        //this.filterService.addFilter({ key: 'id_employee', value: user.id_employee });
        this.filterList[4].value = this.selectedManager;

        this.filters = [
          { key: 'status', value: 'A' },
          { key: 'id_line', value: 2 },
          { key: 'id_employee', value : this.selectedManager}
        ]
      });

    
    this.getData();
  }

  AddOne(id) {


    console.log("projects > addone id = ");
    console.log(id);
    let title
    if (id != 0) {
      title = "Edit Project"
    } else {
      title = "New Project"
    }

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ProjectsFormComponent,
      nzComponentParams: {
        projectId: id,
      },
    });
    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => this.getData());

  }

  //Fullfill ordersList e displayData
  getData() {

    this.mainListService.get(this.filters)
      .subscribe(

        data => {

        this.itemsList = data;
        this.myTable = {
          options: { title: 'Projects' },
          itemList: {
            fields: [
              { key: "id_project", label: "ID", alias: "ID", format: "", visible: true },
              { key: "name", label: "Project", alias: "Project Name", format: "", visible: false },
              { key: "shortname", label: "Project", alias: "Project Shortname", format: "", visible: true },

              { key: "linename", label: "Line", alias: "Line Name", format: "", visible: false },
              { key: "linshortname", label: "Line", alias: "Line Shortname", format: "", visible: true },

              { key: "cusname", label: "Customer", alias: "Customer Name", format: "", visible: false },
              { key: "cusshortname", label: "Customer", alias: "Customer Shortname", format: "", visible: true },

              { key: "empname", label: "Manager", alias: "Manager Name", format: "", visible: false },
              { key: "empshortname", label: "Manager", alias: "Manager Shortname", format: "", visible: true },

              { key: "rappel", label: "Rappel", alias: "Rappel", format: "", visible: false },
              { key: "salesmargin", label: "Sales Margin", alias: "Sales Margin", format: "percentage", visible: false },

              { key: "status", label: "Status", alias: "Status", format: "AI", visible: true },
            ],
            results: data,//.slice(0, data.length-1),
            buttons: [
              { key: "edit", label: "", hint: "Edit", icon: "fas fa-edit" },
              { key: "delete", label: "", hint: "Delete", icon: "far fa-trash-alt" }
            ]
          },
        }

        },
        error => {
          let errorMsg = "Loading Query => " + error.error.message;
          this.notification.error("Error", errorMsg);
          this.gta.eventEmitter("loading_error", "broken_experience", "listing_table", "[projects] " + error.error.message, 3);

        }
      );
  }


  Delete(id) {
    this.subs.push(
      this.editService.delete(id)
        .subscribe(
          data => {
            this.notification.success("Success", "deleted successfully");
            this.gta.eventEmitter("updating_succes", "good_experience", "deleting", "[projects]", 1);
            this.getData();
          },
          error => {
            this.notification.error("Error", error.error.message);
            this.gta.eventEmitter("saving_error", "broken_experience", "deleting", "[projects] " + error.error.message, 3);

          }
        
      ));

  }

  /* START - PARTE DO PADRÃO */
  _count = 0
  selectFieldsToggle: Boolean = false;
  showFilters: Boolean = false;
  exportData: Array<any> = []
  fields: Array<any> = []
  visibleFields: Array<any> = [];
  filterList = [
    { key: "id_project", label: "_id", value: "", type: "text", visible: true },
    { key: "name", label: "Name", value: "", type: "text", visible: true },
    { key: "id_customer", label: "Customer", value: "", type: "editableselect", minChar: 3, visible: true, dataFrom: { endpoint: 'customer', id: 'id_customer', label: 'name' } },
    { key: "id_customer_sector", label: "Sector", value: "", type: "editableselect", minChar: 3, visible: true, dataFrom: { endpoint: 'customersector', id: 'id_customer_sector', label: 'name' } },
    { key: "id_employee", label: "Manager", value: "", type: "select", visible: true, dataFrom: { endpoint: 'manager', id: 'id_employee', label: 'name' } },
    { key: "id_line", label: "Line", value: 2, type: "select", visible: true, dataFrom: { endpoint: 'line', id: 'id_line', label: 'name' } },
    { key: "status", label: "Status", value: "A", type: "radio", visible: true, dataFrom: [{ label: "Ativo", value: "A" }, { label: "Inativo", value: "I" }] },
  ]

  FilterToggle() {
    this.showFilters = !this.showFilters;
  }

  mostraEvento($event) {

    console.log("mostra evento ai");
    console.log($event);

    if ($event.event == "edit") {
      this.AddOne($event.data.id_project);
    } else if ($event.event == "delete") {
      this.Delete($event.data.id_project)
    }
  }

  aplicaFiltros($event) {
    this.filters = $event.event
    this.getData();
  }

  /* FINISH - PARTE DO PADRÃO */

  ngOnDestroy() {
    this.subs.map(s => s.unsubscribe);
  }
}

