import { Injectable } from '@angular/core';
import { GenericalService } from './generical.service';
import {API_ENDPOINT} from '../../app.api';
import {User} from '../model/user.model';



@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

 constructor(private gServ : GenericalService) { }

  getAll() {
  	let url = `${API_ENDPOINT}/employee`;
  	return this.gServ.get(url);
  }

  getAll_with_users() {
    let url = `${API_ENDPOINT}/employee/users`;
    return this.gServ.get(url);
  }

  getEmployeeCategory() {
    let url = `${API_ENDPOINT}/employee/category`;
    return this.gServ.get(url);
  }

  getCSR(id, _period) {
    //console.log("employee service getCSR")
    let url = `${API_ENDPOINT}/employee/${id}/csr`;
    let obj = [{key: 'startperiod', value: _period}]
    return this.gServ.getFiltered(url, obj);
  }

  getById(id) {
     let retorno : any;
     let url = `${API_ENDPOINT}/employee/${id}`;
     return this.gServ.get(url);
  }

  getFiltered(filters) {
    let url = `${API_ENDPOINT}/employee`
    return this.gServ.getFiltered(url, filters);
  }

  getFilteredAvanced(filters) {
    let url = `${API_ENDPOINT}/employee/advanced`
    return this.gServ.postclean(url, filters);
  }

  insert(obj) {
  	let url = `${API_ENDPOINT}/employee`;
     return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
  	let url = `${API_ENDPOINT}/employee/${id}`;
  	return this.gServ.putclean(url, obj);
  }

  delete(id) {
  	 let url = `${API_ENDPOINT}/employee/${id}`;
  	 return this.gServ.delete(url);
  }

  
}
