import { Component, OnInit, Input, OnDestroy, SimpleChanges, Inject, NgZone, PLATFORM_ID  } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


@Component({
  selector: 'app-donught-chart-amchart',
  templateUrl: './donught-chart-amchart.component.html',
  styleUrls: ['./donught-chart-amchart.component.css']
})
export class DonughtChartAmchartComponent implements OnInit {
  @Input() chartDiv
  @Input() chartData
  @Input() chartValue
  @Input() chartCategory

  chart
  
  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) { }

  ngOnInit() {
  	if (!this.chartDiv) {
  		this.chartDiv = "chartdiv";	
  	}
  }

  ngOnChanges(changes: SimpleChanges) {
  	//console.log("donught-chart-amchart teve changes")
  	if (this.chartData) {
  		this.chegaramOsDados();
  	} 
  }

  chegaramOsDados() {


  	//console.log("chegarm os dados")

  	//console.log(this.chartData)
  	am4core.useTheme(am4themes_animated);
  	this.chart = am4core.create(this.chartDiv, am4charts.PieChart);
  	// Set inner radius
  	//this.chart.responsive.enabled = true;
	this.chart.innerRadius = am4core.percent(100);
	this.chart.data = this.chartData;
	// Add and configure Series
	let pieSeries = this.chart.series.push(new am4charts.PieSeries());
	pieSeries.dataFields.value = this.chartValue;
	pieSeries.dataFields.category = this.chartCategory;
	pieSeries.slices.template.stroke = am4core.color("#fff");
	pieSeries.slices.template.strokeWidth = 2;
	pieSeries.slices.template.strokeOpacity = 1;

	// This creates initial animation
	pieSeries.hiddenState.properties.opacity = 1;
	pieSeries.hiddenState.properties.endAngle = -90;
	pieSeries.hiddenState.properties.startAngle = -90;

  }

  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }


  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }



}
