import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ThemeConstantService } from '../../../../shared/services/theme-constant.service';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {

 
  @Input() chart

  themeColors = this.colorConfig.get().colors;
    
    gold = this.themeColors.gold;
    blue = this.themeColors.blue;
    red = this.themeColors.red;
    volcano = this.themeColors.volcano;
    cyan = this.themeColors.cyan;
    lime = this.themeColors.lime;
    transparent = this.themeColors.transparent;
    white = this.themeColors.white;
    //orange = this.themeColors.orange;

    blueLight = this.themeColors.blueLight;
    purple = this.themeColors.purple;

    redLight = this.themeColors.redLight;
    purpleLight = this.themeColors.purpleLight;
    cyanLight = this.themeColors.cyanLight;


    ChartData: Array<any> = [{
                label: 'Series A',
                backgroundColor: this.transparent,
                borderColor: this.blue,
                //pointBackgroundColor: this.blue,
                //pointBorderColor: this.white,
                //pointHoverBackgroundColor: this.blueLight,
                //pointHoverBorderColor: this.blueLight,
                data: [65, 59, 80, 81, 56, 55, 40]
            },
            {
                label: 'Series B',
                backgroundColor: this.transparent,
                borderColor: this.cyan,
                pointBackgroundColor: this.cyan,
                pointBorderColor: this.white,
                pointHoverBackgroundColor: this.cyanLight,
                pointHoverBorderColor: this.cyanLight,
                data: [28, 48, 40, 19, 86, 27, 90]
            }]
    currentratingChartLabelsIdx = 1;
    ChartLabels:Array<any> = ["16th",  "18th",  "20th",  "22th",  "24th",  "26th"];
    ChartOptions: any = {
        maintainAspectRatio: true,
        responsive: true,
        hover: {
            mode: 'nearest',
            intersect: true
        },
        //tooltips: {
        //    mode: 'index'
        //},
        elements: {
            line: {
                tension: 0.2,
                borderWidth: 2
            }
        },
        scales: {
            xAxes: [{ 
                gridLines: [{
                    display: false,
                }],
                ticks: {
                    display: true,
                    fontColor: this.themeColors.grayLight,
                    fontSize: 13,
                    padding: 10
                }
            }],
            yAxes: [{
                display: true
            }],
        }
    };
    ChartColors: Array<any> = [
        { 
            backgroundColor: this.themeColors.transparent,
            borderColor: this.blue,
            pointBackgroundColor: this.blue,
            pointBorderColor: this.themeColors.white,
            pointHoverBackgroundColor: this.blueLight,
            pointHoverBorderColor: this.blueLight
        }
    ];
    ChartType = 'line';
    /*
    ChartData: any[] = [
        { 
            data: [20, 30, 35, 45, 55, 45],
            label : "open"
        },
        { 
            data: [20, 30, 35, 45, 55, 45],
            label : "won"
        },
        { 
            data: [20, 30, 35, 45, 55, 45],
            label : "lost"
        },
 
    ];
    */
  constructor(private colorConfig:ThemeConstantService,) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
     
   
     this.ChartLabels = this.chart.labels;
     this.ChartData = this.chart.data;
     //this.ChartType = this.chart.chartType;
     //console.log("no componente line-chart")
     //console.log(this.ChartData)
     //this.ChartLegend = this.chart.legends;
     
  }

}
