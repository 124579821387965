import { Component, TemplateRef, OnInit, OnDestroy} from '@angular/core';
import { AppsService } from '../../../shared/services/apps.service';

import {Router} from '@angular/router';
import {Filter} from '../../../shared/model/filter.model';
import { TableService } from '../../../shared/services/table.service';
import { ProjectList } from '../../../shared/interfaces/project-list.type';
import { SubprojectService} from '../../services/subproject.service';
//import { AngularCsv } from 'angular7-csv/dist/Angular-csv'


import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';
import {OfferingService} from '../../../shared/services/offering.service';
import {ManagerService} from '../../../shared/services/manager.service';
import {ProjectService} from '../../../shared/services/project.service';

import {PhaseService} from '../../services/phase.service';

import {SubProject} from '../../model/subproject.model';
import {NewSubprojectComponent} from  './new-subproject/new-subproject.component'
import {SubprojectContractService} from '../../services/subproject-contract.service';
import {SubprojectlistfilterService} from '../../services/subprojectlistfilter.service';

import { Subscription } from 'rxjs';
import {CustomerService} from '../../../shared/services/customer.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { User } from '../../../shared/model/user.model';
import { GoogleAnalyticsService } from '../../../shared/services/google-analytics.service';

export class projOptions {
  label : string;
  value : string;
  checked : boolean;
 }



@Component({
  selector: 'app-subproject-list',
  templateUrl: './subproject-list.component.html',
  styleUrls: ['./subproject-list.component.css']
})
export class SubprojectListComponent implements OnInit {
   

    // cria um array de serviços
    subs: Array<Subscription> = []; 

    // ignorar
  
    total_size = 0;
    estaInserindo: boolean = true;
    count = 0;
    /*
    checkOptionsOne: Array<projOptions> = [
      { label: 'Bodyshop', value: 'bodyshop', checked: false },
      { label: 'Use Case', value: 'usecase', checked: false },
      { label: 'Technology', value: 'technology', checked: false },
    ]
    */

    // F I L T R O S  ////////////////////////////////////////////////////////////

    // LISTA DE FILTROS
    filters: Array<Filter> = []

    // boolean para mostrar filtros
    showFilters = false;


    // FORMULÁRIO DE FILTROS
    // configura o formulário
    // vai para o componente app-flex - filter
    // que serve para criar um formulário de filtros
    // e fica na pasta /shared/components / flexfilter

    filterList = [
      { key: "name", label: "Subproject Name", value: "", type: "text", visible: true },
      { key: "id_customer", label: "Customer", value: "", type: "editableselect", minChar: 3,  visible: true, dataFrom: { endpoint: 'customer', id: 'id_customer', label: 'name' } },
      { key: "id_offering", label: "Offering", value: "", type: "editableselect", minChar: 2, visible: true, dataFrom: { endpoint: 'offering', id: 'id_offering', label: 'name' } },
      { key: "id_employee", label: "Manager", value: "", type: "select", minChar: 3, visible: true, dataFrom: { endpoint: 'manager/leaders', id: 'id_employee', label: 'name' } },
      { key: "id_project", label: "EXT Id", value: "", type: "editableselect", minChar: 5, searchField: 'id_project', visible: true, dataFrom: { endpoint: 'project/gambi', id: 'id_project', label: 'extname' } },
      { key: "id_project", label: "EXT Name", value: "", type: "editableselect", minChar: 5, visible: true, dataFrom: { endpoint: 'project/gambi', id: 'id_project', label: 'extname' } },

      { key: "id_subproject_contract", label: "Contracts", value: "", type: "editableselect", minChar: 3, visible: true, dataFrom: { endpoint: 'subproject/contracts', id: 'id_subproject_contract', label: 'name' } },
      { key: "status", label: "Status", value: "A", type: "radio", visible: true, dataFrom: [{ label: "All", value: "0" }, { label: "Active", value: "A" }, { label: "Inativo", value: "I" }] },
    ]

    // serve para atualizar o componente (caso não atualize alguma informação)
    _count = 0;

    selectedManager
    

    // T A B E L A   ////////////////////////////////////////////////////////////
    ordersList
    displayData
    myTable

    viewTipoCampos = "financial"
    viewTipoCamposVetor = {}

    view = 'A'


    constructor(private auth: AuthenticationService, 
                private modalService: NzModalService, 
                private notification: NzNotificationService,
                private mainListService : SubprojectService,
                private editService : SubprojectService,
                private router : Router,
                private filterService: SubprojectlistfilterService,
                private gta: GoogleAnalyticsService
                //private colorConfig: ThemeConstantService,
                //private tableSvc : TableService
                //private comunidadeListService : SubprojectService,
                //private RTListService : SubprojectService,
                //private customerService : CustomerService,
                //private offeringService : OfferingService,
                //private managerService : ManagerService,
                //private projectService : ProjectService,
                //private phaseService : PhaseService,
                //private contractService : SubprojectContractService,
                    ) { }

  



  ngOnInit() {
     
      this.auth.currentUser
        .subscribe((data: User) => {
          let user: User;
          user = data[0];
          this.selectedManager = user.id_employee;
          //this.filterService.addFilter({ key: 'id_employee', value: user.id_employee });
          this.filterList[3].value = this.selectedManager;
      });

    this.filterService.data
    .subscribe((filtros: Array<Filter>) => {
      this.filters = filtros;
    })

    if (this.filters.length === 0) {
      this.filterService.addFilters([
        { key: 'status', value: this.view },
        { key: "portfolio", value: "S" },
        { key: 'subprojects', value: "0" },
        { key: 'id_employee', value: this.selectedManager }
      ]);  
    }

    /*
    this.filters = [
      { key: 'status', value: this.view },
      { key: "portfolio", value: "S" },
      { key: 'subprojects', value: "0" },
      { key: 'id_employee', value: this.selectedManager }
    ]
    */
    
    this.getData();
  }

 
  getData() {

    this.subs.push(
      this.filterService.data
        .subscribe((filtros: Array<Filter>) => {
          //console.log("como ficaram os filtros");
          //console.log(this.filters);
            

            this.mainListService.getFiltered(filtros)
            .subscribe(
              
              data => {



                //console.log("data subprojectlist");
                //console.log(data)
               
                  this.ordersList = data;
                  this.displayData = data;

                  //console.log(this.ordersList);
                  this.viewTipoCamposVetor = {
                    standard: ['id_subproject', 'offname', 'name', 'empshortname', 'phasename', 'startdate', 'enddate', 'status',],
                    comunity: ['id_subproject', 'offname', 'name', 'comunidade', 'rt', 'empshortname', 'status'],
                    financial: ['id_subproject', 'offname', 'name', 'NETREV', 'CSR', 'EXP', 'margin', 'marginpercent'],
                    last_update: ['id_subproject', 'offname', 'name', 'empshortname', 'status', 'date_last_update', 'user_last_update'],
                  }

                  this.myTable = {
                    headers: {
                      
                      radiogroup: {
                        options: [
                          { key: "financial", label: "Financial", hint: "Edit", icon: ""},
                          { key: "standard", label: "Dates", hint: "Edit", icon: "" },
                          { key: "comunity", label: "Department", hint: "Edit", icon: "" },
                          { key: "last_update", label: "Updates", hint: "Edit", icon: "" },
                        ],
                        selected: "financial"
                      }
                    },
                    itemList: {
                      fields: [
                        { key: "id_subproject", label: "ID", alias: "ID", format: "", visible: false },
                        { key: "offname", label: "Offering", alias: "Offering", format: "", visible: true },
                        { key: "name", label: "Name", alias: "Name", format: "", visible: true },
                        { key: "comunidade", label: "Departamento", alias: "Comunidade", format: "", visible: false },
                        { key: "rt", label: "Area/RT", alias: "Area/RT", format: "", visible: false },
                        { key: "empshortname", label: "Manager", alias: "Manager", format: "", visible: false },
                        { key: "phasename", label: "Phase", alias: "Phase", format: "", visible: false },
                        { key: "startdate", label: "Start", alias: "Start", format: "", visible: false },
                        { key: "enddate", label: "End", alias: "End", format: "", visible: false },
                        { key: "status", label: "Status", alias: "Status", format: "AI", visible: false },
                        { key: "NETREV", label: "Net Rev", alias: "Net Rev", format: "currency", visible: true },
                        { key: "CSR", label: "CSR", alias: "CSR", format: "currency", visible: true },
                        { key: "EXP", label: "EXP", alias: "EXP", format: "currency", visible: true },
                        { key: "margin", label: "Margin", alias: "Margin", format: "currency", visible: false },
                        { key: "marginpercent", label: "Margin %", alias: "Margin %", format: "percentage", visible: true },
                        { key: "bodyshop", label: "Bodyshop", alias: "Bodyshop", format: "", visible: false },
                        { key: "technology", label: "Technology", alias: "Technology", format: "", visible: false },
                        { key: "usecase", label: "Use Case", alias: "Use Case", format: "", visible: false },
                        { key: "date_last_update", label: "date_last_update", alias: "date_last_update", format: "", visible: false },
                        { key: "user_last_update", label: "user_last_update", alias: "user_last_update", format: "", visible: false },

                      ],
                      results: data,
                      buttons: [
                        { key: "view", label: "", hint: "View", icon: "fa fa-search-plus" },
                        { key: "edit", label: "", hint: "Edit", icon: "fas fa-edit" },
                        { key: "delete", label: "", hint: "Delete", icon: "far fa-trash-alt" }
                      ]
                    },
                  }
                
              },
              error => {
                let errorMsg = "Loading Subprojects => " + error.error.message;
                this.notification.error("Error", errorMsg);
                this.gta.eventEmitter("loading_error", "broken_experience", "listing_table", "[subprojects] " + error.error.message, 10);

              },
            )
        }))
    //buttons: [
    //  { key: "new", label: "New", hint: "New", icon: "", type: "primary" },
    //],
  }

  aplicaFiltros($event) {
    //console.log("aplica filtros no subproject list - evento");
    //console.log($event.event);
    let arrayfiltros = $event.event
    //console.log(arrayfiltros)
    this.filterService.addFilters(arrayfiltros);
    this.myTable = null;

    this.getData();
  }

   mostraEvento($event) {
    //console.log($event)
    if ($event.event== "view") {
       this.ManageProject($event.data.id_subproject) 
    } else if ($event.event == "edit") {
        this.AddOne($event.data.id_subproject)
        
    } else if ($event.event == "new") {
        this.AddOne(0)
    } else if ($event.event == "delete") {
        this.Delete($event.data)
    }
  }

  radioSelected($event) {

    //console.log("radio event: ");
    //console.log($event);

    let vetor =  this.viewTipoCamposVetor[$event];
    let size = this.myTable.itemList.fields.length;
    //console.log("vetor = ")
    //console.log(vetor)
    //console.log("size vetor de campos")

    for (var i = 0; i < size; ++i) {
        if  (vetor.includes(this.myTable.itemList.fields[i].key) ) {
             this.myTable.itemList.fields[i].visible = true;
             //console.log("true")
        } else {
             this.myTable.itemList.fields[i].visible = false;
             //console.log("false")
        }
    }

    this.count = this.count + 1;    
  }


  log(obj: Array<projOptions>): void {
      for (var i = 0; i < obj.length; ++i) {
        if (obj[i].checked) {
           this.filterService.addFilter({key: obj[i].value, value: "S"});
        } else {
           this.filterService.addFilter({key: obj[i].value, value: "N"});  
        }
      }
  }

  PageDataChange($event : Array<any>) : void {
      this.displayData = $event;
  }


  AddOne(id) {      

     let title
     if (id != 0) {
        title = "Edit SubProject"
     } else {
       title = "New SubProject"
     }

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: NewSubprojectComponent,
      nzComponentParams: {
        subprojectId : id,
      },
    });
    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => this.getData());

  }

  Delete(item) {
    this.subs.push(
      this.editService.delete(item.id_subproject)
      .subscribe(
          data => {
            this.notification.success("Success", "deleted successfully");
          // this.gta.eventEmitter("new_deletion", "deleting", "subproject-list", "Delete", item.id_subproject);
            this.getData();
          },
          error => {
            let errorMsg = "Deleting => " + error.error.message;
            this.notification.error("Error", errorMsg);
            this.gta.eventEmitter("saving_error", "broken_experience", "deleting", "[subprojects] " + error.error.message, 3);

          }))
      
      
  }
    
  ManageProject(projectId) {
      let str : String = `/portfolio/subproject/${projectId}`
      this.router.navigate([str]);
  }


  zeraFilter() {
      this.filters = [];
  }

  FilterToggle() {
    this.showFilters = !this.showFilters;
  }

  ngOnDestroy(): void {
    this.subs.map(s => s.unsubscribe);

    
  }


}



/*
  addFilter(obj : Filter) {
  
      let index = this.filters.findIndex(x => x.key == obj.key);
      if (index === -1) {
         this.filters.push(obj); 
      } else {
         this.filters[index].value = obj.value;
      }        
  }

  sort(sortAttribute: any) {
    this.ordersList = this.tableSvc.sort(sortAttribute, this.ordersList);
  }
*/

/*
  getFile() {
     new  AngularCsv(this.ordersList, "SubProject List", this.csvOptions);
  }
*/


/*
this.gta.eventEmitter("loading_error", "broken_experience", "listing_table", "[business-units] " + error.error.message, 3);
this.gta.eventEmitter("saving_error", "broken_experience", "deleting", "[business-units] " + error.error.message, 3);
*/

//this.offSubscription =

/*
this.subs.push(
  this.offeringService.getAll()
    .subscribe(
        data => {
          this.offeringList = data;
          this.selectedOffering = this.filterService.getValueOf('id_offering');
        },
        error => {
          let errorMsg = "Loading Offerings => " + error.error.message;
          this.notification.error("Error", errorMsg);
          this.gta.eventEmitter("loading_error", "broken_experience", "listing_a_box", "[subprojects-list / offerings]  " + error.error.message, 1);
        }
     ))

  //this.empSubscription =
this.subs.push(
  this.managerService.getLeadersFromPortfolio()
    .subscribe(
      data => {
        this.managerList = data;
        this.selectedManager = this.filterService.getValueOf('id_employee');
      },
      error => {
        let errorMsg = "Loading Managers => " + error.error.message;
        this.notification.error("Error", errorMsg);
        this.gta.eventEmitter("loading_error", "broken_experience", "listing_a_box", "[subprojects-list / managers]  " + error.error.message, 1);
      }
    ));
  
  //this.phaseSubscription =
this.subs.push(
  this.phaseService.getAll()
  .subscribe(
    data => {
      this.phaseList = data;
      this.selectedPhase = this.filterService.getValueOf('id_subproject_phase');
    },
    error => {
      let errorMsg = "Loading Project Phases => " + error.error.message;
      this.notification.error("Error", errorMsg);
      this.gta.eventEmitter("loading_error", "broken_experience", "listing_a_box", "[subprojects-list / phases]  " + error.error.message, 1);
    }
    ));

this.subs.push(
  this.contractService.getAll(this.filters)
  .subscribe(
    data => {
      this.contractList = data;
      this.selectedContract = this.filterService.getValueOf('id_subproject_contract');
    },
    error => {
      let errorMsg = "Loading Contracts => " + error.error.message;
      this.notification.error("Error", errorMsg);
      this.gta.eventEmitter("loading_error", "broken_experience", "listing_a_box", "[subprojects-list / contracts]  " + error.error.message, 1);
    }));

this.subs.push(
  this.comunidadeListService.getComunidades()
    .subscribe(
      data => {
        this.comunidadeList = data;
      },
      error => {
        let errorMsg = "Loading Departments => " + error.error.message;
        this.notification.error("Error", errorMsg);
        this.gta.eventEmitter("loading_error", "broken_experience", "listing_a_box", "[subprojects-list / comunidades]  " + error.error.message, 1);
      }));

this.subs.push(
  this.RTListService.getRTs()
      .subscribe(
        data => {
          this.RTList = data;
        },
        error => {
          let errorMsg = "Loading SubDepartments => " + error.error.message;
          this.notification.error("Error", errorMsg);
          this.gta.eventEmitter("loading_error", "broken_experience", "listing_a_box", "[subprojects-list / RTs]  " + error.error.message, 1);
        }));

this.subs.push(
  this.customerService.getAll()
      .subscribe(
        data => {
          this.customerList = data;
         
        },
        error => {
          let errorMsg = "Loading Customers => " + error.error.message;
          this.notification.error("Error", errorMsg);
          this.gta.eventEmitter("loading_error", "broken_experience", "listing_a_box", "[subprojects-list / customers]  " + error.error.message, 1);

      }));

   
  //{ key: 'id_employee', value: this.selectedManager }
  */



      //this.getProjects();


/*
getProjects() {
  this.subs.push(
    this.filterService.data
      .subscribe((filtros : Array<Filter>) => {
          this.projectService.getFiltered(filtros)
          .subscribe(
            data => {
              this.projectList = data;
            },
            error => {
              this.gta.eventEmitter("error_found", "loading", "subproject-list", "getProjects", 1);
            });
  }));
}
*/
