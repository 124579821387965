import { Component, OnInit, Input, OnDestroy   } from '@angular/core';
import {Subscription} from 'rxjs';
import {Router, ActivatedRoute} from '@angular/router';

// Somente para a listagem
import {Filter} from '../../../../shared/model/filter.model';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
import { TableService } from '../../../../shared/services/table.service';
import { ThemeConstantService } from '../../../../shared/services/theme-constant.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';
import {NewTransactionComponent} from '../new-transaction/new-transaction.component'
import {NewTransactionComposedComponent} from '../new-transaction-composed/new-transaction-composed.component';

// Para ambos
import {SubprojectService} from '../../../services/subproject.service';
import {SubProject} from '../../../model/subproject.model';
import {SubProjectTransaction} from '../../../model/subprojecttransaction.model';
import {SubprojectTransactionService} from '../../../services/subproject-transaction.service';

import {OfferingService} from '../../../../shared/services/offering.service';
import {NewEmployeeFastComponent} from '../../../portfolio-registers/employee-list/new-employee-fast/new-employee-fast.component'
import {EmployeeCsrFastComponent} from '../../../portfolio-registers/employee-list/employee-csr-fast/employee-csr-fast.component';
import {SubprojectContractService} from '../../../services/subproject-contract.service';


export class ResultsHeader {
  REV: number;
  NETREV : number;
  CSR : number;
  EXP : number;
  margin : number;
  marginpercent : number;
 }

@Component({
  selector: 'app-subproject-manage',
  templateUrl: './subproject-manage.component.html',
  styleUrls: ['./subproject-manage.component.css']
})
export class SubprojectManageComponent implements OnInit {
  
  @Input() selectedProjectId : number;
  
  isShared : boolean = false;
  selectedProjectData : SubProject;
  
  selectedContract

  offeringList
  contractList

  subs : Array<Subscription> = []


  summresults : ResultsHeader = {REV : 0.00, NETREV: 0.00, CSR: 0.00, EXP: 0.00, margin : 0.00, marginpercent : 0.00}

  groupedFlag = 'clean'

  monthHours
  period : Date
  dateFormat = 'yyyyMM';
  itemList : any;
  itemDisplay : any;
  offeringId : number;
  filters : Array<Filter> = []

  // varíáveis compartilhadas
  view : string = '0';

  constructor(// Serviços da LISTA
              private route : ActivatedRoute,
              private tableSvc : TableService, 
              private modalService: NzModalService, 
              private notification: NzNotificationService,
              private router : Router,
              private offeringService : OfferingService,
              private subprojectService : SubprojectService,
              private contractService : SubprojectContractService,
              private listService : SubprojectTransactionService,
              private editService : SubprojectTransactionService,
              private summService : SubprojectTransactionService,
              private periodService : SubprojectTransactionService,) {}

  ngOnInit() {


      this.subs.push(this.subprojectService.getById(this.selectedProjectId)
                                      .subscribe((data : SubProject) => {
                                            this.offeringId = data[0].id_offering;
                                            if ((this.offeringId == 7) || (this.offeringId == 18)) {
                                               this.isShared = true;
                                               this.getOfferings();
                                            }
                                      }))
     

      

      // starta o periodo com a data atual
      this.period = new Date();

      // busca quantas horas há no periodo
      this.getMonthHours(this.period);


      this.addFilter({key: "periodstart", value : this.DataParaString(this.period)})
      
      // busca o id do projeto
      if (this.selectedProjectId) {
          this.addFilter({key: 'id_subproject', value: this.selectedProjectId}) 
          this.getListData();
          this.getContracts();
      }
  }

  // REV/CSR/EXP
  onListTypeChange(id) {
        this.addFilter({key: 'type', value: id});
        this.getListData();
  }

  // Pega a lista de transações do projeto,
  // conforme o filtro
  getListData() {
       this.subs.push(this.listService.getTransactionsOld(this.filters)
      .subscribe((data : any) => {
          this.itemList = data;
          this.itemDisplay = data;
          console.log(data)
      }));
      this.subs.push(this.summService.getTransactionsSummarized(this.filters)
      .subscribe((data : any) => {
          this.summresults = data[0];
          console.log(data)
      }));
  }

  onPeriodChange(id) {
     this.period = id;
     this.getMonthHours(this.period);
     this.addFilter({key: "periodstart", value : this.DataParaString(this.period)})
     this.getListData();
  }

  onGroupChange($event) {
    this.addFilter({key: "grouped", value : $event});
    this.getListData();
  }

  onContractChange($event) {
    if ($event != 0) {
      this.addFilter({key: "id_subproject_contract", value : $event});
      this.getListData();
    }
  }

  getContracts () {
    this.subs.push(this.contractService.getContractsBySubProjectId(this.selectedProjectId) 
        .subscribe((data : any) => { 
            this.contractList = data;
    }));
  }

  getMonthHours(_period) {
    let period = this.DataParaString(_period);
    this.subs.push(this.periodService.getHoursPeriod(period)
    .subscribe((data: any) => {
      
       this.monthHours = data[0].quantity;
    }));
  }

  newTransaction(type, id) {      
     if (type === "") {
        type = "REV"
     }

     let title
     if (id != 0) {
        title = "Edit Transaction"
     } else {
       title = "New Transaction"
     }

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: NewTransactionComponent,
      nzComponentParams: {
        _type: type,
        subprojectId : this.selectedProjectId,
        transactionId: id,
        period : this.period,
        offeringId : this.offeringId,
        isShared : this.isShared
      },
    });
    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => this.getListData());

  }

  newTransactionComposed(type, id) {      
     
     let title
     if (id != 0) {
        title = "Edit Transaction"
     } else {
       title = "New Transaction"
     }

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: NewTransactionComposedComponent,
      nzWidth : 800,
      nzComponentParams: {
        _type: type,
        subprojectId : this.selectedProjectId,
        transactionId: id,
        period : this.period,
        isShared : this.isShared
      },
    });
    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => this.getListData());

  }

  newEmployeeFast(id) {      

     let title
     if (id != 0) {
        title = "Edit Employee"
     } else {
       title = "New Employee"
     }

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: NewEmployeeFastComponent,
      nzComponentParams: {
        employeeId : id
      },
    });
    const instance = modal.getContentComponent();
    //modal.afterClose.subscribe(result => this.getData());

  }

  newEmployeeCSRFast(id) {
    let title
     if (id != 0) {
        title = "Edit Employee CSR"
     } else {
       title = "New Employee CSR"
     }

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: EmployeeCsrFastComponent,
      nzComponentParams: {
        employeeId : id
      },
    });
    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => this.getListData());
  }

  // Copia o mes corrente inteiro para o próximo mês
  showConfirm(): void {
    this.modalService.confirm({
      nzTitle: '<b>Do you want to copy to next month?</b>',
      nzContent: 'Every line on the current month will be copied to the next',
      nzOnOk: () => {
            let obj = {"fromlastmonth": "S", "period": this.DataParaString(this.period), "id_subproject" : this.selectedProjectId} 
            this.editService.copyNextMonth(obj).subscribe((data : any) => {
                console.log("Rodou!!!")
            })
     }
    })
  }

  copyTransaction(id) {
    let obj = {"period": this.DataParaString(this.period), "id_subproject" : this.selectedProjectId, "id_subproject_results" : id} 
    this.editService.copyNextMonth(obj).subscribe((data : any) => {
        this.getListData();
    })
  }

  Delete(id) {
    this.editService.delete(id)
      .subscribe((data : any) => {
          if (data.result === "Success") {
              this.notification.blank("Success", "deleted successfully");
           } else {
              this.notification.blank("Error", "error deleting");
           }
           this.getListData();
      });
  }

  getOfferings() {
    this.subs.push(this.offeringService.getAll()
        .subscribe((data : any) => {
              this.offeringList = data;
        }));
  }

  onOfferingChange(id) {
        this.addFilter({key: 'id_offering', value: id});
        this.getListData();
  }


  addFilter(obj : Filter) {
    
    let index = this.filters.findIndex(x => x.key == obj.key);
    if (index === -1) {
       this.filters.push(obj); 
    } else {
       this.filters[index].value = obj.value;
    }        
  }

  getFile() {
     new  AngularCsv(this.itemDisplay, "SubProject List", this.csvOptions);
  }

  sort1(sortAttribute: any) {
        this.itemDisplay = this.tableSvc.sort(sortAttribute, this.itemDisplay);
  }


  newDate(o) {
    let newMonth = 0;
    let newYear = 0;
    let year = this.period.getFullYear();
    let month = Number(this.period.getMonth())+1;

    newMonth = month + o;
    newYear = year;
    if (newMonth === 13) {
         newMonth = 1;
         newYear = year + 1;
    } else if (newMonth === 0) {
         newMonth = 12;
         newYear = year -1; 
    }  

    let valor = newMonth +'/01/' + newYear;
    this.period = new Date(valor);
    this.getMonthHours(this.period);
    this.addFilter({key: "periodstart", value : this.DataParaString(this.period)})
    this.getListData();
  }

  csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: true,
      //title: 'Your Holiday List :',
      useBom: true,
      noDownload: false,
      headers: ["ProjectId", "Project Name" , "TransactionId", "SubprojectId", "Subproject Name", "Period", "Type" , "CategoryId", "Category Name", "EmployeeId", "Employee Name", "Tax", "Quantity", "Total", "Comment", "OfferingId", "Offering Name", "ContractId", "Contract Name" , "Contract SubName", "Type Id"]
  };

  DataParaString(o) {
    let year = o.getFullYear();
    let month = Number(o.getMonth())+1;
    let newMonth = String(month)

    if (month < 10) {
         newMonth = "0" + String(month)
    }  

    let valor = year + newMonth;
    return valor
  }

  ngOnDestroy() {
     this.subs.map(s => s.unsubscribe);
  }

  


}
