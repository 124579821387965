import { Component, OnInit, OnDestroy } from '@angular/core';
import {OfferingService} from '../../shared/services/offering.service';
import {ManagerService} from '../../shared/services/manager.service';
import {ProjectService} from '../../shared/services/project.service';
import {PhaseService} from '../services/phase.service';
import {PortfolioFilterService} from '../services/portfolio-filter.service';
import {Filter} from '../../shared/model/filter.model';
import {Subscription} from 'rxjs';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-portfolio-results',
  templateUrl: './portfolio-results.component.html',
  styleUrls: ['./portfolio-results.component.css']
})
export class PortfolioResultsComponent implements OnInit {
  
  filterToggle : boolean = true;
  
  nzTabPosition = 0;

  filters : Array<Filter> = []
  projectListFiltered : Array<string> = []
  busca : String;
  dateFormat = 'yyyyMM';

  periodstart
  periodend

  subs : Array<Subscription> = [];

  selectedOffering : number = 0;
  selectedProject 
  selectedPortfolio 
 
  selectedPhase : number = 0;
 

  offeringList
  managerList
  projectList
  portfolioList
  phaseList


  constructor(private filterService : PortfolioFilterService,
              private gta : GoogleAnalyticsService,
              private projectService : ProjectService, 
              private notification : NzNotificationService
              ) { }

  ngOnInit() {
      this.periodstart = new Date();
      this.periodend = new Date();

      this.filterService.clearFilters();

      this.filterService.addFilters([
                                    {key: "periodstart", value: this.DataParaString(this.periodstart)},
                                    {key: "periodend", value: this.DataParaString(this.periodend)},
                                    {key: "grouped", value: "grouped"},
                                    {key: "portfolio", value: "S"},
                                    ])

      this.subs.push(this.filterService.data
       .subscribe((data : any) => {
          this.filters = data;
          this.selectedOffering = this.filterService.getValueOf('id_offering');
          this.selectedProject = this.filterService.getValueOf('id_project');
          this.selectedPortfolio = this.filterService.getValueOf('id_portfolio');
          this.selectedPhase = this.filterService.getValueOf('id_subproject_phase')
      }))

      this.subs.push(this.filterService.data
        .subscribe((filtros : Array<Filter>) => {
            this.getPortfolios();
            this.getProjects(filtros);
        }));

      

  }

  getProjects(filtros) {
    
    this.subs.push(this.projectService.getFiltered(filtros)
      .subscribe(
          data  => {
            this.projectList = data;
          },
          error => {
            let errorMsg = "Loading Projects => " + error.error.message;
            this.notification.error("Error", errorMsg);
            this.gta.eventEmitter("loading_error", "broken_experience", "listing_a_box", "[portfolio-results] projectService_GetFiltered " + error.error.message , 1);

          }));
    
  }

  getPortfolios() {
    
    this.subs.push(this.projectService.getPortolios()
      .subscribe(
        data  => {
            this.portfolioList = data;
        },
        error => {
          let errorMsg = "Loading Portfolios => " + error.error.message;
          this.notification.error("Error", errorMsg);
          this.gta.eventEmitter("loading_error", "broken_experience", "listing_a_box", "[portfolio-results] getPortolios " + error.error.message, 1);
        }
      ));
    
  }

  toggleFilter() {
    this.filterToggle = ! this.filterToggle;
  }

  onNameChange(id) {
    this.filterService.addFilter({key: 'name', value: id.target.value});      
  }

  onOfferingChange(id) {
    this.filterService.addFilter({key: 'id_offering', value: id});
  }

  onProjectChange(id) {
    this.filterService.addFilter({key: 'id_project', value: id});
  }

  onPortfolioChange(id) {
    this.filterService.addFilter({key: 'id_portfolio', value: id});
  }

  isNotSelected(value: string): boolean {
    return this.projectList.indexOf(value) === -1;
  }

  onPhaseChange(id) {
    this.filterService.addFilter({key: 'id_subproject_phase', value: id});
  }

  onPeriodStartChange(id) {
    this.filterService.addFilter({key: 'periodstart', value: this.DataParaString(id)});
  }

  onPeriodEndChange(id) {
    this.filterService.addFilter({key: 'periodend', value: this.DataParaString(id)});
  }

  DataParaString(o) {
    let year = o.getFullYear();
    let month = Number(o.getMonth())+1;
    let newMonth = String(month)

    if (month < 10) {
         newMonth = "0" + String(month)
    }  

    let valor = year + newMonth;
    return valor
  }

  ngOnDestroy() {
     this.subs.map(s => s.unsubscribe);
  }

}
