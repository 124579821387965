import { Injectable } from '@angular/core';
import { GenericalService } from '../../shared/services/generical.service';
import {API_ENDPOINT} from '../../app.api';



@Injectable({
  providedIn: 'root'
})
export class PhaseService {

  constructor(private gServ : GenericalService) { }

  getAll() {
  	let url = `${API_ENDPOINT}/subproject_phase`;
  	return this.gServ.get(url);
  }

  

}
