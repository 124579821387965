import { Component, OnInit, Input, OnDestroy, SimpleChanges  } from '@angular/core';

import {FilterService} from '../../../shared/services/filter.service';

import {ResultsService} from '../../../shared/services/results.service'
import {Subscription} from 'rxjs'

@Component({
  selector: 'app-customer-distribuction',
  templateUrl: './customer-distribuction.component.html',
  styleUrls: ['./customer-distribuction.component.css']
})
export class CustomerDistribuctionComponent implements OnInit { 

 sub1 : Subscription;

 _chartData
 _chartLabels
 axisXLabel

 type = "percentage"
 count = 0;

 constructor(private resultsService : ResultsService,
 			 private filterService : FilterService,) { }

  ngOnInit() {
  	
  	//this.filterService.addFilter({key : "entity", value: "customer"})
      this.sub1 = this.filterService.data
      .subscribe ((filtros : any) => {

		  	this.resultsService.getResultsPerCustomerAndLine(filtros)
		  		.subscribe((data : any)=> {
		  			//console.log("customer distribuition")
		  			//console.log (data)
		  			this._chartData = data.data;
		  			this._chartLabels = data.labels;
		  			this.axisXLabel = "linshortname";
		  			


					
		  	 })
		});
	
	
	}

	onResultsViewChange($event) {
		this.type = $event;
		this.count = this.count + 1;
	}



}
