import { Component, OnInit, Input} from '@angular/core';
// Para ambos

import {SubprojectTransactionService} from '../../../services/subproject-transaction.service';
import { NzModalRef } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
// Somente para o modal
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import {OfferingService} from '../../../../shared/services/offering.service';
import {EmployeeService} from '../../../../shared/services/employee.service';
import {SubprojectCategoryService} from '../../../services/subproject-category.service';

import {SubProjectTransaction} from '../../../model/subprojecttransaction.model';
import {Subscription} from 'rxjs';
import {SubprojectContractService} from '../../../services/subproject-contract.service';
import { GoogleAnalyticsService } from '../../../../shared/services/google-analytics.service';

@Component({
  selector: 'app-new-transaction',
  templateUrl: './new-transaction.component.html',
  styleUrls: ['./new-transaction.component.css']
})
export class NewTransactionComponent implements OnInit {
  
  @Input() subprojectId
  @Input() _type
  @Input() transactionId
  @Input() period
  @Input() isShared
  @Input() offeringId
  // variáveis o EDIT

  dateFormat = 'yyyyMM';
  formData

  subs : Array<Subscription> = []

 
  categoryList
  employeeList
  offeringList
  contractList

  salvando : boolean = false;
 
  csrWarning : string = ""


  // varíáveis compartilhadsa
  
  view : string = '0';
  

  constructor(  // Serviços do edit
  		        private modal: NzModalRef,		
              private fb: FormBuilder,
              private notification: NzNotificationService,
              private editService : SubprojectTransactionService,
              private offeringService : OfferingService,
              private employeeService : EmployeeService,
              private employeeCSRService : EmployeeService,
              private categoryService : SubprojectCategoryService,
              private periodService : SubprojectTransactionService,
              private contractService: SubprojectContractService,
              private gta : GoogleAnalyticsService  ) { 
      this.formData = this.fb.group({
          type : [null, [Validators.required]],
          id_subproject_results : [null],
          id_subproject : [null, [Validators.required]],
          id_category : [null, [Validators.required]],
          id_employee : [null, [Validators.required]],
          id_offering : [null],
          tax : [null, [Validators.required]],
          repeat : ['0'],
          times : [1],
          period : [null, [Validators.required]],
          quantity : [null, [Validators.required]],
          id_subproject_contract : [null],
          comment : [null],
        });
  }

  ngOnInit() {

    // traz a lista de employees
    this.subs.push(
      this.employeeService.getAll()
        .subscribe((data : any) => {
              this.employeeList = data;
    }));

      
    //console.log("whats the type?")
    //console.log(this._type)
    if (this.transactionId == 0) {
        // seta o tipo selecionado
        this.formData.controls['id_subproject'].setValue(this.subprojectId);
        this.formData.controls['type'].setValue(this._type);
        // busca as categorias relacionadas
        this.getCategories(this._type);
        // leva o período selecionado
        this.formData.controls['period'].setValue(this.period);
          
        // busca as horas do período selecionado
        if (this._type != "EXP") {
            this.getMonthHours(this.period);
        } else {
          this.formData.controls['quantity'].setValue(1);
        }
    } else {
        this.getSelectedId(this.transactionId);
    } 

    if (this.isShared) {
        this.getOfferings();
    } else {
        this.formData.controls['id_offering'].setValue(this.offeringId);
    }

    if (this._type == "REV") {
         this.getContracts(this.subprojectId);
    }
       
  }

  getContracts(subprojectId) {
    this.subs.push(
      this.contractService.getContractsBySubProjectId(subprojectId)
        .subscribe(
          data  => {
          this.contractList = data;
          
          },
          error => {
            let errorMsg = "Loading Contracts => " + error.error.message;
            this.notification.error("Error", errorMsg);
            this.gta.eventEmitter("form_error", "broken_experience", "listing_a_box", "[new-transaction / contracts]  " + error.error.message, 5);
          }));
  }

  getOfferings() {
    this.subs.push(
      this.offeringService.getAll()
        .subscribe(
          data=> {
          this.offeringList = data;
          },
          error => {
            let errorMsg = "Loading Offerings => " + error.error.message;
            this.notification.error("Error", errorMsg);
            this.gta.eventEmitter("form_error", "broken_experience", "listing_a_box", "[new-transaction / offerings]  " + error.error.message, 5);
          }));
  }


  // Busca as categorias
  getCategories(_type) {
    this.subs.push(
      this.categoryService.getByType(_type)
        .subscribe(
            data=> {
          this.categoryList = data;
          },
          error => {
            let errorMsg = "Loading Categories => " + error.error.message;
            this.notification.error("Error", errorMsg);
            this.gta.eventEmitter("form_error", "broken_experience", "listing_a_box", "[new-transaction / categories]  " + error.error.message, 5);
          }));
  }

  // Se o tipo mudar:
  // 1. Muda a lista de categorias
  // 2. Se for CSR:
  //    2.a. E tiver um empregado selecionado, busca o CSR
  // 3. Se for receita:
  //    3.a. ### Busca a última tarifa
  onTypeChange($event) {
    // Se mudar o tipo
    // Atualiza a lista de categorias 
    this.getCategories($event);

    // zera o valor do listbox
    this.formData.controls['id_category'].setValue(null);

    // e, se trocar para o CSR, já com o empregado selecionado
    // busca o CSR
    this.formData.controls['tax'].setValue(null);

    if ($event == "CSR") {
        this.onEmployeeChange(this.formData.controls['id_employee'].value);
    } else if ($event == "REV") {
        this.getContracts(this.subprojectId);
    } else if ($event == "EXP") {
        this.formData.controls['quantity'].setValue(1);
    }
  }

  onPeriodChange($event) {
      this.getMonthHours($event);
      this.onEmployeeChange(this.formData.controls['id_employee'].value); 
  }

  onEmployeeChange($event) {
    if (this.formData.controls['type'].value == "CSR") {
       if ($event != null) {
           let employee = $event;
           let period = this.DataParaString(this.formData.controls['period'].value);
           this.getCSR(employee, period);
       }   
    }
  }

  getCSR(employeeId, period) {
    this.subs.push(
      this.employeeCSRService.getCSR(employeeId, period)
        .subscribe(
            (data : any)  => {
            if (data.length > 0) {
               this.formData.controls['tax'].setValue(data[0].tax);
            } else {
               this.formData.controls['tax'].setValue(null);
               this.csrWarning = "CSR not found. Proceed?"
            }
          },
          error => {
            let errorMsg = "Loading Contracts => " + error.error.message;
            this.notification.error("Error", errorMsg);
            this.gta.eventEmitter("form_error", "broken_experience", "listing_a_box", "[new-transaction / employee CSR]  " + error.error.message, 5);
          }));
  }

  getMonthHours(_period) {
    let period = this.DataParaString(_period);
    this.subs.push(
      this.periodService.getHoursPeriod(period)
        .subscribe(
          (data: any) => {
          
          if (data) {
            if (data[0]) {
              this.formData.controls['quantity'].setValue(data[0].quantity)
            } else {
              this.formData.controls['quantity'].setValue(null)
            }
          } else {
            this.formData.controls['quantity'].setValue(null)
          }
          },
          error => {
            let errorMsg = "Loading Contracts => " + error.error.message;
            this.notification.error("Error", errorMsg);
            this.gta.eventEmitter("form_error", "broken_experience", "listing_a_box", "[new-transaction / MonthHours]  " + error.error.message, 5);
          }        ));
  }

  DataParaString(o) {
    let year = o.getFullYear();
    let month = Number(o.getMonth())+1;
    let newMonth = String(month)

    if (month < 10) {
         newMonth = "0" + String(month)
    }  

    let valor = year + newMonth;
    return valor
  }

  StringParaData(o : String) : Date {

    if (o.length > 0) {
       let newYear = o.substr(0, 4)
       //substr(0,3)
       let newMonth = o.substr(4,2)

       let valor = newMonth +'/01/' + newYear;

       console.log(valor)
       return new Date(valor);  
    } else {
       return new Date();  
    }
    
  }


  getSelectedId(id) {
    this.subs.push(
      this.editService.getById(id)
        .subscribe((data: any) => {
          //console.log("getSelectedId")
          //console.log(data)
          this.fullFillForm(data[0]);
        }));
  }

  fullFillForm(obj : SubProjectTransaction) {
        
        if (obj) {
            
            this.formData.controls['type'].setValue(obj.type);
            this.formData.controls['id_subproject_results'].setValue(obj.id_subproject_results);
            this.formData.controls['id_subproject'].setValue(obj.id_subproject);
            this.formData.controls['id_category'].setValue(obj.id_category);
            this.formData.controls['id_employee'].setValue(obj.id_employee);
            this.formData.controls['repeat'].setValue('0');
            this.formData.controls['times'].setValue(1);
            this.formData.controls['id_offering'].setValue(obj.id_offering);
            this.formData.controls['id_subproject_contract'].setValue(obj.id_subproject_contract);
            
            let period = obj.period
            period = period.toString()
            this.formData.controls['period'].setValue(this.StringParaData(period)) ;
            this.formData.controls['quantity'].setValue(obj.quantity);
            this.formData.controls['tax'].setValue(obj.tax);
            this.formData.controls['comment'].setValue(obj.comment);
            return true;
        } else {
            return false;
        }
    }

  SubmitForm() {

    this.salvando = true;
    if (this.formData.invalid) {
      this.formData.markAllAsTouched();
      this.salvando = false;
      return;
    }

        let obj = new SubProjectTransaction;

        obj.id_subproject = this.formData.controls['id_subproject'].value;
        obj.id_subproject_results = this.formData.controls['id_subproject_results'].value;
        obj.type = this.formData.controls['type'].value;
        obj.id_category = this.formData.controls['id_category'].value;
        obj.id_employee = this.formData.controls['id_employee'].value;
        obj.repeat = this.formData.controls['repeat'].value;
        obj.times = this.formData.controls['times'].value;
        obj.period = this.DataParaString(this.formData.controls['period'].value);
        obj.quantity = this.formData.controls['quantity'].value;
        obj.tax = this.formData.controls['tax'].value;
        obj.comment = this.formData.controls['comment'].value;

        obj.id_subproject_contract = this.formData.controls['id_subproject_contract'].value;

        if (this.formData.controls['id_offering'].value) {
            obj.id_offering = this.formData.controls['id_offering'].value;
        } else {
            obj.id_offering = this.offeringId
        }


        
        // manda para o serviço
        if (!obj.id_subproject_results) {
             
          this.subs.push(
            this.editService.insert(obj)
              .subscribe(
                (data: any) => {
                  if (obj.times > 1) {
                    this.notification.success("Success", data.successCount.toString() + " inserted successfully");
                  } else {
                    this.notification.success("Success", "inserted successfully");
                  }
                  
                  this.destroyModal();
                }, error => {
                  let errorMsg = "Error Inserting => " + error.error.message;
                  this.notification.error("Error", errorMsg);
                  this.salvando = false;
                  this.gta.eventEmitter("saving_error", "broken_experience", "inserting", "[new-transaction] " + error.error.message, 5);
                  this.salvando = false;
                }
                ));
        // se estiver fazendo update        
        } else {
          this.subs.push(
            this.editService.update(obj.id_subproject_results, obj)
              .subscribe(
                (data: any) => {
                  if (obj.times > 1) {
                    this.notification.success("Success", data.successCount.toString() + " updated successfully");
                  } else {
                    this.notification.success("Success", "updated successfully");
                  }
                  this.destroyModal()
                }, error => {
                  this.salvando = false;
                  let errorMsg = "Error Updating => " + error.error.message;
                  this.notification.error("Error", errorMsg);
                  this.salvando = false;
                  this.gta.eventEmitter("saving_error", "broken_experience", "updating", "[new-transaction] " + error.error.message, 5);
                  this.salvando = false;
                }
              ));
        } 
    }


  /////////////////////////////
  /// UTILIDADES
  /////////////////////////////
  destroyModal(): void {
    this.subs.map(s => s.unsubscribe);
    this.modal.destroy({ data: 'this the result data' });
  }
  
 

}
