import { Component, OnInit, OnDestroy } from '@angular/core';
import { ThemeConstantService } from '../../shared/services/theme-constant.service';
import {ResultsService} from '../../shared/services/results.service';
//import {ResultsHeadlinesComponent} from '../../widgets/results-headlines/results-headlines.component';
import {Filter} from '../../shared/model/filter.model';
import {FilterService} from '../../shared/services/filter.service';

import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-general-results',
  templateUrl: './general-results.component.html',
  styleUrls: ['./general-results.component.css']
})
export class GeneralResultsComponent implements OnInit {

    
    filterToggle : boolean = false;

    counter : number = 0;
    viewType = "current"

    viewResults = "LineResults"

    nzTabPosition = 0;

    taskListIndex: number = 0;
    selectedTab : string = 'general';

    lineResults : any;
    lineSelectedPeriod : string = "PTD";
    
    constructor(private route: ActivatedRoute,
                private filterService : FilterService,
                private colorConfig:ThemeConstantService,
                private resultsService : ResultsService,
               ) {}

    ngOnInit() {

        
        if (this.route.snapshot.queryParams['tab']) {
            this.nzTabPosition = this.route.snapshot.queryParams['tab'];
        } else {
            //console.log("sem parametros")
        }

    }

    ngOnDestroy() {
      this.filterService.clearFilters();
    }

    goToLineProjects(id) {
        this.filterService.addFilter({key: 'id_line', value: id});
        this.nzTabPosition = 1; // ProjectResults
    }

    toggleFilter() {
        this.filterToggle = ! this.filterToggle;
    }

    onResultsViewChange($event) {
        this.viewResults = $event;
    }



 
}

