import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {ResultsService} from '../../../shared/services/results.service';
import {CustomerService} from '../../../shared/services/customer.service';

import {LineService} from '../../../shared/services/line.service';
import {ManagerService} from '../../../shared/services/manager.service';
import {BusinessunitService} from '../../../shared/services/businessunit.service';
import {Filter} from '../../../shared/model/filter.model';
import {FilterService} from '../../../shared/services/filter.service';

import {Subscription} from 'rxjs';

@Component({
  selector: 'app-production-filters',
  templateUrl: './production-filters.component.html',
  styleUrls: ['./production-filters.component.css']
})
export class ProductionFiltersComponent implements OnInit {

  filters : Array<Filter> = [];
  @Input() defaultValues : Array<Filter> = [];

  sub1 : Subscription;
  sub2 : Subscription;
  sub3 : Subscription;
  sub4 : Subscription;
  sub5 : Subscription;
  sub6 : Subscription;

  periodList : any;
  BUList : any;
  customerList : any;
  managerList : any;
  lineList : any;

  selectedBU : number = 0;
  selectedLine : number = 0;
  selectedCustomer 
  selectedManager 

  periodstart
  periodend
  dateFormat = 'yyyyMM';

  status : string = "0"
  rappel : string = "0"

  constructor(private _filterService : FilterService,
              private _BUService : BusinessunitService,
              private _periodService : ResultsService,
              private _customerService : CustomerService,
              private _managerService : ManagerService,
              private _lineService : LineService,) { }

  ngOnInit() {

       //this.periodstart = new Date();
       //this.periodend = new Date();

       //console.log("ativei o [production-filters]")

       this._filterService.addFilters([
                                    //{key: "periodstart", value: this.DataParaString(this.periodstart)},
                                    //{key: "periodend", value: this.DataParaString(this.periodend)},
                                    {key: "status", value: "A"},
                                    ])

       /*
       if (this.defaultValues) {
          console.log(this.defaultValues);
          for (var i = 0; i < this.defaultValues.length; ++i) {
              this.addToFilterArray(this.defaultValues[i]);
          }
       }
       
       this.sub1 = this._filterService.data
       .subscribe((data : any) => {
          this.filters = data;
          this.selectedBU = this._filterService.getValueOf('id_bu');
          this.selectedLine = this._filterService.getValueOf('id_line');
          
          //this.periodstart = this._filterService.getValueOf('period_start');
          //this.periodend = this._filterService.getValueOf('period_end');
          
          this.selectedCustomer = this._filterService.getValueOf('id_customer');
          this.selectedManager = this._filterService.getValueOf('id_employee');
       })
       */


       this.populateInitialData();

       
       //this.filtered.emit(this.filters);
  }

  async populateInitialData() {
    //get managers
       this.sub2 = this._BUService.getAll()
       .subscribe((data : any) => {
           this.BUList = data;
       });

       this.getLines(this.selectedBU);

       /*
       this.sub4 = this._periodService.getPeriods()
       .subscribe((data : any) => {
           this.periodList = data;
       });
       */

       //get customers
       this.sub5 = this._customerService.getAll()
       .subscribe((data : any) => {
           this.customerList = data;
       });

       this.getManager(this.selectedLine);
  }

  getLines(bu) {
    if (bu != this.selectedBU) {
       this.selectedLine = 0;
    }

    if (bu != 0) {
      this.sub3 = this._lineService.getFilteredByBu(bu)
       .subscribe((data : any) => {
           
           this.lineList = data;
           
       });
     } else { 
      this.sub3 = this._lineService.getAll()
       .subscribe((data : any) => {
           this.lineList = data;
       });
     }
  }

  getManager(line) {
    if (line != this.selectedLine) {
        this.selectedManager = 0;
    }

    if (line != 0) {
      this.sub6 = this._managerService.getFilteredByLine(line)
       .subscribe((data : any) => {
           this.managerList = data;
           
       });
     } else { 
      this.sub6 = this._managerService.getAll()
       .subscribe((data : any) => {
           this.managerList = data;
       });
     }
  }

  addToFilterArray(obj : Filter) {

    this._filterService.addFilter(obj);

  }

  onBUChange(id) {
    this.addToFilterArray({key: 'id_bu', value: id});
    if (id != 0) {
      this.getLines(id)
    } else {
      this.getLines(0)
    }
    //this.setLine();
  } 

  onLineChange(id) {
    this.addToFilterArray({key: 'id_line', value: id});
    if (id != 0) {
      this.getManager(id)
    } else {
      this.getManager(0)
    }
    //this.setLine();
  } 

  onPeriodStartChange(id) {
    this.addToFilterArray({key: 'period_start', value: this.DataParaString(id)});
    
  } 

  onPeriodEndChange(id) {
    this.addToFilterArray({key: 'period_end', value: this.DataParaString(id)});
    
  } 

  onCustomerChange(id) {
    this.addToFilterArray({key: 'id_customer', value: id});
    //this.setLine();
  } 

  onManagerChange(id) {
    this.addToFilterArray({key: 'id_employee', value: id});
    //this.setLine();
  }

  onStatusChange($event) {
     console.log("onStatusChange($event)")
     console.log($event)
     this.addToFilterArray({key: 'status', value: $event});
  }

  onRappelChange($event) {
     this.addToFilterArray({key: 'rappel', value: $event});
  }

  DataParaString(o) {
    let year = o.getFullYear();
    let month = Number(o.getMonth())+1;
    let newMonth = String(month)

    if (month < 10) {
         newMonth = "0" + String(month)
    }  

    let valor = year + newMonth;
    return valor
  }

  ngOnDestroy() {
    if (this.sub1) { this.sub1.unsubscribe()}
    if (this.sub2) { this.sub2.unsubscribe()}  
    if (this.sub3) { this.sub3.unsubscribe()}
    if (this.sub4) { this.sub4.unsubscribe()}
    if (this.sub5) { this.sub5.unsubscribe()}
    if (this.sub6) { this.sub6.unsubscribe()}
    
  }


}
