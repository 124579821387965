import { Component, OnInit } from '@angular/core';
import {EmployeecsrService} from '../../services/employeecsr.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import {FlexmodalComponent} from '../../../shared/components/flexmodal/flexmodal.component';

@Component({
  selector: 'app-csr-update',
  templateUrl: './csr-update.component.html',
  styleUrls: ['./csr-update.component.css']
})
export class CsrUpdateComponent implements OnInit {
  
  
  periodos

  myTable
  constructor(private csrService : EmployeecsrService,
  			  private modalService : NzModalService) { }

  ngOnInit() {
  	this.getData();
  	
  }

  getData() {
  	this.csrService.getCSRTable([])
		.subscribe((respond : any) => {
			
			this.periodos = respond['periods']
			console.log("periods")

			console.log(this.periodos)

		this.myTable = {
           itemList : {
              fields : [
                 {key : "id_employee", label: "ID", alias : "ID", format: "", visible: true},
                 {key : "name", label: "Name", alias : "Name", format: "", visible: true},
              ],
              results : respond['data'],
              buttons : [{key : "edit", label: "", hint: "Edit", icon: "fas fa-edit"},]
            
             },
             options : {
               title : "CSRs per Employee",
             },
           }
          

           for (var i = 0; i < this.periodos.length; ++i) {
           	  let item = {key : this.periodos[i], label: this.periodos[i], alias : this.periodos[i], format: "currency", visible: true}
           	  this.myTable.itemList.fields.push(item)
           }
                 
    })
  }

  mostraEvento($event) {
    
    if ($event.event == "edit") {
        this.updateCSR($event.data.id_employee, this.periodos)
    }
  }
  formFields
  updateCSR(id, periodos) {


    this.formFields = {
      endpoint : `csr\${id}\csrvalues`,  
        id : 'id_employee',
        formFields : []
     }

     for (var i = 0; i < periodos.length; ++i) {
     	let item = {key : this.periodos[i], label: this.periodos[i] , value: "", type: "number" ,  visible : true}
     	this.formFields.formFields.push(item)
     }

     let title
     
     title = "UPDATE Employee CSR";
     

    let modalWidth = 500;
   

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: FlexmodalComponent,
      nzWidth : modalWidth,
      nzComponentParams: {
        _id : id,
        formFields :this.formFields.formFields,
        endpoint : `csr\${id}\csrvalues`,  
      },
    });
    
    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => this.getData());

  


  }




}
