import { Routes, RouterModule } from '@angular/router';

export const FullLayout_ROUTES: Routes = [
	/*{
        path: 'kanban',
        loadChildren: () => import('../../kanban/kanban.module').then(m => m.KanbanModule)
    }
    { 
        path: 'login', 
        component: LoginComponent
    },*/
    

];


