import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules,CanActivate } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full-layout/full-layout.component";
import { CommonLayoutComponent } from "./layouts/common-layout/common-layout.component";

import { FullLayout_ROUTES } from "./shared/routes/full-layout.routes";
import { CommonLayout_ROUTES } from "./shared/routes/common-layout.routes";
import { LoginComponent } from './shared/login-flow/login/login.component';
import {AccessDeniedComponent} from './shared/access-denied/access-denied.component';
import { AuthGuard} from './shared/auth-guard/auth.guard'
import {ResetPasswordComponent} from './shared/login-flow/reset-password/reset-password.component' 
import { ForgottenPasswordComponent } from './shared/login-flow/forgotten-password/forgotten-password.component';
import {CadastrosAuxiliaresComponent} from './shared/components/cadastros-auxiliares/cadastros-auxiliares.component';
import { FlexmodalComponent } from './shared/components/flexmodal/flexmodal.component'




const appRoutes: Routes = [

    /*{
        path: '',
        redirectTo: '/production/business-unit-dashboard', //general-dashboard
        pathMatch: 'full'
    },*/
    { path : 'admin', component : CadastrosAuxiliaresComponent},
    { 
        path: '', 
        component: CommonLayoutComponent,
        children: CommonLayout_ROUTES,
        canActivate : [AuthGuard]
    },
    { 
        path: '', 
        component: FullLayoutComponent, 
        children: FullLayout_ROUTES,
        canActivate : [AuthGuard]
    },
    
    { 
        path: 'accessdenied', 
        component: AccessDeniedComponent
    },

    { 
        path: 'login', 
        component: LoginComponent
    },
    { 
        path: 'forgotten-password', 
        component: ForgottenPasswordComponent
    },
    { 
        path: 'reset-password/:code', 
        component: ResetPasswordComponent,
        
    },
    {
        path : "testemodal",
        component : FlexmodalComponent
  },
  

    
    

];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { 
            preloadingStrategy: PreloadAllModules,
            useHash: true,
            scrollPositionRestoration: 'enabled' 
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}
