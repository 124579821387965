import { Component, OnInit, Input } from '@angular/core';
import { GenericalService} from '../../../shared/services/generical.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import {FlexmodalComponent} from '../flexmodal/flexmodal.component';
import {Filter} from '../../interfaces/filter.type';
import {API_ENDPOINT} from '../../../app.api';

@Component({
  selector: 'app-flexlist',
  templateUrl: './flexlist.component.html',
  styleUrls: ['./flexlist.component.css']
})
export class FlexlistComponent implements OnInit {

  @Input() entidade
  @Input() config

  myTable
  _count : number = 1;
  filters : Array<Filter> = [];
  selectFieldsToggle : boolean = false;
  showFilters : boolean = false;


  endpoint : string = "";

  testeListaDeFiltros

  constructor(private gServ : GenericalService,
              private modalService: NzModalService,
              private notification : NzNotificationService) { }

  ngOnInit(): void {
    //console.log("config:")
    //console.log(this.config);
    this.getData();
  }

  getData() {
    //console.log("chamou getData");
    this.endpoint = `${API_ENDPOINT}/${this.config.endpoint}`;
    
    this.testeListaDeFiltros = this.config.filterFields;
    
    this.gServ.getFiltered(this.endpoint, this.filters)
    .subscribe((data : any) => {
        //console.log(data);
        this._count = this._count + 1;
        this.myTable = this.config.table;
        this.myTable.itemList.results = data;
    })
  }

  AddOne(id) {  

     //console.log("end point = ");
     //console.log(this.endpoint);

     let title
     if (id != 0) {
        title = "Editing " + this.config.table.options.title;
     } else {
       title = "New " + this.config.table.options.title;
     }

    let modalWidth = 500;
    if (this.config.modalWidth) {
       modalWidth = this.config.modalWidth;
    }


    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: FlexmodalComponent,
      nzWidth : modalWidth,
      nzComponentParams: {
        _id : id,
        formFields : this.config.formFields,
        endpoint : this.endpoint
      },
    });
    
    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => { this.getData()} );

  }

  mostraEvento($event) {
    //console.log("evento recebindo no flexlist")
    //console.log($event);
     
    if ($event.event == "edit") {
        //this.newEmployeeFast($event.data.id_employee)
        let id = $event.data[this.config.id];
        this.AddOne(id);
    } else if ($event.event == "delete") {
        this.Delete($event.data)
    }
   
  }

  mostraToggler($event) {
    this.FilterToggle();
  }

  FilterToggle() {
    this.showFilters = ! this.showFilters;
  }

  FieldsToggle() {
    this.selectFieldsToggle = !this.selectFieldsToggle;
  }

  aplicaFiltros($event) {
    //console.log("teste filtro")
    //console.log($event)
    this.filters = $event.event;
    this.getData();
  } 

  DownloadResults() {

  }

  Delete(data) {
    let myId = data[this.config.id]
    this.gServ.delete(`${this.config.endpoint}/${myId}`)
    .subscribe((data : any) => {
      if (data.result === "Success") {
          this.notification.blank("Sucesso", "inativado com sucesso");
       } else {
          this.notification.blank("Erro", "erro na inativação");
       }
       this.getData();
    });

  }


}
