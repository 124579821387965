import { Component, OnInit, OnDestroy } from '@angular/core';
import {Filter} from '../../../shared/model/filter.model';
import {SubprojectResultsService} from '../../services/subproject-results.service';
import {PortfolioFilterService} from '../../services/portfolio-filter.service';
import { TableService } from '../../../shared/services/table.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import {Subscription} from 'rxjs';
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-offering-results',
  templateUrl: './offering-results.component.html',
  styleUrls: ['./offering-results.component.css']
})
export class OfferingResultsComponent implements OnInit {
  
  ordersList
  displayData
  ordersListCount : number = null;
  filters : Array<Filter> = []

  subs : Array<Subscription> = [];
  subOffering: Subscription = null;

  selectedProject
  selectedPortfolio

  view = "gestao"

  csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: true,
      title: 'SubProject Results',
      useBom: true,
      noDownload: false,
      headers: ["Offering Id", "Offering Name", "Employees", "Gross Revenue", "Net Revenue", "CSR", "EXP", "Margin", "Margin %"]
  };

  constructor(private tableSvc : TableService, 
              private listService : SubprojectResultsService,
              private summService : SubprojectResultsService,
              private filterService : PortfolioFilterService, ) { }

  ngOnInit() {
    this.subs.push(this.filterService.data
      .subscribe ((filtros : any) => {
          this.filters = filtros;
          this.testProject();
      }));
     
  }

  testProject() {
    this.selectedProject = this.filterService.getValueOf("id_project");
    this.selectedPortfolio = this.filterService.getValueOf("id_portfolio");
    
    if (this.selectedProject) {
      //console.log("offering resutls -  rolou por portfolio")
      this.getListData(); 

    } else if  (this.selectedPortfolio) {
      this.getListData(); 
      //console.log("offering resutls - nao rolou por portfolio")
    }
  }

  myTable
 
  bubblechart


  _chartData : Array<any> = [];
  _chartSeries : Array<string> = [];

  getListData() {
    if (this.subOffering) this.subOffering.unsubscribe();
    this.subOffering = this.listService.getOffering(this.filters)
      .subscribe((data : any) => {
          console.log(data)
          this.ordersList= data;

           this.myTable = {
                     itemList : {
                        fields : [
                           {key : "id_offering", label: "ID", alias : "ID", format: "", visible: false},
                           {key : "name", label: "Offering", alias : "Offering Name", format: "", visible: true},
                           {key : "employees", label: "Employees", alias : "Employees", format: "", visible: true},

                           {key : "REV", label: "Gross Revenue", alias : "Gross Revenue", format: "currency", visible: false},
                           {key : "NETREV", label: "Net Revenue", alias : "Net Revenue", format: "currency", visible: true},

                           {key : "AVG_GROSS_TAX", label: "AVG Gross Rate", alias : "AVG Gross Rate", format: "currency",  red : 135, direction : "<", visible: false},
                           {key : "AVG_NET_TAX", label: "AVG Net Rate", alias : "AVG Net Rate", format: "currency", red : 135, direction : "<", visible: false},
                           {key : "AVG_CSR", label: "Average CSR", alias : "Average CSR", format: "currency", red : 72, direction : ">=", visible: false},
                           {key : "EXPECTED_AVG_CSR", label: "EXPECTED_AVG_CSR", alias : "EXPECTED_AVG_CSR", format: "currency",  red : 72, direction : ">=", visible: false},
                           {key : "MARGIN_OVER_AVG_CSR", label: "MARGIN_OVER_AVG_CSR", alias : "MARGIN_OVER_AVG_CSR", format: "percentage", red : 0.45, direction : "<", visible: false},

                           
                           {key : "CSR", label: "CSR", alias : "CSR", format: "currency", visible: true},
                           {key : "EXP", label: "Expenses", alias : "Expenses", format: "currency", visible: true},

                           {key : "margin", label: "Analytic Margin", alias : "Analytic Margin", format: "currency", visible: false},
                           
                           {
                               key : "marginpercent", 
                               label: "Margin %", 
                               alias : "Margin %", 
                               format: "percentage", 
                               visible: true,
                               red : 0.40,
                               direction : "<"
                           },
                        ],
                        results : this.ordersList.lines
                        //buttons : [
                        //  {key : "edit", label: "", hint: "Edit this project", icon: "fas fa-edit"}
                        //]
                    },
                    headers : {
                        fields : [
                           {key : "NETREV", label : "Net Revenue", format: "currency"},
                           {key : "CSR", label : "CSR", format: "currency"},
                           {key : "EXP", label : "Expenses", format: "currency"},
                           {key : "margin", label : "Analytic Margin", format: "currency"},
                           {
                               key : "marginpercent", 
                               label : "PTD", 
                               format: "percentage",
                               red : 0.40,
                               direction : "<"
                           },
                           {key : "employees", label : "Employees", format: ""},
                           {
                               key : "AVG_GROSS_TAX", 
                               label: "AVG GrossRate", 
                               format: "currency", 
                               red : 135,
                               direction : "<"
                           },
                           {
                               key : "AVG_CSR", 
                               label: "AVG CSR", 
                               format: "currency", 
                               red : 72,
                               direction : ">="
                           },
                        ],
                        results : [this.ordersList.total]
                    }
                 }


                    let myDataAM : Array<any> = []//= []

           //let offerings : Array<string> = []

            //let dataPlus = {}
            /*
            console.log("offering data")
            console.log(data)
            

            for (var i = 0; i < data.length-1; ++i) {
                  
                  if ((data[i].marginpercent) && (data[i].AVG_GROSS_TAX) && (data[i].id_offering != 7)) { //
                      

                      let obj2 = {}
                      obj2["x" + data[i].name] = data[i].AVG_GROSS_TAX.toFixed(2), 
                      obj2["y" + data[i].name] = data[i].marginpercent.toFixed(4), 
                      obj2["value" + data[i].name] = (data[i].NETREV/1000).toFixed(2), 
                      obj2["label"] = data[i].name;
                     

                      

                      if (this._chartSeries.includes(data[i].name) == false) {
                         //offerings.push(data[i].offname);
                         this._chartSeries.push(data[i].name)
                         //dataPlus[data[i].offname] = []
                      }

                      myDataAM.push(obj2);

                      
                  }
              }
              

              
              console.log("portfolio results chart series")
              console.log(this._chartSeries)
              //console.log("dataPLus");
              //console.log(dataPlus)

              this.bubblechart = {

                  data : myDataAM
              }

              */
      });

/*
      this.summService.getOfferingHistorical(this.filters)
      .subscribe((data : any) => {
          console.log(data)
      });
*/

  }

//   getListData() {
//     this.subs.push(this.listService.getOffering(this.filters)
//       .subscribe((data : any) => {
//           console.log(data)
//           this.ordersList= data;

//            this.myTable = {
//                      itemList : {
//                         fields : [
//                            {key : "id_offering", label: "ID", alias : "ID", format: "", visible: false},
//                            {key : "name", label: "Offering", alias : "Offering Name", format: "", visible: true},
//                            {key : "employees", label: "Employees", alias : "Employees", format: "", visible: true},

//                            {key : "REV", label: "Gross Revenue", alias : "Gross Revenue", format: "currency", visible: false},
//                            {key : "NETREV", label: "Net Revenue", alias : "Net Revenue", format: "currency", visible: true},

//                            {key : "AVG_GROSS_TAX", label: "AVG Gross Rate", alias : "AVG Gross Rate", format: "currency",  red : 135, direction : "<", visible: false},
//                            {key : "AVG_NET_TAX", label: "AVG Net Rate", alias : "AVG Net Rate", format: "currency", red : 135, direction : "<", visible: false},
//                            {key : "AVG_CSR", label: "Average CSR", alias : "Average CSR", format: "currency", red : 72, direction : ">=", visible: false},
//                            {key : "EXPECTED_AVG_CSR", label: "EXPECTED_AVG_CSR", alias : "EXPECTED_AVG_CSR", format: "currency",  red : 72, direction : ">=", visible: false},
//                            {key : "MARGIN_OVER_AVG_CSR", label: "MARGIN_OVER_AVG_CSR", alias : "MARGIN_OVER_AVG_CSR", format: "percentage", red : 0.45, direction : "<", visible: false},

                           
//                            {key : "CSR", label: "CSR", alias : "CSR", format: "currency", visible: true},
//                            {key : "EXP", label: "Expenses", alias : "Expenses", format: "currency", visible: true},

//                            {key : "margin", label: "Analytic Margin", alias : "Analytic Margin", format: "currency", visible: false},
                           
//                            {
//                                key : "marginpercent", 
//                                label: "Margin %", 
//                                alias : "Margin %", 
//                                format: "percentage", 
//                                visible: true,
//                                red : 0.40,
//                                direction : "<"
//                            },
//                         ],
//                         results : this.ordersList.lines
//                         //buttons : [
//                         //  {key : "edit", label: "", hint: "Edit this project", icon: "fas fa-edit"}
//                         //]
//                     },
//                     headers : {
//                         fields : [
//                            {key : "NETREV", label : "Net Revenue", format: "currency"},
//                            {key : "CSR", label : "CSR", format: "currency"},
//                            {key : "EXP", label : "Expenses", format: "currency"},
//                            {key : "margin", label : "Analytic Margin", format: "currency"},
//                            {
//                                key : "marginpercent", 
//                                label : "PTD", 
//                                format: "percentage",
//                                red : 0.40,
//                                direction : "<"
//                            },
//                            {key : "employees", label : "Employees", format: ""},
//                            {
//                                key : "AVG_GROSS_TAX", 
//                                label: "AVG GrossRate", 
//                                format: "currency", 
//                                red : 135,
//                                direction : "<"
//                            },
//                            {
//                                key : "AVG_CSR", 
//                                label: "AVG CSR", 
//                                format: "currency", 
//                                red : 72,
//                                direction : ">="
//                            },
//                         ],
//                         results : [this.ordersList.total]
//                     }
//                  }


//                     let myDataAM : Array<any> = []//= []

//            //let offerings : Array<string> = []

//             //let dataPlus = {}
//             /*
//             console.log("offering data")
//             console.log(data)
            

//             for (var i = 0; i < data.length-1; ++i) {
                  
//                   if ((data[i].marginpercent) && (data[i].AVG_GROSS_TAX) && (data[i].id_offering != 7)) { //
                      

//                       let obj2 = {}
//                       obj2["x" + data[i].name] = data[i].AVG_GROSS_TAX.toFixed(2), 
//                       obj2["y" + data[i].name] = data[i].marginpercent.toFixed(4), 
//                       obj2["value" + data[i].name] = (data[i].NETREV/1000).toFixed(2), 
//                       obj2["label"] = data[i].name;
                     

                      

//                       if (this._chartSeries.includes(data[i].name) == false) {
//                          //offerings.push(data[i].offname);
//                          this._chartSeries.push(data[i].name)
//                          //dataPlus[data[i].offname] = []
//                       }

//                       myDataAM.push(obj2);

                      
//                   }
//               }
              

              
//               console.log("portfolio results chart series")
//               console.log(this._chartSeries)
//               //console.log("dataPLus");
//               //console.log(dataPlus)

//               this.bubblechart = {

//                   data : myDataAM
//               }

//               */
//       }));

// /*
//       this.summService.getOfferingHistorical(this.filters)
//       .subscribe((data : any) => {
//           console.log(data)
//       });
// */

//   }

  getFile() {
     new  AngularCsv(this.displayData, "Offering Results", this.csvOptions);
  }

  onStatusChange($event) {
    this.filterService.addFilter({key: "grouped", value : $event})
    // this.getListData();
  }

  sort(sortAttribute: any) {
    this.displayData = this.tableSvc.sort(sortAttribute, this.displayData);
  }

  ngOnDestroy() {
    if (this.subOffering) this.subOffering.unsubscribe();
    this.subs.map(s => s.unsubscribe);
  }


}
