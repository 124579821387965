import { Component, OnInit, OnDestroy } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
//import { ThemeConstantService } from '../../../shared/services/theme-constant.service';
import { Filter } from '../../shared/model/filter.model';

//import { Customer, CustomerDisplay } from '../../../shared/model/customer.model';

//import { ProjectService } from '../../../shared/services/project.service';
import { LineService } from '../../shared/services/line.service';
//import { NewEmployeeFastComponent } from '../../../portfolio/portfolio-registers/employee-list/new-employee-fast/new-employee-fast.component';
import { FlexmodalComponent } from '../../shared/components/flexmodal/flexmodal.component';
import { API_ENDPOINT } from '../../app.api';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';

@Component({
  selector: 'app-lines',
  templateUrl: './lines.component.html',
  styleUrls: ['./lines.component.css']
})
export class LinesComponent implements OnInit {

  myTable
  itemsList

  subtitle: string = ""
  filters: Array<Filter> = [{ key: 'orderBy', value: "name" }, { key: 'status', value: "A" }]


  subs: Array<Subscription> = [];
  _count = 0
  selectFieldsToggle: Boolean = false;
  showFilters: Boolean = false;
  exportData: Array<any> = []
  fields: Array<any> = []
  visibleFields: Array<any> = [];

  formFields = [
    { key: "id_line", label: "_id", value: "", type: "text", visible: false },
    { key: "name", label: "Name", value: "", type: "text", visible: true },
    { key: "shortname", label: "Short name", value: "", type: "text", visible: true },
    { key: "id_bu", label: "Business Unit", value: "", type: "select", visible: true, dataFrom: { endpoint: 'bu', id: 'id_bu', label: 'name' } },
    { key: "status", label: "Status", value: "A", type: "AI", visible: true },
  ]

  endpoint = `${API_ENDPOINT}line`

  filterList = [
    { key: "name", label: "Name", value: "", type: "text", visible: true },
    { key: "shortname", label: "Short Name", value: "", type: "text", visible: true },
    { key: "id_bu", label: "Business Unit", value: "", type: "select", visible: true, dataFrom: { endpoint: 'bu', id: 'id_bu', label: 'name' } },
    { key: "status", label: "Status", value: "A", type: "radio", visible: true, dataFrom: [{ label: "Ativo", value: "A" }, { label: "Inativo", value: "I" }] },
  ]

  constructor(private mainListService: LineService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private editService: LineService,
    private gta  : GoogleAnalyticsService  ) { }

  ngOnInit() {

    this.getData();
  }

  AddOne(id) {
    let title
    if (id != 0) {
      title = "Editing Line"
    } else {
      title = "New Line"
    }

    let modalWidth = 500;
    //if (this.modalWidth) {
    //   modalWidth = this.config.modalWidth;
    //}


    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: FlexmodalComponent,
      nzWidth: modalWidth,
      nzComponentParams: {
        _id: id,
        formFields: this.formFields,
        endpoint: this.endpoint
      },
    });

    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => { this.getData() });

  }


  getData() {
    this.subs.push(
      this.mainListService.get(this.filters)
        .subscribe(
          data => {
          this.myTable = {
            options: { title: 'Lines / Foundations' },
            itemList: {
              fields: [
                { key: "id_line", label: "ID", alias: "ID", format: "", visible: true },
                { key: "name", label: "Name", alias: "Name", format: "", visible: true },
                { key: "shortname", label: "Shortname", alias: "Shortname", format: "", visible: true },

                //{key : "empname", label: "Leader Name", alias : "Leader Name", format: "", visible: true},

                { key: "buname", label: "Business Unit", alias: "Business Unit", format: "", visible: true },

                //{key : "avatario", label: "Sector", alias : "Sector Name", format: "", visible: true},
                //{key : "isgroup", label: "Sector", alias : "Sector Name", format: "", visible: true},

                { key: "status", label: "Status", alias: "Status", format: "AI", visible: true },
              ],
              results: data,//.slice(0, data.length-1),
              buttons: [
                { key: "edit", label: "", hint: "Edit", icon: "fas fa-edit" },
                { key: "delete", label: "", hint: "Delete", icon: "far fa-trash-alt" }
              ]
            },
          }
          },
          error => {
            let errorMsg = "Loading Query => " + error.error.message;
            this.notification.error("Error", errorMsg);
            this.gta.eventEmitter("loading_error", "broken_experience", "listing_table", "[lines] " + error.error.message, 3);
          }));
  }

  


  mostraEvento($event) {
    if ($event.event == "edit") {
      this.AddOne($event.data.id_line);
    } else if ($event.event == "delete") {
      this.Delete($event.data.id_line)
    }
  }

  /* START - PARTE DO PADRÃO */

  mostraToggler($eshowFiltersvent) {
    this.FilterToggle();
  }

  FilterToggle() {
    this.showFilters = !this.showFilters;
  }

  FieldsToggle() {
    this.selectFieldsToggle = !this.selectFieldsToggle;
    this._count = this._count + 1;
  }


  aplicaFiltros($event) {
    this.filters = $event.event;
    this.getData();
  }

  /* FINISH - PARTE DO PADRÃO */


  Delete(id) {
    this.subs.push(
      this.editService.delete(id)
        .subscribe(
          data => {
            this.notification.success("Success", "deleted successfully");
            this.gta.eventEmitter("updating_succes", "good_experience", "deleting", "[lines]", 1);
            this.getData();
          },
          error => {
            this.notification.error("Error", error.error.message);
            this.gta.eventEmitter("saving_error", "broken_experience", "deleting", "[lines] " + error.error.message, 3);
          }
        ))

  }

  ngOnDestroy() {
    this.subs.map(s => s.unsubscribe);
  }

}
