import { Component, OnInit, Input} from '@angular/core';
// Para ambos

import {SubprojectTransactionService} from '../../../services/subproject-transaction.service';
import { NzModalRef } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';
// Somente para o modal
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import {OfferingService} from '../../../../shared/services/offering.service';
import {EmployeeService} from '../../../../shared/services/employee.service';
import {SubprojectCategoryService} from '../../../services/subproject-category.service';

import {SubProjectTransactionComposed} from '../../../model/subprojecttransaction.model';
import {Subscription} from 'rxjs';
import {SubprojectContractService} from '../../../services/subproject-contract.service';

@Component({
  selector: 'app-new-transaction-composed',
  templateUrl: './new-transaction-composed.component.html',
  styleUrls: ['./new-transaction-composed.component.css']
})
export class NewTransactionComposedComponent implements OnInit {

  @Input() subprojectId
  @Input() _type
  @Input() transactionId
  @Input() period
  @Input() isShared
  @Input() offeringId
  // variáveis o EDIT

  dateFormat = 'yyyyMM';
  formData

 
  categoryREVList
  categoryCSRList
  categoryEXPList


  employeeList
  offeringList
  contractList

  salvando : boolean = false;
 
  csrWarning : string = ""


  // varíáveis compartilhadsa
  
  view : string = '0';
  

  constructor(  // Serviços do edit
  		        private modal: NzModalRef,		
              	private fb: FormBuilder,
               	private notification: NzNotificationService,
              	private editService : SubprojectTransactionService,
              	private offeringService : OfferingService,
              	private employeeService : EmployeeService,
              	private employeeCSRService : EmployeeService,
              	private categoryService : SubprojectCategoryService,
              	private periodService : SubprojectTransactionService,
              	private contractService : SubprojectContractService) { 

      this.formData = this.fb.group({
          type : [null, [Validators.required]],
          id_subproject_results : [null],
          id_subproject : [null, [Validators.required]],
          id_employee : [null, [Validators.required]],
          id_offering : [null],

          // REVENUE
          id_category_REV : [null, [Validators.required]],
          tax_REV : [null, [Validators.required]],
          quantity_REV : [null, [Validators.required]],

          // CSR
          id_category_CSR : [null, [Validators.required]],
          tax_CSR : [null, [Validators.required]],
          quantity_CSR : [null, [Validators.required]],

          // EXPENSES
          id_category_EXP1 : [null, [Validators.required]],
          tax_EXP1 : [null, [Validators.required]],
          quantity_EXP1 : [null, [Validators.required]],

          id_category_EXP2 : [null, [Validators.required]],
          tax_EXP2 : [null, [Validators.required]],
          quantity_EXP2 : [null, [Validators.required]],

          id_category_EXP3 : [null, [Validators.required]],
          tax_EXP3 : [null, [Validators.required]],
          quantity_EXP3 : [null, [Validators.required]],
          

          // OTHER
          repeat : ['0'],
          times : [1],
          period : [null, [Validators.required]],
          
          id_subproject_contract : [null],
          comment : [null],
        });
  }

  ngOnInit() {

    // traz a lista de employees
     this.employeeService.getAll()
        .subscribe((data : any) => {
              this.employeeList = data;
    });

      
    //console.log("whats the type?")
    //console.log(this._type)
    if (this.transactionId == 0) {
        // seta o tipo selecionado
        // seta o tipo selecionado
        this.formData.controls['id_subproject'].setValue(this.subprojectId);
        //this.formData.controls['type'].setValue(this._type);
        this.formData.controls['type'].setValue("C");

        // busca as categorias relacionadas
        this.getCategories(this._type);
        // leva o período selecionado
        this.formData.controls['period'].setValue(this.period);
          
        // busca as horas do período selecionado
        if (this._type != "EXP") {
            this.getMonthHours(this.period);
        } else {
          this.formData.controls['quantity_EXP1'].setValue(1);
          this.formData.controls['quantity_EXP2'].setValue(1);
          this.formData.controls['quantity_EXP3'].setValue(1);
        }


    } else {
        this.getSelectedId(this.transactionId);
    } 

    if (this.isShared) {
        this.getOfferings();
    }

    
    this.getContracts(this.subprojectId);
    
       
  }

  getContracts(subprojectId) {
      //console.log("buscou contratos")
      this.contractService.getContractsBySubProjectId(subprojectId) 
        .subscribe((data : any) => { 
            this.contractList = data;
            //console.log(data);
        })
  }

  getOfferings() {
     this.offeringService.getAll()
        .subscribe((data : any) => {
              this.offeringList = data;
        })
  }


  // Busca as categorias
  getCategories(_type) {
    this.categoryService.getByType('REV')
      .subscribe((data : any) => {
            this.categoryREVList = data;
      })

    this.categoryService.getByType('CSR')
      .subscribe((data : any) => {
            this.categoryCSRList = data;
      })
      
   this.categoryService.getByType('EXP')
      .subscribe((data : any) => {
            this.categoryEXPList = data;
      })     
  }

  /*
  // Se o tipo mudar:
  // 1. Muda a lista de categorias
  // 2. Se for CSR:
  //    2.a. E tiver um empregado selecionado, busca o CSR
  // 3. Se for receita:
  //    3.a. ### Busca a última tarifa
  onTypeChange($event) {
    // Se mudar o tipo
    // Atualiza a lista de categorias 
    this.getCategories($event);

    // zera o valor do listbox
    this.formData.controls['id_category'].setValue(null);

    // e, se trocar para o CSR, já com o empregado selecionado
    // busca o CSR
    this.formData.controls['tax'].setValue(null);

    if ($event == "CSR") {
        this.onEmployeeChange(this.formData.controls['id_employee'].value);
    } else if ($event == "REV") {
        this.getContracts(this.subprojectId);
    } else if ($event == "EXP") {
        this.formData.controls['quantity'].setValue(1);
    }
  }
  */

  onPeriodChange($event) {
      this.getMonthHours($event);
      this.onEmployeeChange(this.formData.controls['id_employee'].value); 
  }

  onEmployeeChange($event) {
    if (this.formData.controls['type'].value == "C") {
       if ($event != null) {
           let employee = $event;
           let period = this.DataParaString(this.formData.controls['period'].value);
           this.getCSR(employee, period);
       }   
    }
  }

  getCSR(employeeId, period) {
       this.employeeCSRService.getCSR(employeeId, period)
          .subscribe((data : any) => {
            if (data.length > 0) {
               this.formData.controls['tax_CSR'].setValue(data[0].tax);
            } else {
               this.formData.controls['tax_CSR'].setValue(null);
               this.csrWarning = "CSR not found. Proceed?"
            }
      });
  }

  getMonthHours(_period) {
    let period = this.DataParaString(_period);
    this.periodService.getHoursPeriod(period)
    .subscribe((data: any) => {
        console.log(data)
        if (data) {
           if (data[0]) {
             this.formData.controls['quantity_REV'].setValue(data[0].quantity);
             this.formData.controls['quantity_CSR'].setValue(data[0].quantity);  
           } else {
             this.formData.controls['quantity_REV'].setValue(null)
             this.formData.controls['quantity_CSR'].setValue(null)
           }
        } else {
            this.formData.controls['quantity_REV'].setValue(null)
             this.formData.controls['quantity_CSR'].setValue(null)
        }
    })
  }

  DataParaString(o) {
    let year = o.getFullYear();
    let month = Number(o.getMonth())+1;
    let newMonth = String(month)

    if (month < 10) {
         newMonth = "0" + String(month)
    }  

    let valor = year + newMonth;
    return valor
  }

  StringParaData(o : String) : Date {

    if (o.length > 0) {
       let newYear = o.substr(0, 4)
       //substr(0,3)
       let newMonth = o.substr(4,2)

       let valor = newMonth +'/01/' + newYear;

       console.log(valor)
       return new Date(valor);  
    } else {
       return new Date();  
    }
    
  }


  getSelectedId(id) {
         this.editService.getById(id)
        .subscribe((data : any) => {
              //console.log("getSelectedId")
              //console.log(data)
              this.fullFillForm(data[0]);
        });
  }

  fullFillForm(obj : SubProjectTransactionComposed) {
        
        if (obj) {
            
            this.formData.controls['type'].setValue(obj.type);
            this.formData.controls['id_subproject_results'].setValue(obj.id_subproject_results);
            this.formData.controls['id_subproject'].setValue(obj.id_subproject);
            this.formData.controls['id_employee'].setValue(obj.id_employee);

            this.formData.controls['id_category_REV'].setValue(obj.id_category_REV);
            this.formData.controls['quantity_REV'].setValue(obj.quantity_REV);
            this.formData.controls['tax_REV'].setValue(obj.tax_REV);

            this.formData.controls['id_category_CSR'].setValue(obj.id_category_CSR);
            this.formData.controls['quantity_CSR'].setValue(obj.quantity_CSR);
            this.formData.controls['tax_CSR'].setValue(obj.tax_CSR);

            this.formData.controls['id_category_EXP1'].setValue(obj.id_category_EXP1);
            this.formData.controls['quantity_EXP1'].setValue(obj.quantity_EXP1);
            this.formData.controls['tax_EXP1'].setValue(obj.tax_EXP1);

            this.formData.controls['id_category_EXP2'].setValue(obj.id_category_EXP2);
            this.formData.controls['quantity_EXP2'].setValue(obj.quantity_EXP2);
            this.formData.controls['tax_EXP2'].setValue(obj.tax_EXP2);

            this.formData.controls['id_category_EXP3'].setValue(obj.id_category_EXP3);
            this.formData.controls['quantity_EXP3'].setValue(obj.quantity_EXP3);
            this.formData.controls['tax_EXP3'].setValue(obj.tax_EXP3);

            this.formData.controls['repeat'].setValue('0');
            this.formData.controls['times'].setValue(1);
            this.formData.controls['id_offering'].setValue(obj.id_offering);
            this.formData.controls['id_subproject_contract'].setValue(obj.id_subproject_contract);
            
            let period = obj.period
            period = period.toString()
            this.formData.controls['period'].setValue(this.StringParaData(period)) ;
            
            this.formData.controls['comment'].setValue(obj.comment);
            return true;
        } else {
            return false;
        }
    }

  SubmitForm() {

        if (this.formData.invalid) {
            console.log(this.formData)
            this.notification.blank("Error", "Fill required data before saving");
            return;
        } else {
          //this.salvando = true;
          console.log("erro no form")
        }

        let obj = new SubProjectTransactionComposed;

        obj.id_subproject = this.formData.controls['id_subproject'].value;
        obj.id_subproject_results = this.formData.controls['id_subproject_results'].value;
        obj.type = this.formData.controls['type'].value;
        obj.id_employee = this.formData.controls['id_employee'].value;
        

        obj.id_category_REV = this.formData.controls['id_category_REV'].value;
        obj.quantity_REV = this.formData.controls['quantity_REV'].value;
        obj.tax_REV = this.formData.controls['tax_REV'].value;

        obj.id_category_CSR = this.formData.controls['id_category_CSR'].value;
        obj.quantity_CSR = this.formData.controls['quantity_CSR'].value;
        obj.tax_CSR = this.formData.controls['tax_CSR'].value;

        obj.id_category_EXP1 = this.formData.controls['id_category_EXP1'].value;
        obj.quantity_EXP1 = this.formData.controls['quantity_EXP1'].value;
        obj.tax_EXP1 = this.formData.controls['tax_EXP1'].value;

        obj.id_category_EXP2 = this.formData.controls['id_category_EXP2'].value;
        obj.quantity_EXP2 = this.formData.controls['quantity_EXP2'].value;
        obj.tax_EXP2 = this.formData.controls['tax_EXP2'].value;

        obj.id_category_EXP3 = this.formData.controls['id_category_EXP3'].value;
        obj.quantity_EXP3 = this.formData.controls['quantity_EXP3'].value;
        obj.tax_EXP3 = this.formData.controls['tax_EXP3'].value;

        obj.repeat = this.formData.controls['repeat'].value;
        obj.times = this.formData.controls['times'].value;
        obj.period = this.DataParaString(this.formData.controls['period'].value);
        
        obj.comment = this.formData.controls['comment'].value;

        obj.id_subproject_contract = this.formData.controls['id_subproject_contract'].value;

        obj.id_offering = this.formData.controls['id_offering'].value;

        console.log("o que vai para o json")
        console.log(obj)
        
        // manda para o serviço
        if (!obj.id_subproject_results) {
             console.log('inserindo form data: ');
             console.log(obj);
             this.editService.insertComposed(obj)
             .subscribe((data : any) => {
               console.log(data)
               if (data.result === "Success") {
                   if (obj.times > 1) {
                      this.notification.blank("Success", data.successCount.toString() + " inserted successfully");
                    } else {
                      this.notification.blank("Success", "inserted successfully");  
                    }
               } else {
                  this.notification.blank("Error", "error inserting");
               }
               this.salvando = false;
               //this.destroyModal();
             });
        // se estiver fazendo update        
        } else {
            this.editService.update(obj.id_subproject_results, obj)
            .subscribe((data : any) => {
              console.log(data)
              if (data.result === "Success") {
                  if (obj.times > 1) {
                    this.notification.blank("Success", data.successCount.toString() + " updated successfully");
                  } else {
                    this.notification.blank("Success", "updated successfully");  
                  }
               } else {
                  this.notification.blank("Error", "error updating");
               }
               this.salvando = false;
               //this.destroyModal()   
            });
        } //this.SendNotification(
    }


  /////////////////////////////
  /// UTILIDADES
  /////////////////////////////
  destroyModal(): void {
    this.modal.destroy({ data: 'this the result data' });
  }
  
 

}
