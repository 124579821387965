import { Injectable } from '@angular/core';
import { GenericalService } from './generical.service';
import {API_ENDPOINT} from '../../app.api';
import {User} from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

 constructor(private gServ : GenericalService) { }

  getAll() {
  	let url = `${API_ENDPOINT}/customer`;
  	return this.gServ.get(url);
  }

  getLineCustomerMargin(id, filters) {
  	let url = `${API_ENDPOINT}/line/${id}/customer/margin`;
  	return this.gServ.getFiltered(url, filters);
  }

  getById(id) {
  	let url = `${API_ENDPOINT}/customer/${id}`;
  	return this.gServ.get(url);
  }

  getFiltered(filters) {
    let url = `${API_ENDPOINT}/customer`
    return this.gServ.getFiltered(url, filters);
  }

  get(filters) {
    let url = `${API_ENDPOINT}/customer`
    return this.gServ.getFiltered(url, filters);
  }
  /*
  getAllResults(filters) {
  	let url = `${API_ENDPOINT}/customer/results`;
  	return this.gServ.getFiltered(url, filters);
  }

  getAllWIP(filters) {
  	//
  }

  importCSV(file) {
    
  }
  
  getResults(id, filters) {
  	let url = `${API_ENDPOINT}/`;
  	return this.gServ.getFiltered(url, filters);
  }

  getResultsOverTime(id, filters) {
  	let url = `${API_ENDPOINT}/`;
  	return this.gServ.getFiltered(url, filters);
  }

  getWIP(id, filters) {
  	let url = `${API_ENDPOINT}/`;
  	return this.gServ.getFiltered(url, filters);
  }

  getWIPOverTime(id, filters) {
  	let url = `${API_ENDPOINT}/`;
  	return this.gServ.getFiltered(url, filters);
  }

  getStatistics(id, filters) {
  	let url = `${API_ENDPOINT}/`;
  	return this.gServ.getFiltered(url, filters);
  }
  
  getTopProjects(id, filters) {
  	let url = `${API_ENDPOINT}/`;
  	return this.gServ.getFiltered(url, filters);
  }

  getOKRs(id, filters) {
  	let url = `${API_ENDPOINT}/`;
  	return this.gServ.getFiltered(url, filters);
  }
  */
  insert(obj) {
  	let url = `${API_ENDPOINT}/customer`;
     return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
  	let url = `${API_ENDPOINT}/customer/${id}`;
  	return this.gServ.putclean(url, obj);
  }

  delete(id) {
  	 let url = `${API_ENDPOINT}/customer/${id}`;
  	 return this.gServ.delete(url);
  }

}
