import { Component, OnInit, OnDestroy } from '@angular/core';
import {ResultsService} from '../../../shared/services/results.service';
import {BusinessunitService} from '../../../shared/services/businessunit.service';
import {Filter} from '../../../shared/model/filter.model';
import {FilterService} from '../../../shared/services/filter.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-top-projects',
  templateUrl: './top-projects.component.html'
})
export class TopProjectsComponent implements OnInit {

  MarginList : any;
  RevenueList : any;
  ContributionList : any;
    
  tabHeader = "Top Projects"
  taskListIndex = 0 

  sub1 : Subscription;
  sub2 : Subscription;
  sub3 : Subscription;
  sub4 : Subscription;

  //filters : Array<Filter> = [];

  constructor(private filterService : FilterService,
              private resultsService : BusinessunitService,
              private revenueService : BusinessunitService, 
              private contributionService : BusinessunitService) {}

  ngOnInit() {
      let type : string = ""
      let params: string = ""
      let id : string = ""
      //console.log("buscando top projects")
      //type, id, params58

      this.sub1 = this.filterService.data
      .subscribe ((filtros : any) => {
          //this.filters = filtros;
          //console.log("[top projects] filter service data: ");
          //console.log(filtros)

          this.sub2 = this.resultsService.getTopProjects(id, 'PTD', filtros)
          .subscribe((data : any) => {
             this.MarginList = data;
             //console.log("top projects");
             //console.log(data);
          });

          this.sub3 = this.revenueService.getTopProjects(id, 'revenue', filtros)
          .subscribe((data : any) => {
             this.RevenueList = data;
             //console.log("revenue data:");
             //console.log(data)

          });

          this.sub4 = this.contributionService.getTopProjects(id, 'contribution', filtros)
          .subscribe((data : any) => {
             this.ContributionList = data;
             //console.log("contribution")
             //console.log(data)
             
          });
      });

  }

  ngOnDestroy () {
    if (this.sub1) {
      this.sub1.unsubscribe()
    }
    if (this.sub2) {
      this.sub2.unsubscribe()
    }
    if (this.sub3) {
      this.sub3.unsubscribe()
    }
    if (this.sub4) {
      this.sub4.unsubscribe()
    }

  }

}
