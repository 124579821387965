
export class SubProject {
  id_subproject : number;
  name : string;
  shortname : string;
  id_offering : number;
  id_employee : number;
  startdate : string;
  enddate : string;
  id_project : number;
  usecase : string;
  technology : string;
  bodyshop : string;
  status : string;
  id_subproject_phase : number;
  comunidade: string;
  id_subproject_type: number;
  subproject_description: string;

  rt : string;


  project_tax : number;
  sales_margin : number;


  empname : string;
  offname : string;
  phasename : string;
 }
