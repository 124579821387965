import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';


import { ManagerDashboardComponent } from './dashboard/manager-dashboard/manager-dashboard.component';
import {ProjectDashboardComponent } from './dashboard/project-dashboard/project-dashboard.component';
import { ImportationComponent } from './importation/importation.component';
import { AuthGuard} from '../shared/auth-guard/auth.guard'
import { GeneralResultsComponent } from './general-results/general-results.component';
import {NewEmployeeFastComponent} from '../portfolio/portfolio-registers/employee-list/new-employee-fast/new-employee-fast.component';



import {CustomerSectorRevenueComponent} from './general-results/customer-sector-revenue/customer-sector-revenue.component';

const routes: Routes = [
    {
        path: 'results',
        component: GeneralResultsComponent, //FiltertestComponent,
        data: {
            title: 'General Results Dashboard',
            headerDisplay: "none"
        },
        canActivate : [AuthGuard], 
       
    },
    
    {
        path: 'project-dashboard',
        component: ProjectDashboardComponent,
        data: {
            title: 'Projects Dashboard',
            headerDisplay: "none"
        },
        canActivate : [AuthGuard],
        
    },
    {
        path: 'manager',
        component: ManagerDashboardComponent,
        data: {
            title: 'Managers',
            headerDisplay: "none"
        },
        canActivate : [AuthGuard]
    },
    {
        path: 'import',
        component: ImportationComponent,
        data: {
            title: 'Import',
            headerDisplay: "Importando coisas"
        },
        canActivate : [AuthGuard]
    },
    
    
    
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ProductionRoutingModule { }
