import { Component, OnInit, OnDestroy } from '@angular/core';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';
import { TableService } from '../../../shared/services/table.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'

// Filter
import {Filter} from '../../../shared/model/filter.model';
import {FilterService} from '../../../shared/services/filter.service';

import {ResultsService} from '../../../shared/services/results.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-wip-results',
  templateUrl: './wip-results.component.html',
  styleUrls: ['./wip-results.component.css']
})
export class WipResultsComponent implements OnInit {
  
  csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: ',',
    showLabels: true,
    showTitle: true,
    //title: 'Your Holiday List :',
    useBom: true,
    noDownload: false,
    headers: ["Holiday ID", "Holiday Date", "Holiday Comment", "Holiday Status"]
  };

  filters : Array<Filter> = [];
  lineWIPResults

  filtersSubscription : Subscription;
  subscription: Subscription; 


  constructor(private filterService : FilterService,
              private colorConfig:ThemeConstantService,
              private resultsService : ResultsService,) { }

  ngOnInit() {

  	this.filtersSubscription = this.filterService.data
      .subscribe ((filtros : any) => {
        this.filters = filtros;
        
         this.subscription = this.resultsService.getWIP(filtros, "line")
          .subscribe((data : any) => {
             this.lineWIPResults = data.data;
             console.log(data)
         })        
      })
  }

  DownloadResults() {
       let head = []
       for (var i = 0; i < this.lineWIPResults.keys.length; ++i) {
         head.push(this.lineWIPResults.keys[i]);
       }
       this.csvOptions.headers = head;
       new  AngularCsv(this.lineWIPResults, "WIP Results", this.csvOptions);
  }

  ngOnDestroy() {
    this.filtersSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

}
