import { Component, OnInit, OnDestroy } from '@angular/core';
import { TableService } from '../../shared/services/table.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd';


import { EmployeeService } from '../../shared/services/employee.service';

import { Employee, EmployeeDisplay } from '../../shared/model/employee.model';
import { Filter } from '../../shared/model/filter.model';

import { EmployeesFormComponent } from './employees-form/employees-form.component';

import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { ThemeConstantService } from '../../shared/services/theme-constant.service';
import { ManagerService } from '../../shared/services/manager.service';
import { Customer } from '../../shared/model/customer.model'

import { Subscription, pipe, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators'
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css']
})
export class EmployeesComponent implements OnInit {

  lineFilterList
  itemsList

  offeringList
  empCategoryList
  customerList

  view = "A"
  subtitle: string = ""
  managerList

  subs: Array<Subscription> = [];


  selectedManager
  selectedOffering
  selectedProject


  projectList
  ordersList
  queryError = false;


  busca
  busca_sapcode

  selectedLine

  myTable

  filters: Array<Filter> = [{ key: 'orderBy', value: "name" }, { key: 'id_line', value: 2 }];

  constructor(private colorConfig: ThemeConstantService,
    private tableSvc: TableService,
    private notification: NzNotificationService,
    private modalService: NzModalService,
    private mainListService: EmployeeService,
    private editService: EmployeeService,
    private empCategoryService: EmployeeService,
    private managerService: ManagerService,
    private gta: GoogleAnalyticsService
    ) { }

  ngOnInit() {



    this.addFilter({ key: 'status', value: "A" });
    this.getData();
  }

  /* START - PARTE DO PADRÃO */
  _count = 0
  selectFieldsToggle: Boolean = false;
  showFilters: Boolean = true;
  exportData: Array<any> = []
  fields: Array<any> = []
  visibleFields: Array<any> = [];
  filterList = [
    { key: "name", label: "Name", value: "", type: "text", visible: true },
    { key: "sapcode", label: "SAP Code", value: "", type: "text", visible: true },
    { key: "id_line", label: "Line", value: "", type: "select", visible: true, dataFrom: { endpoint: 'line', id: 'id_line', label: 'name' } },
    { key: "id_category", label: "Category", value: "", type: "select", visible: true, dataFrom: { endpoint: 'employee/category', id: 'id_category', label: 'name' } },
    { key: "id_offering", label: "Offering", value: "", type: "select", visible: true, dataFrom: { endpoint: 'offering', id: 'id_offering', label: 'name' } },
    { key: "id_manager", label: "Manager", value: "", type: "select", visible: true, dataFrom: { endpoint: 'manager', id: 'id_employee', label: 'name' } },
    { key: "status", label: "Status", value: "A", type: "radio", visible: true, dataFrom: [{ label: "Ativo", value: "A" }, { label: "Inativo", value: "I" }] },
  ]

  mostraToggler($eshowFiltersvent) {
    this.FilterToggle();
  }

  FilterToggle() {
    this.showFilters = !this.showFilters;
  }

  FieldsToggle() {
    this.selectFieldsToggle = !this.selectFieldsToggle;
    this._count = this._count + 1;
  }

  aplicaFiltros($event) {
    this.filters = $event.event;
    this.getData();
  }

  mostraEvento($event) {
    if ($event.event == "edit") {
      this.AddOne($event.data.id_employee);
    } else if ($event.event == "delete") {
      this.Delete($event.data.id_employee)
    }
  }


  getData() {

    this.subs.push(
      this.mainListService.getFilteredAvanced(this.filters)
        .subscribe(
          data => {

            this.itemsList = data;
            this.myTable = {
              options: { title: 'Employees' },
              itemList: {
                fields: [
                  { key: "id_employee", label: "ID", alias: "ID", format: "", visible: true },
                  { key: "sapcode", label: "SapId", alias: "SapId", format: "", visible: true },
                  { key: "name", label: "Name", alias: "Employee Name", format: "", visible: true },
                  { key: "extraname", label: "Extraname", alias: "Employee Extra Name", format: "", visible: false },
                  { key: "linshortname", label: "Line", alias: "Line", format: "", visible: true },
                  { key: "offname", label: "Chapter", alias: "Chapter", format: "", visible: true },
                  { key: "subprojects", label: "SubProjects", alias: "SubProjects", format: "", visible: false },
                  { key: "leaders", label: "Leader", alias: "Leader", format: "", visible: true },
                  { key: "status", label: "Status", alias: "Status", format: "AI", visible: true },
                ],
                results: data,
                buttons: [
                  { key: "edit", label: "", hint: "Edit", icon: "fas fa-edit" },
                  { key: "delete", label: "", hint: "Delete", icon: "far fa-trash-alt" }
                ]
              },
            }

          },
          error => {
            let errorMsg = "Loading Query => " + error.error.message;
            this.notification.error("Error", errorMsg);
            this.gta.eventEmitter("saving_error", "broken_experience", "deleting", "[employees] " + error.error.message, 3);
            this.queryError = true;
          }))
  }





  newEmployee(id) { this.notification.blank("Sorry", "not ready yet"); }

  AddOne(id) {
    let title
    if (id != 0) {
      title = "Edit Employee"
    } else {
      title = "New Employee"
    }

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: EmployeesFormComponent,
      nzComponentParams: {
        employeeId: id
      },
    });
    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => this.getData());

  }

  addFilter(obj: Filter) {

    let index = this.filters.findIndex(x => x.key == obj.key);
    if (index === -1) {
      this.filters.push(obj);
    } else {
      this.filters[index].value = obj.value;
    }
  }



  zeraFilter() {
    this.filters = [];
  }

  getFile() {
    new AngularCsv(this.ordersList, "Employee List", this.csvOptions);
  }

  csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: ',',
    showLabels: true,
    showTitle: true,
    //title: 'Your Holiday List :',
    useBom: true,
    noDownload: false,
    headers: ["Employee Id", "Name", "Shortname", "Extraname",
      "Email", "Avatar", "Date Start", "Date new category",
      "Date Resignation", "Subsidiary", "Line Id", "Line Shortname",
      "Category Id", "Category Name", "Status", "SAP Code", "Subprojects", "Leaders"]
  };

  Delete(item) {
    this.subs.push(
      this.editService.delete(item.id_employee)
        .subscribe(
          data => {
            this.notification.success("Success", "deleted successfully");
            this.gta.eventEmitter("updating_succes", "good_experience", "deleting", "[employees]", 1);
            this.getData();
          },
          error => {
            this.notification.error("Error", error.error.message);
            this.gta.eventEmitter("saving_error", "broken_experience", "deleting", "[employees] " + error.error.message, 3);

          }

        ));

  }

  ngOnDestroy() {
    this.subs.map(s => s.unsubscribe);
  }

}


