export const cadastros = {

	 bu : {
        endpoint : 'bu',   
      id : 'id_bu',
      formFields : [
         {key : "id_bu", label: "id" , value: "", type: "number", visible : false},
         {key : "name", label: "Name" , value: "", type: "text" ,  visible : true},
         {key : "shortname", label: "Short Name" , value: "", type: "text" ,  visible : true},
         {key : "status", label: "Status" , value: "A", type: "AI", visible : true},
      ],
      filterFields : [
         {key : "name", label: "Name" , value: "", type: "text" ,  visible : true},
         {key : "shortname", label: "Shortname" , value: "", type: "text" ,  visible : true},
         {key : "status", label: "Status" , value: "A", type: "radio",  visible : true, dataFrom: [{label: "Ativo", value : "A" }, {label: "Inativo", value : "I" }]},
      ],  
        table : {
                 options : {
                    title : "Business Unit"
                   },
                 itemList : {
                    fields : [
                       {key : "id_line", label: "id", alias : "id", format: "", visible: false},
                       {key : "name", label: "Name", alias : "Nome", format: "", visible: true},
                       {key : "shortname", label: "Short Name", alias : "Short Name", format: "", visible: true},
                       {key : "status", label: "Status", alias : "Status", format: "AI", visible: true},
                    ],
                    results : [],
                    buttons : [
                      {key : "edit", label: "", hint: "Edit", icon: "fas fa-edit"},
                    ]
                },
          } 
    }, 

    line : {
  		endpoint : 'line',	
      id : 'id_line',
      formFields : [
         {key : "id_line", label: "id" , value: "", type: "number", visible : false},
         {key : "name", label: "Name" , value: "", type: "text" ,  visible : true},
         {key : "shortname", label: "Short Name" , value: "", type: "text" ,  visible : true},
         {key : "id_bu", label: "Business Unit" , value: "", type: "text" ,  visible : true},
         {key : "status", label: "Status" , value: "A", type: "AI", visible : true},
      ],
      filterFields : [
         {key : "name", label: "Name" , value: "", type: "text" ,  visible : true},
         {key : "shortname", label: "Shortname" , value: "", type: "text" ,  visible : true},
         {key : "status", label: "Status" , value: "A", type: "radio",  visible : true, dataFrom: [{label: "Ativo", value : "A" }, {label: "Inativo", value : "I" }]},
      ],  
  		table : {
                 options : {
                    title : "Line"
             	   },
                 itemList : {
                    fields : [
                       {key : "id_line", label: "id", alias : "id", format: "", visible: false},
                       {key : "name", label: "Name", alias : "Nome", format: "", visible: true},
                       {key : "shortname", label: "Short Name", alias : "Short Name", format: "", visible: true},
                       {key : "buname", label: "Business Unit", alias : "Business Unit", format: "", visible: true},
                       {key : "status", label: "Status", alias : "Status", format: "AI", visible: true},
                    ],
                    results : [],
                    buttons : [
                      {key : "edit", label: "", hint: "Edit", icon: "fas fa-edit"},
                    ]
                },
          } 
    },
    offering : {
        endpoint : 'offering',   
      id : 'id_offering',
      formFields : [
         {key : "id_offering", label: "id" , value: "", type: "number", visible : false},
         {key : "name", label: "Name" , value: "", type: "text" ,  visible : true},
         {key : "shortname", label: "Short Name" , value: "", type: "text" ,  visible : true},
         {key : "id_line", label: "Line" , value: "", type: "text" ,  visible : true},
         {key : "status", label: "Status" , value: "A", type: "AI", visible : true},
      ],
      filterFields : [
         {key : "name", label: "Name" , value: "", type: "text" ,  visible : true},
         {key : "shortname", label: "Shortname" , value: "", type: "text" ,  visible : true},
         {key : "status", label: "Status" , value: "A", type: "radio",  visible : true, dataFrom: [{label: "Ativo", value : "A" }, {label: "Inativo", value : "I" }]},
      ],  
        table : {
                 options : {
                    title : "Offering"
                   },
                 itemList : {
                    fields : [
                       {key : "id_offering", label: "id", alias : "id", format: "", visible: false},
                       {key : "name", label: "Name", alias : "Nome", format: "", visible: true},
                       {key : "shortname", label: "Short Name", alias : "Short Name", format: "", visible: true},
                       {key : "linname", label: "Business Unit", alias : "Line", format: "", visible: true},
                       {key : "status", label: "Status", alias : "Status", format: "AI", visible: true},
                    ],
                    results : [],
                    buttons : [
                      {key : "edit", label: "", hint: "Edit", icon: "fas fa-edit"},
                    ]
                },
          } 
    },
    customer_sector : {
        endpoint : 'customersector',   
      id : 'id_customer_sector',
      formFields : [
         {key : "id_id_customer_sector", label: "id" , value: "", type: "number", visible : false},
         {key : "name", label: "Name" , value: "", type: "text" ,  visible : true},
         {key : "status", label: "Status" , value: "A", type: "AI", visible : true},
      ],
      filterFields : [
         {key : "name", label: "Name" , value: "", type: "text" ,  visible : true},
         {key : "status", label: "Status" , value: "A", type: "radio",  visible : true, dataFrom: [{label: "Ativo", value : "A" }, {label: "Inativo", value : "I" }]},
      ],  
        table : {
                 options : {
                    title : "Customer Sector"
                   },
                 itemList : {
                    fields : [
                       {key : "id_customer_sector", label: "id", alias : "id", format: "", visible: false},
                       {key : "name", label: "Name", alias : "Nome", format: "", visible: true},
                       {key : "status", label: "Status", alias : "Status", format: "AI", visible: true},
                    ],
                    results : [],
                    buttons : [
                      {key : "edit", label: "", hint: "Edit", icon: "fas fa-edit"},
                    ]
                },
          } 
    }, 
    period_hours : {
        endpoint : 'period_hours',   
      id : 'period',
      formFields : [
         {key : "period", label: "period" , value: "", type: "", visible : true},
         {key : "quantity", label: "quantity" , value: "", type: "" ,  visible : true},
      ],
      filterFields : [
         {key : "period", label: "period" , value: "", type: "number" ,  visible : true},
         {key : "quantity", label: "quantity" , value: "", type: "number" ,  visible : true},
      ],  
        table : {
                 options : {
                    title : "Hours per period"
                   },
                 itemList : {
                    fields : [
                       {key : "period", label: "Period", alias : "Period", format: "", visible: true},
                       {key : "quantity", label: "Hours", alias : "Hours", format: "", visible: true},
                    ],
                    results : [],
                    buttons : [
                      {key : "edit", label: "", hint: "Edit", icon: "fas fa-edit"},
                    ]
                },
          } 
    }, 
    subproject_phase : {
        endpoint : 'subproject_phase',   
      id : 'id_subproject_phase',
      formFields : [
         {key : "id_subproject_phase", label: "id" , value: "", type: "number", visible : false},
         {key : "name", label: "Name" , value: "", type: "text" ,  visible : true},
         {key : "status", label: "Status" , value: "A", type: "AI", visible : true},
      ],
      filterFields : [
         {key : "name", label: "Name" , value: "", type: "text" ,  visible : true},
         {key : "status", label: "Status" , value: "A", type: "radio",  visible : true, dataFrom: [{label: "Ativo", value : "A" }, {label: "Inativo", value : "I" }]},
      ],  
        table : {
                 options : {
                    title : "Subproject Phase"
                   },
                 itemList : {
                    fields : [
                       {key : "id_subproject_phase", label: "id", alias : "id", format: "", visible: false},
                       {key : "name", label: "Name", alias : "Nome", format: "", visible: true},
                       {key : "status", label: "Status", alias : "Status", format: "AI", visible: true},
                    ],
                    results : [],
                    buttons : [
                      {key : "edit", label: "", hint: "Edit", icon: "fas fa-edit"},
                    ]
                },
          } 
    },
    employee_category : {
        endpoint : 'employee_category',   
      id : 'id_category',
      formFields : [
         {key : "id_category", label: "id" , value: "", type: "number", visible : false},
         {key : "name", label: "Name" , value: "", type: "text" ,  visible : true},
         //{key : "Executive", label: "Executive" , value: "", type: "text" ,  visible : true},
         //{key : "Leader", label: "Leader" , value: "", type: "text" ,  visible : true},
         //{key : "Junior", label: "Junior" , value: "", type: "text" ,  visible : true},
         {key : "status", label: "Status" , value: "A", type: "AI", visible : true},
      ],
      filterFields : [
         {key : "name", label: "Name" , value: "", type: "text" ,  visible : true},
         //{key : "Executive", label: "Executive" , value: "", type: "text" ,  visible : true},
         //{key : "Leader", label: "Leader" , value: "", type: "text" ,  visible : true},
         //{key : "Junior", label: "Junior" , value: "", type: "text" ,  visible : true},
         {key : "status", label: "Status" , value: "A", type: "radio",  visible : true, dataFrom: [{label: "Ativo", value : "A" }, {label: "Inativo", value : "I" }]},
      ],  
        table : {
                 options : {
                    title : "Employee Category"
                   },
                 itemList : {
                    fields : [
                       {key : "id_subproject_phase", label: "id", alias : "id", format: "", visible: false},
                       {key : "name", label: "Name", alias : "Nome", format: "", visible: true},
                       //{key : "Executive", label: "Executive", alias : "Executive", format: "", visible: true},
                       //{key : "Leader", label: "Leader", alias : "Leader", format: "", visible: true},
                       //{key : "Junior", label: "Junior", alias : "Junior", format: "", visible: true},
                       {key : "status", label: "Status", alias : "Status", format: "AI", visible: true},
                    ],
                    results : [],
                    buttons : [
                      {key : "edit", label: "", hint: "Edit", icon: "fas fa-edit"},
                    ]
                },
          } 
    },
        
}

