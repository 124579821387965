import { Component, OnInit, Input } from '@angular/core';
import { TableService } from '../../../shared/services/table.service';
import {PortfolioService} from '../../services/portfolio.service';
import {ProjectService} from '../../../shared/services/project.service';

import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-portfolio-project',
  templateUrl: './portfolio-project.component.html',
  styleUrls: ['./portfolio-project.component.css']
})
export class PortfolioProjectComponent implements OnInit {
  

  @Input() selectedId
  orderList
  displayData

  projectList
  selectedProject

  constructor(private portfolioService: PortfolioService,
              private notification: NzNotificationService,
              private editService: PortfolioService,
              private tableSvc : TableService, 
              private projectService : ProjectService) { }

  ngOnInit() {
    this.projectService.getAll()
    .subscribe((data : any) => {
      this.projectList = data;
    });
    this.getData();
  }

  getData() {
     this.portfolioService.getProjects(this.selectedId)
     .subscribe((data : any) => {
         this.orderList = data;
         this.displayData = data;
     })
  }

  AddProject(id) {
    this.editService.addProject(this.selectedId, id)
    .subscribe((data : any) => {
      this.getData();
    });
  }

  Delete(item) {
      this.editService.deleteProject(item.id_portfolio, item.id_project)
      .subscribe((data : any) => {
          if (data.result === "Success") {
              this.notification.blank("Success", "deleted successfully");
           } else {
              this.notification.blank("Error", "error deleting");
           }
           this.getData();
      });
      
  }

  sort(sortAttribute: any) {
     this.orderList = this.tableSvc.sort(sortAttribute, this.orderList);
  }

}
