import { Injectable } from '@angular/core';
import { GenericalService } from './generical.service';
import {API_ENDPOINT} from '../../app.api';
import {User} from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class BusinessunitService {


  constructor(private gServ : GenericalService) { }

  get(filters) {
    let url = `${API_ENDPOINT}/bu`;
    return this.gServ.getFiltered(url, filters);
  }

  getAll() {
  	let url = `${API_ENDPOINT}/bu`;
  	return this.gServ.get(url);
  }

  getById(id) {
  	let url = `${API_ENDPOINT}/bu/${id}`;
  	return this.gServ.get(url);
  }
/*
  getBudgetCompleteness() {
  	let url = `${API_ENDPOINT}/businessunit/budget`;
  	return this.gServ.get(url);
  }
*/
  getAllResults(filters) {
  	let url = `${API_ENDPOINT}/bu/results`;
    //let url = `${API_ENDPOINT}/results`;
  	return this.gServ.getFiltered(url, filters);
  }
/*
  getResultsMoveBackwards(filters) {
    let url = `${API_ENDPOINT}/businessunit/resultsmovebackwards`;
    return this.gServ.getFiltered(url, filters);
  }

  getAnalysisHeadlines(filters) {
    let url = `${API_ENDPOINT}/businessunit/analisys_headlines`;
    return this.gServ.getFiltered(url, filters);
  }  

  getAllWIP(filters) {
  	let url = `${API_ENDPOINT}/`;
  	return this.gServ.getFiltered(url, filters);
  }
  
  getResults(id, filters) {
  	let url = `${API_ENDPOINT}/businessunit/fy`;
  	return this.gServ.getFiltered(url, filters);
  }

  getResultsOverTime(id, filters) {
  	let url = `${API_ENDPOINT}/businessunit/period`;
  	return this.gServ.getFiltered(url, filters);
  }

  getWIP(id, filters) {
  	let url = `${API_ENDPOINT}/`;
  	return this.gServ.getFiltered(url, filters);
  }

  getWIPOverTime(id, filters) {
  	let url = `${API_ENDPOINT}/businessunit/wip/period`;
  	return this.gServ.getFiltered(url, filters);
  }

  getStatistics(id, filters) {
  	let url = `${API_ENDPOINT}/businessunit/statistics`
  	return this.gServ.getFiltered(url, filters);
  }
*/  
  //######
  getTopProjects(id, type, filters) {
  	let url = `${API_ENDPOINT}/project/top/5/type/${type}`;
  	return this.gServ.getFiltered(url, filters);
  }

  insert(obj) {
  	let url = `${API_ENDPOINT}/businessunit`;
     return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
  	let url = `${API_ENDPOINT}/businessunit/${id}`;
  	return this.gServ.putclean(url, obj);
  }

  delete(id) {
  	 let url = `${API_ENDPOINT}/businessunit/${id}`;
  	 return this.gServ.delete(url);
  }

  
}
