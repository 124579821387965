import { Component, OnInit, OnDestroy } from '@angular/core';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { TableService } from '../../../shared/services/table.service';

import {ResultsService} from '../../../shared/services/results.service';
import {Subscription} from 'rxjs';

import {Filter} from '../../../shared/model/filter.model';
import {FilterService} from '../../../shared/services/filter.service';


@Component({
  selector: 'app-manager-results',
  templateUrl: './manager-results.component.html',
  styleUrls: ['./manager-results.component.css']
})
export class ManagerResultsComponent implements OnInit {


  Results : any;
  displayResults : any;
  myTable

  sub1 : Subscription;
  sub2: Subscription; 
 
  filters : Array<Filter> = [];

  themeColors = this.colorConfig.get().colors;
    blue = this.themeColors.blue;
    blueLight = this.themeColors.blueLight;
    cyan = this.themeColors.cyan;
    cyanLight = this.themeColors.cyanLight;
    gold = this.themeColors.gold;
    purple = this.themeColors.purple;
    purpleLight = this.themeColors.purpleLight;
    red = this.themeColors.red;
    transparent = this.themeColors.transparent


  csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: ',',
    showLabels: true,
    showTitle: true,
    //title: 'Your Holiday List :',
    useBom: true,
    noDownload: false,
    headers: ["Holiday ID", "Holiday Date", "Holiday Comment", "Holiday Status"]
  };


  constructor(//private tableSvc : TableService, 
              private filterService : FilterService,
              private colorConfig:ThemeConstantService,
              private tableSvc : TableService, 
  			      private resultsService : ResultsService,
              ) { }

  ngOnInit() {
      this.sub1 = this.filterService.data
      .subscribe ((filtros : any) => {
        this.filters = filtros;
        this.sub2 = this.resultsService.getResults(filtros, "manager")
          .subscribe((data : any) => {
             this.Results = data;
             this.displayResults = data;
             console.log(data)

             this.myTable = {
                 options : {
                   title : "Manager Results",
                 },
                 itemList : {
                    fields : [
                       
                       {key : "empname", label: "Manager", alias : "Manager Name", format: "", visible: false},
                       {key : "empshortname", label: "Manager", alias : "Manager Shortname", format: "", visible: true},
                       {key : "projectcount", label: "Project Count", alias : "Project Count", format: "number", visible: false},
                       {key : "netrevenue", label: "Net Revenue", alias : "Net Revenue", format: "currency", visible: true},
                       {key : "marginvalue", label: "Analytic Margin", alias : "Analytic Margin", format: "currency", visible: true},
                       {key : "salesmargin", label: "Sales Margin", alias : "Sales Margin", format: "percentage", visible: false},
                       {
                           key : "marginpercent", 
                           label: "PTD", 
                           alias : "PTD", 
                           format: "percentage", 
                           visible: true,
                           red : 0.40,
                           direction : "<"
                       },
                    ],
                    results : data.data
                },
                headers : {
                    fields : [
                       {key : "netrevenue", label : "Net Revenue", format: "currency"},
                       {key : "marginvalue", label : "Analytic Margin", format: "currency"},
                       {
                           key : "marginpercent", 
                           label : "PTD", 
                           format: "percentage",
                           red : 0.40,
                           direction : "<"
                       },
                    ],
                    results : [data.header]
                }
             }
         }) 
      }) 
  }

  DownloadResults() {
       let head = []
       for (var i = 0; i < this.Results.keys.length; ++i) {
         head.push(this.Results.keys[i]);
       }
       this.csvOptions.headers = head;
       new  AngularCsv(this.Results, "Project Results", this.csvOptions);
  }

  sort1(sortAttribute: any) {
        this.displayResults = this.tableSvc.sort(sortAttribute, this.displayResults);
        //console.log("sort has been finished")
  }

  ngOnDestroy() {
    if (this.sub1) {
      this.sub1.unsubscribe()
    }
    if (this.sub2) {
      this.sub2.unsubscribe()
    }
    
  }
}
