import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {Filter} from '../model/filter.model';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  
  //private currentFilterSubject: BehaviorSubject<Filter[]>;
  //public currentFilters: Observable<Filter[]>;
  private dataSource  = new BehaviorSubject<Filter[]>([]);
  public data = this.dataSource.asObservable();

  private filters : Array<Filter>;

  constructor() { 
  	this.clearFilters();
  }

  clearFilters() {
    this.filters = [
          {key: 'id_bu', value: 0},
          {key: 'id_line', value: 0},
          {key: 'period_start', value: 0},
          {key: 'period_end', value: 0},
          {key: 'id_customer', value: 0},
          {key: 'id_employee', value: 0},
          {key: 'entity', value: ""}
    ];

    this.dataSource.next(this.filters);
  }


  addFilter(obj : Filter) {
    
    let index = this.filters.findIndex(x => x.key == obj.key);
    if (index === -1) {
       this.filters.push(obj); 
    } else {
       this.filters[index].value = obj.value;
    }        

    this.dataSource.next(this.filters);
    console.log("filter service, this filters");
    console.log(this.filters)
  }

  addFilters(obj : Array<Filter>) {

    for (var i = 0; i < obj.length; ++i) {
      let index = this.filters.findIndex(x => x.key == obj[i].key);
      if (index === -1) {
         this.filters.push(obj[i]); 
      } else {
         this.filters[index].value = obj[i].value;
      } 
    }
    this.dataSource.next(this.filters);
  }

  getValueOf(key) {
    let index = this.filters.findIndex(x => x.key == key);
    if (index >= 0) {
       return this.filters[index].value;   
    } else {
       return null;
    }
    
  }

  

}
