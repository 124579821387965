import { Injectable } from '@angular/core';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(public datepipe: DatePipe,) { }


  // Para datas completas, sempre usar o tipo DATE no banco
  // nunca usar datetime


  // Complete dates
  StringToDate(o : String) : Date {

    if (o.length > 0) {
       let newYear = o.substr(0, 4)
       let newMonth = o.substr(5,2)
       let newDay = o.substr(8,2)
       //console.log(newYear + "-" + newMonth + "-" + newDay);
       return new Date(Number(newYear),Number(newMonth)-1, Number(newDay));  
    } else {
       return new Date();  
    }
  }

  DateToString(o : Date) : string {
    //console.log("date service, data recebidda")
    //console.log(o)
    let teste = this.datepipe.transform(o, 'yyyy-MM-dd');
    //console.log(": data trasnformada")
    //console.log(teste)
  	return teste
  }


  // datas incompletas
  PeriodToString(o) {
    console.log("date service PeriodToString(o), data recebidda")
    console.log(o)
    let teste = this.datepipe.transform(o, 'yyyyMM');
    console.log(": data trasnformada")
    console.log(teste)
    return teste
  }

  StringToPeriod(o : String) : Date {
    /*
    if (o.length > 0) {
       let newYear = o.substr(0, 4)
       //substr(0,3)
       let newMonth = o.substr(4,2)

       let valor = newMonth +'/01/' + newYear;

       console.log(valor)
       return new Date(valor);  
    } else {
       return new Date();  
    }*/
    return this.StringToDate(o)
  }

  FirstDayThisMonth() : Date {
    let today = new Date;
    //console.log("FirstDayThisMonth > today");
    //console.log(today)

    //console.log("FirstDayThisMonth > today (string)");
    let o = this.DateToString(today);
    //console.log(o)

   

    //console.log("FirstDayThisMonth > new year")
    let newYear = o.substr(0, 4)
    //console.log(newYear)

    //console.log("FirstDayThisMonth > newMonth")
    let newMonth = o.substr(5,2)
    //console.log(newMonth)


    let valor = newYear + '-' + newMonth + '-01';

    return this.StringToDate(valor);

  }

  LastDayThisMonth() : Date {
    let today = new Date;
    
    let lastDayOfMonth= new Date(today.getFullYear(), today.getMonth()+1, 0);
    let lastDayOfMonth_str = this.DateToString(lastDayOfMonth)
    let lastDayOfMonth_final = lastDayOfMonth_str.substr(8,2)
    console.log("lastDayOfMonth")
    console.log(lastDayOfMonth)

    console.log("lastDayOfMonth_str")
    console.log(lastDayOfMonth_str)
    console.log("teste lastDayOfMonth_final");
    console.log(lastDayOfMonth_final)
 

    let o = this.DateToString(today)
    let newYear = o.substr(0, 4)
    let newMonth = o.substr(5,2)
    let valor = newYear + '-' + newMonth + '-'+ lastDayOfMonth_final;

    //console.log("valor no servico de data");
    //console.log(valor)
    
    return this.StringToDate(valor);

  }



  
}
