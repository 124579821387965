import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';

import {ResultsService} from '../../../shared/services/results.service';
import {Subscription} from 'rxjs';

import {Filter} from '../../../shared/model/filter.model';
import {FilterService} from '../../../shared/services/filter.service';

@Component({
  selector: 'app-line-results',
  templateUrl: './line-results.component.html',
  styleUrls: ['./line-results.component.css']
})
export class LineResultsComponent implements OnInit {

  
   lineResults : any;
   summresults : any;

   sub1 : Subscription;
   sub2 : Subscription;
   sub3 : Subscription;
   sub4 : Subscription;

   
   viewResults = "results"
   //viewResults = "wip"
   

   @Input() myTabId : number;
   @Input() currentTab : number;
   @Output() lineSelected = new EventEmitter();
 
   filters : Array<Filter> = [];

   graphResults : Array<any> = [];

   myTable
   myTableWip
   
  
  constructor(private filterService : FilterService,
              private colorConfig:ThemeConstantService,
  			  private resultsService : ResultsService,
              private buService : ResultsService
              ) { }

  ngOnInit() {

      
        this.sub1 = this.filterService.data
        .subscribe ((filtros : any) => {
          //this.filters = filtros;
         
            
              if (this.viewResults == "results")  {
                  this.getResults(filtros)
              } else {
                this.getWip(filtros);
                
              }
                      
          
        })

      
      
  }

  getResults (filtros) {

    this.sub3 = this.resultsService.getResults(filtros, "line")
                .subscribe((data : any) => {
                   console.log(data)
                   
                   this.lineResults = data.data;
                   
                   this.myTable = {
                     itemList : {
                        fields : [
                           {key : "id_line", label: "ID", alias : "ID", format: "", visible: false},
                           {key : "name", label: "Line", alias : "Line", format: "", visible: false},
                           {key : "shortname", label: "Line", alias : "Line Shortname", format: "", visible: true},

                           {key : "netrevenue", label: "Net Revenue", alias : "Net Revenue", format: "currency", visible: true},
                           {key : "marginvalue", label: "Margin", alias : "Margin", format: "currency", visible: true},
                           {key : "empname", label: "Manager", alias : "Manager Name", format: "", visible: false},
                           {key : "salesmargin", label: "Sales Margin", alias : "Sales Margin", format: "percentage", visible: false},
                           {
                               key : "marginpercent", 
                               label: "PTD", 
                               alias : "PTD", 
                               format: "percentage", 
                               visible: true,
                               red : 0.40,
                               direction : "<"
                           },
                        ],
                        results : data.data,
                        buttons : [
                          {key: "manage", label: "", hint : "Manage", icon: "fa fa-search-plus"},
                        ]
                    },
                    headers : {
                        fields : [
                           {key : "netrevenue", label : "Net Revenue", format: "number"},
                           {key : "marginvalue", label : "Analytic Margin", format: "number"},
                           {
                               key : "salesmargin", 
                               label : "Sales Margin", 
                               format: "percentage",
                               red : 0.40,
                               direction : "<"
                           },{
                               key : "marginpercent", 
                               label : "PTD", 
                               format: "percentage",
                               red : 0.40,
                               direction : "<"
                           },
                        ],
                        results : [data.header]
                    }
                 } 

              })
  }

  getWip(filtros) {
    this.sub3 = this.resultsService.getWIP(filtros, "line")
      .subscribe((data : any) => {
         console.log("wip data")
         console.log(data)

         this.myTableWip = {
             itemList : {
                fields : [
                   {key : "id_line", label: "ID", alias : "ID", format: "", visible: false},
                   {key : "name", label: "Line", alias : "Line", format: "", visible: false},
                   {key : "shortname", label: "Line", alias : "Line Shortname", format: "", visible: true},

                   {key : "WIP30", label: "WIP30", alias : "WIP30", format: "currency", visible: true},
                   {key : "WIP60", label: "WIP60", alias : "WIP60", format: "currency", visible: true},
                   {key : "WIP90", label: "WIP90", alias : "WIP90", format: "currency", visible: false},
                   {
                       key : "WIP120", 
                       label: "WIP120", 
                       alias : "WIP120", 
                       format: "currency", 
                       visible: true,
                       red : 0,
                       direction : ">"
                   },
                   {key : "WIPTotal", label: "Total", alias : "Total", format: "currency", visible: true},
                   {key : "DSOvalue", label: "DSO", alias : "DSO", format: "number", visible: true},
                ],
                results : data.data,
                buttons : [
                  {key : "edit", label: "", hint: "Edit this project", icon: "fas fa-edit"}
                ]
            },
            headers : {
                fields : [
                   {key : "WIPTotal", label : "WIP Total", format: "currency"},
                   {
                       key : "WIP120", 
                       label : "WIP120", 
                       format: "currency",
                       red : 0,
                       direction : ">"
                   },
                ],
                results : [data.header]
            }
         }
     }) 
  }

  mostraEvento($event) {

  }

  onResultsViewChange($event) {
   
    this.ngOnInit();
  }

  goToProjects(lineId) {
    this.lineSelected.emit(lineId);
  }

   ngOnDestroy() {
    if (this.sub1) { this.sub1.unsubscribe() }
    if (this.sub2) { this.sub2.unsubscribe() }
    if (this.sub3) { this.sub3.unsubscribe() }
    if (this.sub4) { this.sub4.unsubscribe() }
  }


}
