import { Injectable } from '@angular/core';
import { GenericalService } from '../../shared/services/generical.service';
import {API_ENDPOINT} from '../../app.api';

@Injectable({
  providedIn: 'root'
})
export class SubprojectService {

  constructor(private gServ : GenericalService) { }

  getAll() {
  	let url = `${API_ENDPOINT}/subproject`;
  	return this.gServ.get(url);
  }

  getFiltered(filters) {
  	let url = `${API_ENDPOINT}/subproject/filtered`;
  	return this.gServ.postclean(url, filters);
  }

  getById(id) {
  	let url = `${API_ENDPOINT}/subproject/${id}`;
  	return this.gServ.get(url);
  }

  insert(obj) {
    let url = `${API_ENDPOINT}/subproject`;
     return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
    let url = `${API_ENDPOINT}/subproject/${id}`;
    return this.gServ.putclean(url, obj);
  }

  delete(id) {
     let url = `${API_ENDPOINT}/subproject/${id}`;
     return this.gServ.delete(url);
  }

  getComunidade(comunidade) {
    let url = `${API_ENDPOINT}/subproject/autocomplete/comunidade`;
    return this.gServ.postclean(url, {"comunidade" : comunidade});
  }

  getComunidades() {
    let url = `${API_ENDPOINT}/subproject/comunidade`;
    return this.gServ.get(url);
  }

  getRT(comunidade) {
    let url = `${API_ENDPOINT}/subproject/autocomplete/rt`;
    return this.gServ.postclean(url, {"rt" : comunidade});
  }


  getRTs() {
    let url = `${API_ENDPOINT}/subproject/rt`;
    return this.gServ.get(url);
  }
}
