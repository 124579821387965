import { Component, OnInit } from '@angular/core';
import {PipelineService} from '../../commercial/services/pipeline.service';



export class Squad {
  name : string;
  manager : string;
}


export class RT {
  name : string;
  squads : Array<Squad>;
}

export class Comunidade {
  comunidade : string;
  RTs : Array<RT>;
}

@Component({
  selector: 'app-controle-de-horas',
  templateUrl: './controle-de-horas.component.html',
  styleUrls: ['./controle-de-horas.component.css']
})
export class ControleDeHorasComponent implements OnInit {
  
  nzTabPosition = 0
  //toggleFilter = false;

  myTable
  opportunityFields = []
  myItauMap = []

  myRTs : Array<RT> = [];
  comunidadeSelecionada : string = "";

  RTSelecionada : string = "";
  mySquads : Array<Squad> = []

  uploadFields = [
      {name: 'comunidade', isKey: 'Yes',     type: 'string' , description: '',     example: 'Canais' },
      {name: 'RT', isKey: 'Yes', type: 'string' , description: '', example: 'AVI' },
      {name: 'squad', isKey: 'Yes',  type: 'string' , description: '', example: 'Pagina de ajuda' },
      {name: 'manager', isKey: 'Yes',  type: 'string' , description: '',   example: 'Elielson' },
      
  ]

  constructor(private oppMap : PipelineService,
  	          private oppMap2 : PipelineService, 
             ) { }


  ngOnInit() {
    /*
    console.log("opp map map")
  	this.oppMap2.getOpportunityMapMap()
  	.subscribe((data : Array<Comunidade>)=>{
      console.log("opp map map reuslts")
  		console.log(data)
      this.myItauMap = data;
  	});

  	this.oppMap.getOpportunityMap()
  	.subscribe((data : any)=>{
  		//console.log(data);

  		this.myTable = {
           itemList : {
              fields : [
                 {key : "comunidade", label: "comunidade", alias : "comunidade", format: "", visible: true},
                 {key : "RT", label: "RT", alias : "RT", format: "", visible: true},
                 {key : "squad", label: "squad", alias : "squad", format: "", visible: true},
                 {key : "manager", label: "manager", alias : "manager", format: "", visible: true},
              ],
              results : data,//.slice(0, data.length-1),
              //buttons : [
              //  {key : "edit", label: "", hint: "Edit", icon: "fas fa-edit"},
              //  {key : "delete", label: "", hint: "Delete", icon: "far fa-trash-alt"}
              //]
          },
       }  
  	})*/


    



  }

/*
  selectComunidade(comunidade) {
     //this.comunidadeSelecionada = comunidade;
      this.myRTs = null;
      this.mySquads = null;
      this.comunidadeSelecionada = comunidade;

      
      let teste : Array<Comunidade>;
      teste  = this.myItauMap.filter(function (pilot : Array<Comunidade>) {
                return pilot["comunidade"].toString().toLowerCase().includes(comunidade.toLowerCase());
              });
     this.myRTs = teste[0].RTs
     
  }

  selectRT(myRT) {
     this.mySquads = null;
     this.RTSelecionada = myRT;
     
     let teste : any;

     teste = this.myRTs.filter(function (pilot : RT) {
              return pilot["name"].toString().toLowerCase().includes(myRT.toLowerCase());
            });
     
     this.mySquads = teste[0].squads
    
  }
*/
}
