
export class Customer {
  id_customer : number;
  name : string;
  shortname : string;
  avatario : string;
  id_customer_group : number;
  isgroup : string;
  id_customer_sector : number;
  customersectorname : string;
  id_organization : number;
  status : string;
}

export class CustomerDisplay {
  id_customer : number;
  name : string;
  shortname : string;
  avatario : string;
  id_customer_group : number;
  isgroup : string;
  id_customer_sector : number;
  customersectorname : string;
  id_organization : number;
  status : string;
	checked : boolean = false;
 }
