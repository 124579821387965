import { Injectable } from '@angular/core';
import { GenericalService } from './generical.service';
import {API_ENDPOINT} from '../../app.api';
import {User} from '../model/user.model';


@Injectable({
  providedIn: 'root'
})
export class LineService {

  constructor(private gServ : GenericalService) { }

  get(filtros) {
    let url = `${API_ENDPOINT}/line`;
    return this.gServ.getFiltered(url, filtros);
  }

  getAll() {
  	let url = `${API_ENDPOINT}/line`;
  	return this.gServ.get(url);
  }

  getById(id) {
    let url = `${API_ENDPOINT}/line/${id}`;
    return this.gServ.get(url);
  }

  getFilteredByBu(bu) {
    let url = `${API_ENDPOINT}/line?id_bu=${bu}`;
    return this.gServ.get(url);
  }


  // PRE-FECHAMENTO
  getProjectEstimation(filters) {
    //let url = `${API_ENDPOINT}/line/${id}/project/results`;
    let url = `${API_ENDPOINT}/line/project/estimation`;
    return this.gServ.getFiltered(url, filters);
  }

  insert(obj) {
  	let url = `${API_ENDPOINT}/line`;
     return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
  	let url = `${API_ENDPOINT}/line/${id}`;
  	return this.gServ.putclean(url, obj);
  }

  delete(id) {
  	 let url = `${API_ENDPOINT}/line/${id}`;
  	 return this.gServ.delete(url);
  }

  
}
