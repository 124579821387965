import { Injectable } from '@angular/core';
import { GenericalService } from './generical.service';
import {API_ENDPOINT} from '../../app.api';
import {User} from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class ResultsService {


  constructor(private gServ : GenericalService) { }

  getResults(filters, entity) {
    let url = ""
    url = `${API_ENDPOINT}/results`;   
    return this.gServ.getFilteredWithEntity(url, filters, entity);
  }

  getResultsMonthly(filters, entity) {
    let url = `${API_ENDPOINT}/results/monthly`;
    return this.gServ.getFilteredWithEntity(url, filters, entity);
  }


  getPeriods() {
     let url = `${API_ENDPOINT}/periods`;
     return this.gServ.get(url);  
  }

  getMarginBackwards(filters, entity) {
    let url = `${API_ENDPOINT}/results/margin/backwards`;
    return this.gServ.getFilteredWithEntity(url, filters, entity);
  }



  

  getResultsPerCustomerAndLine(filters) {
    let url = `${API_ENDPOINT}/results/linecustomer`;  
    return this.gServ.getFiltered(url, filters);
  }

  getGini(filters, entity) {
    let url = `${API_ENDPOINT}/results/gini`;   
    return this.gServ.getFilteredWithEntity(url, filters, entity);
  }
/*
  getResultsFY(filters, type, entity) {
    //console.log("qual entity chegou aqui")
    //console.log(entity)
    //console.log("filters")
    //console.log(filters)
    let url = ""
    url = `${API_ENDPOINT}/results/fy`;   
    return this.gServ.getFilteredWithEntity(url, filters, entity);
  }
*/
  getResultsBackwards(filters, entity) {
    let url = `${API_ENDPOINT}/results/backwards`;
    return this.gServ.getFilteredWithEntity(url, filters, entity);
  }

  getWIP(filters, entity) {
    let url = `${API_ENDPOINT}/results/wip`;   
    return this.gServ.getFilteredWithEntity(url, filters, entity);
  }

  getBubbleChart(filters) {
    let url = `${API_ENDPOINT}/results/bubblechart`;
    return this.gServ.getFiltered(url, filters);  
  }

  getResultsByPeriod(filters, entity) {
    let url = `${API_ENDPOINT}/results/period`;
    return this.gServ.getFilteredWithEntity(url, filters, entity);
  }

  getWIPByPeriod(filters, entity) {
    let url = `${API_ENDPOINT}/results/wip/period`;
    return this.gServ.getFilteredWithEntity(url, filters, entity);
  }

}
