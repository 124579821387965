import { Component, OnInit, Input , ChangeDetectionStrategy} from '@angular/core';
import {API_ENDPOINT} from '../../../app.api';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-upload-area',
  templateUrl: './upload-area.component.html',
  styleUrls: ['./upload-area.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadAreaComponent implements OnInit {
  
  @Input() uploadURL : string;
  @Input() uploadFields : Array<any>;


  /*fields = [
    {name: 'id_customer', isKey: 'Yes', type: 'numeric' , description: 'SAP number', example: '11198' },
    {name: 'name', isKey: 'No', type: 'string' , description: 'Customer name', example: 'Itau S/A' },
    {name: 'shortname', isKey: 'No', type: 'string' , description: '15 characters name', example: 'Itau' },
  ]*/

  uploadCSV_Endpoint

  constructor(private notification: NzNotificationService) { }

  ngOnInit() {
    this.uploadCSV_Endpoint = `${API_ENDPOINT}` + "/" + this.uploadURL;

    //console.log("[uploadArea] endpoint : " + this.uploadURL);
    //console.log("[uploadArea] fields: ");
    //console.log(this.uploadFields);
  }

  handleUpload({ file, fileList }): void {
        //console.log("handleUpload")
        const status = file.status;
        if (status !== 'uploading') {

            console.log(file, fileList);
            console.log(file);

        }
        
        if (status === 'done') {
            this.notification.success("Success", `${file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            this.notification.error("Error", `${file.name} file upload failed.`);
        }
    }

}
