import { Component, OnInit } from '@angular/core';
import {GenericalService} from '../../services/generical.service';
import {cadastros} from './cadastros.model';

@Component({
  selector: 'app-cadastros-auxiliares',
  templateUrl: './cadastros-auxiliares.component.html',
  styleUrls: ['./cadastros-auxiliares.component.css']
})
export class CadastrosAuxiliaresComponent implements OnInit {
  nzTabPosition = 0;
  _bu = cadastros.bu;
  _line = cadastros.line;
  _offering = cadastros.offering;
  _customer_sector = cadastros.customer_sector;
  
  // portfolio
  _period_hours = cadastros.period_hours;
  _subproject_phase = cadastros.subproject_phase;
  _employee_category = cadastros.employee_category;

  constructor() { }

  ngOnInit() {
  }

}
