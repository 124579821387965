import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Filter} from '../../shared/model/filter.model';
import {PortfolioService} from '../services/portfolio.service';
import { NzModalService } from 'ng-zorro-antd';
import {NewPortfolioComponent} from './new-portfolio/new-portfolio.component';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { TableService } from '../../shared/services/table.service';

@Component({
  selector: 'app-portfolio-setup',
  templateUrl: './portfolio-setup.component.html',
  styleUrls: ['./portfolio-setup.component.css']
})
export class PortfolioSetupComponent implements OnInit {
  
  ordersList
  displayData
  filters : Array<Filter> = []

  filterToggle : boolean;

  constructor(private portfolioService: PortfolioService,
              private router : Router,
              private tableSvc : TableService,
              private modalService: NzModalService, ) { }

  ngOnInit() {
  	this.getData();
  }

  getData() {
        this.portfolioService.getFiltered(this.filters)
        .subscribe((data : any) => {
          this.ordersList = data;
          this.displayData = data; 
          console.log(data)          
      })
   }

  onNameChange(id) {
      this.addFilter({key: 'name', value: id.target.value});
      this.getData();        
  }

  addFilter(obj : Filter) {
  
      let index = this.filters.findIndex(x => x.key == obj.key);
      if (index === -1) {
         this.filters.push(obj); 
      } else {
         this.filters[index].value = obj.value;
      }        
  }

  ManageProject(id) {
      let str : String = `/portfolio/${id}`
      this.router.navigate([str]);
  }

  toggleFilter() {
    this.filterToggle = ! this.filterToggle;
  }

  newTransaction(id) {      

     let title
     if (id != 0) {
        title = "Edit Portfolio"
     } else {
       title = "New Portfolio"
     }

    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: NewPortfolioComponent,
      nzComponentParams: {
        portfolioId : id,
      },
    });
    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => this.getData());

  }


  PageDataChange($event : Array<any>) : void {
      this.displayData = $event;
  }

  sort(sortAttribute: any) {
    this.ordersList = this.tableSvc.sort(sortAttribute, this.ordersList);
  }

  getFile() {
     new  AngularCsv(this.ordersList, "SubProject List", this.csvOptions);
  }

  csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: ',',
      showLabels: true,
      showTitle: true,
      //title: 'Your Holiday List :',
      useBom: true,
      noDownload: false,
      headers: ["Subproject ID", "Subproject Name", "Subproject Shortname", "Employee Id", "Employee Name", "Offering Id", "Offering Name", "Subproject Status", "Phase", "Start Date", "End Date", "Transactions Count"]
  };

}
