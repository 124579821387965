import { Component, OnInit, Input } from '@angular/core';
import {EmployeeService} from '../../../../shared/services/employee.service';
import { NzModalRef } from 'ng-zorro-antd';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import {Filter} from '../../../../shared/model/filter.model';
import {EmployeecsrService} from '../../../services/employeecsr.service';



export class CSR {
  id_employee : number;
  m1_label : number;
  m2_label : number;
  m3_label : number;
  m1_value : number;
  m2_value : number;
  m3_value : number;

 }

 export class ValoresCSR {
  
  M1 : number;
  M2 : number;
  M3 : number;

 }

 




@Component({
  selector: 'app-employee-csr-fast',
  templateUrl: './employee-csr-fast.component.html',
  styleUrls: ['./employee-csr-fast.component.css']
})
export class EmployeeCsrFastComponent implements OnInit {
  
  formData
  @Input() employeeId

  employeeList
  periodList

  m1_label = ""
  m2_label = ""
  m3_label = ""

  estaInserindo : boolean = false;
  mostrarTextBoxes : boolean = false;


  salvando : boolean = false;

  filters : Array<Filter> = []


  constructor(private fb: FormBuilder,
  			      private notification: NzNotificationService,
              private modal: NzModalRef,
              private listService : EmployeeService,
              private editService : EmployeecsrService,
              private employeeService : EmployeeService,
              private csrService : EmployeecsrService,
              private csrValuesService : EmployeecsrService,) { 

  		this.formData = this.fb.group({
          id_employee : [null, [Validators.required]],
          m1_value : [null, [Validators.required]],
          m2_value : [null, [Validators.required]],
          m3_value : [null, [Validators.required]],
      });

  }

  ngOnInit() {

      this.csrService.getPeriods() 
        .subscribe((data : any) => {
           this.periodList = data;
           this.m1_label = data[0].id_promotion_period
           this.m2_label = data[1].id_promotion_period
           this.m3_label = data[2].id_promotion_period

      });

  		this.formData.controls['id_employee'].setValue(this.employeeId);
	  	//listar a galera
	  	this.listService.getFiltered(this.filters)
	      .subscribe((data : any) => {
	         this.employeeList = data;
	    });  	 
  }

  onEmployeeChange(id) {

     this.addFilter({"key": "M1", "value" : this.m1_label})
     this.addFilter({"key": "M2", "value" : this.m2_label})
     this.addFilter({"key": "M3", "value" : this.m3_label})
     let vetor = [this.m1_label, this.m2_label, this.m3_label]
     this.addFilter({"key": "id_employee", "value" : id})

     this.csrValuesService.getCSRValues(this.filters)
       .subscribe((data : Array<ValoresCSR>) => {
              if (data) {
                 this.formData.controls["m1_value"].setValue(data[0].M1);
                 this.formData.controls["m2_value"].setValue(data[0].M2);
                 this.formData.controls["m3_value"].setValue(data[0].M3);
                 this.estaInserindo = false;  
              } else {
                this.estaInserindo = true;
              }
              this.mostrarTextBoxes = true;
       })

     
  }

  destroyModal(): void {
    this.modal.destroy({ data: 'this the result data' });
  }

  addFilter(obj : Filter) {
    
    let index = this.filters.findIndex(x => x.key == obj.key);
    if (index === -1) {
       this.filters.push(obj); 
    } else {
       this.filters[index].value = obj.value;
    }        
  }

  SubmitForm() {

    
    if (this.formData.invalid) {
        this.notification.blank("Error", "Fill required data before saving");
        console.log(this.formData.invalid)
        console.log(this.formData)
        return;
    }else {
        this.salvando = true;
    }

    let obj = new CSR;

    // ###### tem erro no id_employee
    obj.id_employee = this.formData.controls['id_employee'].value;

    obj.m1_label = Number(this.m1_label);
    obj.m2_label = Number(this.m2_label);
    obj.m3_label = Number(this.m3_label);
  
    obj.m1_value = this.formData.controls['m1_value'].value;
    obj.m2_value = this.formData.controls['m2_value'].value;
    obj.m3_value = this.formData.controls['m3_value'].value;
    



    if (this.estaInserindo) {
         this.editService.insert(this.formData.controls['id_employee'].value, obj)
         .subscribe((data : any) => {
           if (data.result === "Success") {
              this.notification.blank("Success", "inserted successfully");
           } else {
              this.notification.blank("Error", "error inserting");
           }
           this.salvando = false;
           this.destroyModal();
         });

    // se estiver fazendo update        
    } else {
        this.editService.update(this.formData.controls['id_employee'].value, obj)
        .subscribe((data : any) => {
          if (data.result === "Success") {
              this.notification.blank("Success", "updated successfully");
           } else {
              this.notification.blank("Error", "error updating");
           }
           this.salvando = false;
           this.destroyModal();
        });
    } //this.SendNotification(
  }

}
