import { Component, OnInit, OnDestroy } from '@angular/core';
import { Filter } from '../../../shared/model/filter.model';
import { SubprojectResultsService } from '../../services/subproject-results.service';
import { PortfolioFilterService } from '../../services/portfolio-filter.service';
import { TableService } from '../../../shared/services/table.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nonbillable-list',
  templateUrl: './nonbillable-list.component.html',
  styleUrls: ['./nonbillable-list.component.css']
})
export class NonbillableListComponent implements OnInit {

  ordersList
  displayData
  ordersListCount: number = null;
  filters: Array<Filter> = []

  subs: Array<Subscription> = [];

  selectedProject
  selectedPortfolio

  view = "grouped"

  csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: ',',
    showLabels: true,
    showTitle: true,
    title: 'SubProject Results',
    useBom: true,
    noDownload: false,
    headers: ["Offering Id", "Offering Name", "Employees", "Gross Revenue", "Net Revenue", "CSR", "EXP", "Margin", "Margin %"]
  };

  constructor(private tableSvc: TableService,
    private listService: SubprojectResultsService,
    private summService: SubprojectResultsService,
    private filterService: PortfolioFilterService,) { }

  ngOnInit() {

    this.subs.push(this.filterService.data
      .subscribe((filtros: any) => {
        this.filters = filtros;
        this.testProject();
      }));

  }

  testProject() {
    this.selectedProject = this.filterService.getValueOf("id_project");
    this.selectedPortfolio = this.filterService.getValueOf("id_portfolio");

    if (this.selectedProject) {
      //console.log("offering resutls -  rolou por portfolio")
      this.getListData();

    } else if (this.selectedPortfolio) {
      this.getListData();
      //console.log("offering resutls - nao rolou por portfolio")
    }
  }

  myTable

  bubblechart


  _chartData: Array<any> = [];
  _chartSeries: Array<string> = [];

  getListData() {
    this.subs.push(this.listService.getNonBillable(this.filters)
      .subscribe((data: any) => {
        console.log(data);

        this.ordersList = data;

        this.myTable = {
          itemList: {
            fields: [
              { key: "sector", label: "Sector", alias: "Sector", format: "", visible: false },
              { key: "customer", label: "Customer", alias: "Customer", format: "", visible: true },
              { key: "id_project", label: "Id Project", alias: "Id Project", format: "", visible: false },
              { key: "projectname", label: "Project", alias: "Project", format: "", visible: false },
              { key: "id_employee", label: "Id Employee", alias: "Id Employee", format: "", visible: false },
              { key: "sapcode", label: "sapcode", alias: "sapcode", format: "", visible: false },
              { key: "name", label: "Name", alias: "Name", format: "", visible: true },
              { key: "empcatname", label: "Level", alias: "Level", format: "", visible: true },
              { key: "linname", label: "Line", alias: "Line", format: "", visible: true },
              { key: "catname", label: "Category", alias: "Category", format: "", visible: true },
              { key: "rate", label: "Rate", alias: "Rate", format: "currency", visible: true },
              { key: "quantity", label: "Quantity", alias: "Quantity", format: "", visible: true },
              { key: "total", label: "Total", alias: "Total", format: "currency", visible: true },
            ],
            results: this.ordersList.lines,
          },
          headers: {
            fields: [
              { key: "rate", label: "AVG Rate", format: "currency" },
              { key: "quantity", label: "Quantity", format: "currency" },
              { key: "total", label: "Total Cost", format: "currency" },
            ],
            results: this.ordersList.total
          }

        }
      }));

   
  }

  getFile() {
    new AngularCsv(this.displayData, "NonBillable Results", this.csvOptions);
  }

  onStatusChange($event) {
    this.filterService.addFilter({ key: "grouped", value: $event })
    this.getListData();
  }

  sort(sortAttribute: any) {
    this.displayData = this.tableSvc.sort(sortAttribute, this.displayData);
  }

  ngOnDestroy() {
    this.subs.map(s => s.unsubscribe);
  }


}
