import { Injectable } from '@angular/core';
import { GenericalService } from './generical.service';
import {API_ENDPOINT} from '../../app.api';
import {User} from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private gServ : GenericalService) { }

  
  getAll() {
  	let url = `${API_ENDPOINT}/project/filtered`;
    return this.gServ.postclean(url, []);
  }
  

  getFiltered(filters) {
    let url = `${API_ENDPOINT}/project/filtered`;
    return this.gServ.postclean(url, filters);
  }

  get(filters) {
    let url = `${API_ENDPOINT}/project`;
    return this.gServ.getFiltered(url, filters);
  }


/*
  getAllWithSubProjects(status) {
    let url = ""
    if (status == "A") {
       url = `${API_ENDPOINT}/project?subprojects=S&subprojects_status=${status}`;
    } else if (status == "Portfolio") {
      url = `${API_ENDPOINT}/project?subprojects=S&portfolio=S&subprojects_status=${status}`;      
    } else {
      url = `${API_ENDPOINT}/project?subprojects=S`;
    }
    return this.gServ.get(url);
  }
*/
  getById(id) {
     let retorno : any;
     let url = `${API_ENDPOINT}/project/${id}`;
     return this.gServ.get(url);
  }
/*
  getResults(filter) {
     let retorno : any;
     let url = `${API_ENDPOINT}/project/results`;
     return this.gServ.getFiltered(url, filter);
  }
*/
  getByName(name) {
     let retorno : any;
     let url = `${API_ENDPOINT}/project/${name}`;
     return this.gServ.get(url);
  }


/*
  getWIP(filters) {
    let url = `${API_ENDPOINT}/project/wip`
    return this.gServ.getFiltered(url, filters);
  }

  getFiltered(filters) {
    let url = `${API_ENDPOINT}/project`
    return this.gServ.getFiltered(url, filters);
  }*/

  insert(obj) {
    let url = `${API_ENDPOINT}/project`;
    return this.gServ.postclean(url, obj);
  }

  update(id, obj) {
    let url = `${API_ENDPOINT}/project/${id}`;
    return this.gServ.putclean(url, obj);
  }

  delete(id) {
     let url = `${API_ENDPOINT}/project/${id}`;
     return this.gServ.delete(url);
  }

  getPortolios() {
     let retorno : any;
     let url = `${API_ENDPOINT}/portfolio/employee`;
     return this.gServ.get(url);
  }

}
