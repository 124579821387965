
export class SubContract {
  id_subproject_contract : number;
  id_subproject : number;
  name : string;
  subname : string;
  comment : string;

  startdate : string;
  enddate : string;

  tax : number;
  quantity : number;
  status : string;  

 }
